import React from "react";
import theme from "../theme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

const ThemeProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): React.ReactElement => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
