import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupplierListStateType } from "./types";
import { useActions } from "../../common/hooks/useActions";
import { globalResetAction } from "../../common/redux/actions";

export const supplierListInitialState: SupplierListStateType = {
  tableState: {
    page: 1,
    pageSize: 10,
  },
  openModal: false,
};

const SupplierListSlice = createSlice({
  name: "supplierListState",
  initialState: supplierListInitialState,
  reducers: {
    setTableState: (
      state,
      { payload }: PayloadAction<SupplierListStateType["tableState"]>
    ) => {
      state.tableState = payload;
    },
    setOpenModal: (
      state,
      { payload }: PayloadAction<SupplierListStateType["openModal"]>
    ) => {
      state.openModal = payload;
    },
    setSupplier: (
      state,
      { payload }: PayloadAction<SupplierListStateType["supplier"]>
    ) => {
      state.supplier = payload;
    },
    setState: (state, { payload }: PayloadAction<SupplierListStateType>) => {
      state = payload;
      return state;
    },
    closeModal: (state) => {
      state.openModal = false;
      delete state.supplier;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => supplierListInitialState);
  },
});

export const useSupplierListActions = (): typeof SupplierListSlice.actions =>
  useActions(SupplierListSlice.actions);

export default SupplierListSlice;
