const faqBaseUrl = "https://solusi-help.gudangada.com";

export const externalUrls = {
  // FAQ
  FAQ: faqBaseUrl,

  POS: `${faqBaseUrl}/bantuan-tutorial/kasir_1/cara-tambah-barang-ke-keranjang-kasir`,
  INVENTORY_FAQ: `${faqBaseUrl}/bantuan-tutorial/inventori`,
  GOOD_STOCK_BAD_STOCK: `${faqBaseUrl}/bantuan-tutorial/inventori/cara-ubah-status-barang-dari-good-stock-ke-bad-stock`,
  ADD_INVENTORY_FAQ: `${faqBaseUrl}/bantuan-tutorial/inventori/cara-tambah-inventori-pertama-kali`,
  INVENTORY_BULK_ADD_BARCODE_FAQ: `${faqBaseUrl}/
  bantuan-tutorial/akun-pengaturan/cara-menghubungkan-gudangada-solusi-dengan-barcode-scanner`,
  ADD_ITEMS_TO_CART_FAQ: `${faqBaseUrl}/bantuan-tutorial/langkah-pertama/cara-tambah-barang-ke-keranjang-kasir`,
  BULK_UPLOAD_FAQ: `${faqBaseUrl}/bantuan-tutorial/daftar-barang/cara-tambah-barang-sekaligus-bulk`,
  STOK_OPNAME_HELP_FAQ: `${faqBaseUrl}/bantuan-tutorial/daftar-barang/cara-stok-opname-barang`,
  PROFIT_LOSS_FAQ: `${faqBaseUrl}/bantuan-tutorial/laporan/kenapa-perhitungan-untungrugi-saya-tidak-akurat`,
  EXPENSE_FAQ: `${faqBaseUrl}/bantuan-tutorial/laporan/pengeluaran-yang-dimasukkan-ke-dalam-catatan-pengeluaran`,
  PRINTER_FAQ: `${faqBaseUrl}/bantuan-tutorial/langkah-pertama/cara-menghubungkan-gudangada-solusi-dengan-printer`,
  BARCODE_SCANNER_FAQ: `${faqBaseUrl}/bantuan-tutorial/langkah-pertama/cara-menghubungkan-gudangada-solusi-dengan-barcode-scanner`,
  DEBT_RECEIVABLE_FAQ: `${faqBaseUrl}/bantuan-tutorial/laporan/perhitungan-laporan-utang-konsinyasi`,
  CUSTOMER_DEBT_FAQ: `${faqBaseUrl}/bantuan-tutorial/akun-pengaturan/cara-atur-kebijakan-piutang-pelanggan`,
  CONTACT_US: `${faqBaseUrl}/bantuan-tutorial/hubungi-kami`,
  WHATSAPP: "https://wa.me/628196009999",
  TERMS_CONDITIONS: "https://solusi.gudangada.com/terms-and-conditions",
  PRODUCT_IMAGE_PLACEHOLDER: "https://via.placeholder.com/60?text=Mock+Image",
};
