import { RequestObj } from "./types";
import router, { responseRouter } from "./router";
import "./routes";
import "./routes/response-interceptor";

export async function interceptOfflineRequest(req: RequestObj<any, any, any>) {
  if (process.env.NODE_ENV !== "production") {
    console.group("Request Interceptor");
    console.log(
      "%c Intercepting API Request with this request object",
      "background-color: blue; color: white; font-size: 14px"
    );
    console.log("request: %o", req);
    console.groupEnd();
  }
  return await router.listen(req);
}

export async function interceptOfflineResponse(
  req: RequestObj<any, any, any>,
  resp: any
) {
  if (process.env.NODE_ENV !== "production") {
    console.group("Response Interceptor");
    console.log(
      "%c Intercepting API Response with this request object and response object",
      "background-color: green; color: white; font-size: 14px"
    );
    console.log("request: %o", req);
    console.log("response: %o", resp);
    console.groupEnd();
  }
  return await responseRouter.listen(req, resp);
}
