import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortType } from "../..";
import { useActions } from "../../common/hooks/useActions";
import { globalResetAction } from "../../common/redux/actions";
import { ProfitReportSortBy } from "./models";
import { ProfitPerProductState } from "./types";

const initialState: ProfitPerProductState = {
  listState: {
    pageSize: 10,
    page: 1,
    sortBy: null,
    sortType: null,
    productVariantName: undefined,
  },
  drawerState: {
    open: false,
    item: undefined,
  },
};

const ProfitPerProductSlice = createSlice({
  initialState,
  name: "profitPerProduct",
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.listState.page = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      state.listState.productVariantName = action.payload;
      state.listState.page = 1;
    },
    setItem: (
      state,
      action: PayloadAction<ProfitPerProductState["drawerState"]["item"]>
    ) => {
      state.drawerState.item = action.payload;
    },
    setOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.drawerState.open = action.payload;
    },
    setSortBy: (state, action: PayloadAction<ProfitReportSortBy>) => {
      state.listState.sortBy = action.payload;
    },
    setSortType: (state, action: PayloadAction<SortType>) => {
      state.listState.sortType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const useProfitPerProductActions = (): typeof ProfitPerProductSlice.actions =>
  useActions(ProfitPerProductSlice.actions);

export default ProfitPerProductSlice;
