import React from "react";
import { IconProps } from "./types";

const DiscountIcon: React.VFC<IconProps> = ({
  fill = "#F3F3F3",
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 15L15 9"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 10C9.77614 10 10 9.77614 10 9.5C10 9.22386 9.77614 9 9.5 9C9.22386 9 9 9.22386 9 9.5C9 9.77614 9.22386 10 9.5 10Z"
        fill={fill}
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 15C14.7761 15 15 14.7761 15 14.5C15 14.2239 14.7761 14 14.5 14C14.2239 14 14 14.2239 14 14.5C14 14.7761 14.2239 15 14.5 15Z"
        fill={fill}
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99997 7.2C4.99997 6.61653 5.23176 6.05695 5.64434 5.64437C6.05692 5.23179 6.6165 5 7.19997 5H8.19997C8.78087 4.99967 9.33806 4.76961 9.74997 4.36L10.45 3.66C10.6544 3.4544 10.8975 3.29125 11.1652 3.17991C11.4329 3.06858 11.72 3.01126 12.01 3.01126C12.2999 3.01126 12.587 3.06858 12.8547 3.17991C13.1225 3.29125 13.3655 3.4544 13.57 3.66L14.27 4.36C14.6819 4.76961 15.2391 4.99967 15.82 5H16.82C17.4034 5 17.963 5.23179 18.3756 5.64437C18.7882 6.05695 19.02 6.61653 19.02 7.2V8.2C19.0203 8.7809 19.2504 9.33809 19.66 9.75L20.36 10.45C20.5656 10.6545 20.7287 10.8975 20.8401 11.1652C20.9514 11.433 21.0087 11.7201 21.0087 12.01C21.0087 12.3 20.9514 12.587 20.8401 12.8548C20.7287 13.1225 20.5656 13.3656 20.36 13.57L19.66 14.27C19.2504 14.6819 19.0203 15.2391 19.02 15.82V16.82C19.02 17.4035 18.7882 17.9631 18.3756 18.3756C17.963 18.7882 17.4034 19.02 16.82 19.02H15.82C15.2391 19.0203 14.6819 19.2504 14.27 19.66L13.57 20.36C13.3655 20.5656 13.1225 20.7288 12.8547 20.8401C12.587 20.9514 12.2999 21.0087 12.01 21.0087C11.72 21.0087 11.4329 20.9514 11.1652 20.8401C10.8975 20.7288 10.6544 20.5656 10.45 20.36L9.74997 19.66C9.33806 19.2504 8.78087 19.0203 8.19997 19.02H7.19997C6.6165 19.02 6.05692 18.7882 5.64434 18.3756C5.23176 17.9631 4.99997 17.4035 4.99997 16.82V15.82C4.99964 15.2391 4.76957 14.6819 4.35997 14.27L3.65997 13.57C3.45437 13.3656 3.29121 13.1225 3.17988 12.8548C3.06855 12.587 3.01123 12.3 3.01123 12.01C3.01123 11.7201 3.06855 11.433 3.17988 11.1652C3.29121 10.8975 3.45437 10.6545 3.65997 10.45L4.35997 9.75C4.76957 9.33809 4.99964 8.7809 4.99997 8.2V7.2"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DiscountIcon;
