import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { HandleBadStockUIInterface } from "./types";
import { HandleBadStockAction } from "./models";

const initialHandleBadStockUIState: HandleBadStockUIInterface = {
  handleType: null,
  isErrorSubmit: false,
  isSubmitting: false,
  totalCost: "0",
};

export const HandleBadStockUIStateSlice = createSlice({
  name: "handleBadStock",
  initialState: initialHandleBadStockUIState,
  reducers: {
    resetState: () => {
      return initialHandleBadStockUIState;
    },
    setHandleType: (state, action: PayloadAction<HandleBadStockAction>) => {
      state.handleType = action.payload;
    },
    setTotalCost: (state, action: PayloadAction<string>) => {
      state.totalCost = action.payload;
    },

    setIsErrorSubmit: (state, action: PayloadAction<boolean>) => {
      state.isErrorSubmit = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
  },
});

const { actions } = HandleBadStockUIStateSlice;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useHandleBadStockUIStateActions = () => useActions(actions);

export default HandleBadStockUIStateSlice.reducer;
