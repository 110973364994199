import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const LoudSpeaker: React.VFC<IconProps> = ({
  width = "24",
  height = "24",
  fill = theme.palette.interface.red.dark,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 8H4C3.46957 8 2.96086 8.21071 2.58579 8.58579C2.21071 8.96086 2 9.46957 2 10V14C2 14.5304 2.21071 15.0391 2.58579 15.4142C2.96086 15.7893 3.46957 16 4 16H5V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H8C8.26522 21 8.51957 20.8946 8.70711 20.7071C8.89464 20.5196 9 20.2652 9 20V16H12L17 20V4L12 8ZM15 15.6L13 14H4V10H13L15 8.4V15.6ZM21.5 12C21.5 13.71 20.54 15.26 19 16V8C20.53 8.75 21.5 10.3 21.5 12Z"
        fill={fill}
      />
    </svg>
  );
};

export default LoudSpeaker;
