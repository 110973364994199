import { DeliveryOrderListState } from "..";
import { GlobalReportAPIRequest } from "../..";
import { iApiResponse, iListApiResponse } from "../../../common/models";

export enum DeliveryStatus {
  FULLY_DELIVERED = "FULLY_DELIVERED",
  PARTIALLY_DELIVERED = "PARTIALLY_DELIVERED",
  UNDELIVERED = "UNDELIVERED",
}

export enum DeliveryOrderErrorCode {
  INVALID_DELIVERY_QUANTITY = "INVALID_DELIVERY_QUANTITY",
  INVALID_DELIVERY_STATUS = "INVALID_DELIVERY_STATUS",
  INVALID_DELIVERY_TIME_EXCEEDED = "INVALID_DELIVERY_TIME_EXCEEDED",
}

export interface iDeliveryFeasibilityAPIRequest {
  orderId: string[];
}

export type iDeliveryFeasibilityAPIResponse = iApiResponse<
  iDeliveryFeasibility[]
>;

export interface iDeliveryFeasibility {
  orderId: string;
  orderCode: string;
  customerId: string;
  customerName: string;
  salesPersonId: string;
  salesPersonName: string;
  createdAt: string;
  deliveryStatus: DeliveryStatus;
  latestPaymentId: string;
  orderItemList: iOrderItem[];
}

export interface iOrderItem {
  id: string;
  inventoryId: number;
  productVariantId: string;
  productVariantName: string;
  uomId: string;
  uomName: string;
  quantity: number;
  deliveryQuantity?: number;
  totalDeliveredQuantity: number; // sum of all delivered_quantity for all RPB where order_item_id
  availableDeliveryQuantity: number; // quantity - total_delivered
}

export interface iGetDeliveryOrderAPIRequest {
  deliveryId: string;
}

export type iDeliveryOrderAPIResponse = iApiResponse<iDeliveryOrderResponse>;

export interface iDeliveryOrderResponse {
  storeId: string;
  deliveryId: string;
  deliveryCode: string;
  warehousePerson?: iWarehousePerson;
  courier?: iCourierPerson;
  deliveryOrderList: iDeliveryOrder[];
  partiallyDeliveredCount?: iPartiallyDeliveredCount;
}

export interface iCreateDeliveryOrderAPIRequest {
  storeId: string;
  courierId?: string;
  warehousePersonId?: string;
  orderList: iDeliveryOrderListRequest[];
}

export interface iUpdateDeliveryOrderAPIRequest
  extends iCreateDeliveryOrderAPIRequest {
  deliveryId: string;
}

export interface iDeliveryOrderListRequest {
  orderId: string;
  orderItemList: iDeliveryOrderRequestItem[];
}

export interface iDeliveryOrderRequestItem {
  orderItemId: string;
  deliveryQuantity: number;
}
export interface iDeliveryOrder
  extends Omit<iDeliveryFeasibility, "orderItemList"> {
  deliveryItemList: iDeliveryOrderItem[];
}

export interface iDeliveryOrderItem {
  id: string;
  orderItemId: string;
  inventoryId: string;
  productVariantId: string;
  productVariantName: string;
  uomId: string;
  uomName: string;
  quantity: number;
  deliveryQuantity: number;
  totalDeliveredQuantity: number; // sum of all delivered_quantity for all RPB where order_item_id
  availableDeliveryQuantity: number; // quantity - total_delivered
}

export interface iWarehousePerson {
  warehousePersonId: string;
  warehousePersonName: string;
}

export interface iCourierPerson {
  courierId: string;
  courierName: string;
}

export interface iSalesPerson {
  salesPersonId: string;
  salesPersonName: string;
}

export interface iCashierPerson {
  cashierId: string;
  cashierName: string;
}

export interface iPartiallyDeliveredCount {
  orderCount: number; // y invoice
  itemVariantCount: number; // x barang
  orderIdList: string[];
}

export interface iGetDeliveryOrderReportAPIRequest {
  deliveryId: string;
}

export type iGetDeliveryOrderReportAPIResponse = iApiResponse<iDeliveryOrderReportResponse>;

export interface iDeliveryOrderReportResponse {
  storeId: string;
  storeName: string;
  createdAt: string;
  transactionCount: number;
  warehousePersonName?: string;
  courierName?: string;
  deliveryCode: string;
  deliveryId: string;
  deliveryItemList: iDeliveryOrderReportItem[];
}

export interface iDeliveryOrderReportItem {
  productVariantName: string;
  uomName: string;
  deliveryQuantity: number;
}

export interface iGetDeliveryOrderManifestAPIRequest {
  orderId: string;
}

export type iGetDeliveryOrderManifestAPIResponse = iApiResponse<iDeliveryOrderManifest>;

export interface iDeliveryOrderManifest {
  orderId: string;
  orderCode: string;
  deliveryId: string;
  deliveryCode: string;
  createdAt: string;
  store: iStore;
  customer: iCustomer | null;
  deliveryItemList: iDeliveryOrderManifestItem[];
}

export interface iStore {
  name: string;
  address: string;
  phoneNumber?: string;
}

export interface iCustomer {
  name: string;
  phoneNumber: string;
  address: string;
}

export interface iDeliveryOrderManifestItem {
  productVariantName: string;
  uomName: string;
  deliveryQuantity: number;
}
export interface iGetDeliveryOrderListAPIRequest
  extends GlobalReportAPIRequest,
    DeliveryOrderListState {}

export type iGetDeliveryOrderListAPIResponse = iListApiResponse<
  iDeliveryOrderReport[]
>;

export interface iDeliveryOrderReport {
  createdAt: string;
  deliveryId: string;
  deliveryCode: string;
  orderCount: number;
  salesPerson: iSalesPerson[];
  warehousePerson?: iWarehousePerson;
  courier?: iCourierPerson;
}

export interface iDeliveryOrderDetail {
  createdAt: string;
  storeId: string;
  deliveryId: string;
  deliveryCode: string;
  warehousePerson?: iWarehousePerson;
  courier?: iCourierPerson;
  orderCount: number;
  orderItemVariantCount: number;
  deliveryOrderList: Array<{
    orderId: string;
    orderCode: string;
    createdAt: string;
    salesPersonId: string;
    salesPersonName: string;
    customerId: string;
    customerName: string;
    deliveryStatus: string;
    latestPaymentId: string;
    deliveryItemList: Array<{
      id: string;
      orderItemId: string;
      inventoryId: string;
      productVariantId: string;
      productVariantName: string;
      uomId: string;
      uomName: string;
      quantity: number;
      deliveryQuantity: number;
      totalDeliveredQuantity: number;
      availableDeliveryQuantity: number;
    }>;
  }>;
  partiallDeliveredCount?: {
    orderCount: number;
    itemVariantCount: number;
    orderIdList: string[];
  };
}

export type iGetDeliveryOrderDetailAPIRequest = {
  deliveryId: string;
};

export type iGetDeliveryOrderDetailAPIResponse = iApiResponse<iDeliveryOrderDetail>;
