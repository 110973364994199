import { useInterval } from "@common/hooks";
import { getUserToken } from "@gada-saas/web-core";
import * as React from "react";

import { useOfflineInitialDataFetcher } from "./useOfflineIniitalDataFetcher";

export default function OfflineInitialDataFetcher() {
  const { fetchAllInitialData } = useOfflineInitialDataFetcher();
  const token = getUserToken();

  React.useEffect(() => {
    if (!token) {
      return;
    }

    fetchAllInitialData();
  }, [fetchAllInitialData, token]);

  useInterval({
    callback: fetchAllInitialData,
    delay: 1800000,
  });

  return null;
}
