import {
  iInventory,
  iInventoryListItem,
  iProductVariant,
} from "../../inventory";
import { Customer } from "../../crm/models";
import { iCart, iCartSaved, iInsufficientStockResponse } from "./models";
import { iDiscountItem, ItemType } from "../models";

export interface iInventoryCartUnit extends iInventory {
  countInCart: number;
  lastReserved?: number;
  discountItem?: iDiscountItem | null;
}

export interface iInventoryCartItem
  extends Omit<
    iInventoryListItem,
    | "inventories"
    | "basePriceInSmallestUom"
    | "totalInSmallestUom"
    | "totalInventoryValue"
  > {
  inventories: iInventoryCartUnit[];
}

export interface iUseCartProps {
  debounceCartUpdate?: number;
}

export interface iCustomItem {
  id: string;
  name: string;
  price: number;
  createdAt: string;
  countInCart: number;
}

export interface iCartItem {
  custom?: iCustomItem;
  inventory?: iInventoryCartItem;
}

export interface iCartTransaction {
  id: string;
  storeId: string;
  customer: Customer;
  items: iCartItem[];
  date: string;
}

export type iCartItems = {
  [itemId in number | string]: iCartItem;
};

export interface iCartState {
  currentCartId: string;
  customer?: Customer;
  isLoading: boolean;
  cartItemCountInCart: Record<string | number, number>;
}

export interface iSavedCarts {
  [cartId: string]: iCartSaved;
}

export type MapInventories = {
  [inventoryId in string | number]: iInventory;
};

export type CartItemInventory = iProductVariant & {
  mapInventories: MapInventories;
};

export type CustomCartItem = Omit<iCustomItem, "countInCart">;

export type MapCartItemValue = CartItemInventory | CustomCartItem;

export type MapCartItem = {
  [id in string | number]: MapCartItemValue;
};

export interface iPosState {
  savedCarts: iSavedCarts;
  offlineSavedCarts: iSavedCarts;
  cart: iCartState;
  cartDetail: iCart | null;
  errors: Record<string, iInsufficientStockResponse>;
  barcodeErrorHandled: null | boolean;
  cartItem?: ItemType;
  showReservedQuantityToolTip: string | null;
  isCartDiscountRemovedModalShown: boolean;
  isLoadingSavedCarts: boolean;
  cartIdBeingLoaded: string | null;
  uiState: {
    cartItemList: Array<{
      id: string | number;
      inventoryIds?: Array<number>;
    }>;
    isDiscountItemModalOpen: boolean;
  };
  dataState: {
    mapCartItemDiscount: Record<string | number, iDiscountItem>;
    mapCartItem: MapCartItem;
  };
}

/* Actions Payload */
export type DeleteCartPayload = {
  cartIds: string[];
};

export type DeleteOfflineCartPayload = {
  offlineCartIds: string[];
};

export type AddInventoryToCartPayload = {
  productVariant: iProductVariant;
  inventoryUnit: iInventory;
};
export type AddCustomItemToCartPayload = {
  id: string;
  name: string;
  price: number;
};
export type DeleteInventoryFromCartPayload = {
  productVariantId: ItemType["productVariantId"];
  inventoryUnitId: number;
};
export type DeleteCustomItemFromCartPayload = {
  id: string;
};
export type ChangeInventoryCountPayload = {
  productVariantId: ItemType["productVariantId"];
  inventoryUnitId: number;
  count: number;
};

export type ChangeCustomItemCountPayload = {
  id: string;
  count: number;
};
export type LoadCartPayload = {
  cartTransaction: iCartTransaction;
  cartDetail: iCart;
};

export enum DiscountItemTypes {
  FREE = "FREE",
  FLAT = "FLAT",
  PERCENTAGE = "PERCENTAGE",
}

export interface iCartSummaryInfo {
  total: string;
  subTotal: number;
  totalTransactionDiscount: number;
  totalItemDiscount: number;
  totalTaxAmount: string;
  isTaxInludeInPrice?: boolean;
  isTaxActive?: boolean;
  transactionDiscountName: string | null;
}

export type CreatedAtHashmap = {
  [id: string]: number;
};
