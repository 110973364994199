import * as React from "react";
import { IconProps } from "./types";

const EmployeeAccessIcon: React.VFC<IconProps> = ({
  width = 24,
  height = 24,
}) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        d="m22 3h-20c-1.09 0.04-1.96 0.91-2 2v14c0.04 1.09 0.91 1.96 2 2h20c1.09-0.04 1.96-0.91 2-2v-14c-0.0184-0.52457-0.235-1.0227-0.6062-1.3938-0.3711-0.37116-0.8692-0.58778-1.3938-0.60619zm0 16h-20v-14h20v14zm-8-2v-1.25c0-1.66-3.34-2.5-5-2.5s-5 0.84-5 2.5v1.25h10zm-5-10c-0.66304 0-1.2989 0.26339-1.7678 0.73223s-0.73223 1.1047-0.73223 1.7678c0 0.3283 0.06466 0.6534 0.1903 0.9567s0.30979 0.5789 0.54193 0.8111c0.46884 0.4688 1.1047 0.7322 1.7678 0.7322 0.3283 0 0.65339-0.0647 0.95671-0.1903 0.30329-0.1256 0.57889-0.3098 0.81109-0.5419 0.2321-0.2322 0.4163-0.5078 0.5419-0.8111s0.1903-0.6284 0.1903-0.9567-0.0647-0.65339-0.1903-0.95671c-0.1256-0.30331-0.3098-0.57891-0.5419-0.81106-0.2322-0.23214-0.5078-0.41629-0.81109-0.54193-0.30332-0.12564-0.62841-0.1903-0.95671-0.1903zm5 0v1h6v-1h-6zm0 2v1h6v-1h-6zm0 2v1h4v-1h-4z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default EmployeeAccessIcon;
