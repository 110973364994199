import {
  iApiResponse,
  iListApiResponse,
  iListSortApiRequest,
  iSortApiRequest,
} from "../common/models";
import { iPriceTier } from "../inventory";

export enum PromotionStatus {
  ALL = "ALL",
  ON_GOING = "ON_GOING",
  UP_COMING = "UP_COMING",
  PAST = "PAST",
}

export enum PromotionType {
  DISCOUNT = "DISCOUNT",
  BUNDLING = "BUNDLING",
  FREE_ITEM = "FREE_ITEM",
  ALL = "ALL",
}

export enum PromotionSortBy {
  CREATED_AT = "CREATED_AT",
  PROMOTION_NAME = "PROMOTION_NAME",
  PROMOTION_PERIOD = "PROMOTION_PERIOD",
  FREQUENCY = "FREQUENCY",
  PROMOTION_VALUE_USED = "PROMOTION_VALUE_USED",
  NET_SALES = "NET_SALES",
}

export interface GetPromotionListAPIRequest
  extends iListSortApiRequest<PromotionSortBy> {
  storeId: string;
  keyword: string;
  promotionType: PromotionType;
  status: PromotionStatus;
  startDate: string;
  endDate: string;
}

export interface GetPromotionDataAPIRequest {
  storeId: string;
  keyword: string;
  promotionType: PromotionType;
  status: PromotionStatus;
  startDate: string;
  endDate: string;
}

export enum PromotionTargetType {
  PERCENTAGE = "PERCENTAGE",
  DISCOUNT = "DISCOUNT",
}

export interface iPromotion {
  id: string;
  promotionName: string;
  promotionType: PromotionType;
  startDate: string;
  endDate: string;
  status: PromotionStatus;
  frequency: number;
  promotionValueUsed: string;
  netSales: string;
}

export interface PromoDiscountItem {
  inventoryId: number;
  productVariantName: string;
  targetType: PromotionTargetType;
  amount: string;
  maximumRedeem: number | null;
  redeemPromoUsed: number | null;
  productVariantId: number;
  uomId: number;
  uomName: string;
  productVariantPhoto?: string;
}

export interface iPromotionDetail extends iPromotion {
  grossSales: string;
  refund: string;
  totalRedeemPromoUsed: number;
  discountItems: PromoDiscountItem[];
}

export interface iPromotionData {
  totalPromotionValueUsed: number;
  totalNetSales: number;
}

export type GetPromotionListAPIResponse = iListApiResponse<iPromotion[]>;

export type GetPromotionDataAPIResponse = iApiResponse<iPromotionData>;

export type GetPromotionDetailAPIRequest = {
  promotionId: string;
  storeId: string;
};

export interface DownloadPromotionDataAPIResponseData {
  url: string;
}

export interface DownloadPromotionDataAPIRequest
  extends iSortApiRequest<PromotionSortBy> {
  storeId: string;
  keyword: string;
  promotionType: PromotionType;
  status: PromotionStatus;
  startDate: string;
  endDate: string;
}

export type GetPromotionDetailAPIResponse = iApiResponse<iPromotionDetail>;

export type DownloadPromotionDataAPIResponse = iApiResponse<DownloadPromotionDataAPIResponseData>;

export type PromotionInventoriesSearchAPIRequest = {
  storeId: string;
  keyword?: string;
  includeProductVariantIds?: number[];
  excludeProductVariantIds?: number[];
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  excludePromotionId?: string;
};

export type PromotionInventoryType = {
  inventoryId: number;
  productUnitId: number;
  unitOfMeasurement: {
    id: number;
    shortName: string;
    longName: string;
  };
  promoApplied: boolean;
  priceTiers: Array<iPriceTier>;
};

export interface iPromotionInventory {
  productVariantId: number;
  productVariantName: string;
  productVariantPhoto: string | null;
  displayName: string;
  inventories: PromotionInventoryType[];
  promoApplied: boolean;
}

export type PromotionInventoriesSearchAPIResponse = iListApiResponse<
  iPromotionInventory[]
>;

export interface iPromotionDiscountItem {
  inventoryId: number;
  productVariantName: string;
  targetType: string;
  amount: string;
  maximumRedeem?: number | null;
}

export type UpsertPromotionType = {
  storeId: string;
  promotionName: string;
  promotionType: string;
  startDate: string;
  endDate: string;
  discountItems: Array<iPromotionDiscountItem>;
};

export type CreatePromotionAPIRequest = UpsertPromotionType;

export type CreatePromotionAPIResponse = iApiResponse<
  UpsertPromotionType & { id: string }
>;

export type UpdatePromotionAPIRequest = {
  routeParams: {
    promotionId: string;
  };
  payload: Partial<UpsertPromotionType>;
};

export type UpdatePromotionAPIResponse = iApiResponse<UpsertPromotionType>;

export type DeletePromotionAPIRequest = {
  promotionId: string;
  storeId: string;
};
