import React from "react";

const UserIcon: React.VFC = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 2.01875C8.79301 2.01875 9.08315 2.07646 9.35386 2.18859C9.62457 2.30072 9.87054 2.46508 10.0777 2.67227C10.2849 2.87946 10.4493 3.12543 10.5614 3.39614C10.6735 3.66685 10.7313 3.95699 10.7313 4.25C10.7313 4.54301 10.6735 4.83316 10.5614 5.10386C10.4493 5.37457 10.2849 5.62054 10.0777 5.82773C9.87054 6.03492 9.62457 6.19928 9.35386 6.31141C9.08315 6.42354 8.79301 6.48125 8.5 6.48125C7.90824 6.48125 7.34071 6.24617 6.92227 5.82773C6.50383 5.40929 6.26875 4.84176 6.26875 4.25C6.26875 3.65824 6.50383 3.09071 6.92227 2.67227C7.34071 2.25383 7.90824 2.01875 8.5 2.01875ZM8.5 11.5812C11.6556 11.5812 14.9813 13.1325 14.9813 13.8125V14.9813H2.01875V13.8125C2.01875 13.1325 5.34438 11.5812 8.5 11.5812ZM8.5 0C6.15188 0 4.25 1.90188 4.25 4.25C4.25 6.59812 6.15188 8.5 8.5 8.5C10.8481 8.5 12.75 6.59812 12.75 4.25C12.75 1.90188 10.8481 0 8.5 0ZM8.5 9.5625C5.66313 9.5625 0 10.9862 0 13.8125V17H17V13.8125C17 10.9862 11.3369 9.5625 8.5 9.5625Z"
        fill="#6B7E7D"
      />
    </svg>
  );
};

export default UserIcon;
