import { ActionCreatorsMapObject, bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useMemo } from "react";

export function useActions<T extends ActionCreatorsMapObject>(
  actions: T,
  deps?: Array<any>
): T {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      return bindActionCreators<T, T>(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch]
  );
}
