import * as React from "react";
import {
  createAnalytics,
  SegmentConfig,
  SegmentInstance,
} from "../utils/createAnalyticsAdapter";
import { useRouter } from "next/router";
import { CustomLogPlugin } from "../utils/createLogOnlyPlugin";
import { useActiveStoreState, useGetUserInfoQuery } from "@gada-saas/web-core";
import { PageData } from "analytics";

const defaultSegmentConfig: SegmentConfig = {
  app: process.env.NEXT_PUBLIC_SEGMENT_APP_NAME,
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
  plugins: [
    process.env.NODE_ENV === "development" ? CustomLogPlugin() : undefined,
  ].filter(Boolean) as Record<string, unknown>[],
  withQueryParams: true,
  allowAnonymousRequests: false,
};

const instance: SegmentInstance = createAnalytics(defaultSegmentConfig);

/**
 * A segment analytics hooks as a helper for analytics
 * @param config Segment analytics settings
 * @returns AnalyticsInstance that could be used for tracking
 */
export const useSegmentAnalytics = (
  config?: SegmentConfig
): SegmentInstance => {
  const router = useRouter();
  const { data: userInfoData } = useGetUserInfoQuery();
  const activeStore = useActiveStoreState();

  const firstTime = React.useRef(true);

  const analytics = React.useMemo(() => {
    if (config) {
      return createAnalytics({ ...defaultSegmentConfig, ...config });
    }
    return instance;
  }, [config]);

  const handleRouteChange = React.useCallback(() => {
    if (firstTime.current) {
      firstTime.current = false;
      return;
    }
    analytics.referrer = window.location.href;
  }, [analytics]);

  const userTraits: Record<string, unknown> = React.useMemo(
    () => ({
      anonymousId: analytics.user().anonymousId,
      user_id: userInfoData?.data.userInfo.id,
      phone_number: userInfoData?.data.userInfo.phoneNumber,
      email: userInfoData?.data.userInfo.email,
      created_at: userInfoData?.data.userInfo.createdAt,
      store_id: activeStore.storeId,
      store_access_role: activeStore.storeRoleList.map((s) => s.name).join("|"),
      store_access_position: activeStore.storeRoleList
        .map((s) => s.position)
        .join("|"),
      is_sync_with_marketplace: activeStore.isStoreLinked,
    }),
    [
      activeStore.isStoreLinked,
      activeStore.storeId,
      activeStore.storeRoleList,
      analytics,
      userInfoData?.data.userInfo.createdAt,
      userInfoData?.data.userInfo.email,
      userInfoData?.data.userInfo.id,
      userInfoData?.data.userInfo.phoneNumber,
    ]
  );

  const handleTrackAnalytics = React.useCallback(
    async (
      eventName: string,
      payload: any = {},
      options?: any,
      callback?: (...params: any[]) => any
    ) => {
      analytics.track(
        eventName,
        { ...payload, ...userTraits },
        options,
        callback
      );
    },
    [analytics, userTraits]
  );

  const handleTrackPage = React.useCallback(
    async (
      name?: string,
      payload: PageData = {},
      options?: any,
      callback?: (...params: any[]) => any
    ) => {
      analytics.page(name, { ...payload, ...userTraits }, options, callback);
    },
    [analytics, userTraits]
  );

  React.useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [handleRouteChange, router]);

  return React.useMemo(
    () => ({
      ...analytics,
      track: handleTrackAnalytics,
      page: handleTrackPage,
    }),
    [analytics, handleTrackAnalytics, handleTrackPage]
  );
};
