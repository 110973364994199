import { createApi } from "@reduxjs/toolkit/dist/query/react";
import apiSauceBaseQuery from "./common/api/apiSauceBaseQuery";

const tagTypes: readonly string[] = [
  "Otp",
  "Tutorial",
  "Customers",
  "CustomerSummaries",
  "Employee",
  "Permission",
  "Position",
  "TransactionEmployee",
  "Todos",
  "Uoms",
  "ProductUnits",
  "Inventories",
  "InventoryList",
  "InventoryList",
  "ProductUnits",
  "Principals",
  "Cart",
  "POS_Inventories",
  "InventoryRecommendationRanking",
  "Order",
  "OrderReceipt",
  "CustomerOrders",
  "Promotion",
  "CustomerDebts",
  "PaymentReport",
  "DebtReceivables",
  "DeliveryOrder",
  "ExpenseReport",
  "OfflineTransactions",
  "SalesReport",
  "SalesTrendReport",
  "SalesCategoryReport",
  "Tax",
  "StoreSetting",
  "RefundConfig",
  "StoreSpecialtyType",
  "StoreOperatingTime",
  "Supplier",
  "User",
  "BankAccount",
] as const;

export const gadaSaasApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  tagTypes,
  reducerPath: "gada-saas-api",
  endpoints: () => ({}),
});
