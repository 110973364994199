import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

export const useReferrer = (): string | undefined => {
  const [history, setHistory] = useState<string[]>([]);
  const router = useRouter();

  const handleRouteChange = useCallback(
    (url: string) => {
      const sanitizedUrl = url.slice(0, url.indexOf("?"));
      const index = history.indexOf(sanitizedUrl);
      if (index !== -1) {
        setHistory(history.slice(index));
      } else {
        setHistory([sanitizedUrl, ...history]);
      }
    },
    [history]
  );

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, handleRouteChange]);

  return history[1];
};
