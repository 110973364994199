import { GetAllPositionsResponse, PositionModel } from ".";

export const transformPositionsToPositionModels = (
  response: GetAllPositionsResponse
): PositionModel[] => {
  const positions: PositionModel[] = [];
  response.data.map((value: string) => {
    const obj: PositionModel = {
      id: value,
      position: value,
    };
    positions.push(obj);
  });

  return positions;
};
