import {
  iApiResponse,
  iListApiResponse,
  iListSortApiRequest,
} from "../../common/models";
import { DeliveryStatus } from "../../report/delivery-order";
import { GlobalReportState, PaymentStatus } from "../../report/types";
import { iStoreBankAccountList } from "../../user";
import {
  CustomItemType,
  DiscountType,
  iObjectHighlight,
  iRefund,
  ItemType,
  OrderTax,
  PaymentType,
} from "../models";

export type PostOrderAPIRequest = {
  currency: string;
  paymentType: string;
  storeId: string;
  amount: string;
  cartId: string;
  customerId: string | null;
  salesPersonId: string | null;
  dueDateDuration?: number;
  storeBankAccountId?: string;
  referenceNo?: string;
  selectedOfflineBankAccount?: iStoreBankAccountList;
};

export type PostOrderPaymentAPIRequest = {
  orderId: string;
  storeId: string;
  paymentType: string;
  currency: string;
  amount: string;
  storeBankAccountId?: string;
  referenceNo?: string | null;
};

export type SalesPerson = {
  id: string;
  name: string;
  phoneNumber: string;
};

export type OrderDelivery = {
  createdAt: string;
  deliveryId: string;
  deliveryCode: string;
  warehousePerson?: {
    id: string;
    name: string;
  };
  courier?: {
    id: string;
    name: string;
  };
  deliveryItemVariantCount: number;
};

export type OrderType = {
  cashierId: string;
  cashierName: string;
  channel: string;
  createdAt: string;
  currency: string;
  customerAddress: string;
  customerId: string | null;
  customerName: string | null;
  deliveryStatus: DeliveryStatus;
  dueDate?: Date;
  grossAmount: string;
  id: string;
  netAmount: string;
  objectHighlight: iObjectHighlight;
  orderCode: string;
  orderCustomList: CustomItemType[];
  orderDeliveryList: OrderDelivery[];
  orderDiscountList: DiscountType[];
  orderItemList: ItemType[];
  orderPaymentList: PaymentType[];
  partiallyDeliveredItemVariantCount?: number;
  paymentStatus: PaymentStatus;
  refundList: iRefund[];
  salesPerson?: SalesPerson;
  storeAddress: string;
  storeId: string;
  storeName: string;
  tax?: OrderTax;
  refundStatus: RefundStatus;
  orderStatus: string;
  totalCharges: string;
  totalDiscount: string;
  totalItems: number;
  totalPayment: string;
  totalRefundAmount: string;
  totalRefundChargesAmount: string;
  totalRefundDiscountAmount: string;
  totalSellerFee: string;
  unpaidAmount: string;
  updatedAt: string; // default value
};

export enum RefundStatus {
  REFUNDABLE = "REFUNDABLE",
  NON_REFUNDABLE = "NON_REFUNDABLE",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  FULLY_REFUNDED = "FULLY_REFUNDED",
}

export enum UserPosition {
  SALES = "SALES",
  KASIR = "KASIR",
}

export type OrderApiResponse = iApiResponse<OrderType>;

export type ListOrderApiResponse = {
  page: number;
  total: number;
  message: string[];
  data: OrderType[];
};

export type GetCustomerOrderAPIRequest = {
  durationType: string;
  pageSize: number;
  page: number;
  customerId: string;
  storeId: string;
};

export type GetCustomerOrderAPIResponse = {
  data: OrderType[];
  page: number;
  total: number;
  message: string | string[];
};

export type SendOrderReceiptResponse = {
  data: Record<string, unknown>;
  message: unknown;
};

export type SendOrderReceiptRequest = {
  storeId: string;
  paymentId: string;
  recipientEmailList: string[];
};

export type GetOrderByIdRequest = {
  storeId: string;
  orderId: string;
};

export type GetOrderReceiptByIdRequest = {
  storeId: string;
  paymentId: string;
};

export type GetOrdersRequest = iListSortApiRequest<string> &
  GlobalReportState & {
    storeId: string;
    userId?: string[];
    deliveryStatus?: DeliveryStatus[];
    excludeId?: string[];
    userPosition?: UserPosition;
  };

export type GetOrdersResponse = iListApiResponse<OrderType[]>;
