import { create } from "apisauce";
import { gadaSaasApi } from "../base-rtkq-api";
import { Endpoints, iBaseQueryErrorResponse } from "../common/api";
import {
  iDownloadStockOpnameFileAPIRequest,
  iDownloadStockOpnameFileAPIResponse,
  iGetStockOpnameAPIRequest,
  iGetStockOpnameAPIResponse,
  iDownloadStockOpnameRecountInventoriesFileAPIRequest,
  iStartStockOpnameAPIRequest,
  iStartStockOpnameAPIResponse,
  iCancelStockOpnameAPIRequest,
  iGetFileURLAPIRequest,
  iCreateRecountAPIRequest,
  iCreateRecountAPIResponse,
  iGetActiveStockOpnameAPIRequest,
  iStockOpnameRecountAPIResponse,
  iGetFilterEmployeesAPIResponse,
  iGetFilterEmployeesAPIRequest,
  iUploadFileAPIRequest,
} from "./models";

const StockOpnameApi = gadaSaasApi.injectEndpoints({
  endpoints: (builder) => ({
    getStockOpnameList: builder.mutation<
      iGetStockOpnameAPIResponse,
      iGetStockOpnameAPIRequest
    >({
      query: (data) => ({
        method: "GET",
        url: Endpoints.STOCK_OPNAME_RECOUNT,
        queryParams: data,
      }),
    }),
    startStockOpname: builder.mutation<
      iStartStockOpnameAPIResponse,
      iStartStockOpnameAPIRequest
    >({
      query: (data) => ({
        method: "POST",
        url: Endpoints.STOCK_OPNAME_RECOUNT_START,
        data,
      }),
    }),
    cancelStockOpname: builder.mutation<void, iCancelStockOpnameAPIRequest>({
      query: ({ id }) => ({
        method: "DELETE",
        url: Endpoints.STOCK_OPNAME_RECOUNT_BY_ID,
        routeParams: { id },
      }),
    }),
    downloadStockOpnameFile: builder.mutation<
      iDownloadStockOpnameFileAPIResponse,
      iDownloadStockOpnameFileAPIRequest
    >({
      query: ({ id, storeId }) => ({
        method: "GET",
        url: Endpoints.STOCK_OPNAME_RECOUNT_DOWNLOAD,
        routeParams: { id },
        queryParams: { storeId },
      }),
    }),
    downloadStockOpnameRecountInventoriesFile: builder.mutation<
      iDownloadStockOpnameFileAPIResponse,
      iDownloadStockOpnameRecountInventoriesFileAPIRequest
    >({
      query: (data) => ({
        method: "GET",
        url: Endpoints.STOCK_OPNAME_RECOUNT_INVENTORIES_DOWNLOAD,
        queryParams: data,
      }),
    }),
    getFileUrl: builder.mutation<
      iDownloadStockOpnameFileAPIResponse,
      iGetFileURLAPIRequest
    >({
      query: ({ storeId, id, fileType }) => ({
        method: "POST",
        url: Endpoints.STOCK_OPNAME_RECOUNT_UPLOAD,
        data: { storeId, fileType },
        routeParams: { id },
      }),
    }),
    uploadFile: builder.mutation<unknown, iUploadFileAPIRequest>({
      queryFn: ({ file, url }) => {
        const formData = new FormData();
        formData.append("Content-Type", file.type);
        formData.append("file", file);
        const apiSauce = create({ baseURL: url });

        return apiSauce
          .put(url, undefined, {
            headers: { "Content-Type": "" },
            data: file,
          })
          .then((res) => {
            if (!res.ok) {
              return {
                error: (res.data as unknown) as iBaseQueryErrorResponse,
              };
            } else {
              return {
                data: res.data,
              };
            }
          });
      },
    }),
    createRecountRequest: builder.mutation<
      iCreateRecountAPIResponse,
      iCreateRecountAPIRequest
    >({
      query: ({ url, storeId, id, fileType }) => ({
        method: "PATCH",
        url: Endpoints.STOCK_OPNAME_RECOUNT_BY_ID,
        data: { url, storeId, fileType },
        routeParams: { id },
      }),
    }),
    getActiveStockOpname: builder.query<
      iStockOpnameRecountAPIResponse,
      iGetActiveStockOpnameAPIRequest
    >({
      query: ({ storeId }) => ({
        method: "GET",
        url: Endpoints.STOCK_OPNAME_RECOUNT_BY_STORE_ID,
        routeParams: { storeId },
      }),
    }),
    getFilterEmployees: builder.mutation<
      iGetFilterEmployeesAPIResponse,
      iGetFilterEmployeesAPIRequest
    >({
      query: (data) => ({
        method: "GET",
        url: Endpoints.STOCK_OPNAME_EMPLOYEES,
        queryParams: data,
      }),
    }),
  }),
});

export const {
  useGetStockOpnameListMutation,
  useStartStockOpnameMutation,
  useCancelStockOpnameMutation,
  useDownloadStockOpnameFileMutation,
  useDownloadStockOpnameRecountInventoriesFileMutation,
  useGetFileUrlMutation,
  useUploadFileMutation,
  useCreateRecountRequestMutation,
  useLazyGetActiveStockOpnameQuery,
  useGetFilterEmployeesMutation,
} = StockOpnameApi;

export default StockOpnameApi;
