import { IconProps } from "./types";
import * as React from "react";

const PulseIcon: React.VFC<IconProps> = ({ width = 120, height = 120 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 136 136" fill="none">
      <g filter="url(#filter0_d_37_2984)">
        <circle cx="68" cy="64" r="60" fill="#EBF8F7" />
      </g>
      <g filter="url(#filter1_d_37_2984)">
        <circle cx="68" cy="66" r="48" fill="#C4EFEE" />
      </g>
      <g filter="url(#filter2_d_37_2984)">
        <circle cx="68" cy="66" r="36" fill="#05A39B" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.716 52.8491C88.2597 53.393 88.5651 54.1305 88.5651 54.8995C88.5651 55.6685 88.2597 56.406 87.716 56.9498L64.5152 80.1506C63.9714 80.6943 63.2339 80.9998 62.4649 80.9998C61.6959 80.9998 60.9583 80.6943 60.4145 80.1506L48.8141 68.5502C48.2858 68.0033 47.9935 67.2707 48.0001 66.5103C48.0067 65.7499 48.3117 65.0225 48.8494 64.4848C49.3871 63.9471 50.1145 63.6421 50.8749 63.6355C51.6353 63.6289 52.3679 63.9212 52.9148 64.4495L62.4649 73.9995L83.6153 52.8491C84.1591 52.3054 84.8967 52 85.6657 52C86.4347 52 87.1722 52.3054 87.716 52.8491Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_37_2984"
          x="0"
          y="0"
          width="136"
          height="136"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_37_2984"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_37_2984"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_37_2984"
          x="12"
          y="14"
          width="112"
          height="112"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_37_2984"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_37_2984"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_37_2984"
          x="24"
          y="26"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_37_2984"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_37_2984"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PulseIcon;
