import { ApiErrorResponse } from "apisauce";

export interface iApiResponse<T> {
  message?: string | string[];
  errorCode?: string;
  data: T;
}

export interface iListApiResponse<T> extends iApiResponse<T> {
  page: number;
  total: number;
}

export interface iGadaApiError<E> {
  errors: Record<string, string> | { schemaErrors?: Record<string, string[]> };
  schemaErrors?: Record<string, string[]>;
  errorCode?: string;
  message?: string;
  data?: E;
}

export type iGadaApiErrorResponse<E> = ApiErrorResponse<iGadaApiError<E>>;

export interface iListApiRequest {
  page: number;
  pageSize: number;
}

export interface iSortApiRequest<T> {
  sortType?: SortType | null;
  sortBy?: T;
}

export interface iListSortApiRequest<T>
  extends iListApiRequest,
    iSortApiRequest<T> {}

export enum SortType {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export interface StoreAddressLocation {
  latitude: string;
  longitude: string;
}

export interface BankAccountModel {
  id?: string;
  name: string;
  code: string;
  isActive?: boolean;
  logoUrl: null | string;
}

export interface BankAccount {
  bank: BankAccountModel;
  bankAccountId: string | undefined;
  bankAccountName: string;
  bankAccountNumber: string;
}

export interface Account {
  createdAt: string;
  updatedAt: string;
  canonicalId: number;
  isActive: boolean;
  isStoreLinked: boolean;
  marketplaceStoreId: string;
  marketplaceStoreUuid: string;
  marketplaceStoreName: string;
  marketplaceStoreCode: string;
  marketplaceStoreAddress: string;
  marketplaceStoreAddressName: string | null;
  marketplaceStoreAddressLatitude: string;
  marketplaceStoreAddressLongitude: string;
  storeId: string;
  storeName: string;
  storeCode: string;
  storeAddress: string;
  storeAddressName: string;
  storeAddressLatitude: string;
  storeAddressLongitude: string;
  storeBankAccountList: BankAccount[];
  storeAddressLocation: StoreAddressLocation | null;
  storeAddressDistrictId?: number;
  storeAddressDistrict?: string;
  storeAddressCity?: string;
  storeAddressProvince?: string;
}
