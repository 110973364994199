import Analytics, {
  AnalyticsInstance,
  AnalyticsPlugin,
  PageData,
} from "analytics";
import { getQueryParams } from "@utils/urls";
import SegmentPlugin from "@analytics/segment";
import Fingerprint from "@fingerprintjs/fingerprintjs";
import { CustomLogPlugin } from "./createLogOnlyPlugin";
import { getDeviceEnvironmentHeaders } from "./getDeviceEnvHeaders";
import { isEmpty } from "lodash";

const FINGERPRINT_STORAGE_KEY = "FINGERPRINT";

export interface SegmentConfig {
  app?: string;
  version?: string;
  debug?: boolean;
  writeKey?: string;
  plugins?: AnalyticsPlugin[];
  withQueryParams?: boolean;
  allowAnonymousRequests?: boolean;
}

export interface SegmentInstance extends Omit<AnalyticsInstance, "page"> {
  page: (
    name?: string,
    traits?: PageData,
    options?: any,
    callback?: (...params: any[]) => any
  ) => Promise<any>;
  referrer: string;
  identifyPromise: Promise<void>;
}

export const createAnalytics = (config: SegmentConfig): SegmentInstance => {
  const isProd = process.env.NEXT_PUBLIC_ENV === "prod";
  const plugins = [
    config.writeKey
      ? SegmentPlugin({
          writeKey: config.writeKey,
        })
      : CustomLogPlugin(),
    ...(config.plugins ?? []),
  ];

  const withQueryParams = (traits: any) => {
    const { path: _urlPath, ...urlParams } = getQueryParams(
      window.location.search
    );

    const { path: _referrerPath, ...referrerParams } = getQueryParams(
      traits.referrer ?? ""
    );
    return {
      ...traits,
      referrer_parameter: referrerParams,
      url_parameter: urlParams,
    };
  };

  const analyticsInstance = Analytics({
    ...config,
    debug: config.debug ?? !isProd,
    plugins,
  });

  let resolver: () => void;

  analyticsInstance.once("identifyEnd", () => {
    resolver();
  });

  let fingerprintPromise = Promise.resolve();

  const deviceInformations: Record<string, string> = {};
  const env = getDeviceEnvironmentHeaders() ?? {};

  deviceInformations.deviceId = env["Device-Identifier"];
  deviceInformations.advertisementId = env["Advertising-ID"];

  if (typeof window !== "undefined") {
    const fingerprintId = localStorage.getItem(FINGERPRINT_STORAGE_KEY);
    if (fingerprintId === null) {
      fingerprintPromise = Fingerprint.load()
        .then((agent) => agent.get())
        .then((result) => {
          const { visitorId } = result;
          deviceInformations.fingerprintId = visitorId;
          localStorage.setItem(FINGERPRINT_STORAGE_KEY, visitorId);
        });
    } else {
      deviceInformations.fingerprintId = fingerprintId;
    }
  }

  return {
    ...analyticsInstance,
    reset(callback?: (...params: any[]) => any) {
      this.identifyPromise = new Promise((resolve) => {
        resolver = resolve;
      });
      analyticsInstance.once("identifyEnd", () => {
        resolver();
      });
      return analyticsInstance.reset(callback);
    },
    referrer: typeof document !== "undefined" ? document.referrer : "",
    identifyPromise: new Promise((resolve) => {
      resolver = resolve;
    }),
    async track(
      eventName: string,
      payload: any = {},
      options?: any,
      callback?: (...params: any[]) => any
    ) {
      await fingerprintPromise;
      if (!config.allowAnonymousRequests) await this.identifyPromise;
      const traits = {
        referrer: this.referrer,
        device: deviceInformations,
        ...payload,
      };
      return analyticsInstance.track(
        eventName,
        config.withQueryParams ? withQueryParams(traits) : traits,
        options,
        callback
      );
    },
    async page(
      name?: string,
      payload: PageData = {},
      options?: any,
      callback?: (...params: any[]) => any
    ) {
      await fingerprintPromise;
      if (!config.allowAnonymousRequests) await this.identifyPromise;
      if (!name && isEmpty(payload)) {
        return analyticsInstance.page();
      }
      const traits = {
        name,
        referrer: this.referrer,
        device: deviceInformations,
        ...payload,
      };
      return analyticsInstance.page(
        config.withQueryParams ? withQueryParams(traits) : traits,
        options,
        callback
      );
    },
  };
};
