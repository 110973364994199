import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { BadStockReportInterface, BadStockReportStateInterface } from "./types";
import uniqBy from "lodash/uniqBy";
import { SortType } from "../../common/models";
import { BadStockReason } from "./models";

const initialBadStockReportState: BadStockReportInterface = {
  BadStockReportUIState: {
    pageSize: 50,
    page: 1,
    totalItems: 0,
    search: "",
    displayOnBoardingToolTip: true,
    brands: [],
    reason: BadStockReason.NONE,
    sortType: SortType.DESCENDING,
  },
  BadStockReportState: [],
};

export const BadStockReportSlice = createSlice({
  name: "badStockReport",
  initialState: initialBadStockReportState,
  reducers: {
    resetState: (state) => {
      return {
        BadStockReportUIState: state.BadStockReportUIState,
        BadStockReportState: [],
      };
    },
    addInventory: (
      state,
      action: PayloadAction<
        BadStockReportStateInterface[] | BadStockReportStateInterface
      >
    ) => {
      const newInventories = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const newState = uniqBy(
        [...state.BadStockReportState, ...newInventories],
        (i) => i.productVariantId + i.reason
      );

      return {
        BadStockReportUIState: state.BadStockReportUIState,
        BadStockReportState: newState,
      };
    },
    removeInventory: (
      state,
      action: PayloadAction<{
        productVariantId: number;
        reason: BadStockReason;
      }>
    ) => {
      const { productVariantId, reason } = action.payload;
      const index = state.BadStockReportState.findIndex(
        (item) =>
          item.productVariantId === productVariantId && item.reason === reason
      );
      if (index > -1) {
        state.BadStockReportState.splice(index, 1);
      }
    },
    updateInventory: (
      state,
      action: PayloadAction<BadStockReportStateInterface>
    ) => {
      const index = state.BadStockReportState.findIndex(
        (item) =>
          item.productVariantId === action.payload.productVariantId &&
          item.reason === action.payload.reason
      );
      if (index > -1) {
        state.BadStockReportState[index] = action.payload;
      }
    },
    updateSearch: (state, action: PayloadAction<string>) => {
      state.BadStockReportUIState.search = action.payload;
    },
    addBrands: (state, action: PayloadAction<number[] | number>) => {
      const brands = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      state.BadStockReportUIState.brands = uniqBy(
        [...state.BadStockReportUIState.brands, ...brands],
        (brand) => brand
      );
    },
    removeBrands: (state, action: PayloadAction<number[] | number>) => {
      const brands = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      state.BadStockReportUIState.brands = state.BadStockReportUIState.brands.filter(
        (b) => !brands.includes(b)
      );
    },
    resetBrands: (state) => {
      state.BadStockReportUIState.brands = [];
    },
    updateReason: (state, action: PayloadAction<BadStockReason>) => {
      state.BadStockReportUIState.reason = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.BadStockReportUIState.page = action.payload;
    },
    updateTotalItems: (state, action: PayloadAction<number>) => {
      state.BadStockReportUIState.totalItems = action.payload;
    },
    setSortType: (state, action: PayloadAction<SortType>) => {
      state.BadStockReportUIState.sortType = action.payload;
    },
    toggleDisplayOnBoardingToolTip: (state) => {
      state.BadStockReportUIState.displayOnBoardingToolTip = !state
        .BadStockReportUIState.displayOnBoardingToolTip;
    },
  },
});

const { actions } = BadStockReportSlice;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBadStockReportStateActions = () => useActions(actions);

export default BadStockReportSlice.reducer;
