import { InitialState } from "./types";

const initialState: InitialState = {
  loading: false,
  saasStore: undefined,
  marketplaceStoreId: "",
  hasStores: true,
  hasConflict: false,
  conflicts: [],
};

export default initialState;
