import { Card } from "@braze/web-sdk";
import * as React from "react";

export interface NotificationContextProps {
  isBrazeInit: Boolean;
  unreadCards: number;
  setUnreadCards: React.Dispatch<React.SetStateAction<number>>;
  contentCards: Record<string, ContentCard>;
  setContentCards: React.Dispatch<
    React.SetStateAction<Record<string, ContentCard>>
  >;
  activeContentCard: ContentCardDetail | null;
  setActiveContentCard: React.Dispatch<
    React.SetStateAction<ContentCardDetail | null>
  >;
}

export interface ContentCard {
  id: string;
  type: "ClassicCard" | "CaptionedImage";
  categoryIconUrl: string;
  title: string;
  imageUrl: string;
  description: string;
  linkText?: string;
  isPinned: boolean;
  isRead: boolean;
  actionType: LinkTextActionType;
  actionUrl?: string;
  detail: ContentCardDetail;
  createdAt: Date;
  originalCard: Card;
}

export enum LinkTextActionType {
  URL = "URL",
  DETAIL = "DETAIL",
}

export interface ContentCardDetail {
  category: string;
  categoryIconUrl: string;
  title: string;
  image: string;
  description: string[];
  buttonTitle: string;
  buttonAction: string;
}
