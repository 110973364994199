import { responseRouter as router } from "../../router";
import { Endpoints } from "../../../../../common/api/endpoints";
import { StoreTopRequest, StoreTopResponse } from "../../../../../stores";

router.get<StoreTopResponse, void, void, StoreTopRequest>(
  Endpoints.STORE_SETTING,
  async (_req, resp) => {
    // TODO : Implement save to local db
    return resp;
  }
);

router.get<StoreTopResponse, void, void, StoreTopRequest>(
  Endpoints.GET_ALL_BANK_ACCOUNTS,
  async (_req, resp) => {
    return resp;
  }
);
