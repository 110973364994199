import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "@gada-saas/web-core/common/hooks/useActions";
import {
  iDeliveryOrderState,
  iUpdateOrderItemSelectedQuantityPayload,
  iClearOrderItemPayload,
  iDeleteOrderPayload,
  iDeliveryOrderEmployee,
} from "./types";
import {
  iDeliveryFeasibility,
  iDeliveryOrderResponse,
  mapFeasibilityToDeliveryOrder,
} from "../..";
import { globalResetAction } from "../../../common/redux/actions";

const initialState: iDeliveryOrderState = {
  ordersData: [],
  warehouse: null,
  courier: null,
  deliveryOrder: null,
};

const deliveryOrderSlice = createSlice({
  initialState,
  name: "deliveryOrder",
  reducers: {
    resetState() {
      return initialState;
    },
    setWarehouse(
      state,
      { payload }: PayloadAction<iDeliveryOrderEmployee | null>
    ) {
      state.warehouse = payload;
    },
    setCourier(
      state,
      { payload }: PayloadAction<iDeliveryOrderEmployee | null>
    ) {
      state.courier = payload;
    },
    resetCourierAndWarehouse(state) {
      state.warehouse = initialState.warehouse;
      state.courier = initialState.courier;
    },
    updateOrderItemSelectedQuantity(
      state,
      { payload }: PayloadAction<iUpdateOrderItemSelectedQuantityPayload>
    ) {
      const orderIndex = state.ordersData.findIndex(
        (_order) => _order.orderId === payload.orderId
      );
      if (orderIndex === -1) return;
      const orderItemIndex = state.ordersData[
        orderIndex
      ].orderItemList.findIndex((_item) => _item.id === payload.id);
      if (orderItemIndex === -1) return;
      state.ordersData[orderIndex].orderItemList[
        orderItemIndex
      ].selectedQuantity = payload.quantity;
    },
    deleteOrder(state, { payload }: PayloadAction<iDeleteOrderPayload>) {
      const orderIndex = state.ordersData.findIndex(
        (order) => order.orderId === payload.orderId
      );
      if (orderIndex === -1) return;
      state.ordersData.splice(orderIndex, 1);
    },
    clearOrderItem(state, { payload }: PayloadAction<iClearOrderItemPayload>) {
      const orderIndex = state.ordersData.findIndex(
        (order) => order.orderId === payload.orderId
      );
      if (orderIndex === -1) return;
      const orderItemIndex = state.ordersData[
        orderIndex
      ].orderItemList.findIndex((orderItem) => orderItem.id === payload.id);
      if (orderItemIndex === -1) return;
      state.ordersData[orderIndex].orderItemList[
        orderItemIndex
      ].selectedQuantity = 0;
    },
    setOrdersData(
      state,
      {
        payload: { arrDeliveryFeasibility, deliveryOrderData },
      }: PayloadAction<{
        arrDeliveryFeasibility: iDeliveryFeasibility[];
        deliveryOrderData: iDeliveryOrderResponse | undefined;
      }>
    ) {
      state.ordersData = mapFeasibilityToDeliveryOrder(
        arrDeliveryFeasibility,
        deliveryOrderData
      );
    },
    setDeliveryOrderData(
      state,
      { payload }: PayloadAction<iDeliveryOrderResponse>
    ) {
      state.deliveryOrder = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const useDeliveryOrderActions = (): typeof deliveryOrderSlice.actions =>
  useActions(deliveryOrderSlice.actions);

export default deliveryOrderSlice.reducer;
