import { CustomItemType, DiscountType, ItemType } from "../../../pos/models";
import { DiscountTypes } from "../../../pos/payment/types";
import { CartAPIResponse, iTax } from "../../../pos/cart/models";
import { CartStatus } from "../../../pos/cart/constants";
import { PaymentStatus } from "../../../report/types";
import { iGetUserInfoResponseData } from "../../../user/models";
import { formatDate, parseDate } from "../../utils";

// Todo: move to utils
const BACKEND_DATE_FORMAT = "YYYY-MM-DD[T]HH:mm:ss[.]SSS[00]ZZ";
const FRONTEND_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS00ZZ";

export const currencyFormatter = (
  unformattedValue: string | number | null | undefined,
  currency = "IDR"
): string => {
  if (typeof unformattedValue === "undefined" || unformattedValue === null)
    return "-";

  const unformattedString =
    typeof unformattedValue !== "string"
      ? unformattedValue.toString()
      : unformattedValue;

  const formattedString = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(parseFloat(unformattedString));

  return formattedString;
};

export function parseStringToDate(dateString: string) {
  return parseDate(dateString, FRONTEND_DATE_FORMAT);
}

export function formatDateToString(date: Date) {
  return formatDate(date, BACKEND_DATE_FORMAT, false);
}

export function parseStringTo2DecimalFloat(val: string) {
  return parseFloat(parseFloat(val).toFixed(2));
}

export function roundDown(num: number, decimals = 2) {
  decimals = decimals || 0;
  return Math.floor(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function getPlaceholderResponse(param: {
  currUser?: iGetUserInfoResponseData;
  storeId?: string;
  cartStatus?: CartStatus;
  paymentStatus?: PaymentStatus;
  cartId?: string;
}): CartAPIResponse["data"] {
  const today = new Date();
  const formattedToday = formatDateToString(today);

  const user: iGetUserInfoResponseData = param.currUser ?? {
    userInfo: {
      id: "",
      name: "",
      email: "a@a.com",
      createdAt: "",
      phoneNumber: "",
      status: "",
      blockedUntil: null,
      loginNeeded: false,
    },
    isUserLinked: false,
    userStoreList: [],
  };

  return {
    cartCustomList: [],
    cartDiscountList: [],
    cartItemList: [],
    cartStatus: param.cartStatus ?? CartStatus.ACTIVE,
    cashierId: user.userInfo.id,
    cashierName: user.userInfo.name,
    createdAt: formattedToday,
    currency: "IDR",
    customer: null,
    grossAmount: "0",
    id: param.cartId ?? "",
    netAmount: "0",
    paymentStatus: param.paymentStatus ?? PaymentStatus.UNPAID,
    storeId: param.storeId ?? "",
    totalItems: 0,
    totalPayment: "0",
    updatedAt: formattedToday,
  };
}

export function recalculateOverallPrices(param: {
  cartItemList?: ItemType[];
  cartCustomItemList?: CustomItemType[];
  cartDiscountList?: DiscountType[];
  taxSettings?: Omit<iTax, "totalTaxAmount">;
}) {
  const cartItemList = param.cartItemList || [];
  const cartCustomItemList = param.cartCustomItemList || [];
  const cartDiscountList = param.cartDiscountList || [];
  const taxSettings = param.taxSettings || {
    isTaxActive: false,
    isTaxIncludeInPrice: false,
    isTaxAfterDiscount: false,
    taxPercentageAmount: "0",
  };
  const cartTaxPercentageAmount =
    parseInt(taxSettings.taxPercentageAmount, 10) || 0;

  let totalItems = 0,
    grossAmount = 0.0,
    netAmount = 0.0,
    totalTaxAmount = 0.0;
  let itemsTotal = 0.0,
    itemTotalDiscount = 0,
    itemTotalPriceBeforeDiscount = 0.0;

  // Iterate inventory item
  for (let i = 0; i < cartItemList.length; i++) {
    const currItem = cartItemList[i];
    totalItems += currItem.quantity;
    grossAmount += parseStringTo2DecimalFloat(currItem.totalPrice);
    netAmount += parseStringTo2DecimalFloat(currItem.netPrice);
    itemTotalDiscount += parseFloat(currItem.discount ?? "0");
  }
  itemsTotal = netAmount;
  itemTotalPriceBeforeDiscount = grossAmount;

  // Iterate custom item
  for (let i = 0; i < cartCustomItemList.length; i++) {
    const currCustomItem = cartCustomItemList[i];
    grossAmount += parseStringTo2DecimalFloat(currCustomItem.totalPrice);
    netAmount += parseStringTo2DecimalFloat(currCustomItem.totalPrice);
  }

  for (let i = 0; i < cartDiscountList.length; i++) {
    const currDiscount = cartDiscountList[i];
    const totalDiscount = calculateDiscountAmount(
      currDiscount,
      grossAmount,
      itemsTotal
    );

    currDiscount.totalDiscount = String(totalDiscount);

    itemTotalDiscount += calculateItemOnlyDiscountAmount(
      currDiscount,
      itemsTotal
    );

    netAmount -= totalDiscount;
  }

  netAmount = Math.max(0, netAmount);

  if (taxSettings.isTaxActive) {
    let taxableItemsTotal = itemTotalPriceBeforeDiscount;
    let taxPercentageAmountDivider = 100;

    if (taxSettings.isTaxAfterDiscount) {
      taxableItemsTotal -= itemTotalDiscount;
    }

    if (taxSettings.isTaxIncludeInPrice) {
      taxPercentageAmountDivider += cartTaxPercentageAmount;
    }

    // Round down total tax amount
    totalTaxAmount = roundDown(
      (taxableItemsTotal * cartTaxPercentageAmount) / taxPercentageAmountDivider
    );

    if (!taxSettings.isTaxIncludeInPrice) {
      netAmount += totalTaxAmount;
    }
  }

  return {
    totalItems,
    netAmount,
    tax: {
      ...taxSettings,
      totalTaxAmount: totalTaxAmount.toString(),
    },
    grossAmount,
    cartDiscountList,
  };
}

export function calculateDiscountAmount(
  discount: DiscountType,
  grossAmount: number,
  itemAmount: number // currently is not used
) {
  if (discount.discountType === DiscountTypes.PERCENTAGE) {
    return (grossAmount * parseStringTo2DecimalFloat(discount.amount)) / 100;
  } else if (discount.discountType === DiscountTypes.PRICE) {
    return parseStringTo2DecimalFloat(discount.amount);
  } else {
    return (itemAmount * parseStringTo2DecimalFloat(discount.amount)) / 100;
  }
}

export function calculateTotalItemDiscount(itemList: ItemType[]): number {
  let res = 0;
  itemList.forEach((item) => {
    if (item.discount) {
      res += Number(item.discount);
    }
  });
  return res;
}

export function calculateItemOnlyDiscountAmount(
  discount: DiscountType,
  itemAmount: number
) {
  if (discount.discountType === DiscountTypes.PRICE) {
    return parseStringTo2DecimalFloat(discount.amount);
  } else {
    return (itemAmount * parseStringTo2DecimalFloat(discount.amount)) / 100;
  }
}

export function generateOrderCode(): string {
  const today = new Date();
  const formattedDate = formatDate(today, "YYYY/MM", false);
  return `${formattedDate}/W${generateRandomString(6)}`;
}

export function generateRandomString(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
