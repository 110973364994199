import { responseRouter as router } from "../../router";
import { Endpoints } from "../../../../../common/api/endpoints";
import {
  CreateCartAPIRequest,
  CartAPIResponse,
  CartAddItemAPIRequest,
  CartCheckoutAPIResponse,
  CartCheckoutAPIRequest,
  GetCartByIdAPIRequest,
  CartCancelAPIResponse,
  CartSavedListAPIResponse,
  CartSavedListAPIRequest,
  CartSaveAPIRequest,
  CartDiscountAPIRequest,
  CartClearItemAPIRequest,
  DeleteCartDiscountRequest,
  UpdateCartDIscountAPIRequest,
  CartAddCustomItemAPIRequest,
  CartUpdateCustomItemAPIRequest,
  CartClearCustomItemAPIRequest,
  CartActivateAPIRequest,
  CreateDiscountItemAPIRequest,
  UpdateDiscountItemAPIRequest,
  DeleteDiscountItemAPIRequest,
} from "../../../../../pos";
import { db } from "../../../../../inventory/offline";

router.post<CartAPIResponse, CreateCartAPIRequest>(
  Endpoints.CART,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.put<CartAPIResponse, CartAddItemAPIRequest>(
  Endpoints.ADD_ITEM_TO_CART,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.post<CartCheckoutAPIResponse, CartCheckoutAPIRequest>(
  Endpoints.CHECKOUT_CART,
  async (_req, resp) => {
    // TODO : Implement save to local db
    return resp;
  }
);

router.post<CartAPIResponse, GetCartByIdAPIRequest>(
  Endpoints.GET_CART_BY_ID,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.get<CartAPIResponse, void, GetCartByIdAPIRequest>(
  Endpoints.GET_CART_BY_ID,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.patch<CartCancelAPIResponse, { id: string[]; storeId: string }>(
  Endpoints.CANCEL_CART,
  async (req, resp) => {
    const { storeId } = req.body;
    const activeCarts = await db.carts
      .where("storeId")
      .equals(storeId)
      .and((x) => x.cartStatus === "Active")
      .toArray();

    const cartIds = activeCarts.map((c) => c.id);

    await db.carts.bulkDelete(cartIds);
    return resp;
  }
);

router.get<CartSavedListAPIResponse, void, void, CartSavedListAPIRequest>(
  Endpoints.CART,
  async (_req, resp) => {
    await db.carts.bulkPut(resp.data);
    return resp;
  }
);

router.post<CartAPIResponse, CartSaveAPIRequest["data"]>(
  Endpoints.SAVE_CART,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.delete<CartAPIResponse, void, CartClearItemAPIRequest>(
  Endpoints.CART_ITEM,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.post<CartAPIResponse, CartDiscountAPIRequest>(
  Endpoints.CART_DISCOUNT,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.delete<CartAPIResponse, void, DeleteCartDiscountRequest>(
  Endpoints.CART_DISCOUNT_BY_ID,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.patch<
  CartAPIResponse,
  Omit<UpdateCartDIscountAPIRequest, "cartId" | "discountId">,
  Pick<UpdateCartDIscountAPIRequest, "cartId" | "discountId">
>(Endpoints.CART_DISCOUNT_BY_ID, async (_req, resp) => {
  await db.carts.put(resp.data);
  return resp;
});

router.post<CartAPIResponse, CartAddCustomItemAPIRequest>(
  Endpoints.ADD_CUSTOM_ITEM_TO_CART,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.patch<
  CartAPIResponse,
  Pick<CartUpdateCustomItemAPIRequest, "cartId" | "customItemId">,
  Omit<CartUpdateCustomItemAPIRequest, "cartId" | "customItemId">
>(Endpoints.CART_CUSTOM_ITEM, async (_req, resp) => {
  await db.carts.put(resp.data);
  return resp;
});

router.delete<CartAPIResponse, void, CartClearCustomItemAPIRequest>(
  Endpoints.CART_CUSTOM_ITEM,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.post<CartAPIResponse, CartActivateAPIRequest>(
  Endpoints.ACTIVATE_CART,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);

router.post<
  CartAPIResponse,
  CreateDiscountItemAPIRequest["payload"],
  CreateDiscountItemAPIRequest["routeParams"]
>(Endpoints.CREATE_DISCOUNT_ITEM, async (_req, resp) => {
  await db.carts.put(resp.data);
  return resp;
});

router.patch<
  CartAPIResponse,
  UpdateDiscountItemAPIRequest["payload"],
  UpdateDiscountItemAPIRequest["routeParams"]
>(Endpoints.MUTATE_DISCOUNT_ITEM, async (_req, resp) => {
  await db.carts.put(resp.data);
  return resp;
});

router.delete<CartAPIResponse, unknown, DeleteDiscountItemAPIRequest>(
  Endpoints.MUTATE_DISCOUNT_ITEM,
  async (_req, resp) => {
    await db.carts.put(resp.data);
    return resp;
  }
);
