import * as React from "react";
import { useModalState } from "@common/hooks";
import { Modal } from "@gada-saas/web-ui";
import { iModalOptions } from "./types";

const useModal = () => {
  const [isOpen, toggleIsOpen, setOpen] = useModalState();
  const [options, setOptions] = React.useState<iModalOptions>({
    title: "",
    content: "",
    primaryButtonText: "",
    onClickPrimaryButton: () => {},
    secondaryButtonText: "",
    onClickSecondaryButton: () => {},
  });

  const showModal = React.useCallback(
    (modalOptions: iModalOptions) => {
      setOptions(modalOptions);
      setOpen(true);
    },
    [setOpen, setOptions]
  );

  const setModalOptions = React.useCallback(
    (modalOptions: Partial<iModalOptions>) => {
      setOptions((o) => ({ ...o, ...modalOptions }));
    },
    []
  );

  const ModalComponent = React.useMemo(() => {
    return (
      <Modal
        open={isOpen}
        withCloseButton
        onClickCloseButton={toggleIsOpen}
        {...options}
      />
    );
  }, [options, isOpen, toggleIsOpen]);

  return {
    isOpen,
    toggleIsOpen,
    setOpen,
    showModal,
    Modal: ModalComponent,
    setModalOptions,
  };
};

export default useModal;
