import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const ReportIcon: React.VFC<IconProps> = ({
  width = "24",
  height = "24",
  fill = theme.palette.interface.neutral.light,
}) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        d="m3.9375 18.286v0.75h16.219c0.0211 0 0.0437 0.0084 0.0622 0.0272 0.0189 0.0192 0.0316 0.0478 0.0316 0.08s-0.0127 0.0607-0.0316 0.0799c-0.0185 0.0188-0.0411 0.0272-0.0622 0.0272h-16.312c-0.04045 0-0.09375-0.037-0.09375-0.1071v-14.286c0-0.03219 0.0127-0.06073 0.03162-0.07995 0.01852-0.01882 0.04111-0.02719 0.06213-0.02719s0.04361 0.00837 0.06213 0.02719c0.01892 0.01922 0.03162 0.04776 0.03162 0.07995v13.429zm16.291-11.124c5e-4 0.02877-0.0092 0.05497-0.0247 0.0743l-5.8921 5.9857c-1e-4 0-1e-4 1e-4 -1e-4 1e-4 -0.0185 0.0187-0.0411 0.027-0.062 0.027s-0.0435-0.0083-0.062-0.027c0 0 0-1e-4 -1e-4 -1e-4l-2.7784-2.8225-0.5345-0.543-0.5345 0.543-3.0461 3.0945c-0.01736 0.0143-0.0373 0.0206-0.05601 0.0202-0.02057-3e-4 -0.04257-0.0088-0.06069-0.0272-0.01846-0.0187-0.03105-0.0464-0.03159-0.0778-5e-4 -0.0288 0.0092-0.055 0.0247-0.0743l3.6421-3.7c1e-4 -4e-5 1e-4 -9e-5 1e-4 -1.4e-4 0.0185-0.01869 0.041-0.027 0.062-0.027s0.0435 0.00831 0.062 0.027c0 5e-5 0 1e-4 1e-4 1.4e-4l2.7784 2.8226 0.5345 0.5429 0.5345-0.5429 5.2961-5.3803c0.0174-0.01431 0.0373-0.02057 0.056-0.02023 0.0206 3.7e-4 0.0426 0.00878 0.0607 0.02717 0.0185 0.01877 0.0311 0.04645 0.0316 0.07786z"
        fill={fill}
        stroke={theme.palette.interface.neutral.light}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ReportIcon;
