import * as React from "react";
import { Text } from "@gada-saas/web-ui";

import { useModal, useWindowFocus } from "@common/hooks";

function OfflineRefreshModal() {
  const intervalId = React.useRef<NodeJS.Timeout | null>();
  const { Modal, showModal } = useModal();
  const isWindowFocused = useWindowFocus();

  const updateServiceWorker = React.useCallback(() => {
    // Check whether serviceWorker is supported or not
    const host = window.location.host;

    if (host.startsWith("localhost")) {
      // do not check if it in dev mode
      return;
    }
    if ("serviceWorker" in navigator) {
      /**
       *
       *  Always tries to update the service worker.
       *  By updating service worker, our app will also be updated.
       *  (it's making byte-to-byte comparison, referred from this doc: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update)
       *
       */
      navigator.serviceWorker.getRegistration("/").then((registration) => {
        if (registration) {
          registration.update().catch((err) => {
            console.error(err);
          });
        }
      });
    }
  }, []);

  // // Check when window is focused
  React.useEffect(() => {
    if (isWindowFocused) {
      // Try to update service worker immediately on focus
      updateServiceWorker();

      intervalId.current = setInterval(
        updateServiceWorker,
        // Check for an update every one hour
        1000 * 60 * 60
      );
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [isWindowFocused, updateServiceWorker]);

  React.useEffect(() => {
    if (
      !("serviceWorker" in navigator) ||
      process.env.NODE_ENV !== "production"
    )
      return;

    /**
     * Show refresh modal if new service worker is activated
     * (an article about service worker lifecycle: https://web.dev/service-worker-lifecycle/)
     */
    //
    const channel = new BroadcastChannel("sw-messages");
    channel.addEventListener("message", (event) => {
      if (event.data && event.data.type === "NEW_SW_IS_ACTIVATED") {
        showModal({
          content: (
            <Text>
              Versi baru web ini sudah tersedia, tekan tombol Reload di bawah
              untuk mendapatkan versi terbaru.
            </Text>
          ),
          onClickPrimaryButton: () => window.location.reload(),
          primaryButtonText: "Reload",
        });
      }
    });
  }, [showModal]);

  return Modal;
}

export default OfflineRefreshModal;
