import { CustomerDetail } from "../../crm/models";
import { SalesPerson } from "../order/models";

export type PaymentStateType = {
  isLoading: boolean;
  customer?: CustomerDetail;
  paymentType: string;
  downPaymentMethod: string;
  isPaymentSuccess: boolean;
  salesPerson: SalesPerson | null;
  paymentDuration: number | undefined;
};

export enum PaymentTypes {
  CASH = "Cash",
  BANK_TRANSFER = "Bank Transfer",
  GIRO = "Giro",
}

export enum DiscountTypes {
  PRICE = "Price",
  PERCENTAGE = "Percentage",
  ITEM_PERCENTAGE = "Item Percentage",
}
