import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { SortType } from "../../common/models";
import { globalResetAction } from "../../common/redux/actions";
import { SalesProductState, SalesProductStateSortBy } from "./types";

const initialState: SalesProductState = {
  productVariantName: "",
  sortBy: SalesProductStateSortBy.QUANTITY_IN_SMALLEST_UOM_AGGREGATE,
  sortType: SortType.DESCENDING,
  pageSize: 10,
  page: 1,
};

const salesPerProductSlice = createSlice({
  initialState,
  name: "salesPerProductSlice",
  reducers: {
    setProductName(state: SalesProductState, action: PayloadAction<string>) {
      state.productVariantName = action.payload;
      state.page = 1;
    },
    setSortBy(
      state: SalesProductState,
      action: PayloadAction<SalesProductStateSortBy>
    ) {
      state.sortBy = action.payload;
    },
    setSortType(state: SalesProductState, action: PayloadAction<SortType>) {
      state.sortType = action.payload;
    },
    setPage(state: SalesProductState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const {
  setProductName,
  setSortBy,
  setSortType,
  setPage,
} = salesPerProductSlice.actions;

export const useSalesPerProductActions = (): typeof salesPerProductSlice.actions =>
  useActions(salesPerProductSlice.actions);

export default salesPerProductSlice.reducer;
