import * as React from "react";
import { useIntervalOptions } from "./types";

export const useInterval = ({ callback, delay }: useIntervalOptions) => {
  const savedCallback = React.useRef<useIntervalOptions["callback"]>();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;
