import { createSlice } from "@reduxjs/toolkit";
import { iGetUserInfoErrorResponse, CachedUserSliceStateType } from "./types";
import UserApi from "./hooks";
import OtpApi from "../auth/otp/hooks";
import { useActions } from "../common/hooks/useActions";

const initialState: CachedUserSliceStateType = {
  data: {
    userInfo: {
      id: "",
      name: "",
      createdAt: "",
      email: "",
      phoneNumber: "",
      status: "",
      blockedUntil: null,
      loginNeeded: null,
    },
    isUserLinked: false,
    userStoreList: [],
  },
  isFetching: false,
  isLoading: false,
  error: null,
};

const CachedUserSlice = createSlice({
  initialState,
  name: "cachedUserInfo",
  reducers: {
    resetErrorState: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(UserApi.endpoints.getUserInfo.matchPending, (state) => {
        state.isFetching = true;
        state.isLoading = true;
      })
      .addMatcher(
        UserApi.endpoints.getUserInfo.matchFulfilled,
        (state, action) => {
          state.isFetching = false;
          state.isLoading = false;
          state.data = action.payload.data;
          state.error = null;
        }
      )
      .addMatcher(
        UserApi.endpoints.getUserInfo.matchRejected,
        (state, action) => {
          if (action.payload) {
            // bypass error for network error
            if (action.payload.data?.problem !== "NETWORK_ERROR") {
              state.error = (action.payload as unknown) as iGetUserInfoErrorResponse;
            }
          }
          state.isFetching = false;
          state.isLoading = false;
        }
      )
      .addMatcher(OtpApi.endpoints.getOtp.matchPending, (state) => {
        state.error = null;
      });
  },
});

export const useCachedUserInfoActions = (): typeof CachedUserSlice["actions"] =>
  useActions(CachedUserSlice.actions, undefined);

export default CachedUserSlice;
