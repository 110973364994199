import * as Sentry from "@sentry/react";
export const DEVICE_ENVIRONMENT_STORAGE_KEY = "DEVICE_ENVIRONMENT_HEADERS";

export const getDeviceEnvironmentHeaders = (): {
  [key: string]: string;
} | null => {
  const isBrowser = typeof window !== "undefined";
  const deviceEnvironmentHeaders = isBrowser
    ? localStorage.getItem(DEVICE_ENVIRONMENT_STORAGE_KEY)
    : null;

  if (deviceEnvironmentHeaders) {
    try {
      return JSON.parse(deviceEnvironmentHeaders);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          devEnv: deviceEnvironmentHeaders,
        },
      });

      return null;
    }
  }

  return null;
};
