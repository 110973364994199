import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { pxToRem } from "./utils";

const palette: PaletteOptions = {
  brand: "#00D1C7",
  primary: {
    main: "#007C76",
  },
  interface: {
    white: "#FFFFFF",
    neutral: {
      dark: "#323232",
      primary: "#6B7E7D",
      secondary: "#CDCBCB",
      light: "#F3F3F3",
      lighter: "#F9F9F9",
    },
    orange: {
      dark: "#C53E12",
      primary: "#F5511C",
      secondary: "#E5D2BA",
      light: "#FFE7CA",
    },
    teal: {
      dark: "#007C76",
      primary: "#05A39B",
      secondary: "#76C3C0",
      light: "#C4EFEE",
      lighter: "#EBF8F7",
    },
    blue: {
      lighter: "#E1F0FF",
      primary: "#1976D2",
      secondary: "#42AFF5",
    },
    red: {
      dark: "#AE0806",
      primary: "#DA3836",
      light: "#FFF5F2",
    },
    green: {
      primary: "#299F5B",
    },
  },
  overlay: {
    teal: "#285250",
    neutral: "#464646",
  },
};

const typography: TypographyOptions = {
  fontFamily: ["Inter", "sans-serif"].join(","),
  allVariants: {
    color: palette.interface.neutral.dark,
  },
  h1: {
    fontSize: pxToRem(32),
    lineHeight: pxToRem(48),
    fontWeight: 700,
    letterSpacing: "-0.022em",
  },
  h2: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(36),
    fontWeight: 600,
    letterSpacing: "0.0025em",
  },
  h3: {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(28),
    fontWeight: 700,
    letterSpacing: "-0.017em",
  },
  h4: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 600,
    letterSpacing: "-0.011em",
  },
  h5: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: 600,
    letterSpacing: "0.0125em",
  },
  button: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    textTransform: "none",
    fontWeight: 600,
    letterSpacing: "0.0125em",
  },
  buttonSmall: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(14),
    textTransform: "none",
    fontWeight: 600,
    letterSpacing: "0.0125em",
  },
  body1: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 400,
    letterSpacing: "-0.011em",
  },
  body2: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: 400,
    letterSpacing: "-0.006em",
  },
  pricing: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 700,
  },
  pricingSmall: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(14),
    fontWeight: 600,
  },
  pricingSmallLight: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(14),
    fontWeight: 400,
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 700,
  },
  captionLight: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 400,
  },
  label: {
    fontSize: pxToRem(10),
    lineHeight: pxToRem(10),
    fontWeight: 400,
    letterSpacing: "0.005em",
  },
  labelSemiBold: {
    fontSize: pxToRem(10),
    lineHeight: pxToRem(10),
    fontWeight: 600,
    letterSpacing: "0.005em",
  },
  h6: undefined,
  overline: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
};

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    brand: React.CSSProperties["color"];
    interface: PaletteColors;
    overlay: {
      teal: React.CSSProperties["color"];
      neutral: React.CSSProperties["color"];
    };
  }
  interface PaletteOptions {
    brand: React.CSSProperties["color"];
    interface: PaletteColors;
    overlay: {
      teal: React.CSSProperties["color"];
      neutral: React.CSSProperties["color"];
    };
  }
  interface ThemePaletteOptions {
    brand: React.CSSProperties["color"];
    interface: PaletteColors;
    overlay: {
      teal: React.CSSProperties["color"];
      neutral: React.CSSProperties["color"];
    };
  }
  interface PaletteColors {
    white: React.CSSProperties["color"];
    neutral: Partial<PaletteColorOption>;
    orange: Partial<PaletteColorOption>;
    teal: Partial<PaletteColorOption>;
    blue: Partial<PaletteColorOption>;
    red: Partial<PaletteColorOption>;
    green: Partial<PaletteColorOption>;
  }
  interface PaletteColorOption {
    dark: React.CSSProperties["color"];
    primary: React.CSSProperties["color"];
    secondary: React.CSSProperties["color"];
    light: React.CSSProperties["color"];
    lighter: React.CSSProperties["color"];
  }
}

declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    buttonSmall: React.CSSProperties;
    pricing: React.CSSProperties;
    pricingSmall: React.CSSProperties;
    pricingSmallLight: React.CSSProperties;
    captionLight: React.CSSProperties;
    label: React.CSSProperties;
    labelSemiBold: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    buttonSmall?: React.CSSProperties;
    pricing?: React.CSSProperties;
    pricingSmall?: React.CSSProperties;
    pricingSmallLight?: React.CSSProperties;
    captionLight?: React.CSSProperties;
    label?: React.CSSProperties;
    labelSemiBold?: React.CSSProperties;
  }
}

declare module "@material-ui/core/Typography/Typography" {
  interface TypographyPropsVariantOverrides {
    buttonSmall: true;
    pricing: true;
    pricingSmall: true;
    pricingSmallLight: true;
    captionLight: true;
    label: true;
    labelSemiBold: true;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    overline: false;
  }
}

const token = {
  palette,
  typography,
};

export default token;
