import { useSelector, shallowEqual } from "react-redux";
import { AppStateType } from "../../store";

export const useUserMenusState = (): AppStateType["userMenus"] =>
  useSelector((state: AppStateType) => state.userMenus, shallowEqual);

export const useActiveStoreState = (): AppStateType["userMenus"]["activeStore"] =>
  useSelector(
    (state: AppStateType) => state.userMenus.activeStore,
    shallowEqual
  );

export const useSideMenuState = (): AppStateType["sideMenu"] =>
  useSelector((state: AppStateType) => state.sideMenu, shallowEqual);
