import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dayjsInstance } from "../..";
import { useActions } from "../../common/hooks/useActions";
import { globalResetAction } from "../../common/redux/actions";
import { ActivityName, stockType } from "./models";
import { InventoryHistoryInitialState } from "./types";

const initialState: InventoryHistoryInitialState = {
  filterState: {
    dateFrom: dayjsInstance().subtract(1, "month").format("YYYY-MM-DD"),
    dateTo: dayjsInstance().format("YYYY-MM-DD"),
    activityName: "",
    userId: "",
  },
  uiState: {},
};

export const inventoryHistorySlice = createSlice({
  name: "inventory-history",
  initialState,
  reducers: {
    updateDates(
      state: InventoryHistoryInitialState,
      { payload }: PayloadAction<{ dateFrom: string; dateTo: string }>
    ) {
      state.filterState.dateFrom = payload.dateFrom;
      state.filterState.dateTo = payload.dateTo;
    },
    updateActivityName(
      state: InventoryHistoryInitialState,
      { payload }: PayloadAction<ActivityName | "">
    ) {
      state.filterState.activityName = payload;
    },
    updateCashier(
      state: InventoryHistoryInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.filterState.userId = payload;
    },
    setStockType(
      state: InventoryHistoryInitialState,
      { payload }: PayloadAction<stockType | undefined>
    ) {
      state.filterState.stockType = payload;
    },
    setParentActivity(
      state: InventoryHistoryInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.uiState.parentActivity = payload;
    },
    setSubActivity(
      state: InventoryHistoryInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.uiState.subActivity = payload;
    },
    setUiState(
      state: InventoryHistoryInitialState,
      { payload }: PayloadAction<InventoryHistoryInitialState["uiState"]>
    ) {
      state.uiState = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const {
  updateDates,
  updateActivityName,
  updateCashier,
  setStockType,
} = inventoryHistorySlice.actions;

export const useInventoryHistoryActions = (): typeof inventoryHistorySlice.actions =>
  useActions(inventoryHistorySlice.actions);

export default inventoryHistorySlice.reducer;
