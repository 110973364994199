import * as React from "react";
import { IconProps } from "./types";

const GadaIcon: React.VFC<IconProps> = ({
  width = "29",
  height = "28",
  fill = "none",
}) => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox="0 0 29 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)" fill="#fff">
        <path d="m3.9945 9.7389c0.93756 0 1.6976-0.76004 1.6976-1.6976 0-0.93756-0.76004-1.6976-1.6976-1.6976-0.93755 0-1.6976 0.76004-1.6976 1.6976 0 0.93755 0.76004 1.6976 1.6976 1.6976z" />
        <path d="m25.045 9.7389c0.9375 0 1.6976-0.76004 1.6976-1.6976 0-0.93756-0.7601-1.6976-1.6976-1.6976-0.9376 0-1.6976 0.76004-1.6976 1.6976 0 0.93755 0.76 1.6976 1.6976 1.6976z" />
        <path d="m14.52 15.833c0.9375 0 1.6976-0.7601 1.6976-1.6976 0-0.9376-0.7601-1.6976-1.6976-1.6976-0.9376 0-1.6976 0.76-1.6976 1.6976 0 0.9375 0.76 1.6976 1.6976 1.6976z" />
        <path d="m25.045 21.964c0.9375 0 1.6976-0.76 1.6976-1.6976s-0.7601-1.6976-1.6976-1.6976c-0.9376 0-1.6976 0.76-1.6976 1.6976s0.76 1.6976 1.6976 1.6976z" />
        <path d="m14.519 27.986c0.9373 0 1.6971-0.7598 1.6971-1.6971s-0.7598-1.6971-1.6971-1.6971-1.6971 0.7598-1.6971 1.6971 0.7598 1.6971 1.6971 1.6971z" />
        <path d="m3.9945 21.964c0.93756 0 1.6976-0.76 1.6976-1.6976s-0.76004-1.6976-1.6976-1.6976c-0.93755 0-1.6976 0.76-1.6976 1.6976s0.76004 1.6976 1.6976 1.6976z" />
        <path d="m14.829 3.647c0.9214-0.17162 1.5293-1.0577 1.3577-1.9792-0.1716-0.92145-1.0577-1.5293-1.9792-1.3577s-1.5293 1.0577-1.3577 1.9792 1.0577 1.5293 1.9792 1.3577z" />
        <path d="m14.52 27.173c-0.1549-1e-4 -0.307-0.0409-0.4411-0.1182l-10.526-6.0773c-0.13426-0.0775-0.24577-0.1889-0.32329-0.3231-0.07751-0.1342-0.11831-0.2864-0.11828-0.4414v-12.154c3e-5 -0.15504 0.04085-0.30733 0.11836-0.4416 0.0775-0.13426 0.18897-0.24577 0.32321-0.32333l10.526-6.0745 0.8817 1.527-10.083 5.8217v11.135l9.6426 5.5675 9.643-5.5675v-10.114l-9.2024 5.311-0.8827-1.5289 10.525-6.0774c0.1343-0.07751 0.2866-0.11832 0.4416-0.11832 0.155 1e-5 0.3073 0.04082 0.4416 0.11835 0.1342 0.07752 0.2457 0.18902 0.3232 0.32329s0.1183 0.28657 0.1183 0.44161v12.154c0 0.155-0.0409 0.3072-0.1184 0.4414-0.0775 0.1341-0.1889 0.2456-0.3231 0.3231l-10.526 6.0773c-0.134 0.0772-0.286 0.118-0.4406 0.1182z" />
        <g opacity=".5">
          <path d="m14.8 16.5h-0.5616v1.3611h0.5616v-1.3611z" opacity=".5" />
          <path d="m14.8 18.529h-0.5616v1.3611h0.5616v-1.3611z" opacity=".5" />
          <path d="m14.8 20.535h-0.5616v1.3612h0.5616v-1.3612z" opacity=".5" />
          <path d="m14.8 22.565h-0.5616v1.3611h0.5616v-1.3611z" opacity=".5" />
        </g>
        <g opacity=".5">
          <path d="m14.8 4.3455h-0.5616v1.3612h0.5616v-1.3612z" opacity=".5" />
          <path d="m14.8 6.3743h-0.5616v1.3612h0.5616v-1.3612z" opacity=".5" />
          <path d="m14.8 8.3806h-0.5616v1.3612h0.5616v-1.3612z" opacity=".5" />
          <path d="m14.8 10.41h-0.5616v1.3611h0.5616v-1.3611z" opacity=".5" />
        </g>
        <g opacity=".5">
          <path
            d="m7.0792 18.106-1.1788 0.6805 0.28083 0.4864 1.1788-0.6805-0.28083-0.4864z"
            opacity=".5"
          />
          <path
            d="m8.837 17.092-1.1788 0.6806 0.28083 0.4864 1.1788-0.6806-0.28083-0.4864z"
            opacity=".5"
          />
          <path
            d="m10.573 16.089-1.1788 0.6805 0.28083 0.4864 1.1788-0.6805-0.2809-0.4864z"
            opacity=".5"
          />
          <path
            d="m12.331 15.073-1.1788 0.6806 0.2809 0.4864 1.1788-0.6805-0.2809-0.4865z"
            opacity=".5"
          />
        </g>
        <g opacity=".5">
          <path
            d="m16.707 15.075-0.2808 0.4864 1.1788 0.6806 0.2808-0.4864-1.1788-0.6806z"
            opacity=".5"
          />
          <path
            d="m18.464 16.091-0.2808 0.4864 1.1788 0.6806 0.2808-0.4864-1.1788-0.6806z"
            opacity=".5"
          />
          <path
            d="m20.203 17.094-0.2808 0.4864 1.1788 0.6806 0.2808-0.4865-1.1788-0.6805z"
            opacity=".5"
          />
          <path
            d="m21.96 18.108-0.2808 0.4864 1.1788 0.6806 0.2808-0.4864-1.1788-0.6806z"
            opacity=".5"
          />
        </g>
        <g opacity=".5">
          <path
            d="m6.1812 8.9987-0.28083 0.48642 1.1788 0.68059 0.28083-0.48642-1.1788-0.68059z"
            opacity=".5"
          />
          <path
            d="m7.9371 10.013-0.28083 0.4864 1.1788 0.6805 0.28083-0.4864-1.1788-0.6805z"
            opacity=".5"
          />
          <path
            d="m9.6754 11.016-0.28083 0.4864 1.1788 0.6806 0.2809-0.4864-1.1788-0.6806z"
            opacity=".5"
          />
          <path
            d="m11.433 12.03-0.2809 0.4864 1.1788 0.6806 0.2809-0.4864-1.1788-0.6806z"
            opacity=".5"
          />
        </g>
        <g opacity=".5">
          <path
            d="m16.707 2.9272-0.2808 0.48641 1.1788 0.68059 0.2808-0.48642-1.1788-0.68058z"
            opacity=".5"
          />
          <path
            d="m18.464 3.9413-0.2808 0.48641 1.1788 0.68058 0.2808-0.48641-1.1788-0.68058z"
            opacity=".5"
          />
          <path
            d="m20.203 4.9462-0.2808 0.48642 1.1788 0.68058 0.2808-0.48642-1.1788-0.68058z"
            opacity=".5"
          />
          <path
            d="m21.96 5.9601-0.2808 0.48641 1.1788 0.68058 0.2808-0.48641-1.1788-0.68058z"
            opacity=".5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <rect
            transform="translate(2.25)"
            width="24.532"
            height="28"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GadaIcon;
