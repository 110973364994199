import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { SortType } from "../../common/models";
import { globalResetAction } from "../../common/redux/actions";
import { iPrincipal, iUnitOfMeasurement } from "../common/models";
import { GetProductProposalItem } from "./types";

export enum InventorySortByType {
  LOWEST_STOCK = "LOWEST_STOCK",
  RECENTLY_MODIFIED = "RECENTLY_MODIFIED",
  PRODUCT_VARIANT_NAME = "PRODUCT_VARIANT_NAME",
}

type InventoryListState = {
  dataStore: {
    keyword: string | null;
    isConsignor: boolean | null;
    isSoldOnline: boolean | null;
    pageSize: number;
    page: number;
    principalIds: Array<string>;
    sortBy: InventorySortByType;
    sortType: SortType;
    uomId: Array<number>;
    barcode: string | null;
    isLowStock: boolean | null;
  };
  uiStore: {
    activeFilters: ActiveFilterItem[];
    isAddNewItemModalOpened: boolean;
    isDrawerOpened: boolean;
    isProductProposalDrawerOpened: boolean;
    isProductProposalDrawerDetailOpened: boolean;
    productProposalDrawerDetailItem: GetProductProposalItem;
    showDeleteProductVariantModal: boolean;
    productVariantIdToBeDeleted: number;
    totalItems: number;
    isBulkStatusChangeModalOpened: boolean;
    showTambahNavigationModal: boolean;
  };
};

export type ActiveFilterItem =
  | {
      type: "principal";
      value: string;
      displayName: string;
    }
  | { type: "uom"; value: number; displayName: string }
  | { type: "consignmentStatus"; value: boolean; displayName: string }
  | { type: "onlineSellingStatus"; value: boolean; displayName: string };

export const initialListState: InventoryListState = {
  dataStore: {
    keyword: null,
    isConsignor: null,
    isSoldOnline: null,
    pageSize: 10,
    page: 1,
    principalIds: [],
    sortBy: InventorySortByType.RECENTLY_MODIFIED,
    sortType: SortType.DESCENDING,
    uomId: [],
    barcode: null,
    isLowStock: null,
  },
  uiStore: {
    totalItems: 0,
    activeFilters: [],
    isAddNewItemModalOpened: false,
    isDrawerOpened: false,
    isProductProposalDrawerOpened: false,
    isProductProposalDrawerDetailOpened: false,
    productProposalDrawerDetailItem: {
      id: 0,
      uomName: "",
      productUnitId: 0,
      productVariantStoreId: 0,
      productVariantId: 0,
      status: "PENDING",
      createdAt: "",
      productVariantName: "",
      statusReasonNotes: "",
      statusReason: "",
    },
    showDeleteProductVariantModal: false,
    productVariantIdToBeDeleted: 0,
    isBulkStatusChangeModalOpened: false,
    showTambahNavigationModal: false,
  },
};

const InventoryListSlice = createSlice({
  name: "paramIList",
  initialState: initialListState,
  reducers: {
    setKeyword(state, action: PayloadAction<string>) {
      if (!action.payload) {
        state.dataStore.keyword = null;
      } else {
        state.dataStore.keyword = action.payload;
      }
    },
    setIsConsignor(state, action: PayloadAction<boolean | null>) {
      state.dataStore.isConsignor = action.payload;
      if (action.payload !== null) {
        const activeFilterIdx = state.uiStore.activeFilters.findIndex(
          (filter) => {
            filter.type === "consignmentStatus" &&
              filter.value === action.payload;
          }
        );

        // If not found just push, otherwise just replace
        if (activeFilterIdx < 0) {
          state.uiStore.activeFilters.push({
            type: "consignmentStatus",
            value: action.payload,
            displayName: action.payload
              ? "Barang titipan"
              : "Bukan Barang titipan",
          });
        } else {
          state.uiStore.activeFilters[activeFilterIdx] = {
            type: "consignmentStatus",
            value: action.payload,
            displayName: action.payload
              ? "Barang titipan"
              : "Bukan Barang titipan",
          };
        }
      } else {
        // if set to null then it means we remove it from active filter
        state.uiStore.activeFilters = state.uiStore.activeFilters.filter(
          (filter) => {
            filter.type !== "consignmentStatus";
          }
        );
      }
    },
    setIsSoldOnline(state, action: PayloadAction<boolean | null>) {
      state.dataStore.isSoldOnline = action.payload;
      if (action.payload !== null) {
        const activeFilterIdx = state.uiStore.activeFilters.findIndex(
          (filter) => {
            filter.type === "onlineSellingStatus" &&
              filter.value === action.payload;
          }
        );

        // If not found just push, otherwise just replace
        if (activeFilterIdx < 0) {
          state.uiStore.activeFilters.push({
            type: "onlineSellingStatus",
            value: action.payload,
            displayName: action.payload
              ? "Dijual Online"
              : "Tidak dijual Online",
          });
        } else {
          state.uiStore.activeFilters[activeFilterIdx] = {
            type: "onlineSellingStatus",
            value: action.payload,
            displayName: action.payload
              ? "Dijual Online"
              : "Tidak dijual Online",
          };
        }
      } else {
        // if set to null then it means we remove it from active filter
        state.uiStore.activeFilters = state.uiStore.activeFilters.filter(
          (filter) => {
            filter.type !== "consignmentStatus";
          }
        );
      }
    },
    setPage(state, action: PayloadAction<number>) {
      state.dataStore.page = action.payload;
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.uiStore.totalItems = action.payload;
    },
    addPrincipalId(state, action: PayloadAction<string>) {
      state.dataStore.principalIds.push(action.payload);
    },
    togglePrincipal(state, action: PayloadAction<iPrincipal>) {
      if (!state.dataStore.principalIds.includes(action.payload.id)) {
        state.dataStore.principalIds.push(action.payload.id);

        state.uiStore.activeFilters.push({
          type: "principal",
          value: action.payload.id,
          displayName: action.payload.name,
        });
      } else {
        state.dataStore.principalIds = state.dataStore.principalIds.filter(
          (id) => id !== action.payload.id
        );

        const activeFilterIdx = state.uiStore.activeFilters.findIndex(
          (filter) => {
            return (
              filter.type === "principal" && filter.value === action.payload.id
            );
          }
        );

        state.uiStore.activeFilters.splice(activeFilterIdx, 1);
      }
    },
    removePrincipalId(state, action: PayloadAction<string>) {
      state.dataStore.principalIds = state.dataStore.principalIds.filter(
        (id) => id !== action.payload
      );

      const activeFilterIdx = state.uiStore.activeFilters.findIndex(
        (filter) => {
          return filter.type === "principal" && filter.value === action.payload;
        }
      );

      state.uiStore.activeFilters.splice(activeFilterIdx, 1);
    },
    addUomId(state, action: PayloadAction<number>) {
      state.dataStore.uomId.push(action.payload);
    },
    toggleUomId(state, action: PayloadAction<iUnitOfMeasurement>) {
      if (!state.dataStore.uomId.includes(action.payload.id)) {
        state.dataStore.uomId.push(action.payload.id);
        state.uiStore.activeFilters.push({
          type: "uom",
          value: action.payload.id,
          displayName: action.payload.longName,
        });
      } else {
        state.dataStore.uomId = state.dataStore.uomId.filter(
          (id) => id !== action.payload.id
        );

        const activeFilterIdx = state.uiStore.activeFilters.findIndex(
          (filter) => {
            return filter.type === "uom" && filter.value === action.payload.id;
          }
        );

        state.uiStore.activeFilters.splice(activeFilterIdx, 1);
      }
    },
    removeUomId(state, action: PayloadAction<number>) {
      state.dataStore.uomId = state.dataStore.uomId.filter(
        (id) => id !== action.payload
      );
      const activeFilterIdx = state.uiStore.activeFilters.findIndex(
        (filter) => {
          return filter.type === "uom" && filter.value === action.payload;
        }
      );

      state.uiStore.activeFilters.splice(activeFilterIdx, 1);
    },
    setProductProposalDrawerDetailItem(
      state,
      action: PayloadAction<GetProductProposalItem>
    ) {
      state.uiStore.productProposalDrawerDetailItem = action.payload;
    },
    setSortType(state, action: PayloadAction<SortType>) {
      state.dataStore.sortType = action.payload;
    },
    setSortBy(state, action: PayloadAction<InventorySortByType>) {
      state.dataStore.sortBy = action.payload;
    },
    setIsLowStock(state, action: PayloadAction<boolean | null>) {
      state.dataStore.isLowStock = action.payload;
    },
    setDrawerState(state, action: PayloadAction<boolean>) {
      state.uiStore.isDrawerOpened = action.payload;
    },
    showAddNewItemModal(state) {
      state.uiStore.isAddNewItemModalOpened = true;
    },
    hideAddNewItemModal(state) {
      state.uiStore.isAddNewItemModalOpened = false;
    },
    showProductProposalDrawer(state) {
      state.uiStore.isProductProposalDrawerOpened = true;
    },
    hideProductProposalDrawer(state) {
      state.uiStore.isProductProposalDrawerOpened = false;
    },
    showProductProposalDetailDrawer(state) {
      state.uiStore.isProductProposalDrawerDetailOpened = true;
    },
    hideProductProposalDetailDrawer(state) {
      state.uiStore.isProductProposalDrawerDetailOpened = false;
    },
    resetFilter(state) {
      state.dataStore = initialListState.dataStore;
      state.uiStore.activeFilters = [];
    },
    showDeleteProductVariantModal(state) {
      state.uiStore.showDeleteProductVariantModal = true;
    },
    hideDeleteProductVariantModal(state) {
      state.uiStore.showDeleteProductVariantModal = false;
    },
    setProductVariantIdToBeDeleted(state, action: PayloadAction<number>) {
      state.uiStore.productVariantIdToBeDeleted = action.payload;
    },
    showBulkStatusChangeModalOpened(state) {
      state.uiStore.isBulkStatusChangeModalOpened = true;
    },
    hideBulkStatusChangeModalOpened(state) {
      state.uiStore.isBulkStatusChangeModalOpened = false;
    },
    setShowTambahNavigationModal(state, action: PayloadAction<boolean>) {
      state.uiStore.showTambahNavigationModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialListState);
  },
});

const { actions } = InventoryListSlice;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useInventoryListActions = () => useActions(actions);

export default InventoryListSlice.reducer;
