import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../common/hooks/useActions";
import { iRecount, iStockOpnameRecount } from "./models";
import { iStockOpnameState } from "./types";
import StockOpnameApi from "./hooks";
import { globalResetAction } from "../common/redux/actions";

const initialState: iStockOpnameState = {
  activeRecount: null,
  stockOpname: null,
  stockOpnameModal: false,
};

export const stockOpnameSlice = createSlice({
  name: "stock-opname",
  initialState,
  reducers: {
    setActiveRecount(state, { payload }: PayloadAction<iRecount | null>) {
      state.activeRecount = payload;
    },
    setStockOpnameModal(state, { payload }: PayloadAction<boolean>) {
      state.stockOpnameModal = payload;
    },
    showStockOpnameDetail(
      state,
      { payload }: PayloadAction<iStockOpnameRecount>
    ) {
      state.stockOpname = payload;
    },
    hideStockOpnameDetailDrawer(state) {
      state.stockOpname = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);

    builder.addMatcher(
      StockOpnameApi.endpoints.getActiveStockOpname.matchFulfilled,
      (state, { payload }) => {
        state.activeRecount = payload.data.recount
          ? payload.data.recount
          : null;
      }
    );
  },
});

export const useStockOpnameActions = () => useActions(stockOpnameSlice.actions);
export default stockOpnameSlice.reducer;
