import {
  iListApiRequest,
  iListApiResponse,
  SortType,
} from "../../common/models";
import { BadStockReportListInventory } from "./types";

export enum BadStockReason {
  NONE = "",
  EXPIRED_STOCK = "EXPIRED_STOCK",
  BROKEN_STOCK = "BROKEN_STOCK",
  ALMOST_EXPIRED = "ALMOST_EXPIRED",
}

export type iGETBadStockReportListRequest = iListApiRequest & {
  storeId: string;
  keyword?: string;
  reason?: BadStockReason;
  brandIds?: number[];
  sortBy?: string; // (PRODUCT_NAME)
  sortType: SortType;
};

export type BadStockReportList = {
  id: string;
  productVariantId: number;
  productVariantName: string;
  displayName?: string;
  brandId: number;
  brandName: string;
  reason: BadStockReason;
  inventories: BadStockReportListInventory[];
  potentialLoss: string;
};

export type iGETBadStockReportListResponse = iListApiResponse<{
  badStockList: BadStockReportList[];
  totalPotentialLoss: string;
}>;

export type iGETBadStockReportBrandsListRequest = iListApiRequest & {
  storeId: string;
};

export type BadStockReportBrandsList = {
  brandId: number;
  brandName: string;
};

export type iGETBadStockReportBrandsListResponse = iListApiResponse<
  BadStockReportBrandsList[]
>;
