import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles<AppTheme, { isOpen: boolean }>(
  (theme: AppTheme) => ({
    button: {
      width: pxToRem(24),
      height: pxToRem(24),
      borderRadius: pxToRem(4),
      color: theme.palette.interface.teal.primary,
      backgroundColor: theme.palette.interface.teal.lighter,
    },
    icon: {
      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      transform: ({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)"),
    },
  })
);
