import Dexie, { Table } from "dexie";
import { iCart, OrderApiResponse } from "../../pos";
import { S3InventoryListAPIResponseItem } from "./types";
import { AxiosRequestConfig } from "axios";
import { StoreTopType } from "../../stores";
import { iGetUserInfoResponseData } from "../../user";
import { CustomerSummary } from "../../crm/models";
import { Employee } from "../../employee-access";
import { BankAccountModel } from "../../common/models";

export interface IndexedS3InventoryListAPIResponseItem
  extends S3InventoryListAPIResponseItem {
  tokens: string[];
  inventoryIds: number[];
}

export interface IndexedCartItem extends iCart {
  storeId: string;
}

export type RequestQueueItem = AxiosRequestConfig & {
  requestQueueId: string;
  storeId: string;
  originalOrderObject: OrderApiResponse["data"];
  apiResponseStatus: string;
  cartId: string;
  retryCount: number;
  responseError?: {
    data: any;
    errorCode: string;
    message: string;
    schemaErrors?: any;
  };
};

export type OfflineMetaData = {
  storeId: string;
  inventoryLastUpdatedTime: string; // DateString
};

export type SavedCartsRequestQueueItem = AxiosRequestConfig & {
  requestQueueId: string;
  storeId: string;
  apiResponseStatus: string;
  cartId: string;
  retryCount: number;
};

export class InventoryDB extends Dexie {
  inventories!: Table<IndexedS3InventoryListAPIResponseItem, string>;
  carts!: Table<IndexedCartItem, IndexedCartItem["id"]>;
  requestQueue!: Table<RequestQueueItem, RequestQueueItem["requestQueueId"]>;
  savedCartsRequestQueue!: Table<
    SavedCartsRequestQueueItem,
    SavedCartsRequestQueueItem["requestQueueId"]
  >;
  // API Cache
  storeSettings!: Table<StoreTopType, StoreTopType["storeId"]>;
  userInfo!: Table<iGetUserInfoResponseData & { storeId: string }, string>;
  customers!: Table<CustomerSummary, CustomerSummary["customerId"]>;
  employees!: Table<Employee, Employee["id"]>;
  metadata!: Table<OfflineMetaData, OfflineMetaData["storeId"]>;
  bankAccounts!: Table<BankAccountModel, BankAccountModel["id"]>;

  constructor() {
    super("InventoryDB");
    this.version(7).stores({
      inventories:
        "&productVariantId,productVariantName,*tokens,*inventoryIds,*barcodes",
      carts: "&id,storeId,cashierId,cartStatus",
      requestQueue: "&requestQueueId,storeId,apiResponseStatus,responseError",
      savedCartsRequestQueue:
        "&requestQueueId,storeId,apiResponseStatus,cartId",
      storeSettings: "&storeId",
      userInfo: "&storeId",
      customers: "&customerId,storeId,*tokens,id,isOffline",
      employees: "&id,position",
    });

    this.version(8)
      .stores({
        metadata: "&storeId",
      })
      .upgrade((trans) => {
        return trans
          .table("metadata")
          .toCollection()
          .modify((meta) => {
            meta.inventoryLastUpdatedTime = "";
          });
      });

    this.version(9).stores({
      bankAccounts: "&id",
    });
  }
}

export const db = new InventoryDB();
