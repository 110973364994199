import { Typography } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useTextStyles } from "./styles";
import { TextProps } from "./types";

const Text: React.VFC<TextProps> = (props: TextProps) => {
  const { variant = "body1", children, ...restProps } = props;

  const muiCompatibleVariant = getMuiCompatibleVariant(variant);
  const classes = useTextStyles(props);

  return (
    <Typography
      variant={muiCompatibleVariant}
      {...restProps}
      classes={{
        root: clsx(classes.colors, classes.fontConfigs, props.textStyle),
      }}
    >
      {children}
    </Typography>
  );
};

function getMuiCompatibleVariant(variant: TextProps["variant"]) {
  if (
    variant === "buttonSmall" ||
    variant === "pricing" ||
    variant === "pricingSmall" ||
    variant === "pricingSmallLight" ||
    variant === "captionLight" ||
    variant === "label" ||
    variant === "labelSemiBold"
  ) {
    // Let use the default
    return "inherit";
  }
  return variant;
}

export default Text;
export const TextWithForwardedRef = React.forwardRef<
  HTMLSpanElement,
  TextProps
>((props, ref) => <Text {...props} ref={ref} />);
