import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { SortType } from "../../common/models";
import { PromotionStatus, PromotionType } from "../types";
import { PromotionListState } from "./types";

const initialState: PromotionListState = {
  keyword: "",
  promotionType: PromotionType.ALL,
  status: PromotionStatus.ALL,
  startDate: "",
  endDate: "",
  page: 1,
  pageSize: 10,
  sortByIndex: -1,
  sortType: null,
};

export const promotionListSlice = createSlice({
  name: "promotionListState",
  initialState,
  reducers: {
    setPromotionListState(_, action: PayloadAction<PromotionListState>) {
      return action.payload;
    },
    setSort(state, action: PayloadAction<number>) {
      if (action.payload === state.sortByIndex) {
        state.sortType =
          state.sortType === SortType.ASCENDING
            ? SortType.DESCENDING
            : SortType.ASCENDING;
      } else {
        state.sortByIndex = action.payload;
        state.sortType = SortType.ASCENDING;
      }
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setKeyword(state, action: PayloadAction<string>) {
      state.keyword = action.payload;
      state.page = 1;
    },
    setDurationFilter(
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.page = 1;
    },
    setStatusFilter(state, action: PayloadAction<PromotionStatus>) {
      state.status = action.payload;
      state.page = 1;
    },
  },
});

export const usePromotionListActions = (): typeof promotionListSlice.actions =>
  useActions(promotionListSlice.actions);
