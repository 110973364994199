import * as React from "react";
import { IconProps } from "./types";

const PinIcon: React.VFC<IconProps> = ({ width = "16", height = "16" }) => {
  return (
    <svg fill="none" width={width} height={height} viewBox="0 0 16 16">
      <path
        d="m10.667 8v-5.3333h0.6666v-1.3333h-6.6666v1.3333h0.66666v5.3333l-1.3333 1.3333v1.3333h3.4667v4h1.0667v-4h3.4667v-1.3333l-1.3333-1.3333z"
        fill="#fff"
      />
    </svg>
  );
};

export default PinIcon;
