import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreDebtInitialState } from "./types";
import { dayjsInstance } from "../..";
import { useActions } from "../../common/hooks/useActions";
import { globalResetAction } from "../../common/redux/actions";

const initialState: StoreDebtInitialState = {
  unpaidFilters: {
    buyingPeriodStart: dayjsInstance()
      .subtract(3, "month")
      .format("YYYY-MM-DD"),
    buyingPeriodEnd: dayjsInstance().format("YYYY-MM-DD"),
    dueDateStart: dayjsInstance().subtract(3, "month").format("YYYY-MM-DD"),
    dueDateEnd: dayjsInstance().add(1, "month").format("YYYY-MM-DD"),
    searchTerm: "",
    page: 1,
  },
  paidFilters: {
    buyingPeriodStart: dayjsInstance()
      .subtract(3, "month")
      .format("YYYY-MM-DD"),
    buyingPeriodEnd: dayjsInstance().format("YYYY-MM-DD"),
    searchTerm: "",
    page: 1,
  },
  debtId: undefined,
};

const StoreDebtSlice = createSlice({
  name: "store-debt-slice",
  initialState,
  reducers: {
    updateUnPaidBuyingRange(
      state: StoreDebtInitialState,
      { payload }: PayloadAction<{ dateFrom: string; dateTo: string }>
    ) {
      state.unpaidFilters.buyingPeriodStart = payload.dateFrom;
      state.unpaidFilters.buyingPeriodEnd = payload.dateTo;
      state.unpaidFilters.page = 1;
    },
    updateUnPaidDueDateRange(
      state: StoreDebtInitialState,
      { payload }: PayloadAction<{ dateFrom: string; dateTo: string }>
    ) {
      state.unpaidFilters.dueDateStart = payload.dateFrom;
      state.unpaidFilters.dueDateEnd = payload.dateTo;
    },
    updateUnPaidSearchTerm(
      state: StoreDebtInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.unpaidFilters.searchTerm = payload;
      state.unpaidFilters.page = 1;
    },
    updatePaidBuyingRange(
      state: StoreDebtInitialState,
      { payload }: PayloadAction<{ dateFrom: string; dateTo: string }>
    ) {
      state.paidFilters.buyingPeriodStart = payload.dateFrom;
      state.paidFilters.buyingPeriodEnd = payload.dateTo;
      state.paidFilters.page = 1;
    },
    updatePaidSearchTerm(
      state: StoreDebtInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.paidFilters.searchTerm = payload;
      state.paidFilters.page = 1;
    },
    showDebtDetailsDrawer(
      state: StoreDebtInitialState,
      { payload }: PayloadAction<{ id: string }>
    ) {
      state.debtId = payload.id;
    },
    closeDebtDetailsDrawer(state: StoreDebtInitialState) {
      state.debtId = initialState.debtId;
    },
    setPaidFilterPage(
      state: StoreDebtInitialState,
      { payload }: PayloadAction<number>
    ) {
      state.paidFilters.page = payload;
    },
    setUnpaidFilterPage(
      state: StoreDebtInitialState,
      { payload }: PayloadAction<number>
    ) {
      state.unpaidFilters.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const {
  updatePaidBuyingRange,
  updatePaidSearchTerm,
  updateUnPaidBuyingRange,
  updateUnPaidDueDateRange,
  updateUnPaidSearchTerm,
  showDebtDetailsDrawer,
  closeDebtDetailsDrawer,
} = StoreDebtSlice.actions;

export const useStoreDebtActions = (): typeof StoreDebtSlice.actions =>
  useActions(StoreDebtSlice.actions);

export default StoreDebtSlice.reducer;
