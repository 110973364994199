import router from "../router";
import { Endpoints } from "../../endpoints";
import {
  db,
  IndexedS3InventoryListAPIResponseItem,
} from "../../../../inventory/offline/db";
import {
  iGetInventoryListRequest,
  iGetInventoryListResponse,
} from "../../../../inventory/list";

router.post<iGetInventoryListResponse, iGetInventoryListRequest>(
  Endpoints.GET_INVENTORY_LIST,
  async (req) => {
    const {
      page,
      pageSize,
      keyword = "",
      barcode,
      recommendationRanking,
      sortBy,
    } = req.body;

    const data: IndexedS3InventoryListAPIResponseItem[] = [];
    const totalData = await db.inventories.count();

    if (sortBy === "RECOMMENDATION") {
      if (
        recommendationRanking !== undefined &&
        recommendationRanking.length > 0
      ) {
        // Currently, we only show 10 recommended items at max
        const slicedRecommendationRanking = recommendationRanking.slice(0, 10);

        const mapRankingByIndex: Record<string | number, number> = {};
        for (let i = 0; i < slicedRecommendationRanking.length; i++) {
          mapRankingByIndex[slicedRecommendationRanking[i]] = i;
        }

        await db.inventories
          .where("productVariantId")
          .anyOf(slicedRecommendationRanking)
          .each((inventory) => {
            data[mapRankingByIndex[inventory.productVariantId]] = inventory;
          });
      }
    } else {
      if (!keyword) {
        if (sortBy === "PRODUCT_VARIANT_NAME") {
          const sortedInventories = await db.inventories
            .offset(page > 0 ? page - 1 : 0)
            .limit(pageSize)
            .sortBy("productVariantName");
          data.push(...sortedInventories);
        } else {
          const inventories = await db.inventories
            .offset(page > 0 ? page - 1 : 0)
            .limit(pageSize)
            .toArray();
          data.push(...inventories);
        }
      } else {
        const inventories = await db.inventories
          .where("tokens")
          .equals(keyword)
          .toArray();
        data.push(...inventories);
      }

      if (barcode) {
        const inventories = await db.inventories
          .where("barcodes")
          .equals(barcode)
          .toArray();
        data.push(...inventories);
      }
    }

    return {
      data: data.map((d) => ({
        productVariantId: d.productVariantId,
        productVariantName: d.productVariantName,
        productVariantStoreId: d.productVariantStoreId,
        productVariantPhoto: d.productVariantPhoto,
        cumulativeStock: {
          name: d.smallestUomLongName,
          quantity: d.totalInSmallestUom,
        },
        detailedStock: [],
        consignor: d.consignor
          ? {
              id: Number(d.consignor),
              phoneNumber: "",
              name: "",
              bankAccount: [],
              noOfTransaction: 0,
              totalTransaction: {
                currency: "",
                amount: "0",
              },
            }
          : null,
        storeId: d.storeId,
        isLowStock: d.isLowStock,
        inventories: d.inventories,
        basePriceInSmallestUom: String(d.basePriceInSmallestUom),
        totalInSmallestUom: d.totalInSmallestUom,
        totalInventoryValue: d.totalInventoryValue,
      })),
      page: page,
      total: totalData,
    };
  }
);
