/* eslint-disable indent */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { UserMenusStateType, SideMenuState } from "./types";
import { UserApiEndpoints } from "../../user/hooks";
import { isEqual } from "lodash";
import { globalResetAction } from "../../common/redux/actions";

export const initialState: UserMenusStateType = {
  activeStore: {
    storeId: "",
    storeName: "",
    isStoreLinked: false,
    storePermissionList: [],
    storeRoleList: [],
    hasSpecialtyType: false,
  },
  showNoAccessPopup: false,
};

const UserMenuSlice = createSlice({
  name: "userMenus",
  initialState,
  reducers: {
    setActiveStore: (
      state,
      action: PayloadAction<UserMenusStateType["activeStore"]>
    ) => {
      state.activeStore = action.payload;
    },
    setShowNoAccessPopup: (
      state,
      action: PayloadAction<UserMenusStateType["showNoAccessPopup"]>
    ) => {
      state.showNoAccessPopup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
    builder.addMatcher(
      UserApiEndpoints.getUserInfo.matchFulfilled,
      (state, action) => {
        if (isEqual(state.activeStore, initialState.activeStore)) {
          const defaultStore = action.payload.data
            .userStoreList[0] as UserMenusStateType["activeStore"];
          if (!isEqual(state.activeStore, defaultStore))
            state.activeStore = {
              storeId: defaultStore.storeId,
              storeName: defaultStore.storeName,
              isStoreLinked: defaultStore.isStoreLinked,
              storePermissionList: defaultStore.storePermissionList,
              storeRoleList: defaultStore.storeRoleList,
              hasSpecialtyType: defaultStore.hasSpecialtyType,
            };
        }
      }
    );
  },
});

const initialSideMenuState: SideMenuState = {
  activeMenuId: "",
  activeSubMenuId: "",
  expandedMenuIds: [],
};

const SideMenuSlice = createSlice({
  name: "sideMenu",
  initialState: initialSideMenuState,
  reducers: {
    setState(
      state,
      action: PayloadAction<
        Partial<
          Omit<SideMenuState, "expandedMenuIds"> & {
            expandMenuId: string;
            removeMenuId?: string;
          }
        >
      >
    ) {
      const {
        activeMenuId,
        activeSubMenuId,
        expandMenuId,
        removeMenuId,
      } = action.payload;
      state.activeMenuId =
        activeMenuId === undefined ? state.activeMenuId : activeMenuId;
      state.activeSubMenuId =
        activeSubMenuId === undefined ? state.activeSubMenuId : activeSubMenuId;
      state.expandedMenuIds =
        expandMenuId === undefined
          ? state.expandedMenuIds
          : state.expandedMenuIds.find((id) => id === expandMenuId)
          ? state.expandedMenuIds
          : state.expandedMenuIds.concat(expandMenuId);

      if (removeMenuId) {
        state.expandedMenuIds = state.expandedMenuIds.filter(
          (id) => id !== removeMenuId
        );
      }
    },
  },
});

export const useUserMenusActions = (): typeof UserMenuSlice.actions =>
  useActions(UserMenuSlice.actions);
export const useSideMenuActions = (): typeof SideMenuSlice.actions =>
  useActions(SideMenuSlice.actions);

export const {
  reducer: UserMenuSliceReducer,
  name: UserMenuSliceName,
} = UserMenuSlice;

export const {
  reducer: SideMenuSliceReducer,
  name: SideMenuSliceName,
} = SideMenuSlice;
