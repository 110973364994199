import * as React from "react";
import { theme } from "@gada-saas/web-ui";
import { IconProps } from "./types";

const CheckBoxIcon: React.VFC<IconProps> = ({
  width = "24",
  height = "24",
  border = theme.palette.interface.neutral.secondary,
  fill = theme.palette.interface.neutral.light,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="🔍-Product-Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ic_fluent_checkbox_unchecked_24_regular" fillRule="nonzero">
          <path
            d="M5.75,3 L18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 Z M5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 L5.75,4.5 Z"
            id="🎨Color"
            fill={border}
          ></path>
          <path
            d="M7 19h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default CheckBoxIcon;
