import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const GadaSaasLogo: React.VFC<IconProps> = ({
  fill = theme.palette.interface.teal.primary,
  width = "1024",
  height = "308",
}) => {
  return (
    <svg
      enableBackground="new 0 0 1024 308.81"
      version="1.1"
      viewBox="0 0 1024 308.81"
      xmlSpace="preserve"
      width={width}
      height={height}
    >
      <style type="text/css">{`.st0{fill:${fill}}`}</style>

      <path
        className="st0"
        d="m218.8 170.62 0.03-85.86c4.57-2.78 7.61-7.79 7.55-13.5-0.1-8.63-7.21-15.55-15.89-15.45-8.55 0.09-15.41 6.96-15.53 15.41l-74.23 42.57c-2.29-1.25-4.92-1.95-7.71-1.92-8.68 0.1-15.64 7.17-15.54 15.8s7.21 15.55 15.89 15.45c8.49-0.09 15.32-6.87 15.52-15.24l73.6-42.21-0.03 84.96c-4.02 2.44-6.91 6.63-7.46 11.65-0.03 0.27-0.03 0.53-0.05 0.79l-74.26 42.59c-1.76-0.96-3.73-1.6-5.85-1.83-3.31-0.36-6.5 0.35-9.21 1.82l-74.24-42.65c-0.3-5.29-3.22-9.85-7.49-12.45l0.03-85.86c4.38-2.66 7.33-7.37 7.52-12.79l74.25-42.58c3.15 1.71 6.89 2.37 10.68 1.6 8.5-1.73 13.99-9.98 12.25-18.43s-10.04-13.9-18.54-12.18c-7.32 1.49-12.38 7.81-12.55 14.93l-74.1 42.49c-2.32-1.3-5-2.03-7.85-2-8.68 0.1-15.64 7.17-15.54 15.8 0.06 5.58 3.07 10.44 7.54 13.15l-0.03 85.86c-4.57 2.78-7.61 7.79-7.55 13.5 0.1 8.63 7.21 15.55 15.89 15.45 2.84-0.03 5.5-0.82 7.78-2.16l73.78 42.38c0.18 7.72 6.07 14.31 14 15.16 8.63 0.93 16.38-5.27 17.31-13.85 0.05-0.44 0.06-0.87 0.08-1.3l73.8-42.32c1.87 1.09 3.98 1.83 6.28 2.08 8.63 0.93 16.38-5.27 17.32-13.85 0.67-6.21-2.43-11.95-7.45-15.01z"
      />
      <rect className="st0" x="110.58" y="149.24" width="5.2" height="12.53" />
      <rect className="st0" x="110.57" y="167.92" width="5.2" height="12.53" />
      <rect className="st0" x="110.56" y="186.39" width="5.2" height="12.53" />
      <rect className="st0" x="110.56" y="205.07" width="5.2" height="12.53" />
      <rect className="st0" x="110.62" y="37.38" width="5.2" height="12.53" />
      <rect className="st0" x="110.61" y="56.06" width="5.2" height="12.53" />
      <rect className="st0" x="110.6" y="74.53" width="5.2" height="12.53" />
      <rect className="st0" x="110.6" y="93.2" width="5.2" height="12.53" />
      <polygon
        className="st0"
        points="33.37 170.27 35.97 174.75 46.89 168.49 44.29 164.01"
      />
      <polygon
        className="st0"
        points="49.65 160.94 52.25 165.42 63.16 159.16 60.56 154.68"
      />
      <polygon
        className="st0"
        points="65.74 151.71 68.34 156.19 79.25 149.93 76.66 145.45"
      />
      <polygon
        className="st0"
        points="82.02 142.38 84.61 146.86 95.53 140.6 92.93 136.12"
      />
      <polygon
        className="st0"
        points="133.44 136.13 130.84 140.61 141.75 146.88 144.35 142.4"
      />
      <polygon
        className="st0"
        points="149.71 145.48 147.1 149.96 158.01 156.22 160.62 151.75"
      />
      <polygon
        className="st0"
        points="165.79 154.72 163.19 159.2 174.1 165.46 176.7 160.99"
      />
      <polygon
        className="st0"
        points="182.06 164.06 179.46 168.54 190.37 174.81 192.97 170.33"
      />
      <polygon
        className="st0"
        points="36.01 80.17 33.4 84.64 44.32 90.91 46.92 86.43"
      />
      <polygon
        className="st0"
        points="52.28 89.51 49.67 93.99 60.58 100.26 63.19 95.78"
      />
      <polygon
        className="st0"
        points="68.36 98.75 65.76 103.23 76.67 109.5 79.27 105.02"
      />
      <polygon
        className="st0"
        points="84.63 108.1 82.03 112.57 92.94 118.84 95.54 114.36"
      />
      <polygon
        className="st0"
        points="144.39 30.59 133.48 24.32 130.88 28.8 141.79 35.07"
      />
      <polygon
        className="st0"
        points="160.66 39.94 149.75 33.67 147.14 38.15 158.05 44.41"
      />
      <polygon
        className="st0"
        points="176.74 49.18 165.83 42.91 163.23 47.39 174.14 53.65"
      />
      <polygon
        className="st0"
        points="193.01 58.52 182.1 52.25 179.5 56.73 190.41 63"
      />

      <path
        className="st0"
        d="m305.04 156.94c0.26-0.73 0.42-1.53 0.49-2.4s0.11-1.78 0.11-2.73v-0.74c0-0.16-0.01-0.3-0.03-0.41s-0.03-0.25-0.03-0.41v-0.74c-1.06 1.13-2.14 2.24-3.24 3.33s-2.31 2.06-3.65 2.92c-1.34 0.85-2.85 1.54-4.53 2.07s-3.64 0.79-5.87 0.79c-4.94 0-9.51-0.84-13.72-2.51s-7.84-4.01-10.92-7.01c-3.07-3-5.47-6.59-7.18-10.77-1.72-4.18-2.58-8.78-2.57-13.8 0-4.98 0.86-9.64 2.58-13.99 1.72-4.34 4.12-8.13 7.19-11.34 3.07-3.22 6.71-5.74 10.92-7.58 4.21-1.83 8.78-2.75 13.72-2.75 2.27 0 4.33 0.26 6.2 0.77s3.57 1.21 5.13 2.1c1.55 0.89 2.97 1.94 4.25 3.14s2.47 2.47 3.56 3.82l1.54-4.85c0.22-0.62 0.42-1.18 0.6-1.69s0.42-0.94 0.71-1.28 0.67-0.61 1.13-0.79 1.05-0.27 1.78-0.27h8.18c0.51 0 1.02 0.09 1.54 0.27 0.51 0.18 0.98 0.44 1.4 0.76 0.42 0.33 0.76 0.75 1.01 1.26 0.26 0.51 0.38 1.09 0.38 1.74l-0.02 55.53c0 5.67-0.94 10.73-2.8 15.16-1.87 4.44-4.42 8.18-7.66 11.23s-7.07 5.38-11.5 6.98-9.18 2.4-14.27 2.4c-1.98 0-4.1-0.18-6.36-0.52-2.27-0.35-4.53-0.87-6.78-1.56s-4.43-1.58-6.53-2.65-3.96-2.32-5.57-3.74c-0.55-0.4-1.01-0.9-1.4-1.5-0.38-0.6-0.58-1.26-0.58-1.99 0-0.91 0.35-1.87 1.04-2.89l5.22-7.14c0.51-0.55 0.99-0.95 1.43-1.23 0.44-0.27 0.91-0.41 1.43-0.41 0.8 0 1.81 0.44 3.02 1.31 0.91 0.87 1.97 1.57 3.15 2.1 1.19 0.53 2.41 0.95 3.68 1.26 1.26 0.31 2.51 0.51 3.73 0.6s2.33 0.14 3.32 0.14c1.65 0 3.37-0.22 5.16-0.65 1.79-0.44 3.47-1.08 5.02-1.93s2.93-1.9 4.12-3.14c1.22-1.25 2.03-2.67 2.47-4.27zm-31.37-32.41c0 2.44 0.37 4.7 1.12 6.79s1.82 3.9 3.21 5.43 3.06 2.73 5.02 3.6 4.12 1.31 6.5 1.31c2.34 0 4.54-0.41 6.61-1.22 2.07-0.82 3.87-1.95 5.41-3.41 1.54-1.45 2.77-3.16 3.71-5.13 0.93-1.96 1.46-4.09 1.57-6.38v-1.8c-0.11-2.22-0.63-4.32-1.56-6.3s-2.17-3.73-3.7-5.24c-1.54-1.51-3.34-2.71-5.4-3.6-2.07-0.89-4.27-1.34-6.61-1.34-2.38 0-4.55 0.46-6.5 1.39-1.96 0.93-3.63 2.17-5.02 3.73s-2.46 3.4-3.21 5.51c-0.78 2.11-1.15 4.33-1.15 6.66z"
      />
      <path
        className="st0"
        d="m407.97 158.45c-0.48 0.4-0.98 0.65-1.51 0.76s-1.03 0.16-1.51 0.16h-8.29c-0.66 0-1.23-0.12-1.7-0.35-0.48-0.24-0.88-0.55-1.21-0.95s-0.6-0.85-0.82-1.36-0.4-1.03-0.55-1.58l-1.15-5.33c-0.55 1.12-1.46 2.31-2.74 3.56s-2.86 2.4-4.75 3.45c-1.88 1.05-4.05 1.92-6.5 2.61s-5.12 1.03-8.01 1.03c-4.17 0-7.86-0.8-11.08-2.4s-5.93-3.78-8.15-6.55c-2.21-2.76-3.88-5.99-5.02-9.68-1.13-3.69-1.7-7.63-1.7-11.81l0.01-35.73c0-1.38 0.3-2.43 0.91-3.14 0.6-0.71 1.56-1.06 2.88-1.06h12.67c1.57 0 2.65 0.27 3.24 0.79 0.58 0.53 0.88 1.56 0.88 3.09l-0.01 35.55c0 1.57 0.3 3.05 0.9 4.45s1.42 2.64 2.44 3.71 2.22 1.92 3.59 2.54 2.83 0.93 4.36 0.93c1.32 0 2.74-0.22 4.28-0.65 1.54-0.44 2.96-1.12 4.28-2.05s2.41-2.12 3.29-3.58 1.32-3.2 1.32-5.24l0.01-35.49c0-0.62 0.12-1.24 0.36-1.86s0.59-1.09 1.07-1.42c0.47-0.29 0.92-0.49 1.34-0.6s0.91-0.16 1.45-0.16h12.4c1.57 0 2.71 0.38 3.4 1.15 0.69 0.76 1.04 1.78 1.04 3.05l-0.02 61.04c0 0.69-0.16 1.33-0.47 1.91s-0.74 1.04-1.29 1.36l0.36-0.15z"
      />
      <path
        className="st0"
        d="m476.61 152.58c-0.99 0.98-2.11 1.95-3.38 2.92-1.26 0.96-2.63 1.83-4.12 2.59s-3.07 1.38-4.77 1.85-3.5 0.71-5.4 0.71c-4.98 0-9.56-0.93-13.74-2.79-4.19-1.86-7.79-4.39-10.81-7.61s-5.37-7.01-7.07-11.38c-1.7-4.36-2.55-9.06-2.55-14.07 0-5.05 0.85-9.75 2.56-14.1s4.06-8.13 7.08-11.34c3.02-3.22 6.62-5.74 10.81-7.58 4.19-1.83 8.77-2.75 13.75-2.75 2.01 0 3.85 0.17 5.51 0.52s3.18 0.85 4.55 1.5c1.37 0.66 2.63 1.44 3.79 2.35 1.15 0.91 2.24 1.91 3.26 3l0.01-22.75c0-1.16 0.36-2.14 1.07-2.95 0.71-0.8 1.78-1.2 3.21-1.2h12.35c0.47 0 0.98 0.1 1.51 0.3s1.02 0.48 1.45 0.85c0.44 0.36 0.8 0.8 1.07 1.31s0.41 1.07 0.41 1.69l-0.03 81.55c0 2.8-1.48 4.2-4.45 4.2h-10.75c-0.73 0-1.3-0.09-1.7-0.27s-0.74-0.45-1.01-0.79c-0.27-0.35-0.52-0.78-0.74-1.31s-0.49-1.14-0.82-1.83l-1.05-2.62zm-32.08-27.77c0 2.4 0.37 4.65 1.12 6.74s1.82 3.9 3.21 5.43 3.06 2.74 5.02 3.63 4.12 1.34 6.5 1.34c2.34 0 4.54-0.42 6.61-1.25s3.87-1.99 5.41-3.46 2.77-3.21 3.71-5.21c0.93-2 1.46-4.15 1.57-6.44v-0.76c0-2.33-0.44-4.54-1.31-6.65-0.88-2.11-2.09-3.96-3.65-5.57-1.55-1.6-3.38-2.86-5.49-3.79-2.1-0.93-4.38-1.39-6.83-1.39-2.38 0-4.55 0.46-6.5 1.39-1.96 0.93-3.63 2.19-5.02 3.79s-2.46 3.45-3.21 5.56c-0.77 2.09-1.14 4.31-1.14 6.64z"
      />
      <path
        className="st0"
        d="m587.83 155.4c0 0.62-0.11 1.21-0.33 1.77s-0.59 1.01-1.1 1.34c-0.4 0.4-0.88 0.65-1.43 0.76s-1.02 0.16-1.43 0.16h-10.81c-0.77 0-1.39-0.1-1.87-0.3s-0.86-0.48-1.15-0.85c-0.29-0.36-0.54-0.81-0.74-1.34s-0.41-1.1-0.63-1.72l-0.71-2.46c-0.99 0.98-2.11 1.94-3.38 2.89-1.26 0.95-2.65 1.79-4.17 2.53-1.52 0.75-3.15 1.34-4.88 1.8-1.74 0.45-3.54 0.68-5.4 0.68-3.33 0-6.52-0.42-9.57-1.26s-5.91-2.03-8.56-3.58-5.06-3.4-7.21-5.57c-2.16-2.16-4.01-4.57-5.57-7.23-1.55-2.65-2.75-5.52-3.59-8.59s-1.26-6.28-1.26-9.63c0-5.05 0.94-9.75 2.8-14.1 1.87-4.34 4.41-8.13 7.63-11.34 3.22-3.22 7.01-5.74 11.36-7.58 4.35-1.83 9.02-2.75 13.99-2.75 2.27 0 4.38 0.26 6.34 0.77s3.75 1.2 5.38 2.07 3.1 1.92 4.42 3.14 2.47 2.54 3.46 3.96l1.59-4.96c0.37-0.91 0.67-1.64 0.91-2.21 0.24-0.56 0.49-0.98 0.77-1.25s0.61-0.45 1.02-0.55c0.4-0.09 0.97-0.14 1.7-0.14h8.18c0.4 0 0.88 0.06 1.43 0.16 0.55 0.11 1.02 0.36 1.43 0.76 0.51 0.4 0.88 0.86 1.1 1.36s0.33 1.09 0.33 1.74l-0.05 61.52zm-54.03-30.56c0 2.4 0.37 4.65 1.12 6.74s1.82 3.9 3.21 5.43 3.06 2.74 5.02 3.63 4.12 1.34 6.5 1.34c2.45 0 4.73-0.44 6.83-1.33s3.93-2.1 5.49-3.63c1.55-1.53 2.77-3.33 3.65-5.43 0.88-2.09 1.32-4.34 1.32-6.74 0-2.33-0.44-4.54-1.31-6.65s-2.09-3.96-3.65-5.57c-1.55-1.6-3.38-2.86-5.49-3.79-2.1-0.93-4.38-1.39-6.83-1.39-2.38 0-4.55 0.46-6.5 1.39-1.96 0.93-3.63 2.19-5.02 3.79s-2.46 3.45-3.21 5.56c-0.76 2.1-1.13 4.32-1.13 6.65z"
      />
      <path
        className="st0"
        d="m606.84 91.15c0.47-0.4 0.98-0.65 1.51-0.76s1.03-0.16 1.51-0.16h8.29c0.66 0 1.23 0.12 1.7 0.36s0.88 0.56 1.21 0.95c0.33 0.4 0.59 0.85 0.8 1.36 0.2 0.51 0.39 1.03 0.58 1.58l1.15 4.79c0.55-1.12 1.44-2.28 2.66-3.48 1.23-1.2 2.74-2.28 4.56-3.26 1.81-0.98 3.91-1.78 6.28-2.42 2.38-0.63 5.03-0.95 7.96-0.95 4.17 0 7.89 0.8 11.16 2.4s6.05 3.77 8.34 6.53c2.28 2.76 4.02 5.98 5.21 9.66 1.19 3.69 1.78 7.62 1.78 11.8l-0.01 35.73c0 1.38-0.3 2.43-0.91 3.14-0.6 0.71-1.56 1.06-2.88 1.06h-12.67c-1.57 0-2.65-0.26-3.24-0.79s-0.88-1.56-0.88-3.08l0.01-35.51c0-1.56-0.36-3.04-1.07-4.45-0.71-1.4-1.65-2.64-2.82-3.71s-2.5-1.92-3.98-2.54-2.99-0.93-4.53-0.93c-1.32 0-2.66 0.29-4.03 0.87s-2.63 1.39-3.79 2.43c-1.15 1.04-2.12 2.25-2.91 3.65s-1.24 2.92-1.35 4.55l-0.01 35.45c0 0.62-0.12 1.24-0.36 1.85-0.24 0.62-0.6 1.09-1.07 1.42-0.48 0.29-0.92 0.49-1.34 0.6s-0.91 0.16-1.45 0.16h-12.4c-1.57 0-2.71-0.38-3.4-1.15-0.69-0.76-1.04-1.78-1.04-3.05l0.02-61c0-0.69 0.16-1.32 0.47-1.9s0.74-1.03 1.29-1.36l-0.35 0.16z"
      />
      <path
        className="st0"
        d="m739.54 157.1c0.26-0.73 0.42-1.53 0.49-2.4s0.11-1.78 0.11-2.73v-0.74c0-0.16-0.01-0.3-0.03-0.41s-0.03-0.25-0.03-0.41v-0.74c-1.06 1.13-2.14 2.24-3.24 3.33s-2.31 2.06-3.65 2.92c-1.34 0.85-2.85 1.54-4.53 2.07s-3.64 0.79-5.87 0.79c-4.94 0-9.51-0.84-13.72-2.51s-7.84-4.01-10.92-7.01-5.47-6.59-7.18-10.77c-1.72-4.18-2.58-8.78-2.57-13.8 0-4.98 0.86-9.64 2.58-13.99 1.72-4.34 4.12-8.13 7.19-11.34 3.07-3.22 6.71-5.74 10.92-7.58 4.21-1.83 8.78-2.75 13.72-2.75 2.27 0 4.33 0.26 6.2 0.77s3.57 1.21 5.13 2.1c1.55 0.89 2.97 1.94 4.25 3.14s2.47 2.47 3.56 3.82l1.54-4.85c0.22-0.62 0.42-1.18 0.6-1.69s0.42-0.94 0.71-1.28 0.67-0.61 1.13-0.79 1.05-0.27 1.78-0.27h8.18c0.51 0 1.02 0.09 1.54 0.27 0.51 0.18 0.98 0.44 1.4 0.76 0.42 0.33 0.76 0.75 1.01 1.26 0.26 0.51 0.38 1.09 0.38 1.74l-0.02 55.53c0 5.67-0.94 10.73-2.8 15.16-1.87 4.44-4.42 8.18-7.66 11.23s-7.07 5.38-11.5 6.98-9.18 2.4-14.27 2.4c-1.98 0-4.1-0.18-6.36-0.52-2.27-0.35-4.53-0.87-6.78-1.56s-4.43-1.58-6.53-2.65-3.96-2.32-5.57-3.74c-0.55-0.4-1.01-0.9-1.4-1.5-0.38-0.6-0.58-1.26-0.58-1.99 0-0.91 0.35-1.87 1.04-2.89l5.22-7.14c0.51-0.55 0.99-0.95 1.43-1.23 0.44-0.27 0.91-0.41 1.43-0.41 0.8 0 1.81 0.44 3.02 1.31 0.91 0.87 1.97 1.57 3.15 2.1 1.19 0.53 2.41 0.95 3.68 1.26 1.26 0.31 2.51 0.51 3.73 0.6s2.33 0.14 3.32 0.14c1.65 0 3.37-0.22 5.16-0.65 1.79-0.44 3.47-1.08 5.02-1.93s2.93-1.9 4.12-3.14c1.22-1.26 2.03-2.67 2.47-4.27zm-31.37-32.42c0 2.44 0.37 4.7 1.12 6.79s1.82 3.9 3.21 5.43 3.06 2.73 5.02 3.6 4.12 1.31 6.5 1.31c2.34 0 4.54-0.41 6.61-1.22 2.07-0.82 3.87-1.95 5.41-3.41 1.54-1.45 2.77-3.16 3.71-5.13 0.93-1.96 1.46-4.09 1.57-6.38v-1.8c-0.11-2.22-0.63-4.32-1.56-6.3s-2.17-3.73-3.7-5.24c-1.54-1.51-3.34-2.71-5.4-3.6-2.07-0.89-4.27-1.34-6.61-1.34-2.38 0-4.55 0.46-6.5 1.39-1.96 0.93-3.63 2.17-5.02 3.73s-2.46 3.4-3.21 5.51c-0.78 2.12-1.15 4.34-1.15 6.66z"
      />
      <path
        className="st0"
        d="m837.32 150.31c-3.15 3.2-6.81 5.72-10.98 7.55s-8.69 2.75-13.55 2.75c-3.26 0-6.41-0.43-9.46-1.28-3.05-0.86-5.92-2.07-8.59-3.66-2.67-1.58-5.1-3.47-7.3-5.65-2.19-2.18-4.09-4.61-5.68-7.28s-2.81-5.53-3.67-8.56c-0.86-3.04-1.29-6.19-1.29-9.46s0.43-6.43 1.29-9.46c0.86-3.04 2.09-5.88 3.68-8.53s3.49-5.07 5.68-7.25c2.2-2.18 4.63-4.05 7.3-5.62 2.67-1.56 5.52-2.77 8.56-3.62s6.2-1.28 9.49-1.28c4.86 0 9.38 0.92 13.55 2.76s7.83 4.36 10.97 7.56v-6.65c0-1.6 0.81-2.4 2.41-2.4h5.65c1.72 0 2.58 0.8 2.58 2.4l-0.02 64.53c0 0.73-0.24 1.31-0.71 1.75-0.48 0.44-1.04 0.65-1.7 0.65h-5.71c-0.77 0-1.38-0.24-1.84-0.71s-0.69-1.04-0.69-1.69l0.03-6.85zm0.56-25.58c0-3.42-0.66-6.68-1.97-9.79s-3.1-5.83-5.37-8.16c-2.26-2.33-4.92-4.18-7.97-5.57-3.05-1.38-6.29-2.08-9.73-2.08-3.43 0-6.66 0.69-9.68 2.07-3.01 1.38-5.65 3.24-7.89 5.56-2.25 2.33-4.02 5.04-5.32 8.15s-1.95 6.37-1.95 9.79c0 3.38 0.65 6.64 1.94 9.76 1.3 3.13 3.07 5.88 5.31 8.27 2.25 2.38 4.88 4.28 7.89 5.7s6.24 2.13 9.67 2.13 6.68-0.71 9.73-2.12c3.05-1.42 5.71-3.32 7.98-5.7s4.06-5.13 5.37-8.26c1.33-3.11 1.98-6.37 1.99-9.75z"
      />
      <path
        className="st0"
        d="m927.74 159.56c-1.61 0-2.41-0.8-2.41-2.4v-6.6c-3.15 3.2-6.81 5.71-10.98 7.52-4.17 1.82-8.69 2.72-13.55 2.72-3.26 0-6.41-0.43-9.46-1.28-3.05-0.86-5.92-2.07-8.59-3.63s-5.1-3.44-7.3-5.62c-2.19-2.18-4.09-4.6-5.68-7.26-1.59-2.65-2.81-5.5-3.67-8.54s-1.29-6.19-1.29-9.46 0.43-6.43 1.29-9.46c0.86-3.04 2.09-5.89 3.68-8.56s3.49-5.1 5.68-7.28c2.2-2.18 4.63-4.06 7.3-5.64s5.52-2.8 8.56-3.65 6.2-1.28 9.49-1.28c4.86 0 9.38 0.92 13.55 2.76s7.83 4.36 10.97 7.56l0.01-27.33c0-0.65 0.23-1.22 0.69-1.69s1.07-0.71 1.84-0.71h5.71c0.66 0 1.23 0.22 1.7 0.65 0.47 0.44 0.71 1.02 0.71 1.75l-0.03 85.04c0 1.6-0.86 2.4-2.58 2.4l-5.64-0.01zm-1.86-34.52c0-3.38-0.66-6.64-1.97-9.76-1.31-3.13-3.1-5.88-5.37-8.27-2.26-2.38-4.92-4.28-7.97-5.7s-6.29-2.13-9.73-2.13c-3.43 0-6.66 0.71-9.68 2.12-3.01 1.42-5.65 3.32-7.89 5.7-2.25 2.38-4.02 5.13-5.32 8.26s-1.95 6.38-1.95 9.76c0 3.45 0.65 6.73 1.94 9.82 1.3 3.09 3.07 5.8 5.31 8.13 2.25 2.33 4.88 4.18 7.89 5.57 3.01 1.38 6.24 2.08 9.67 2.08s6.68-0.69 9.73-2.07 5.71-3.23 7.98-5.56 4.06-5.03 5.37-8.12c1.33-3.11 1.99-6.38 1.99-9.83z"
      />
      <path
        className="st0"
        d="m1013.3 150.38c-3.15 3.2-6.81 5.72-10.98 7.55s-8.69 2.75-13.55 2.75c-3.26 0-6.41-0.43-9.46-1.28-3.05-0.86-5.92-2.07-8.59-3.66-2.67-1.58-5.1-3.47-7.3-5.65-2.19-2.18-4.09-4.61-5.68-7.28s-2.81-5.53-3.67-8.56c-0.86-3.04-1.29-6.19-1.29-9.46s0.43-6.43 1.29-9.46c0.86-3.04 2.09-5.88 3.68-8.53s3.49-5.07 5.68-7.25c2.2-2.18 4.63-4.05 7.3-5.62 2.67-1.56 5.52-2.77 8.56-3.62s6.2-1.28 9.49-1.28c4.86 0 9.38 0.92 13.55 2.76s7.83 4.36 10.97 7.56v-6.65c0-1.6 0.81-2.4 2.41-2.4h5.65c1.72 0 2.58 0.8 2.58 2.4l-0.02 64.53c0 0.73-0.24 1.31-0.71 1.75-0.48 0.44-1.04 0.65-1.7 0.65h-5.71c-0.77 0-1.38-0.24-1.84-0.71s-0.69-1.04-0.69-1.69l0.03-6.85zm0.56-25.58c0-3.42-0.66-6.68-1.97-9.79s-3.1-5.83-5.37-8.16c-2.26-2.33-4.92-4.18-7.97-5.57-3.05-1.38-6.29-2.08-9.73-2.08-3.43 0-6.66 0.69-9.68 2.07-3.01 1.38-5.65 3.24-7.89 5.56-2.25 2.33-4.02 5.04-5.32 8.15s-1.95 6.37-1.95 9.79c0 3.38 0.65 6.64 1.94 9.76 1.3 3.13 3.07 5.88 5.31 8.27 2.25 2.38 4.88 4.28 7.89 5.7s6.24 2.13 9.67 2.13 6.68-0.71 9.73-2.12c3.05-1.42 5.71-3.32 7.98-5.7s4.06-5.13 5.37-8.26c1.33-3.12 1.99-6.37 1.99-9.75z"
      />

      <path
        className="st0"
        d="m681.73 290.17 9.24-8.8c1.39-1.39 2.73-0.72 4.12 0.45 3.95 3.4 10.08 8.35 16.59 8.35 7.29 0 8.91-3.45 8.91-7.46 0-4.45-7.18-6.85-11.02-8.18-17.2-5.9-26.22-13.69-26.22-26.16 0-13.19 11.8-21.99 28.95-21.99 9.63 0 20.26 4.34 25.88 10.41 1.5 1.72 1.56 3.17-0.17 4.9l-8.18 8.74c-1.5 1.5-3.01 0.72-4.51-0.61-1.84-1.72-4.68-3.84-7.79-4.95-3.78-1.34-13.58-1.56-13.58 4.56 0 3.9 4.62 5.68 12.41 8.18 15.14 5.07 25.55 10.13 25.55 24.99 0 15.64-13.02 25.83-30.5 25.83-11.02 0-21.99-6.18-29.06-13.53-1.34-1.5-2.46-2.9-0.62-4.73z"
      />

      <path
        className="st0"
        d="m785.69 256.15c-3.2 0.21-5.46 1.33-7.22 3.46-1.51 1.83-2.25 3.97-2.28 6.38-0.01 0.84 0.58 1.49 1.35 1.55 0.8 0.05 1.44-0.49 1.55-1.32 0.03-0.21 0.04-0.43 0.06-0.65 0.19-2.94 2.1-5.34 4.82-6.1 0.61-0.17 1.23-0.15 1.83-0.26 0.79-0.14 1.27-0.77 1.23-1.6-0.03-0.94-0.76-1.5-1.34-1.46z"
      />
      <path
        className="st0"
        d="m818.03 259.94c-1.84-4.23-4.35-7.93-7.54-11.1s-6.93-5.68-11.22-7.52-8.86-2.76-13.72-2.76c-3.23 0-6.35 0.42-9.35 1.25-3.01 0.83-5.81 2-8.41 3.51-2.6 1.5-4.97 3.32-7.12 5.45s-3.99 4.49-5.51 7.07-2.7 5.36-3.53 8.35-1.25 6.09-1.25 9.32c0 4.86 0.92 9.42 2.76 13.66 1.84 4.25 4.35 7.95 7.54 11.11 3.19 3.15 6.92 5.65 11.19 7.49s8.83 2.76 13.69 2.76 9.43-0.92 13.69-2.76c4.27-1.84 8-4.34 11.19-7.51s5.71-6.88 7.57-11.13 2.78-8.79 2.78-13.61c-0.01-4.82-0.93-9.35-2.76-13.58zm-26.44 29.63c-0.06 1.08-0.71 1.97-1.69 2.27-0.3 0.09-0.41 0.25-0.48 0.53-0.08 0.35-0.16 0.7-0.27 1.04-0.29 0.89-0.89 1.35-1.77 1.36-1.2 0.02-2.41 0.02-3.61 0-0.87-0.01-1.45-0.47-1.75-1.33-0.05-0.13-0.1-0.27-0.11-0.41-0.08-0.67-0.32-1.12-1.02-1.39-0.8-0.3-1.24-1.06-1.3-1.98-0.04-0.65-0.05-1.3-0.02-1.94 0.04-0.91 0.63-1.49 1.51-1.5 1.5-0.01 2.99 0 4.49 0 1.46 0 2.92-0.01 4.38 0 1.07 0 1.65 0.6 1.66 1.73 0 0.54 0.01 1.09-0.02 1.62zm6.84-21.47c-0.38 2.69-1.47 5.04-3.23 7.04-1.55 1.75-2.72 3.7-3.24 6.04-0.07 0.31-0.13 0.63-0.19 0.95-0.24 1.29-1.04 2-2.31 2.02-1.29 0.01-2.58 0-3.87 0-1.27 0-2.55 0.01-3.82 0-1.37-0.01-2.15-0.68-2.4-2.09-0.43-2.45-1.4-4.62-3.03-6.44-1.74-1.94-2.94-4.18-3.5-6.79-1.17-5.4 1.2-11.42 5.7-14.33 5.65-3.67 12.59-2.63 16.94 2.64 2.59 3.14 3.54 6.84 2.95 10.96z"
      />

      <path
        className="st0"
        d="m831.14 221.92c0-1.73 1.11-2.5 2.62-2.5h15.14c1.5 0 2.56 1 2.56 2.5v83c0 1.5-0.95 2.5-2.56 2.5h-15.14c-1.61 0-2.62-0.78-2.62-2.5v-83z"
      />
      <path
        className="st0"
        d="m908.12 241.74c-0.06-1.73 0.95-2.5 2.56-2.5h15.48c1.67 0 2.5 0.83 2.5 2.5v63.18c0 1.67-0.83 2.5-2.5 2.5h-9.91c-1.72 0-3.39-1.45-3.84-3.06l-1.56-5.96c-5.68 7.57-13.69 10.13-21.38 10.13-9.02 0-25.22-5.23-25.22-29.28v-37.57c0-1.61 0.84-2.45 2.45-2.45h15.14c1.61 0 2.45 0.83 2.45 2.45v36.13c0 6.23 4.73 11.69 12.25 11.69 3.56 0 11.58-2.28 11.58-11.8v-35.96z"
      />
      <path
        className="st0"
        d="m940.13 292.51 7.51-7.35c1.06-1.06 2.23-0.78 3.51 0.33 3.56 3.12 8.13 6.07 13.08 6.07 5.62 0 7.23-2.06 7.23-4.73 0-3.06-1.84-4.01-10.52-6.57-13.64-4.01-19.76-10.74-19.76-22.93 0-10.96 9.24-19.09 25.49-19.09 12.02 0 17.53 5.68 21.82 9.69 1.5 1.34 1.73 2.5-0.06 4.06l-8.18 7.13c-1.5 1.28-2.23 1-3.51-0.06-2.56-2.12-4.9-4.23-9.57-4.23-4.23 0-6.23 1.56-6.23 3.29 0 3.39 3.45 3.45 13.92 6.85 14.25 4.73 17.03 13.64 17.03 21.82 0 14.03-12.75 22.04-26.05 22.04-9.02 0-18.42-2.34-25.55-11.41-1.22-1.35-2-3.08-0.16-4.91z"
      />
      <path
        className="st0"
        d="m1003.2 216.97c0-2.5 1.11-4.45 4.4-4.45h12.25c2.89 0 4.01 1.73 4.01 4.45v10.13c0 3.06-1.39 3.9-4.01 3.9h-12.25c-2.62 0-4.4-0.78-4.4-3.84v-10.19zm0.05 24.6c0-1.56 1.22-2.34 2.34-2.34h15.86c1.34 0 2.34 0.95 2.34 2.34v63.51c0 1.56-0.78 2.34-2.34 2.34h-15.86c-1.56 0-2.34-0.78-2.34-2.34v-63.51z"
      />
    </svg>
  );
};

export default GadaSaasLogo;
