import {
  CachedInventoryRecommendationRankingStateType,
  GetInventoryRecommendationRankingErrorResponse,
  iPosInventoriesState,
} from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import POSInventoriesApi from "./hooks";
import { iFilter } from "./models";
import { DefaultFilter } from "./constants";
import { globalResetAction } from "../../common/redux/actions";

const initialState: iPosInventoriesState = {
  inventoryUiState: {
    loadingInventories: true,
    visibleLayout: null,
    isCartDiscountModalOpen: false,
    saveTransaction: {
      open: false,
      origin: "",
    },
  },
  filtersUiState: {
    filters: [DefaultFilter],
    activeFilter: DefaultFilter,
    searchQuery: "",
    page: 0,
    isLeftOverlayShown: false,
    isRightOverlayShown: false,
  },
};

const inventoriesSlice = createSlice({
  name: "pos_inventories",
  initialState,
  reducers: {
    setSaveTransaction(
      state,
      action: PayloadAction<
        iPosInventoriesState["inventoryUiState"]["saveTransaction"]
      >
    ) {
      state.inventoryUiState.saveTransaction = action.payload;
    },
    setOpenCartDiscountModal(state, action: PayloadAction<boolean>) {
      state.inventoryUiState.isCartDiscountModalOpen = action.payload;
    },
    setVisibleLayout(state, action: PayloadAction<string | null>) {
      state.inventoryUiState.visibleLayout = action.payload;
    },
    toggleFilterOverlay(
      state,
      action: PayloadAction<{
        isLeftOverlayShown?: boolean;
        isRightOverlayShown?: boolean;
      }>
    ) {
      state.filtersUiState = {
        ...state.filtersUiState,
        ...(action.payload.isLeftOverlayShown !== undefined && {
          isLeftOverlayShown: action.payload.isLeftOverlayShown,
        }),
        ...(action.payload.isRightOverlayShown !== undefined && {
          isRightOverlayShown: action.payload.isRightOverlayShown,
        }),
      };
    },
    toggleAppliedFilter(state, { payload }: PayloadAction<iFilter>) {
      if (state.filtersUiState.activeFilter.id === payload.id) {
        state.filtersUiState.activeFilter = DefaultFilter;
      } else {
        state.filtersUiState.activeFilter = payload;
      }
    },
    setSearchQuery(state, { payload }: PayloadAction<string>) {
      state.filtersUiState.searchQuery = payload;
    },
    setLoadingInventories(state, { payload }) {
      state.inventoryUiState.loadingInventories = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);

    builder.addMatcher(
      POSInventoriesApi.endpoints.getFiltersList.matchFulfilled,
      (state, { payload: { data } }) => {
        state.filtersUiState.filters = [DefaultFilter, ...data];
      }
    );
  },
});

export const usePosInventoriesActions = (): typeof inventoriesSlice.actions =>
  useActions(inventoriesSlice.actions);

const cachedInventoryRecommendationRankingInitialState: CachedInventoryRecommendationRankingStateType = {
  data: [],
  isFetching: false,
  isLoading: false,
  error: null,
};

export const CachedInventoryRecommendationRankingSlice = createSlice({
  name: "cachedInventoryRecommendationRanking",
  initialState: cachedInventoryRecommendationRankingInitialState,
  reducers: {
    resetState: () => cachedInventoryRecommendationRankingInitialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      globalResetAction,
      () => cachedInventoryRecommendationRankingInitialState
    );

    builder
      .addMatcher(
        POSInventoriesApi.endpoints.getInventoryRecommendationRanking
          .matchPending,
        (state) => {
          state.isFetching = true;
          state.isLoading = true;
        }
      )
      .addMatcher(
        POSInventoriesApi.endpoints.getInventoryRecommendationRanking
          .matchFulfilled,
        (state, action) => {
          state.data = action.payload.data;
          state.error = null;
          state.isFetching = false;
          state.isLoading = false;
        }
      )
      .addMatcher(
        POSInventoriesApi.endpoints.getInventoryRecommendationRanking
          .matchRejected,
        (state, action) => {
          if (action.payload) {
            // bypass error for network error
            if (action.payload.data?.problem !== "NETWORK_ERROR") {
              state.error = (action.payload as unknown) as GetInventoryRecommendationRankingErrorResponse;
            }
          }
          state.isFetching = false;
          state.isLoading = false;
        }
      );
  },
});

export default inventoriesSlice.reducer;
