import { iGetInventoryListRequest } from "../..";
import { SortType } from "../../common/models";
import { iFilter } from "./models";

export const DefaultFilter: iFilter = { id: -1, name: "Semua" };

export const inventoryListApiBasePayload: Partial<iGetInventoryListRequest> = {
  isActive: true,
  excludeEmptyPriceTiers: true,
  sortBy: "PRODUCT_VARIANT_NAME",
  sortType: SortType.ASCENDING,
};
