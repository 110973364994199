import { useNotifications } from "@common/context/NotificationsProvider";
import * as React from "react";

const usePushPermission = () => {
  const brazeSdk = useNotifications();

  const askPushPermission = React.useCallback(async () => {
    if (brazeSdk?.isBrazeInit) {
      const {
        isPushSupported,
        isPushPermissionGranted,
        isPushBlocked,
        requestPushPermission,
      } = await import(
        /* webpackExports: ["isPushSupported", "isPushPermissionGranted", "isPushBlocked", "requestPushPermission"] */
        "@braze/web-sdk"
      );

      if (!isPushSupported() || isPushPermissionGranted() || isPushBlocked()) {
        return;
      }

      requestPushPermission();
    }
  }, [brazeSdk?.isBrazeInit]);

  return React.useMemo(() => {
    return { askPushPermission };
  }, [askPushPermission]);
};

export default usePushPermission;
