import { useCachedUserInfo, useUserMenusState } from "@gada-saas/web-core";
import { useMemo, useCallback } from "react";
import { hasPermission } from "@utils/permission";

function useUserStorePermission(): [
  string[],
  (permissions: string[]) => boolean
] {
  const { data: userInfoData } = useCachedUserInfo();
  const { activeStore } = useUserMenusState();

  const userStorePermission: string[] = useMemo(() => {
    if (userInfoData) {
      const userStores = userInfoData.userStoreList;
      const userStorePermissionsName = userStores
        .find((s) => s.storeId === activeStore.storeId)
        ?.storePermissionList.map((v) => v.name);

      return userStorePermissionsName ? userStorePermissionsName : [];
    }
    return [];
  }, [activeStore.storeId, userInfoData]);

  const userHasPermission = useCallback(
    (permissions: string[]) => {
      return hasPermission(userStorePermission, permissions);
    },
    [userStorePermission]
  );

  return [userStorePermission, userHasPermission];
}

export default useUserStorePermission;
