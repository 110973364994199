import { TutorialCode } from "@gada-saas/web-core";
import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles<
  AppTheme,
  { tutorialName: TutorialCode | undefined }
>(() => ({
  roundedEdges: {
    "&>*:nth-child(1)": {
      "&>*:nth-child(4)": {
        stroke: "rgba(0, 0, 0, 1)",
        strokeWidth: (prop) =>
          pxToRem(
            prop.tutorialName === TutorialCode.INVENTORY_LIST_SEE_CURRENT_STOCK
              ? 12
              : prop.tutorialName === TutorialCode.POS_ORDER_COMPLETE
              ? 8
              : 20
          ),
        ry: (prop) =>
          prop.tutorialName === TutorialCode.POS_ORDER_COMPLETE ? 10 : 15,
        x: (prop) =>
          prop?.tutorialName &&
          [
            TutorialCode.ADD_INVENTORY_INTRO,
            TutorialCode.POS_INTRO,
            TutorialCode.REPORTING_AFTER_ORDER_COMPLETE,
          ].includes(prop.tutorialName) &&
          -6,
      },
    },
  },
}));

export default useStyles;
