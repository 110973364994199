import * as React from "react";
import { iRowProps } from "./types";
import { useStyles } from "./styles";
import clsx from "clsx";
import { Box } from "@material-ui/core";

const Row: React.FC<iRowProps> = ({
  children,
  flex = 0,
  vCenter = true,
  hCenter = false,
  fullWidth = false,
  style = {},
  className = "",
  spacing,
  justifyContent,
  ...restProps
}) => {
  const classes = useStyles({
    vCenter,
    hCenter,
    flex,
    fullWidth,
    spacing,
    justifyContent,
  });

  return (
    <Box
      className={clsx(classes.container, className)}
      style={style}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default Row;
