import {
  Account,
  BankAccountModel,
  iApiResponse,
  iListApiRequest,
  iListApiResponse,
  StoreAddressLocation,
} from "../common/models";

export interface iSaasUser {
  id: string;
  email: string;
  name: string;
  phoneNumber: string;
  status: string;
  loginNeeded: boolean | null;
  blockedUntil: Date | null;
}

export interface iMarketplaceUser {
  name: string;
  email: string | null;
  phoneNumber: string;
}

export interface IsMarketplaceUserResponseData {
  saasUser: null | iSaasUser;
  marketplaceUser: null | iMarketplaceUser;
}

export type IsMarketplaceUserResponse = iApiResponse<IsMarketplaceUserResponseData>;

export interface iUserInfo {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  status: string;
  blockedUntil: Date | null;
  loginNeeded: boolean | null;
  createdAt: string;
}

export enum UserStoreRole {
  OWNER = "OWNER",
  EMPLOYEE = "EMPLOYEE",
}

export interface iStoreRoleList {
  id: string;
  name: UserStoreRole;
  description: string;
  position: string;
}

export interface iStorePermissionList {
  id: string;
  name: string;
  displayName: string;
  description: string;
}

export interface iBank {
  name: string;
  code: string;
  logoUrl: string;
}

export interface iStoreBankAccountList {
  bank: iBank;
  bankAccountId: string;
  bankAccountName: string;
  bankAccountNumber: string;
}

export interface iUserStoreList {
  canonicalId: boolean;
  isStoreLinked: boolean;
  storeId: string;
  storeName: string;
  storeCode: string;
  storeAddress: string;
  storeAddressName: string | null;
  storeAddressLatitude: string;
  storeAddressLongitude: string;
  marketplaceStoreId: string | null;
  marketplaceStoreUuid: string | null;
  marketplaceStoreName: string | null;
  marketplaceStoreCode: string | null;
  marketplaceStoreAddress: string | null;
  marketplaceStoreAddressName: string | null;
  marketplaceStoreAddressLatitude: string | null;
  marketplaceStoreAddressLongitude: string | null;
  storeRoleList: iStoreRoleList[];
  storePermissionList: iStorePermissionList[];
  storeBankAccountList: iStoreBankAccountList[];
  storeAddressLocation: StoreAddressLocation | null;
  storeAddressDistrictId?: number;
  storeAddressDistrict?: string;
  storeAddressCity?: string;
  storeAddressProvince?: string;
  hasSpecialtyType: boolean;
  hasOperatingTime: boolean;
}

export interface iGetUserInfoResponseData {
  userInfo: iUserInfo;
  isUserLinked: boolean;
  userStoreList: iUserStoreList[];
}

export type GetUserInfoResponse = iApiResponse<iGetUserInfoResponseData>;

export interface UpdateUserRequest {
  name: string | undefined;
  email?: string | undefined;
  phoneNumber: string | undefined;
  skipRefetchUser?: boolean;
}

export type UpdateUserResponse = iApiResponse<iUserInfo>;

export type GetAllBankAccountsRequest = iListApiRequest;

export type GetAllBankAccountsResponse = iListApiResponse<BankAccountModel[]>;

export interface AddBankAccountRequest {
  storeId: string | undefined;
  bankCode: string;
  accountNo: string;
  accountName: string;
}

export type AddBankAccountResponse = iApiResponse<iStoreBankAccountList>;

export interface UpdateBankAccountRequest {
  bankAccountId: string | undefined;
  bankCode: string;
  bankAccountName: string;
  bankAccountNumber: string;
}

export type UpdateBankAccountResponse = iApiResponse<iStoreBankAccountList>;

export interface DeleteBankAccountRequest {
  bankAccountId: string;
}

export type DeleteBankAccountResponse = iApiResponse<unknown>;

export interface GetGeolocationRequest {
  longitude: number;
  latitude: number;
}

export interface GetGeolocationResponse {
  city: string;
  postalCode: string;
  isSupported: boolean;
  shortAddress: string;
  longAddress: string;
  country: string;
  province: string;
}

export interface UpdateStoreRequest {
  storeId: string;
  name: string;
  address: string;
  addressName?: string;
  addressLatitude?: string;
  addressLongitude?: string;
  districtId?: number;
  storeAddressLocation?: StoreAddressLocation | null;
}

export type UpdateStoreResponse = iApiResponse<Account>;

export interface LinkStoresRequest {
  saasStoreId: string;
  marketplaceStoreId: string;
  inventoryIdList: string[];
}

export interface iConflictInventory {
  inventoryId: string;
  quantity: number;
  minPrice: number;
  maxPrice: number;
}

export interface LinkStoresResponseData {
  productVariantName: string;
  uomName: string;
  sourceInventory: iConflictInventory;
  targetInventory: iConflictInventory;
}

export type LinkStoresResponse = iApiResponse<LinkStoresResponseData[]> | void;

export interface UnlinkStoresRequest {
  storeId: string;
}

export interface GetStoreSettingRequest {
  storeId: string;
  customerId?: string;
}

export interface StoreSetting {
  duration: number;
  cumulativeMaxAmount: string;
  invoiceMaxAmount: string;
  isAllowMultipleTop: boolean;
  isMaxAmountActive: boolean;
  isTaxActive: boolean;
  taxPercentageAmount?: boolean;
  isTaxIncludeInPrice: boolean;
  isTaxAfterDiscount: boolean;
}

export type GetStoreSettingResponse = iApiResponse<StoreSetting>;

export interface UpdateStoreSettingRequest extends StoreSetting {
  storeId: string;
}

export interface TnCUser {
  id: string;
  phoneNumber: string;
  email: string;
  isAgreeTnc: boolean;
}

export interface TnCAgreement {
  user: TnCUser;
  content: string;
}

export type GetTnCAgreementResponse = iApiResponse<TnCAgreement>;

export type AgreeTnCResponse = iApiResponse<TnCAgreement>;
