import router from "../router";
import { Endpoints } from "../../../../common/api/endpoints";
import {
  GetEmployeesResponse,
  GetAllEmployeesRequest,
  Employee,
} from "../../../../employee-access";
import { db } from "../../../../inventory/offline";

router.get<GetEmployeesResponse, void, void, GetAllEmployeesRequest>(
  Endpoints.EMPLOYEE,
  async (req) => {
    const { page, pageSize, keyword } = req.query;
    let employeeList: Employee[] = [];

    if (keyword) {
      employeeList = await db.employees
        .where("tokens")
        .equals(keyword)
        .offset((page - 1) * pageSize)
        .limit(pageSize)
        .toArray();
    } else {
      employeeList = await db.employees
        .offset((page - 1) * pageSize)
        .limit(pageSize)
        .toArray();
    }

    return {
      page,
      pageSize,
      total: employeeList.length,
      data: employeeList,
    };
  }
);
