const AUTH_TOKEN_STORAGE_KEY = "AUTH_TOKEN";
const IS_LOGGED_IN_STORAGE_KEY = "IS_LOGGED_IN";

export const setUserToken = (token: string): void => {
  try {
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
  } catch (err) {
    /* do nothing */
  }
};

export const clearUserToken = (): void => {
  try {
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  } catch (err) {
    /* do nothing */
  }
};

export const getUserToken = (): string | null => {
  try {
    const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

    if (token) {
      return token;
    }

    return null;
  } catch (_err) {
    return null;
  }
};

export const setIsLoggedIn = (): void => {
  try {
    localStorage.setItem(IS_LOGGED_IN_STORAGE_KEY, "true");
  } catch (err) {
    /* do nothing */
  }
};

export const clearIsLoggedIn = (): void => {
  try {
    localStorage.removeItem(IS_LOGGED_IN_STORAGE_KEY);
  } catch (err) {
    /* do nothing */
  }
};

export const getIsLoggedIn = (): string | null => {
  try {
    const token = localStorage.getItem(IS_LOGGED_IN_STORAGE_KEY);

    if (token) {
      return token;
    }

    return null;
  } catch (_err) {
    return null;
  }
};
