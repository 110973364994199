import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import { BaseProviderProps } from "../types";

const DatePickerProvider = ({ children }: BaseProviderProps) => {
  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerProvider;
