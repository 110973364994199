import type { BaseQueryEnhancer } from "@reduxjs/toolkit/query";
import { Endpoints } from "./endpoints";
import { request } from "./request";
import debounce from "lodash/debounce";
import { iBaseQueryErrorResponse } from "@gada-saas/web-core/common/api/types";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { setUserToken } from "../utils";

class HandledError {
  constructor(
    public readonly value: any,
    public readonly meta: any = undefined
  ) {}
}

type AccessTokenAPIResponse = {
  accessToken: string;
  accessTokenExpiredAt: number;
  refreshToken: string;
  refreshTokenExpiredAt: number;
  idToken: string;
};

/**
 * Exponential backoff based on the attempt number.
 *
 * @remarks
 * 1. 1000ms * random(0.4, 1.4)
 * 2. 2000ms * random(0.4, 1.4)
 * 3. 3000ms * random(0.4, 1.4)
 * 4. 4000ms * random(0.4, 1.4)
 * 5. 5000ms * random(0.4, 1.4)
 *
 * @param attempt - Current attempt
 * @param maxRetries - Maximum number of retries
 */
async function defaultBackoff(attempt = 0, maxRetries = 3) {
  const attempts = Math.min(attempt, maxRetries);

  const timeout = ~~((Math.random() + 0.4) * (1000 * attempts)); // Force a positive int in the case we make this an option
  await new Promise((resolve) =>
    setTimeout((res: any) => resolve(res), timeout)
  );
}

export interface RetryOptions {
  /**
   * How many times the query will be retried (default: 5)
   */
  maxRetries?: number;
  /**
   * Function used to determine delay between retries
   */
  backoff?: (attempt: number, maxRetries: number) => Promise<void>;
}

function fail(e: any): never {
  throw Object.assign(new HandledError({ error: e }), {
    throwImmediately: true,
  });
}

let IS_RETRYING_TO_REAUTHORIZE = false;

const debouncedReauthorize = debounce(() => {
  request<{ data: AccessTokenAPIResponse }>({
    url: Endpoints.REFRESH_TOKEN,
    method: "POST",
    withCredentials: true,
  })
    .then((result) => {
      if (result.data) {
        IS_RETRYING_TO_REAUTHORIZE = false;
        setUserToken(result.data.data.accessToken);
      }
    })
    .catch((e) => {
      console.log("failed to refresh authorization", e);
    });
}, 300);

const retryWithBackoff: BaseQueryEnhancer<
  unknown,
  RetryOptions,
  RetryOptions | void
> = (baseQuery, defaultOptions) => async (args, api, extraOptions) => {
  const options = {
    maxRetries: 3,
    backoff: defaultBackoff,
    ...defaultOptions,
    ...extraOptions,
  };
  let retry = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const result = await baseQuery(args, api, extraOptions);

      if (result.error) {
        throw new HandledError(result);
      }
      return result;
    } catch (e) {
      retry++;

      // Check if we should call refresh token api.
      // We should avoid calling refresh token if we alerady call it before
      if (e instanceof HandledError) {
        const errorValue = e.value as QueryReturnValue<
          undefined,
          iBaseQueryErrorResponse<null>
        >;

        if (
          errorValue.error?.status === 401 &&
          errorValue.error?.data.data?.errorCode === "EXPIRED"
        ) {
          if (!IS_RETRYING_TO_REAUTHORIZE) {
            IS_RETRYING_TO_REAUTHORIZE = true;
            debouncedReauthorize();
          }

          if (retry <= options.maxRetries) {
            await options.backoff(retry, options.maxRetries);
          } else {
            return e.value;
          }
        } else {
          return e.value;
        }
      } else {
        throw e;
      }

      // if (e.throwImmediately || retry > options.maxRetries) {
      //   if (e instanceof HandledError) {
      //     return e.value;
      //   }

      //   // We don't know what this is, so we have to rethrow it
      //   throw e;
      // }
      // await options.backoff(retry, options.maxRetries);
    }
  }
};

export const retryWithReauthorize = Object.assign(retryWithBackoff, { fail });
