import {
  BoxIcon as InventoryIcon,
  CashierIcon,
  DiscountIcon,
  EmployeeAccessIcon,
  PurchaseListIcon,
  ReportIcon,
  SupplierIcon,
  TransactionHistoryIcon,
} from "@common/icons";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import CustomerIcon from "@material-ui/icons/PermIdentityOutlined";
import { PERMISSION_LIST } from "./permissionList";

export type Page = {
  id: string;
  targetUrl: string;
  permissions: string[];
  text: string;
  icon?: React.ElementType;
  withDivider?: boolean;
};

export type PageItems = {
  id: string;
  text: string;
  items: Page[];
  icon?: React.ElementType;
  withDivider?: boolean;
  permissions: string[];
};

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;

export type MenuTypes =
  | "HOME"
  | "REPORT"
  | "POS"
  | "TRANSACTION_HISTORY"
  | "CRM"
  | "INVENTORY"
  | "PURCHASE"
  | "EMPLOYEE_ACCESS"
  | "SUPPLIER"
  | "PROMOTION";

export type PagePermissionsType = {
  [x in MenuTypes]: XOR<Page, PageItems>;
};

export const PagePermissions: PagePermissionsType = {
  HOME: {
    id: "1",
    targetUrl: "/",
    permissions: [PERMISSION_LIST.COMMON],
    text: "Beranda",
    icon: HomeIcon,
  },
  POS: {
    id: "3",
    targetUrl: "/pos",
    permissions: [PERMISSION_LIST.CASHIER],
    text: "Kasir",
    icon: CashierIcon,
  },
  INVENTORY: {
    id: "6",
    permissions: [PERMISSION_LIST.COMMON],
    text: "Inventori",
    icon: InventoryIcon,
    items: [
      {
        id: "22",
        targetUrl: "/inventory/list",
        permissions: [PERMISSION_LIST.READ_INVENTORY],
        text: "Daftar Barang",
      },
      {
        id: "23",
        targetUrl: "/report/inventory-value/list",
        permissions: [PERMISSION_LIST.READ_INVENTORY],
        text: "Nilai Inventori Barang",
      },
      {
        id: "24",
        targetUrl: "/report/bad-stock",
        permissions: [PERMISSION_LIST.READ_INVENTORY],
        text: "Bad Stock",
      },
    ],
  },
  REPORT: {
    id: "2",
    text: "Laporan",
    icon: ReportIcon,
    permissions: [PERMISSION_LIST.COMMON],
    withDivider: true,
    items: [
      {
        id: "10",
        targetUrl: "/report",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Ringkasan Laporan",
      },
      {
        id: "11",
        targetUrl: "/report/sales",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Ringkasan Penjualan",
      },
      {
        id: "12",
        targetUrl: "/report/top-selling-items",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Penjualan per Barang",
      },
      {
        id: "13",
        targetUrl: "/report/sales-category",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Penjualan per Kategori",
      },
      {
        id: "14",
        targetUrl: "/report/expense",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Pengeluaran",
      },
      {
        id: "15",
        targetUrl: "/report/profit",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Untung/Rugi",
      },
      {
        id: "16",
        targetUrl: "/report/item-profit",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Untung/Rugi per Barang",
      },
      {
        id: "21",
        targetUrl: "/report/store-debt",
        permissions: [PERMISSION_LIST.PURCHASE],
        text: "Utang",
      },
      {
        id: "17",
        targetUrl: "/report/consignment-debt",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Utang Konsinyasi",
      },
      {
        id: "18",
        targetUrl: "/report/customer-debt?tab=debt",
        permissions: [PERMISSION_LIST.CASHIER],
        text: "Piutang Pelanggan",
      },
      {
        id: "19",
        targetUrl: "/report/delivery-order/list",
        permissions: [PERMISSION_LIST.READ_REPORT],
        text: "Rekap Pengiriman",
      },
    ],
  },
  TRANSACTION_HISTORY: {
    id: "4",
    targetUrl: "/report/customer-debt?tab=all&origin=Sidemenu",
    permissions: [PERMISSION_LIST.CASHIER],
    text: "Riwayat Penjualan",
    icon: TransactionHistoryIcon,
  },
  PURCHASE: {
    id: "20",
    targetUrl: "/purchase/list",
    permissions: [PERMISSION_LIST.PURCHASE],
    text: "Pembelian",
    icon: PurchaseListIcon,
    withDivider: true,
  },
  CRM: {
    id: "5",
    targetUrl: "/crm",
    permissions: [PERMISSION_LIST.CASHIER],
    text: "Daftar Pelanggan",
    icon: CustomerIcon,
  },
  PROMOTION: {
    id: "7",
    targetUrl: "/promotion/list",
    text: "Promo",
    icon: DiscountIcon,
    permissions: [PERMISSION_LIST.MANAGE_DISCOUNT],
  },
  SUPPLIER: {
    id: "9",
    text: "Daftar Supplier",
    targetUrl: "/supplier/list",
    permissions: [PERMISSION_LIST.PURCHASE],
    icon: SupplierIcon,
  },
  EMPLOYEE_ACCESS: {
    id: "8",
    text: "Daftar Karyawan",
    targetUrl: "/user/employee-access",
    permissions: [PERMISSION_LIST.READ_EMPLOYEE],
    icon: EmployeeAccessIcon,
  },
};
