import { BaseQueryFn } from "@reduxjs/toolkit/query";
import {
  ApisauceConfig,
  create,
  RequestTransform,
  ResponseTransform,
} from "apisauce";
import { camelizeKeys, decamelizeKeys } from "humps";
import { isEmpty, isObject } from "lodash";
import { compile } from "path-to-regexp";
import qs from "qs";
import { iGadaApiError } from "../models";
import { offlineSelector } from "../../offline";
import { AppStateType } from "../../store";
import { iBaseQueryErrorResponse } from "./types";
import unset from "lodash/unset";
import getConfig from "next/config";
import { interceptOfflineRequest, interceptOfflineResponse } from "./offline";
import { retryWithReauthorize } from "./reauthorize";

const { publicRuntimeConfig } = getConfig();
const { host } = publicRuntimeConfig;

// Axios Wrapper for RTK Query
// See : https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#axios-basequery

export const apiSauce = create({ baseURL: host });

const caseRequestParserTransform: RequestTransform = (request) => {
  if (request.headers["Content-Type"] === "multipart/form-data") return request;
  if (request.params) {
    request.params = decamelizeKeys(request.params);
  }
  if (request.data) {
    if (!request.data.isOffline) {
      request.data = decamelizeKeys(request.data);
    }
  }
  return request;
};

const caseResponseParserTransform: ResponseTransform = (response) => {
  if (
    response.data &&
    response.headers &&
    response.headers["content-type"].includes("application/json")
  ) {
    response.data = camelizeKeys(response.data);
  }
  return response;
};

apiSauce.addRequestTransform(caseRequestParserTransform);
apiSauce.addResponseTransform(caseResponseParserTransform);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clean = (obj: any) => {
  for (const propName in obj) {
    if (isObject(obj[propName])) {
      clean(obj[propName]);
    }
    if (
      obj[propName] === "" ||
      obj[propName] === null ||
      obj[propName] === undefined ||
      (isObject(obj[propName]) && isEmpty(obj[propName]))
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const axiosBaseQuery = (
  { baseUrl }: { baseUrl?: string } = {
    baseUrl: host,
  }
): BaseQueryFn<
  {
    url: string;
    method: ApisauceConfig["method"];
    data?: ApisauceConfig["data"];
    routeParams?: Record<string, string>;
    queryParams?: ApisauceConfig["params"];
    dropCredentials?: boolean;
    forceOffline?: boolean;
  },
  unknown,
  iBaseQueryErrorResponse,
  { withOffline?: boolean } | undefined
> => {
  return async (
    { url, method, data, routeParams, queryParams, forceOffline },
    { getState },
    extraOptions
  ) => {
    const { isOffline } = offlineSelector(getState() as AppStateType);

    let apiError: iBaseQueryErrorResponse["data"] | undefined = undefined;
    // isOffline is passed to service worker so
    // they can decide whether to forward request to actual api or using localdb
    // let requestBody = data;
    // let enhancedQueryParams = queryParams;

    if (extraOptions && extraOptions.withOffline) {
      // Check if query params supplied by hooks
      if (isOffline || forceOffline) {
        try {
          const response = await interceptOfflineRequest({
            url,
            query: queryParams,
            params: routeParams,
            method,
            body: data,
          });
          if (process.env.NODE_ENV !== "production") {
            console.log(`Response from interceptor ${method} ${url}`, response);
          }
          return { data: response };
        } catch (exc) {
          const err: any = exc;
          return {
            error: {
              status: err?.status || 400,
              data: err?.err || null,
            },
          };
        }
      }
    }

    try {
      const result = await apiSauce.any<any, iGadaApiError<unknown>>({
        baseURL: baseUrl,
        url: compile(url || "", { encode: encodeURIComponent })(routeParams),
        params: queryParams,
        paramsSerializer: (params) =>
          qs.stringify(clean(params), { arrayFormat: "repeat" }),
        method,
        data,
        withCredentials: true,
      });

      if (result.ok) {
        if (extraOptions && extraOptions.withOffline) {
          try {
            await interceptOfflineResponse(
              {
                url,
                query: queryParams,
                params: routeParams,
                method,
                body: data,
              },
              result.data
            );
          } catch (err) {
            console.log("Error when intercepting response", err);
          }
        }

        return { data: result.data };
      } else {
        unset(result, "originalError");
        unset(result, "config.transformRequest");
        unset(result, "config.transformResponse");
        unset(result, "config.paramsSerializer");
        unset(result, "config.adapter");
        unset(result, "config.validateStatus");
        apiError = result as iBaseQueryErrorResponse["data"];
        // Throwing error from try is needed because otherwise the base query response type is expected to be a success response
        throw apiError;
      }
    } catch (err) {
      return {
        error: {
          status: apiError?.status || 400,
          data: apiError || err,
        },
      };
    }
  };
};

const ApiSauceWithReauthorize = () => retryWithReauthorize(axiosBaseQuery());

export default ApiSauceWithReauthorize;
