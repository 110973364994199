import router from "../router";
import { Endpoints } from "../../../../common/api/endpoints";
import { StoreTopRequest, StoreTopResponse } from "../../../../stores";
import { db } from "../../../../inventory/offline";
import {
  GetAllBankAccountsRequest,
  GetAllBankAccountsResponse,
} from "../../../../user";

router.get<StoreTopResponse, void, void, StoreTopRequest>(
  Endpoints.STORE_SETTING,
  async (req, _resp) => {
    const { storeId } = req.query;

    const result = await db.storeSettings
      .where("storeId")
      .equals(storeId)
      .limit(1)
      .toArray();

    return {
      data: result[0],
    };
  }
);

router.get<GetAllBankAccountsResponse, void, void, GetAllBankAccountsRequest>(
  Endpoints.GET_ALL_BANK_ACCOUNTS,
  async (req) => {
    const { page, pageSize } = req.query;
    const result = await db.bankAccounts
      .offset((page - 1) * pageSize)
      .limit(pageSize)
      .toArray();

    return {
      data: result,
      page,
      total: result.length,
    };
  }
);
