import * as React from "react";

// Hook
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
// hook returns tuple(array) with type [any, boolean]
export default function useHover<T extends HTMLElement>(): [
  React.MutableRefObject<T>,
  boolean
] {
  const [value, setValue] = React.useState<boolean>(false);
  const ref: any = React.useRef<T | null>(null);

  const isParent = (refNode: T, otherNode: any) => {
    let parent = otherNode?.parentNode || null;
    while (parent) {
      if (refNode == parent) {
        return true;
      } else {
        parent = parent?.parentNode || null;
      }
    }
    return false;
  };

  const handleMouseOver = (ev: MouseEvent): void => {
    const node: T = ref.current;
    if (isParent(node, ev.target) || ev.target == node) {
      setValue(true);
    }
  };

  const handleMouseOut = (ev: MouseEvent): void => {
    const node: T = ref.current;
    if (ev.target == node) {
      setValue(false);
    }
  };

  React.useEffect(
    () => {
      const node: T = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseleave", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseleave", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}
