import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { globalResetAction } from "../../common/redux/actions";

type InventoryListState = {
  uiStore: {
    showConversionHierarchyModal: boolean;
    showPriceEditorModal: boolean;
    showSupplierModal: boolean;
    activeProductVariantId: number;
    showExtraFeesModal: boolean;
    showTaxModal: boolean;
    taxModalContent:
      | "buyingContent"
      | "confirmationContent"
      | "sellingContent"
      | null;
    showExtraDiscountModal: boolean;
    showInventoryNameInputModal: boolean;
    showProductVariantNameEditorModal: boolean;
    productVariantNameEditingContext: "create" | "update";
    productVariantNameInitialValue: string;
    productVariantIndex: number;
  };
  purchaseId?: string;
};

const initialState: InventoryListState = {
  uiStore: {
    showConversionHierarchyModal: false,
    showPriceEditorModal: false,
    showSupplierModal: false,
    activeProductVariantId: 0,
    showExtraFeesModal: false,
    showTaxModal: false,
    showExtraDiscountModal: false,
    showInventoryNameInputModal: false,
    showProductVariantNameEditorModal: false,
    productVariantNameEditingContext: "create",
    productVariantNameInitialValue: "",
    productVariantIndex: 0,
    taxModalContent: null,
  },
  purchaseId: undefined,
};

const BuyingSlice = createSlice({
  name: "buyingState",
  initialState: initialState,
  reducers: {
    showConversionHierarchyModal(state) {
      state.uiStore.showConversionHierarchyModal = true;
    },
    hideConversionHierarchyModal(state) {
      state.uiStore.showConversionHierarchyModal = false;
    },
    showPriceEditorModal(state) {
      state.uiStore.showPriceEditorModal = true;
    },
    hidePriceEditorModal(state) {
      state.uiStore.showPriceEditorModal = false;
    },
    showSupplierModal(state) {
      state.uiStore.showSupplierModal = true;
    },
    hideSupplierModal(state) {
      state.uiStore.showSupplierModal = false;
    },
    setActiveProductVariantId(state, action: PayloadAction<number>) {
      state.uiStore.activeProductVariantId = action.payload;
    },
    showExtraFeesModal(state) {
      state.uiStore.showExtraFeesModal = true;
    },
    showTaxModal(
      state,
      action: PayloadAction<InventoryListState["uiStore"]["taxModalContent"]>
    ) {
      state.uiStore.showTaxModal = true;
      state.uiStore.taxModalContent = action.payload;
    },
    showExtraDiscountModal(state) {
      state.uiStore.showExtraDiscountModal = true;
    },
    showInventoryNameInputModal(state) {
      state.uiStore.showInventoryNameInputModal = true;
    },
    showProductVariantNameEditorModal(
      state,
      action: PayloadAction<{
        context: "create" | "update";
        name?: string;
        index?: number;
      }>
    ) {
      state.uiStore.showProductVariantNameEditorModal = true;
      if (action.payload.context === "create") {
        state.uiStore.productVariantNameEditingContext = "create";
        state.uiStore.productVariantIndex = 0;
        state.uiStore.productVariantNameInitialValue =
          action.payload.name ?? "";
      } else {
        state.uiStore.productVariantNameEditingContext = "update";
        state.uiStore.productVariantIndex = action.payload.index as number;
        state.uiStore.productVariantNameInitialValue = "";
      }
    },
    hideExtraFeesModal(state) {
      state.uiStore.showExtraFeesModal = false;
    },
    hideTaxModal(state) {
      state.uiStore.showTaxModal = false;
      state.uiStore.taxModalContent = null;
    },
    hideExtraDiscountModal(state) {
      state.uiStore.showExtraDiscountModal = false;
    },
    hideInventoryNameInputModal(state) {
      state.uiStore.showInventoryNameInputModal = false;
    },
    hideProductVariantNameEditorModal(state) {
      state.uiStore.showProductVariantNameEditorModal = false;
    },
    setProductVariantIndex(state, action: PayloadAction<number>) {
      state.uiStore.productVariantIndex = action.payload;
    },
    showPurchaseDetail(
      state,
      { payload }: PayloadAction<{ purchaseId: string }>
    ) {
      state.purchaseId = payload.purchaseId;
    },
    closePurchaseDetailDrawer(state) {
      state.purchaseId = initialState.purchaseId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

const { actions } = BuyingSlice;

export const useBuyingActions = () => useActions(actions);

export default BuyingSlice;
