import * as React from "react";
import { theme } from "@gada-saas/web-ui";
import { IconProps } from "./types";

const ArrowRightIcon: React.VFC<IconProps> = ({
  width = "8",
  height = "12",
  fill = theme.palette.interface.red.primary,
  rotate = 0,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      transform={`rotate(${rotate})`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.29 0.710022C1.1973 0.802536 1.12375 0.912424 1.07357 1.0334C1.02339 1.15437 0.997559 1.28405 0.997559 1.41502C0.997559 1.54599 1.02339 1.67567 1.07357 1.79665C1.12375 1.91762 1.1973 2.02751 1.29 2.12002L5.17 6.00002L1.29 9.88002C1.19742 9.9726 1.12398 10.0825 1.07388 10.2035C1.02377 10.3244 0.997982 10.4541 0.997982 10.585C0.997982 10.716 1.02377 10.8456 1.07388 10.9666C1.12398 11.0875 1.19742 11.1974 1.29 11.29C1.38258 11.3826 1.4925 11.456 1.61346 11.5061C1.73442 11.5563 1.86407 11.582 1.995 11.582C2.12593 11.582 2.25558 11.5563 2.37655 11.5061C2.49751 11.456 2.60742 11.3826 2.7 11.29L7.29 6.70002C7.38271 6.60751 7.45625 6.49762 7.50644 6.37665C7.55662 6.25567 7.58245 6.12599 7.58245 5.99502C7.58245 5.86405 7.55662 5.73437 7.50644 5.6134C7.45625 5.49242 7.38271 5.38254 7.29 5.29002L2.7 0.700022C2.32 0.320022 1.68 0.320022 1.29 0.710022Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowRightIcon;
