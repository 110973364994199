import * as React from "react";
import { IconProps } from "./types";
import { theme } from "@gada-saas/web-ui";

const SupplierIcon: React.VFC<IconProps> = ({
  width = "21",
  height = "21",
  fill = theme.palette.interface.neutral.light,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11"
        y="11"
        width="11"
        height="11"
        rx="2"
        stroke={fill}
        strokeWidth="2"
      />
      <rect x="14" y="14" width="5" height="2" rx="1" fill={fill} />
      <path
        d="M10.5 3.01875C10.793 3.01875 11.0832 3.07646 11.3539 3.18859C11.6246 3.30072 11.8705 3.46508 12.0777 3.67227C12.2849 3.87946 12.4493 4.12543 12.5614 4.39614C12.6735 4.66685 12.7313 4.95699 12.7313 5.25C12.7313 5.54301 12.6735 5.83316 12.5614 6.10386C12.4493 6.37457 12.2849 6.62054 12.0777 6.82773C11.8705 7.03492 11.6246 7.19928 11.3539 7.31141C11.0832 7.42354 10.793 7.48125 10.5 7.48125C9.90824 7.48125 9.34071 7.24617 8.92227 6.82773C8.50383 6.40929 8.26875 5.84176 8.26875 5.25C8.26875 4.65824 8.50383 4.09071 8.92227 3.67227C9.34071 3.25383 9.90824 3.01875 10.5 3.01875ZM10.5 1C8.15188 1 6.25 2.90188 6.25 5.25C6.25 7.59812 8.15188 9.5 10.5 9.5C12.8481 9.5 14.75 7.59812 14.75 5.25C14.75 2.90188 12.8481 1 10.5 1Z"
        fill={fill}
      />
      <path
        d="M4.01875 15.9813V14.8125C4.01875 14.1325 7.34437 12.5812 10.5 12.5812C10.7044 12.5812 10.9096 12.5878 11.1145 12.6001L11.2732 10.5625C11.0571 10.5494 10.6927 10.5625 10.5 10.5625C7.66313 10.5625 2 11.9862 2 14.8125V18H11V15.9813H4.01875Z"
        fill={fill}
      />
    </svg>
  );
};

export default SupplierIcon;
