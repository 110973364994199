import { makeStyles } from "@material-ui/core";
import { AppTheme } from "../../..";
import { pxToRem } from "../../../utils";
import { iRowProps } from "./types";

export const useStyles = makeStyles<AppTheme, iRowProps>({
  container: (props) => ({
    display: "flex",
    flexDirection: "row",
    flex: props.flex,
    alignItems: props.vCenter ? "center" : "none",
    justifyContent: props.justifyContent
      ? props.justifyContent
      : props.hCenter
      ? "center"
      : "none",
    width: props.fullWidth ? "100%" : "auto",

    "&>*:not(:last-child)": {
      marginRight: props.spacing && pxToRem(props.spacing),
    },
  }),
});
