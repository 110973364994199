import { useCallback } from "react";
import debounce from "lodash/debounce";

export type FunctionToDebounce = (...args: any) => any;

export const useDebouncedCallback = (
  fnToDebounce: FunctionToDebounce,
  durationInMs = 200,
  deps?: any[] | null
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(fnToDebounce, durationInMs),
    deps ? [fnToDebounce, durationInMs, ...deps] : [fnToDebounce, durationInMs]
  );
};

export default useDebouncedCallback;
