import {
  iApiResponse,
  iListApiRequest,
  iListApiResponse,
  iListSortApiRequest,
} from "../../common/models";
import { GlobalReportState } from "../types";

export enum SalesProductStateSortBy {
  GROSS_AMOUNT_AGGREGATE = "gross_amount_aggregate",
  NET_AMOUNT_AGGREGATE = "net_amount_aggregate",
  QUANTITY_IN_SMALLEST_UOM_AGGREGATE = "quantity_in_smallest_uom_aggregate",
  PRODUCT_VARIANT_NAME = "product_variant_name",
}

export interface SalesProductState
  extends iListSortApiRequest<SalesProductStateSortBy> {
  productVariantName?: string | null;
  uomId?: number | null;
}

export interface SalesPerProduct {
  quantityInSmallestUomAggregate: number;
  refundAmountAggregate: string;
  quantityAggregate: number;
  uomId: number;
  discountAggregate: string;
  productVariantId: number;
  uomName: string;
  transactionCount: number;
  grossAmountAggregate: string;
  createdAt: string;
  netAmountAggregate: string;
  productVariantName: string;
}

export interface GetSalesPerProductRequest {
  globalReportParams: GlobalReportState;
  listParams: SalesProductState;
  storeId: string;
}

export interface GetSalesPerProductDetailsRequest {
  globalReportParams: GlobalReportState;
  uomId?: string;
  storeId: string;
  productVariantId: number;
}

export type GetSalesPerProductResponse = iListApiResponse<SalesPerProduct[]>;

export type GetSalesPerProductDetailsResponse = iApiResponse<SalesPerProduct>;

export interface GetItemProductUnitsRequest extends iListApiRequest {
  productVariantIds: number[];
  storeId?: string;
  includeDelete: boolean;
}

export type GetItemProductUnitsResponse = iListApiResponse<
  GetItemProductUnitsResponseData[]
>;

export interface GetItemProductUnitsResponseData {
  unitOfMeasurement: iUnitOfMeasurement;
  photo1: string;
  smallerProductUnitId: number;
  unitPerPallet: string;
  width: string;
  photo2: string;
  photo4: string;
  length: string;
  photo5: string;
  productVariantId: number;
  height: string;
  id: number;
  isActive: boolean;
  storeId: string;
  GUID: string;
  weight: string;
  unitPerSquareMeter: string;
  photo3: string;
  barcode: iBarcode[];
  multiplierToSmallerProductUnit: number;
}

export interface iUnitOfMeasurement {
  shortName: string;
  id: number;
  storeId: string;
  isActive: boolean;
  longName: string;
}

export interface iBarcode {
  isStoreLevel: boolean;
  barcode: string;
}
