import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Channels, DurationTypes, GlobalReportState } from "./types";
import { useActions } from "../common/hooks/useActions";
import { globalResetAction } from "../common/redux/actions";

const initialState: GlobalReportState = {
  channel: Channels.ALL,
  durationEnd: null,
  durationStart: null,
  durationType: DurationTypes.DAILY,
};

const GlobalReportSlice = createSlice({
  initialState,
  name: "globalReport",
  reducers: {
    setChannel(state, action: PayloadAction<GlobalReportState["channel"]>) {
      state.channel = action.payload;
    },
    setDuration(
      state,
      action: PayloadAction<Omit<GlobalReportState, "channel" | "storeId">>
    ) {
      state.durationEnd = action.payload.durationEnd;
      state.durationStart = action.payload.durationStart;
      state.durationType = action.payload.durationType;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const { actions: GlobalReportActions } = GlobalReportSlice;

export const useGlobalReportActions = (): typeof GlobalReportActions =>
  useActions(GlobalReportActions);

export default GlobalReportSlice.reducer;
