import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { SalesCategoryState } from "./types";
import { SortType } from "../../common/models";
import { globalResetAction } from "../../common/redux/actions";

const initialState: SalesCategoryState = {
  pageSize: 10,
  page: 1,
};

const salesCategorySlice = createSlice({
  name: "sales-category",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSort: (state, action: PayloadAction<string>) => {
      if (action.payload === state.sortBy) {
        state.sortType =
          state.sortType === SortType.ASCENDING
            ? SortType.DESCENDING
            : SortType.ASCENDING;
      } else {
        state.sortBy = action.payload;
        state.sortType = SortType.ASCENDING;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

const { actions } = salesCategorySlice;

export const useSalesCategoryActions = (): typeof actions =>
  useActions(actions);

export default salesCategorySlice.reducer;
