import { theme } from "@gada-saas/web-ui";
import React from "react";
import { IconProps } from "./types";

const LineIcon: React.VFC<IconProps> = ({
  width = "5",
  height = "28",
  fill = theme.palette.interface.neutral.secondary,
}) => {
  return (
    <svg fill="none" width={width} height={height} viewBox="0 0 5 28">
      <rect width="5" height="28" rx="2.5" fill={fill} />
    </svg>
  );
};

export default LineIcon;
