import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { CreatePromotionUIState } from "./types";

const initialState: CreatePromotionUIState = {
  isStepperLabelShown: true,
  activeStepIndex: 0,
  isPromotionModalShown: false,
  modalAction: "",
  isDeletingPromotion: false,
  isBackdropShown: false,
};

export const createPromotionUISlice = createSlice({
  name: "createPromotionUIState",
  initialState,
  reducers: {
    toggleLabel(state) {
      state.isStepperLabelShown = !state.isStepperLabelShown;
    },
    setIsStepperLabelShown(state, action: PayloadAction<boolean>) {
      state.isStepperLabelShown = action.payload;
    },
    setActiveStepIndex(state, action: PayloadAction<number>) {
      state.activeStepIndex = action.payload;
    },
    resetState() {
      return initialState;
    },
    toggleSuccessModal(state) {
      state.isPromotionModalShown = !state.isPromotionModalShown;
    },
    setModalState(
      state,
      action: PayloadAction<
        Partial<
          Pick<
            CreatePromotionUIState,
            "isPromotionModalShown" | "modalAction" | "isDeletingPromotion"
          >
        >
      >
    ) {
      state.isPromotionModalShown =
        action.payload.isPromotionModalShown !== undefined
          ? action.payload.isPromotionModalShown
          : state.isPromotionModalShown;
      state.modalAction =
        action.payload.modalAction !== undefined
          ? action.payload.modalAction
          : state.modalAction;
      state.isDeletingPromotion =
        action.payload.isDeletingPromotion !== undefined
          ? action.payload.isDeletingPromotion
          : state.isDeletingPromotion;
    },
    setIsBackdropShown(state, action: PayloadAction<boolean>) {
      state.isBackdropShown = action.payload;
    },
  },
});

export const useCreatePromotionUIActions = (): typeof createPromotionUISlice.actions =>
  useActions(createPromotionUISlice.actions);

export default createPromotionUISlice;
