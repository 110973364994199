import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const NorthEastIcon: React.VFC<IconProps> = ({
  width = 15,
  height = 15,
  fill = theme.palette.interface.teal.primary,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1c0 .56.45 1 1 1h5.59L.7 12.89a.997.997 0 101.41 1.41L13 3.41V9c0 .55.45 1 1 1s1-.45 1-1V1c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1z"
        fill={fill}
      />
    </svg>
  );
};

export default NorthEastIcon;
