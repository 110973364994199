import {
  CustomerSummary,
  useActiveStoreState,
  useLazyGetInventoryDownloadUrlQuery,
  useLazyGetStoreTopSettingQuery,
  useLazyGetUserInfoQuery,
  useLazyGetSavedCartsQuery,
  formatDate,
  useLazyGetSalesEmployeesQuery,
  useLazyGetAllBankAccountsQuery,
  useLazyGetInventoryRecommendationRankingQuery,
} from "@gada-saas/web-core";
import { useLazyGetCustomerSummaryDownloadUrlQuery } from "@gada-saas/web-core/report/customer-debt/hooks";
import {
  retrieveS3Data,
  populate,
  initStoreSettings,
  initUserInfo,
  S3InventoryListAPIResponseItem,
  populateCustomerData,
  replaceOnlineSavedCarts,
  updateMetaData,
  populateEmployeeData,
  populateBankAccounts,
} from "@gada-saas/web-core/inventory/offline";
import { camelizeKeys } from "humps";
import * as React from "react";
import {
  CartQueryType,
  CartStatus,
} from "@gada-saas/web-core/pos/cart/constants";
import { useGlobalOfflineState } from "@gada-saas/web-core/offline";

export function useOfflineInitialDataFetcher() {
  const { storeId } = useActiveStoreState();
  const { isOffline } = useGlobalOfflineState();
  const [getInvDownloadURL] = useLazyGetInventoryDownloadUrlQuery();
  const [getUserInfo] = useLazyGetUserInfoQuery();
  const [getStoreSettings] = useLazyGetStoreTopSettingQuery();
  const [getCustomerSummary] = useLazyGetCustomerSummaryDownloadUrlQuery();
  const [getSavedCarts] = useLazyGetSavedCartsQuery();
  const [getSalesEmployee] = useLazyGetSalesEmployeesQuery();
  const [getAllBankAccounts] = useLazyGetAllBankAccountsQuery();
  const [getInventoryRecommendationRanking] =
    useLazyGetInventoryRecommendationRankingQuery();

  const fetchInventories = React.useCallback(() => {
    if (!storeId) {
      return;
    }

    getInvDownloadURL({
      storeId,
    })
      .unwrap()
      .then((response) => {
        return retrieveS3Data<S3InventoryListAPIResponseItem[]>({
          s3Url: response.data.storageLink,
        });
      })
      .then((response) => {
        populate(
          camelizeKeys(
            response.data as object[]
          ) as S3InventoryListAPIResponseItem[]
        );
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [getInvDownloadURL, storeId]);

  const fetchStoreSettings = React.useCallback(() => {
    if (!storeId) {
      return;
    }

    getStoreSettings({
      storeId,
    })
      .unwrap()
      .then((res) => {
        initStoreSettings(storeId, res.data);
      })
      .catch((err) => {
        // Possible error, open the app while offline
        console.log("error", err);
      });
  }, [getStoreSettings, storeId]);

  const fetchUserInfo = React.useCallback(() => {
    if (!storeId) {
      return;
    }

    getUserInfo()
      .unwrap()
      .then((user) => {
        initUserInfo(storeId, user.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [getUserInfo, storeId]);

  const fetchCustomerSummary = React.useCallback(() => {
    if (!storeId) {
      return;
    }

    getCustomerSummary({
      storeId,
    })
      .unwrap()
      .then((response) => {
        return retrieveS3Data<CustomerSummary[]>({
          s3Url: response.data.url,
        });
      })
      .then((response) => {
        const customerSummaries = response.data;
        populateCustomerData(
          camelizeKeys(customerSummaries as object[]) as CustomerSummary[]
        );
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [getCustomerSummary, storeId]);

  const fetchSavedCarts = React.useCallback(() => {
    if (!storeId) {
      return;
    }
    getSavedCarts({
      storeId,
      page: 1,
      pageSize: 100,
      cartStatus: CartStatus.SAVED,
      queryType: CartQueryType.STATUS,
    })
      .unwrap()
      .then((response) => {
        replaceOnlineSavedCarts(response.data);
      });
  }, [getSavedCarts, storeId]);

  const fetchSalesEmployee = React.useCallback(() => {
    if (!storeId) return;

    getSalesEmployee({ page: 1, pageSize: 100, storeId })
      .unwrap()
      .then((response) => {
        populateEmployeeData(response?.data);
      });
  }, [getSalesEmployee, storeId]);

  const updateMeta = React.useCallback(() => {
    if (!storeId) return;

    updateMetaData({
      storeId,
      inventoryLastUpdatedTime: formatDate(new Date()),
    });
  }, [storeId]);

  const fetchAllBankAccounts = React.useCallback(() => {
    getAllBankAccounts({
      pageSize: 100,
      page: 1,
    }).then((response) => {
      if (response.isSuccess) {
        populateBankAccounts(response.data.data);
      }
    });
  }, [getAllBankAccounts]);

  const fetchAllInitialData = React.useCallback(() => {
    if (isOffline || !storeId) return;
    fetchInventories();
    fetchStoreSettings();
    fetchUserInfo();
    fetchCustomerSummary();
    fetchSavedCarts();
    updateMeta();
    fetchSalesEmployee();
    fetchAllBankAccounts();
    getInventoryRecommendationRanking({ storeId });
  }, [
    fetchInventories,
    fetchStoreSettings,
    fetchUserInfo,
    fetchCustomerSummary,
    fetchSavedCarts,
    updateMeta,
    fetchSalesEmployee,
    isOffline,
    fetchAllBankAccounts,
    getInventoryRecommendationRanking,
    storeId,
  ]);

  return {
    fetchAllInitialData,
    fetchInventories,
    fetchStoreSettings,
    fetchUserInfo,
    fetchCustomerSummary,
    fetchSavedCarts,
  };
}
