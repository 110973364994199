import * as React from "react";
import { IconProps } from "./types";

const ContactUsIcon: React.VFC<IconProps> = ({
  width = "80",
  height = "80",
}) => {
  return (
    <svg fill="none" viewBox="0 0 80 80" width={width} height={height}>
      <mask
        id="g"
        x="0"
        y="0"
        width="80"
        height="80"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
      >
        <path
          d="m39.088 0.0071188c8.7256-0.1923 16.97 3.5314 24.133 8.7404 7.2858 5.2978 15.129 11.732 16.6 20.879 1.4284 8.882-6.1093 16.135-9.3028 24.5-2.9042 7.6076-2.3172 17.269-8.5427 22.246-6.2562 5.0014-15.042 3.6094-22.888 2.8802-7.2014-0.6693-13.937-2.9422-20.109-6.8762-6.6656-4.2486-13.437-8.9912-16.534-16.48-3.2588-7.8785-3.0288-16.888-0.66803-25.109 2.3996-8.3559 7.3542-15.587 13.907-20.993 6.761-5.5774 14.793-9.5982 23.405-9.788z"
          clipRule="evenodd"
          fill="#F3F3F3"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#g)">
        <path
          d="m39.088 0.0071188c8.7256-0.1923 16.97 3.5314 24.133 8.7404 7.2858 5.2978 15.129 11.732 16.6 20.879 1.4284 8.882-6.1093 16.135-9.3028 24.5-2.9042 7.6076-2.3172 17.269-8.5427 22.246-6.2562 5.0014-15.042 3.6094-22.888 2.8802-7.2014-0.6693-13.937-2.9422-20.109-6.8762-6.6656-4.2486-13.437-8.9912-16.534-16.48-3.2588-7.8785-3.0288-16.888-0.66803-25.109 2.3996-8.3559 7.3542-15.587 13.907-20.993 6.761-5.5774 14.793-9.5982 23.405-9.788z"
          clipRule="evenodd"
          fill="url(#f)"
          fillRule="evenodd"
        />
        <path
          d="m44.788 19.559c5.6247 0.4875 10.482 2.0039 14.279 4.1692 3.5509 2.0248 5.6259 4.5475 5.7637 7.2594 0.1419 2.791-1.5065 5.4929-5.0062 7.6445-3.8928 2.3932-8.99 4.2358-15.036 4.6774-7.0338 0.5138-14.906 0.3633-20.081-2.1126-5.2659-2.5194-5.6616-6.5331-5.6738-10.209-0.0121-3.6845 0.1462-7.8209 5.6062-10.244 5.2952-2.3497 13.22-1.7854 20.149-1.1848z"
          clipRule="evenodd"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          d="m63.159 79.032h-43.16l1.0735-6.2601c1.0456-6.0871 6.3235-10.538 12.501-10.538h16.011c6.1774 0 11.455 4.4505 12.501 10.538l1.0734 6.2601z"
          fill="url(#e)"
        />
        <path
          d="m47.496 59.477v2.7908s-1.4079 3.0416-4.8377 3.8373h-0.0202c-0.4604 0.1125-0.9678 0.1836-1.5031 0.1941-4.5312-0.0922-6.3514-4.0314-6.3514-4.0314v-2.7591c0.815 0.6323 1.7347 1.1474 2.7313 1.4636 0.1422 0.0509 0.2873 0.0913 0.4295 0.1326 0.7573 0.2239 1.5425 0.3364 2.367 0.3364h1.6482c2.0681 0 3.9748-0.7602 5.5364-1.9643z"
          fill="url(#d)"
        />
        <g opacity=".1">
          <g opacity=".1">
            <path
              d="m47.496 59.477v2.7908s-1.4079 3.0416-4.8378 3.8373h-0.0201l-4.6946-5.0002c0.7573 0.2239 1.5424 0.3364 2.367 0.3364h1.6481c2.0691 0 3.9758-0.7602 5.5374-1.9643z"
              fill="#40596B"
              opacity=".1"
            />
          </g>
        </g>
        <path d="m41.137 66.292h-2e-3v0.0041h2e-3v-0.0041z" fill="#E6D2BA" />
        <path
          d="m48.698 55.968s5.3404 5.8834 4.0074 9.5487c-1.3339 3.6654-1.5107 8.1793-0.3767 9.1787 0 0-0.2153 1.2225-2.1027-0.7774 0 0-5.745-5.4269-4.5235-12.092 0 0 1.7654-1.6337 2.9955-5.8584z"
          fill="#323232"
        />
        <path
          d="m50.77 49.998s1.429-5.1617-7.2865-8.9702c0 0-4.2304 6.4312-9.6457 6.855 0 0 0.9302-0.5084 1.183-0.9313 0 0-1.2695 0.988-3.4712-1.2128 0 0-0.0567 1.3041-0.0731 2.9465-0.0182 1.8721 0.2816 3.7384 0.888 5.5095l0.8803 2.5727s-6.6003-4.3996-3.8085-15.74c0 0 1.946-9.8159 14.809-7.8698 0 0 8.2081 1.4386 8.038 12.44 0 0 0.0423 2.7658-1.5136 4.4005z"
          fill="#323232"
        />
        <path
          d="m33.075 50.336c0.3747 1.6068 0.1768 3.0272-0.4431 3.1714-0.6189 0.1441-1.4233-1.0418-1.8-2.6486-0.3738-1.6068-0.1768-3.0263 0.4431-3.1714 0.6189-0.1451 1.4252 1.0408 1.8 2.6486z"
          fill="#E6D2BA"
        />
        <g filter="url(#i)">
          <path
            d="m57.964 69.759-0.9524 11.37h-30.867l-0.9514-11.37c-0.0769-0.567 0.3748-1.0763 0.9514-1.0763h30.867c0.5776 0 1.0293 0.5093 0.9524 1.0763z"
            fill="url(#c)"
          />
        </g>
        <path
          d="m49.183 50.336c-0.3757 1.6068-0.1777 3.0272 0.4412 3.1714 0.6198 0.1441 1.3156-1.0706 1.7999-2.6486 0.2893-0.9408 0.1557-2.8658-0.4411-3.1714-0.567-0.2892-1.4252 1.0408-1.8 2.6486z"
          fill="#E6D2BA"
        />
        <path
          d="m43.482 41.029s-4.2304 6.4311-9.6458 6.854c0 0 0.9293-0.5074 1.184-0.9303 0 0-1.2686 0.985-3.4664-1.207-0.0019 0.0249-0.0039 0.0509-0.0087 0.0749-0.0125 0.2999-0.0567 1.455-0.0701 2.8658-0.0077 0.8207 0.0567 1.6376 0.1691 2.4487 0.0538 0.3315 0.1048 0.6631 0.174 0.9946 0 1e-3 0 1e-3 9e-4 0.0019 0.0798 0.3893 0.1768 0.7765 0.2778 1.1619 0.0845 0.3008 0.1643 0.6055 0.2662 0.9015l0.5718 1.6712c1.7356 4.4332 4.7532 8.0245 8.1936 8.0245 5.1799 0 9.4036-8.1274 9.7169-15.03-0.098-1.7337-1.1647-5.1251-7.3633-7.8313z"
          fill="#FFE7CA"
        />
        <path
          d="m41.129 60.484c1.5143 0 2.742-1.2276 2.742-2.7419h-5.4839c0 1.5143 1.2276 2.7419 2.7419 2.7419z"
          fill="#E6D2BA"
        />
      </g>
      <g clipPath="url(#k)">
        <path
          d="m20.695 35.38c-0.7307-0.1288-2.3916 1.6747-2.3916 1.6747-0.598 0.4186-1.9323-0.3735-2.9528-1.8309s-1.3458-2.9561-0.7105-3.4009c0 0 2.2628-0.944 2.3916-1.6746 0.2801-1.5884-2.0749-4.9517-3.6315-5.2262-0.4765-0.084-1.6013 1.0934-1.6013 1.0934-1.8311 1.2822-0.898 5.836 2.1373 10.171 3.0353 4.3349 6.9897 6.801 8.8264 5.4871 0 0 1.4855-0.6225 1.5695-1.099 0.3063-1.5511-2.0487-4.9143-3.6371-5.1944z"
          fill="url(#b)"
        />
        <path
          d="m15.972 31.14c0.5437-0.2972 1.0034-0.6747 1.0594-0.9924 0.2801-1.5884-2.0749-4.9517-3.6316-5.2261-0.3176-0.056-0.8576 0.4056-1.2163 0.7682l3.7885 5.4503z"
          fill="#76C3C0"
        />
        <path
          d="m19.4 36.036 3.8203 5.456c0.4316-0.2187 1.0875-0.5944 1.1379-0.8803 0.2745-1.5567-2.0749-4.9517-3.6633-5.2318-0.3177-0.056-0.8297 0.2468-1.2949 0.6561z"
          fill="#76C3C0"
        />
      </g>
      <g clipPath="url(#j)">
        <path
          d="m69.355 25.806c0 1.3307-0.6452 2.5807-1.7339 3.4678v3.3064l-4.1532-1.7338h-0.4436c-3.4677 0-6.2903-2.2581-6.2903-5.0404 0-2.7822 2.8226-5.0403 6.2903-5.0403 3.5081 0 6.3307 2.2581 6.3307 5.0403z"
          fill="#76C3C0"
        />
        <path
          d="m59.798 26.895c0.6012 0 1.0887-0.4874 1.0887-1.0887s-0.4875-1.0887-1.0887-1.0887c-0.6013 0-1.0887 0.4874-1.0887 1.0887s0.4874 1.0887 1.0887 1.0887z"
          fill="#fff"
        />
        <path
          d="m63.064 26.895c0.6013 0 1.0887-0.4874 1.0887-1.0887s-0.4874-1.0887-1.0887-1.0887-1.0887 0.4874-1.0887 1.0887 0.4874 1.0887 1.0887 1.0887z"
          fill="#fff"
        />
        <path
          d="m66.371 26.895c0.6013 0 1.0887-0.4874 1.0887-1.0887s-0.4874-1.0887-1.0887-1.0887c-0.6012 0-1.0887 0.4874-1.0887 1.0887s0.4875 1.0887 1.0887 1.0887z"
          fill="#fff"
        />
        <g filter="url(#h)">
          <path
            d="m66.371 19.798c0 3.629-3.6694 6.5726-8.1855 6.5726h-0.5645l-5.4033 2.2177v-4.2742c-1.3709-1.1693-2.2177-2.7822-2.2177-4.5161 0-3.629 3.6694-6.5726 8.1855-6.5726s8.1855 2.9436 8.1855 6.5726z"
            fill="url(#a)"
          />
        </g>
        <path
          d="m58.831 21.815h-1.4112v-0.3629c0-0.4436 0.0806-0.8468 0.2419-1.1291 0.1613-0.2822 0.4435-0.6451 0.9274-1.008 0.4436-0.3629 0.7258-0.6049 0.8065-0.7258 0.1209-0.2016 0.2016-0.4033 0.2016-0.6049 0-0.3225-0.121-0.5645-0.3629-0.8064-0.242-0.242-0.6049-0.3226-1.0081-0.3226s-0.7258 0.121-1.0081 0.3226c-0.2822 0.2419-0.4435 0.5645-0.5645 1.0484l-1.4113-0.1613c0.0404-0.6855 0.3226-1.25 0.8468-1.6936 0.5242-0.4435 1.2097-0.6855 2.0968-0.6855s1.6129 0.242 2.1371 0.7259c0.5242 0.4838 0.8064 1.008 0.8064 1.6532 0 0.3629-0.0806 0.6855-0.2822 0.9677-0.2016 0.2823-0.6049 0.7258-1.25 1.25-0.3226 0.2823-0.5242 0.4839-0.6049 0.6855-0.0806 0.2016-0.1613 0.4436-0.1613 0.8468zm-1.4112 2.1371v-1.5726h1.5725v1.5726h-1.5725z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="i"
          x="17.185"
          y="64.683"
          width="48.788"
          height="28.446"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1153_33728"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1153_33728"
            result="shape"
          />
        </filter>
        <filter
          id="h"
          x="42"
          y="9.2258"
          width="32.371"
          height="31.363"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1153_33728"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1153_33728"
            result="shape"
          />
        </filter>
        <linearGradient
          id="f"
          x1="40"
          x2="40"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDF5F4" offset=".28125" />
          <stop stopColor="#F9F8DE" offset="1" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="41.58"
          x2="41.58"
          y1="62.234"
          y2="79.032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32B8B1" offset="0" />
          <stop stopColor="#66D0CC" offset="1" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="41.14"
          x2="41.14"
          y1="59.477"
          y2="66.299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32B8B1" offset="0" />
          <stop stopColor="#66D0CC" offset="1" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="41.579"
          x2="41.579"
          y1="68.683"
          y2="81.129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDF5F4" offset=".28125" />
          <stop stopColor="#F9F8DE" offset="1" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="19.067"
          x2="16.38"
          y1="25.921"
          y2="41.159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32B8B1" offset="0" />
          <stop stopColor="#66D0CC" offset="1" />
        </linearGradient>
        <linearGradient
          id="a"
          x1="58.186"
          x2="58.186"
          y1="13.226"
          y2="28.589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32B8B1" offset="0" />
          <stop stopColor="#66D0CC" offset="1" />
        </linearGradient>
        <clipPath id="k">
          <rect
            transform="translate(11.462 24.58) rotate(10)"
            width="15.484"
            height="15.484"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="j">
          <rect
            transform="translate(50 13.226)"
            width="19.355"
            height="19.355"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContactUsIcon;
