import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const CashierIcon: React.VFC<IconProps> = ({
  width = "24",
  height = "24",
  fill = theme.palette.interface.neutral.light,
}) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        d="m12.625 9.25v0.45833h4.3907l3.8593 6.7529v5.5388c0 0.2992-0.2425 0.5417-0.5417 0.5417h-16.333c-0.29915 0-0.54167-0.2425-0.54167-0.5417v-5.5388l3.8593-6.7529h4.3907v-3.6667h-4.9583c-0.29915 0-0.54167-0.24252-0.54167-0.54167v-3.5c0-0.29915 0.24252-0.54167 0.54167-0.54167h10.833c0.2992 0 0.5417 0.24252 0.5417 0.54167v3.5c0 0.29915-0.2425 0.54167-0.5417 0.54167h-4.9583v3.2083zm-5.0417-6.875h-0.45833v2.75h10.083v-2.75h-9.625zm0.53167 8.25h-0.26599l-0.13196 0.2309-2.6189 4.5834-0.39182 0.6857h14.921l-3.0107-5.2691-0.132-0.2309h-8.3693zm-3.74 10.542v0.4583h15.583v-4.5833h-7.3333v0.4583c0 0.2531-0.2052 0.4583-0.4583 0.4583-0.2532 0-0.4584-0.2052-0.4584-0.4583v-0.4583h-7.3333v4.125z"
        fill={fill}
        stroke={theme.palette.interface.neutral.light}
        strokeWidth=".91667"
      />
    </svg>
  );
};

export default CashierIcon;
