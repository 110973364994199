import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const DecreaseIcon: React.VFC<IconProps> = ({
  width = "24",
  height = "24",
  fill = theme.palette.interface.teal.primary,
}) => {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m15 18c0-0.56-0.45-1-1-1h-5.59l10.89-10.89c0.187-0.18698 0.292-0.44057 0.292-0.705s-0.105-0.51802-0.292-0.705-0.4406-0.29202-0.705-0.29202-0.518 0.10504-0.705 0.29202l-10.89 10.89v-5.59c0-0.55-0.45-1-1-1s-1 0.45-1 1v8c0 0.55 0.45 1 1 1h8c0.55 0 1-0.45 1-1z"
        fill={fill}
      />
    </svg>
  );
};

export default DecreaseIcon;
