import { responseRouter as router } from "../../router";
import { Endpoints } from "../../../../../common/api/endpoints";
import {
  CustomerDetail,
  GetCustomersRequest,
  GetCustomersResponse,
  CustomerSummary,
  CustomerMutationResponse,
  AddCustomerRequest,
} from "../../../../../crm";
import { iApiResponse } from "../../../../models";

router.get<GetCustomersResponse, void, void, GetCustomersRequest>(
  Endpoints.CUSTOMER,
  async (_req, resp) => {
    // TODO : Implement save to local db
    return resp;
  }
);

router.post<CustomerMutationResponse, AddCustomerRequest["payload"]>(
  Endpoints.CUSTOMER,
  async (_req, resp) => {
    return resp;
  }
);

router.get<
  iApiResponse<CustomerDetail>,
  void,
  { storeId: string; customerId: string }
>(Endpoints.CUSTOMER_BY_ID_STORE_ID, async (_req, resp) => {
  // TODO : Implement save to local db
  return resp;
});

router.get<
  CustomerSummary,
  void,
  void,
  { storeId: string; customerId: string }
>(Endpoints.GET_CUSTOMER_SUMMARY_BY_CUSTOMER_ID, async (_req, resp) => {
  return resp;
});
