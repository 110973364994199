import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const CustomerSupportIcon: React.VFC<IconProps> = ({
  width = "24",
  height = "24",
  fill = theme.palette.interface.neutral.light,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12.22C21 6.73 16.74 3 12 3C7.31 3 3 6.65 3 12.28C2.4 12.62 2 13.26 2 14V16C2 17.1 2.9 18 4 18C4.55 18 5 17.55 5 17V12.19C5 8.36 7.95 5.01 11.78 4.9C12.7174 4.87149 13.6509 5.03143 14.5253 5.37034C15.3997 5.70925 16.1972 6.22024 16.8705 6.87301C17.5438 7.52579 18.0792 8.30707 18.445 9.17057C18.8108 10.0341 18.9995 10.9622 19 11.9V19H12C11.45 19 11 19.45 11 20C11 20.55 11.45 21 12 21H19C20.1 21 21 20.1 21 19V17.78C21.59 17.47 22 16.86 22 16.14V13.84C22 13.14 21.59 12.53 21 12.22Z"
        fill={fill}
      />
      <path
        d="M9 14C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14Z"
        fill={fill}
      />
      <path
        d="M15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14Z"
        fill={fill}
      />
      <path
        d="M18 11.03C17.7615 9.62414 17.0334 8.34797 15.9444 7.42738C14.8555 6.5068 13.4759 6.00117 12.05 6C9.01997 6 5.75997 8.51 6.01997 12.45C7.2531 11.9454 8.34221 11.1435 9.1902 10.1158C10.0382 9.08808 10.6187 7.86652 10.88 6.56C12.19 9.19 14.88 11 18 11.03Z"
        fill={fill}
      />
    </svg>
  );
};

export default CustomerSupportIcon;
