import * as React from "react";
import { useApiErrorHandler } from "../useApiErrorHandler";
import {
  MutationActionCreatorResult,
  QueryActionCreatorResult,
} from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { iApiErrorHandlerReturnType } from "../useApiErrorHandler/types";
import { iBaseQueryErrorResponse } from "@gada-saas/web-core/common/api";

export interface iAsyncActionResponse<T> {
  inventoryId?: number;
  success?: T;
  error?: iApiErrorHandlerReturnType;
}

type UseAsync<T, A extends unknown[]> = [
  (...args: A) => Promise<iAsyncActionResponse<T>>,
  {
    data: T | null;
    isLoading: boolean;
    error?: iApiErrorHandlerReturnType;
  }
];

const useAsyncAction = <T, A extends unknown[] = any>(
  callback: (
    ...args: A
  ) => MutationActionCreatorResult<any> | QueryActionCreatorResult<any>, // TODO: Update it to infer resposne from generic type T
  preferSnack?: boolean,
  showSnackBar?: boolean
): UseAsync<T, A> => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<T | null>(null);
  const [error, setError] = React.useState<iApiErrorHandlerReturnType>();
  const [handleError] = useApiErrorHandler();

  const asyncCallback = React.useCallback(
    async (...args: A) => {
      try {
        setLoading(true);
        setError(undefined);
        const response = await callback(...args).unwrap();
        setData(response as T);
        setLoading(false);
        return { success: response as T };
      } catch (e) {
        const errors = handleError(
          e as iBaseQueryErrorResponse,
          preferSnack,
          showSnackBar
        );
        setError(errors);
        setLoading(false);
        return { error: errors };
      }
    },
    [callback, handleError, preferSnack, showSnackBar]
  );

  return [asyncCallback, { data, isLoading, error }];
};

export default useAsyncAction;
