import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { Channels, DurationTypes, PaymentStatus } from "../types";
import { CustomerDebtState, DueDateType } from "./types";
import { globalResetAction } from "../../common/redux/actions";

const initialState: CustomerDebtState = {
  // First Tab
  allListState: {
    pageSize: 10,
    page: 1,
    keyword: "",
    channel: Channels.SAAS,
    durationType: DurationTypes.MONTHLY,
    paymentStatus: [
      PaymentStatus.PAID,
      PaymentStatus.PARTIALLY_PAID,
      PaymentStatus.UNPAID,
    ],
  },
  paymentReportListState: {
    // Second Tab
    page: 1,
    pageSize: 10,
    keyword: "",
    durationType: DurationTypes.MONTHLY,
    durationEnd: null,
    durationStart: null,
    channel: Channels.SAAS,
  },
  customerDebtListState: {
    // Third Tab
    pageSize: 10,
    page: 1,
    keyword: "",
    channel: Channels.SAAS,
    dueDateDurationType: DueDateType.ALL,
    durationType: DurationTypes.ALL,
    paymentStatus: [PaymentStatus.PARTIALLY_PAID, PaymentStatus.UNPAID],
  },
  orderId: "",
  uiState: {
    drawer: {
      isCustomerDebtDrawer: false,
      open: false,
    },
    isLoading: true,
    activeIndex: -1,
  },
  payOffPaymentState: {
    paymentType: "Cash",
    paymentSuccess: false,
  },
  pastDueDate: 0,
};

const customerDebtSlice = createSlice({
  initialState,
  name: "customerDebt",
  reducers: {
    setCustDebtListState(
      state,
      action: PayloadAction<CustomerDebtState["customerDebtListState"]>
    ) {
      state.customerDebtListState = action.payload;
    },
    setPaymentReportListState(
      state,
      action: PayloadAction<CustomerDebtState["paymentReportListState"]>
    ) {
      state.paymentReportListState = action.payload;
    },
    setPaymentReportDurationState(
      state,
      {
        payload,
      }: PayloadAction<
        Pick<
          CustomerDebtState["paymentReportListState"],
          "durationEnd" | "durationStart" | "durationType"
        >
      >
    ) {
      state.paymentReportListState.durationType = payload.durationType;
      state.paymentReportListState.durationStart = payload.durationStart;
      state.paymentReportListState.durationEnd = payload.durationEnd;
      state.paymentReportListState.page = 1;
    },
    setAllListState(
      state,
      action: PayloadAction<CustomerDebtState["allListState"]>
    ) {
      state.allListState = action.payload;
    },
    toggleDrawer(
      state,
      action: PayloadAction<CustomerDebtState["uiState"]["drawer"]>
    ) {
      state.uiState.drawer = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.uiState.isLoading = action.payload;
    },
    setOrderId(state, action: PayloadAction<string>) {
      state.orderId = action.payload;
    },
    setUiState(state, action: PayloadAction<CustomerDebtState["uiState"]>) {
      state.uiState = action.payload;
    },
    setPayOffPaymentType(state, action: PayloadAction<string>) {
      state.payOffPaymentState.paymentType = action.payload;
    },
    setPayOffPaymentSuccess(state, action: PayloadAction<boolean>) {
      state.payOffPaymentState.paymentSuccess = action.payload;
    },
    setPastDueDate(state, action: PayloadAction<number>) {
      state.pastDueDate = action.payload;
    },
    setActiveIndex(state, action: PayloadAction<number>) {
      state.uiState.activeIndex = action.payload;
    },
    setCustomerDebtListKeyword(state, action: PayloadAction<string>) {
      state.customerDebtListState.keyword = action.payload;
      state.customerDebtListState.page = 1;
    },
    setPaymentReportListKeyword(state, action: PayloadAction<string>) {
      state.paymentReportListState.keyword = action.payload;
      state.paymentReportListState.page = 1;
    },
    setAllListKeyword(state, action: PayloadAction<string>) {
      state.allListState.keyword = action.payload;
      state.allListState.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const useCustomerDebtActions = (): typeof customerDebtSlice.actions =>
  useActions(customerDebtSlice.actions);

export default customerDebtSlice.reducer;
