import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../../common/hooks/useActions";
import { globalResetAction } from "../../../common/redux/actions";
import { SelectInvoiceState } from "./types";

const initialState = {
  keyword: "",
  page: 1,
  pageSize: 10,
} as SelectInvoiceState;

const selectInvoiceSlice = createSlice({
  initialState,
  name: "selectInvoice",
  reducers: {
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setKeyword(state, { payload }: PayloadAction<string>) {
      state.keyword = payload;
      state.page = 1;
    },
    setUserIds(
      state,
      { payload }: PayloadAction<SelectInvoiceState["userId"]>
    ) {
      state.userId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const useSelectInvoiceActions = (): typeof selectInvoiceSlice.actions =>
  useActions(selectInvoiceSlice.actions);

export default selectInvoiceSlice;
