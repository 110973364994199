import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../common/hooks/useActions";
import { globalResetAction } from "../common/redux/actions";
import { StoreInitialState } from "./types";

const initialState: StoreInitialState = {
  activeIndex: 0,
  activeMenu: 0,
  disabled: false,
  isStoreLinked: false,
  selectedStoreId: "",
  openStoreOperatingTimeModal: false,
  storeSpecialtyModal: {
    open: false,
    showNewUserUI: false,
    origin: "userSettings",
  },
};

const storeSlice = createSlice({
  name: "storeSlice",
  initialState,
  reducers: {
    setSelectedStoreId(
      state: StoreInitialState,
      action: PayloadAction<string>
    ) {
      state.selectedStoreId = action.payload;
    },
    updateActiveIndex(
      state: StoreInitialState,
      action: PayloadAction<{ index: number; menu: number }>
    ) {
      state.activeIndex = action.payload.index;
      state.activeMenu = action.payload.menu;
    },
    updateDisabled(state: StoreInitialState, action: PayloadAction<boolean>) {
      state.disabled = action.payload;
    },
    updateIsStoreLinked(
      state: StoreInitialState,
      action: PayloadAction<boolean>
    ) {
      state.isStoreLinked = action.payload;
    },
    setOpenStoreOperatingTimeModal(
      state: StoreInitialState,
      action: PayloadAction<boolean>
    ) {
      state.openStoreOperatingTimeModal = action.payload;
    },
    setStoreSpecialtyModalState(
      state: StoreInitialState,
      action: PayloadAction<Partial<StoreInitialState["storeSpecialtyModal"]>>
    ) {
      state.storeSpecialtyModal = {
        ...state.storeSpecialtyModal,
        ...action.payload,
      };
    },
    resetStoreSpecialtyModalState(state: StoreInitialState) {
      state.storeSpecialtyModal = { ...initialState.storeSpecialtyModal };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const {
  updateActiveIndex,
  updateDisabled,
  updateIsStoreLinked,
  setSelectedStoreId,
} = storeSlice.actions;

export const useStoresActions = (): typeof storeSlice.actions =>
  useActions(storeSlice.actions);

export default storeSlice.reducer;
