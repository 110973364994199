import { AnalyticsInstance, AnalyticsPlugin } from "analytics";

interface PluginMethodArg {
  abort: () => void;
  config: unknown;
  payload: unknown;
  instance: AnalyticsInstance;
  plugins: Record<string, unknown>;
}

/**
 * Just for Funsies, logging analytics activity
 */
const createLogFunction = (type: string) => (arg: PluginMethodArg) => {
  console.group(
    `%canalytics %c%s %c@ ${new Date().toLocaleTimeString()}`,
    "font-weight:400;color:#05A39B;margin-left:6px;",
    "font-weight:bold;color:black;",
    type,
    "font-weight:400;color:grey;"
  );
  console.log(
    "%cpayload%o",
    "font-weight:bold;color:grey;width:128px;",
    arg.payload
  );
  console.groupEnd();
};

export const CustomLogPlugin = (): AnalyticsPlugin => ({
  name: "logOnlyPlugin",
  initialize: createLogFunction("Initialize"),
  identify: createLogFunction("Identify"),
  track: createLogFunction("Track"),
  page: createLogFunction("Page"),
  ready: createLogFunction("Ready"),
  loaded: () => {
    return true;
  },
});
