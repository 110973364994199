import { formatDate } from "../../common/utils";
import { iPriceTier } from "../../inventory";
import { iCart } from "./models";

export const createAddedToCartTimeStamp = () => {
  return formatDate(new Date(), "YYYY-MM-DD[T]HH:mm:ss[.]SSS[00]ZZ", false);
};

export const sortPriceTiers = (priceTiers: iPriceTier[]): iPriceTier[] => {
  return priceTiers
    .slice()
    .sort((a, b) => b.minimumQuantity - a.minimumQuantity);
};

export const getValidPriceTier = (
  numOfUnits: number,
  priceTiers: iPriceTier[]
): iPriceTier => {
  const sortedPriceTiers = sortPriceTiers(priceTiers);
  let validPriceTier = sortedPriceTiers[0];
  for (let i = 0; i < sortedPriceTiers.length; i++) {
    if (numOfUnits >= sortedPriceTiers[i].minimumQuantity) {
      validPriceTier = sortedPriceTiers[i];
      break;
    }
  }
  return validPriceTier;
};

export function getHasAnyDiscounts(cart: iCart) {
  if (cart.cartDiscountList.length > 0) {
    return true;
  }
  for (let i = 0; i < cart.cartItemList.length; i++) {
    if (cart.cartItemList[i].itemDiscount !== null) {
      return true;
    }
  }
  return false;
}