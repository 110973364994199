import { responseRouter as router } from "../../router";
import { Endpoints } from "../../../../../common/api/endpoints";
import {
  GetEmployeesResponse,
  GetAllEmployeesRequest,
} from "../../../../../employee-access";
import { db } from "../../../../../inventory/offline";

router.get<GetEmployeesResponse, void, void, GetAllEmployeesRequest>(
  Endpoints.EMPLOYEE,
  async (_, resp) => {
    await db.employees.bulkPut(resp.data);
    return resp;
  }
);
