import { useRouter } from "next/router";
import React, { createContext, useState, useEffect, useContext } from "react";
import { BaseProviderProps } from "../types";
import { iRouteHistoryContext } from "./types";

const RouteHistoryContext = createContext<iRouteHistoryContext>(
  {} as iRouteHistoryContext
);

export const HistoryProvider = ({ children }: BaseProviderProps) => {
  const { asPath, push, pathname } = useRouter();
  const [history, setHistory] = useState<string[]>([]);

  function back() {
    for (let i = history.length - 2; i >= 0; i--) {
      const route = history[i];
      if (!route.includes("#") && route !== pathname) {
        push(route);

        // if you want to pop history on back
        const newHistory = history.slice(0, i);
        setHistory(newHistory);

        break;
      }
    }
  }

  useEffect(() => {
    setHistory((previous) => [...previous, asPath]);
  }, [asPath]);

  return (
    <RouteHistoryContext.Provider
      value={{
        back,
        history,
        setHistory,
      }}
    >
      {children}
    </RouteHistoryContext.Provider>
  );
};

export function useRouteHistory(): iRouteHistoryContext {
  const context = useContext(RouteHistoryContext);
  return context;
}

export default HistoryProvider;
