import { DownloadFileType } from "@gada-saas/web-core";

export const pxToRem = (px?: number, decimalPlaces = 3): string => {
  if (px === undefined) return "";
  const rem = parseFloat((px / 16).toFixed(decimalPlaces));
  return `${rem}rem`;
};

export const getFileExtension = (url: string): DownloadFileType => {
  return url
    .split("?")
    .shift()
    ?.split(".")
    .pop()
    ?.toUpperCase() as DownloadFileType;
};

export const convertNameToId = (name?: string): string => {
  return (
    name
      ?.trim()
      .toLowerCase()
      .replace(/[ '()]/g, "_") || ""
  );
};
