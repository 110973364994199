import React from "react";
import { SnackbarProvider as NISSnackbarProvider } from "notistack";
import { BaseProviderProps } from "../types";

const SnackbarProvider = ({ children }: BaseProviderProps) => {
  return (
    <NISSnackbarProvider
      id="snackbar_global_error"
      autoHideDuration={3500}
      maxSnack={3}
      hideIconVariant={true}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      {children}
    </NISSnackbarProvider>
  );
};

export default SnackbarProvider;
