import { Channels, DurationTypes } from "..";
import { PaymentStatus } from "../types";

export enum DueDateType {
  ALL = "All",
  DUE_TODAY = "Due Today",
  PAST_DUE = "Past Due",
  PERIOD = "Period",
}

export type CustomerDebtState = {
  allListState: {
    pageSize: number;
    page: number;
    keyword: string | null;
    channel: Channels.SAAS;
    durationType: DurationTypes;
    durationStart?: string;
    durationEnd?: string;
    userId?: string;
    paymentStatus: PaymentStatus[];
  };
  paymentReportListState: {
    page: number;
    pageSize: number;
    keyword: string | null; //(if filled then we filter by customerName)
    userId?: string;
    durationType: DurationTypes;
    durationStart: string | null;
    durationEnd: string | null;
    channel: Channels.SAAS;
  };
  customerDebtListState: {
    pageSize: number;
    page: number;
    keyword: string | null;
    channel: Channels.SAAS;
    durationType: DurationTypes;
    durationStart?: string;
    durationEnd?: string;
    dueDateDurationType: DueDateType;
    dueDateDurationStart?: string;
    dueDateDurationEnd?: string;
    userId?: string;
    paymentStatus: PaymentStatus[];
  };
  uiState: {
    drawer: {
      open: boolean;
      isCustomerDebtDrawer: boolean;
    };
    isLoading: boolean;
    activeIndex: number;
  };
  orderId: string;
  pastDueDate: number;
  payOffPaymentState: {
    paymentType: string;
    paymentSuccess: boolean;
  };
};
