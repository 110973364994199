import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { InventoryValueState } from "./types";

const initialState: InventoryValueState = {
  page: 1,
  pageSize: 10,
  keyword: null,
};

const inventoryValueSlice = createSlice({
  name: "inventoryValue",
  initialState,
  reducers: {
    setKeyword(state, { payload }: PayloadAction<string>) {
      state.page = 1;
      state.keyword = payload;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
  },
});

export const useInventoryValueActions = (): typeof inventoryValueSlice.actions =>
  useActions(inventoryValueSlice.actions);

export default inventoryValueSlice;
