import {
  Button as GButton,
  ButtonProps as GButtonProps,
  CircularProgress,
} from "@material-ui/core";
import * as React from "react";
import useStyles from "./styles";
import { ButtonProps } from "./types";
import clsx from "clsx";

const Button: React.VFC<ButtonProps> = ({
  variant,
  size = "default",
  isLoading = false,
  stopPropagation = false,
  onClick,
  children,
  icon,
  className,
  style,
  noWrap = false,
  ...restProps
}: ButtonProps) => {
  const classes = useStyles();
  const gButtonProps: Omit<GButtonProps, "style"> & {
    style: React.CSSProperties;
  } = { style: { ...style } };

  if (size === "small") {
    gButtonProps.style.height = 38;
  }

  const onClickCallback = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
      onClick?.(e);
    },
    [stopPropagation, onClick]
  );

  if (variant === "primary") {
    gButtonProps.variant = "contained";
  } else if (variant === "secondary") {
    gButtonProps.variant = "contained";
    gButtonProps.color = "secondary";
  } else if (variant === "text") {
    gButtonProps.variant = "text";
    gButtonProps.style.height = "auto";
    gButtonProps.style.backgroundColor = "transparent";
    if (size === "small") {
      gButtonProps.style.fontSize = "14px";
    }
  } else if (variant === "outlined") {
    gButtonProps.className = classes.outlined;
  } else if (variant === "neutral") {
    gButtonProps.className = classes.neutral;
  } else {
    gButtonProps.variant = restProps.disabled ? "contained" : "outlined";
  }

  if (size === "destructive") {
    gButtonProps.className = clsx(gButtonProps.className, classes.destructive);
  } else if (size === "destructive-small") {
    gButtonProps.className = clsx(
      gButtonProps.className,
      classes.destructive,
      classes.destructiveSmall
    );
  }

  if (noWrap) {
    gButtonProps.style.flexShrink = 0;
  }

  const { className: gClassName, ...restGButtonProps } = gButtonProps;

  return (
    <GButton
      onClick={onClickCallback}
      className={clsx(className, gClassName)}
      disabled={isLoading || restProps.disabled}
      {...restGButtonProps}
      {...restProps}
    >
      {!isLoading && icon && <div className={classes.icon}>{icon}</div>}
      {isLoading ? <CircularProgress size={16} /> : children}
    </GButton>
  );
};

export default Button;
