import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const BarcodeIcon: React.VFC<IconProps> = ({
  width = "24",
  height = "20",
  fill = theme.palette.interface.neutral.primary,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H6V16H4V4ZM7 4H8V16H7V4ZM9 4H12V16H9V4ZM13 4H14V16H13V4ZM16 4H18V16H16V4ZM19 4H20V16H19V4ZM2 2V6H0V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H6V2H2ZM22 0C22.5304 0 23.0391 0.210714 23.4142 0.585786C23.7893 0.960859 24 1.46957 24 2V6H22V2H18V0H22ZM2 14V18H6V20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V14H2ZM22 18V14H24V18C24 18.5304 23.7893 19.0391 23.4142 19.4142C23.0391 19.7893 22.5304 20 22 20H18V18H22Z"
        fill={fill}
      />
    </svg>
  );
};

export default BarcodeIcon;
