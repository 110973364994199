import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { globalResetAction } from "../../common/redux/actions";
import { BulkAddInventoryStateInterface, PendingUomsInterface } from "./types";

const initialBulkAddInventoryState: BulkAddInventoryStateInterface = {
  UIState: {
    isToggleFullScreen: false,
    isAddOrEditSupplier: "ADD",
    showSuccessModal: false,
    showNoUomSelectErrorMessage: "none",
  },
};

export const BulkAddInventoryState = createSlice({
  name: "inventoryBulkAdd",
  initialState: initialBulkAddInventoryState,
  reducers: {
    resetState: () => {
      return initialBulkAddInventoryState;
    },
    setToggleFullScreen: (state) => {
      state.UIState.isToggleFullScreen = !state.UIState.isToggleFullScreen;
    },
    setPendingUoms: (
      state,
      action: PayloadAction<PendingUomsInterface | undefined>
    ) => {
      state.UIState.pendingUoms = action.payload;
    },
    setIsAddOrEditSupplier: (state, action: PayloadAction<"ADD" | "EDIT">) => {
      state.UIState.isAddOrEditSupplier = action.payload;
    },
    setShowSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.UIState.showSuccessModal = action.payload;
    },
    setShowNoUomSelectErrorMessage: (
      state,
      action: PayloadAction<"both" | "selling" | "buying" | "none">
    ) => {
      state.UIState.showNoUomSelectErrorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialBulkAddInventoryState);
  },
});

const { actions } = BulkAddInventoryState;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBulkAddInventoryStateActions = () => useActions(actions);

export default BulkAddInventoryState.reducer;
