// Follow express.js standard format for routing
// Read more : http://expressjs.com/en/guide/routing.html#route-parameters
export const Endpoints = {
  // Examples
  GET_ALL_TODO: "todo",
  ADD_TODO: "todo",
  DELETE_TODO: "todo/:id",

  // Tutorial
  TUTORIAL: "tutorial",

  // Customer
  GET_ALL_CUSTOMER_SUMMARY: "customer_summary/",
  CUSTOMER: "customer/",
  CUSTOMER_BY_ID: "customer/:customerId",
  CUSTOMER_BY_ID_STORE_ID: "customer/:customerId/:storeId",
  GET_CUSTOMER_SUMMARY_BY_ID: "customer_summary/:customerSummaryId/:storeId",
  GET_CUSTOMER_SUMMARY_BY_CUSTOMER_ID:
    "customer_summary/:storeId/customer/:customerId",
  GET_CUSTOMER_SUMMARY_DOWNLOAD_URL: "customer_summary/:storeId/download",

  // Payment
  ORDER: "order/",
  ORDER_PAYMENT: "order/payment",
  ORDER_RECEIPT: "order/receipt/",
  SEND_ORDER_RECEIPT: "order/receipt/send",
  GET_ORDER_DETAIL: "order/:storeId/:orderId",
  OFFLINE_ORDER: "offline/order",

  // Refund
  REFUNDABLE_ITEMS: "refund/refundable",
  CALCULATE_REFUND: "refund/calculate",
  REFUND: "refund",
  REFUND_RECEIPT: "refund/:refundId",
  STORE_REFUND_SETTINGS: "refund/config/:storeId",
  REFUND_SETTINGS: "refund/config",
  SEND_REFUND_RECEIPT: "refund/receipt/send",

  // POS
  CART: "cart",
  GET_CART_BY_ID: "cart/:cartId",
  CART_DISCOUNT: "cart/discount",
  CART_DISCOUNT_BY_ID: "cart/:cartId/discount/:discountId",
  SAVE_CART: "cart/save",
  ACTIVATE_CART: "cart/activate",
  CANCEL_CART: "cart/cancel",
  CHECKOUT_CART: "cart/checkout",
  ADD_ITEM_TO_CART: "cart/item",
  CART_ITEM: "cart/:cartId/inventory/:inventoryId",
  ADD_CUSTOM_ITEM_TO_CART: "cart/custom",
  CART_CUSTOM_ITEM: "cart/:cartId/custom/:customItemId",
  OFFLINE_CART_SAVE: "offline/cart/save",
  GET_LOCAL_CART: "cart/local",
  CREATE_DISCOUNT_ITEM: "cart/:cartId/item/:itemId/discount",
  MUTATE_DISCOUNT_ITEM: "cart/:cartId/item/:itemId/discount/:discountId",
  CREATE_BILL_DOWNLOAD_URL: "cart/bill/download",
  SEND_BILL_EMAIL: "cart/bill/send",
  GET_BILL_DOWNLOAD_URL: "cart/bill/:shortCode",

  // Authentication
  GET_OTP: "authentication/otp/start",
  SEND_OTP: "authentication/oauth/token",
  REFRESH_TOKEN: "authentication/refresh",
  LOGOUT: "authentication/logout",

  // User
  USER: "user",
  GET_USER_INFO: "user/userinfo",
  TNC_AGREEMENT: "user/agreement",

  // Registration
  MARKETPLACE_STORES: "store/marketplace",
  REGISTER_NEW_ACCOUNT: "registration",
  REGISTER_EXISTING_ACCOUNT: "registration/existing",

  // Map
  GET_GEOLOCATION: "geolocation",

  // Enums
  GET_ALL_BANKS: "bank",
  GET_ALL_BANK_ACCOUNTS: "bank",

  // Employee Access
  EMPLOYEE: "employee",
  UPDATE_EMPLOYEE: "employee/:employeeId",
  DELETE_EMPLOYEE: "employee/:storeId/:employeeId",
  GET_ALL_ACCESS_RIGHTS: "permission",
  GET_ALL_POSITIONS: "employee/position",
  TRANSACTION_EMPLYEE_LIST: "employee/transaction-employee-list/:storeId",

  // Manage store (add / edit / link store)
  STORE: "store",
  CURRENT_STORE: "store/:storeId",
  STORE_SETTING: "store/setting",
  ADD_BANK_ACCOUNT: "store/bank",
  UPDATE_BANK_ACCOUNT: "store/bank",
  DELETE_BANK_ACCOUNT: "store/bank/:bankAccountId",
  LINK_STORES: "store/link",
  UNLINK_STORES: "store/unlink",
  STORE_SPECIALTY_BY_ID: "store/specialty/:storeId",
  STORE_OPERATING_TIME_BY_ID: "store/operating-time/:storeId",
  STORE_ADDRESS_AREA: "store/address-area",

  // Inventory
  GET_INVENTORY_LIST: "inventory/list",
  GET_PRODUCT_CATEGORY_LIST: "product/category/list",
  GET_PRODUCT_UNIT: "product/unit",
  GET_PRINCIPAL: "product/principal",
  CREATE_PRINCIPAL: "product/principal",
  SEARCH_INVENTORY: "inventory/search",
  GET_PAYMENT_REPORT: "report/payment",
  GET_OFFLINE_PAYMENT_REPORT: "report/offline/payment",
  GET_OFFLINE_PAYMENT_REPORT_BY_ID: "report/offline/payment/:requestQueueId",
  GET_ORDER_REPORT: "report/order",
  GET_OFFLINE_ORDER_REPORT: "report/offline/order",
  GET_OFFLINE_META_DATA: "report/offline/metadata",
  GET_UOM: "product/uom",
  ADD_UOM: "product/uom",
  GET_PRODUCT_CUSTOM_INFORMATION: "product/custom/information",
  GET_PRODUCT_PHOTO_PRESIGNED_URL: "product/custom/pre-signed",
  ADD_CONVERSION: "product/unit/conversion",
  GET_INVENTORY_DETAIL: "inventory/store/:storeId/variant/:variantId",
  UPDATE_INVENTORY_DISPLAY_NAME: "product/variant/:variantId/display-name",
  UPDATE_INVENTORY_STOCK_TOPUP: "inventory/stock/add",
  UPDATE_INVENTORY_STOCK_RECOUNT: "inventory/stock/recount",
  ADJUST_INVENTORY_STOCK: "inventory/stock/adjustment",
  UPDATE_INVENTORY_PRICE_TIERS: "inventory/price/tiers",
  UPDATE_INVENTORY_BARCODE: "product/unit/barcode",
  UPDATE_INVENTORY_STOCK_REMINDER: "product/variant/:variantId/stock-reminder",
  UPDATE_INVENTORY_CONSIGNOR: "product/variant/:variantId/consignor",
  UPDATE_INVENTORY_CUSTOM_PRODUCT_INFO:
    "product/variant/:variantId/custom/change-info",
  UPDATE_INVENTORY_CUSTOM_PRODUCT_DISPLAY:
    "product/variant/:variantId/custom/change-display",
  GET_CONSIGNOR: "consignor",
  GET_CONSIGNOR_BY_ID: "consignor/:consignorId",
  GET_CONSIGNOR_BY_ID_STORE_ID: "consignor/:supplierId/:storeId",
  GET_CONSIGNOR_BY_SUPPLIER_ID: "consignor/:supplierId",
  CREATE_CONSIGNOR: "consignor",
  UPDATE_CONSIGNOR: "consignor/:consignorId",
  DELETE_CONSIGNOR: "consignor/:consignorId",
  ADD_CURATED_INVENTORY: "inventory",
  ADD_CUSTOM_INVENTORY: "inventory/custom",
  ADD_PRODUCT_CATEGORY: "product/category",
  ADD_PRODUCT_BRAND: "product/brand",
  GET_PRODUCT_BRAND: "product/brand",
  DOWNLOAD_INVENTORY_TEMPLATE: "inventory/download/:storeId",
  GET_INVENTORY_TEMPLATE_URL: "inventory/upload/:storeId",
  UPLOAD_INVENTORY_TEMPLATE: "inventory/upload",
  DELETE_INVENTORY_PRODUCT_VARIANT: "product/variant/:variantId/delete",
  DELETE_INVENTORY_PRODUCT_UNIT: "inventory/delete",
  CREATE_SINGLE_INVENTORY: "inventory/curated/singular",
  CREATE_PRODUCT_PROPOSAL: "product/proposal",
  GET_PRODUCT_PROPOSAL: "product/proposal",
  GET_DOWNLOAD_INVENTORY_LIST_S3: "inventory/inventory-list-download/:storeId",
  GET_CONSIGNOR_ITEMS: "consignor/:consignorId/items",
  INVENTORY_HISTORY: "inventory/history",
  INVENTORY_HISTORY_MOVEMENT_LIST: "inventory/history/movement/list",
  UPDATE_INVENTORY_ONLINE_SELLING: "inventory/:inventoryId/online-selling",
  INVENTORY_SUMMARY: "inventory/summary",
  GET_INVENTORY_BAD_STOCK_REPORT_LIST: "inventory/bad-stock",
  GET_INVENTORY_BAD_STOCK_REPORT_BRANDS_LIST: "inventory/bad-stock/brand",
  POST_HANDLE_BAD_STOCK: "inventory/bad-stock/action",
  POST_CALCULATE_REFUND_COST:
    "inventory/bad-stock/action/calculate-refund-cost",
  INVENTORY_CONVERSION_VALIDATION: "product/unit/conversion/validation",
  BULK_ADD: "/inventory/bulk",
  INVENTORY_RECOMMENDATION_RANKING: "inventory/list/recommendation/ranking",
  INVENTORY_MOST_PURCHASED: "inventory/most-purchased",
  SELL_IN_MP_RECOMMENDATION_LIST: "recommendation/online-selling/:storeId",
  SELL_IN_MP_RECOMMENDATION:
    "recommendation/online-selling/:storeId/product-variant/:productVariantId",

  // Change Status
  UPDATE_INVENTORY_STATUS: "inventory/bad-stock/change-status",

  // Buying Flow
  STORE_PURCHASE: "store/purchase",
  STORE_PURCHASE_DOWNLOAD: "store/purchase/download",
  STORE_PURCHASE_DETAIL: "store/purchase/:id",
  STORE_PURCHASE_PAYMENT: "store/purchase/payment",

  // Report
  GET_ALL_MARKETPLACE_STORES: "store/marketplace",
  GET_SALES_PER_CATEGORY_REPORT: "report/sales-per-category",
  DOWNLOAD_SALES_PER_CATEGORY_REPORT: "report/sales-per-category/download",
  GET_SALES_REPORT: "report/sales",
  DOWNLOAD_SALES_REPORT: "report/sales/download",
  GET_SALES_TREND_REPORT: "report/sales-trend",
  GET_SALES_PER_CATEGORY: "report/sales-per-category",
  GET_SALES_PER_PRODUCT: "report/sales-per-product",
  GET_SALES_PER_PRODUCT_DETAILS: "report/sales-per-product/detail",
  DOWNLOAD_SALES_PER_PRODUCT_REPORT: "report/sales-per-product/download",
  GET_PROFIT_REPORT: "report/profit",
  GET_PROFIT_PER_PRODUCT_REPORT: "/report/profit-per-product",
  GET_PROFIT_PER_PRODUCT_DETAIL: "/report/profit-per-product/detail",
  DOWNLOAD_PROFIT_PER_PRODUCT_REPORT: "report/profit-per-product/download",
  DOWNLOAD_PROFIT_REPORT: "report/profit/download",
  GET_PROFIT_TREND_REPORT: "report/profit-trend",
  GET_DEBT_RECEIVABLES: "store/consignor-credit/summary",
  STORE_EXPENSE: "store/expense",
  STORE_EXPENSE_BY_ID: "store/expense/:expenseId",
  DOWNLOAD_STORE_EXPENSE_REPORT: "store/expense/download",
  STORE_EXPENSE_CATEGORY: "store/expense/category",
  STORE_EXPENSE_SUMMARY: "store/expense/summary",
  DOWNLOAD_PROFIT_REPORT_PER_PRODUCT: "report/profit-per-product/download",
  GET_PROFIT_REPORT_PER_PRODUCT: "report/profit-per-product",
  GET_REPORT_DELIVERY_ORDER_LIST: "report/delivery",
  GET_STORE_CREDIT: "store/consignor-credit",
  GET_STORE_CREDIT_DETAILS: "store/consignor-credit/:creditId",
  GET_STORE_DEBIT: "store/consignor-credit/payment",
  PAY_OFF_DEBT: "store/consignor-credit/payment",
  GET_STORE_EXPENSE_SUMMARY: "store/expense/summary",
  GET_REPORT_DELIVERY_ORDER_DETAIL: "delivery",
  INCOMING_TAX_REPORT: "report/tax/incoming",
  OUTGOING_TAX_REPORT: "report/tax/outgoing",

  // Delivery
  DELIVERY_FEASIBLITY: "delivery/feasibility",
  DELIVERY: "delivery",
  DELIVERY_ORDER_REPORT: "report/delivery/recap",
  DELIVERY_ORDER_MANIFEST: "report/delivery/manifest",

  // Stock Opname
  STOCK_OPNAME_RECOUNT: "inventory/stock/recount",
  STOCK_OPNAME_RECOUNT_BY_ID: "inventory/stock/recount/:id",
  STOCK_OPNAME_RECOUNT_BY_STORE_ID: "inventory/stock/recount/:storeId",
  STOCK_OPNAME_RECOUNT_START: "inventory/stock/recount/start",
  STOCK_OPNAME_RECOUNT_DOWNLOAD: "inventory/stock/recount/:id/download",
  STOCK_OPNAME_RECOUNT_UPLOAD: "inventory/stock/recount/:id/upload",
  STOCK_OPNAME_RECOUNT_INVENTORIES_DOWNLOAD:
    "inventory/stock/recount/inventories/download",
  STOCK_OPNAME_EMPLOYEES: "inventory/stock/recount/employees/get",

  // Notification Banner
  NOTIFICATION_BANNER: "banner/list",

  // Promotion
  PROMOTION: "promotion",
  PROMOTION_DATA: "promotion/data",
  PROMOTION_DATA_DOWNLOAD: "promotion/download",
  PROMOTION_BY_ID: "promotion/:promotionId",
  PROMOTION_INVENTORIES_SEARCH: "promotion/inventories/search",
  STOP_PROMOTION: "promotion/:promotionId/stop",
};
