import { getIsLoggedIn, useGetUserInfoQuery } from "@gada-saas/web-core";
import { getQueryParams } from "@utils/urls";
import { useRouter } from "next/router";
import * as React from "react";
import { useInterval } from "react-use";
import { BaseProviderProps } from "../types";
import {
  ContentCard,
  ContentCardDetail,
  LinkTextActionType,
  NotificationContextProps,
} from "./types";

const NotificationContext =
  React.createContext<NotificationContextProps | null>(null);

const NotificationsProvider: React.VFC<BaseProviderProps> = ({ children }) => {
  const router = useRouter();
  const { unauthorized } = getQueryParams(router.asPath);

  const [isBrazeStarted, setBrazeStarted] = React.useState<boolean>(false);
  const [unreadCards, setUnreadCards] = React.useState<number>(0);
  const [contentCards, setContentCards] = React.useState<
    Record<string, ContentCard>
  >({});
  const [activeContentCard, setActiveContentCard] =
    React.useState<ContentCardDetail | null>(null);

  const userInfo = useGetUserInfoQuery();

  const initializeBraze = React.useCallback(async () => {
    if (!unauthorized && userInfo.data?.data.userInfo.id && getIsLoggedIn()) {
      // Since braze is an ES module, we can't directly import it in Next JS.
      // To import it, we will be using magic comments. Hence, the comment below
      const {
        initialize,
        changeUser,
        automaticallyShowInAppMessages,
        requestContentCardsRefresh,
        subscribeToContentCardsUpdates,
        ClassicCard,
        CaptionedImage,
        openSession,
      } = await import(
        /* webpackExports: ["initialize", "changeUser", "automaticallyShowInAppMessages", "subscribeToContentCardsUpdates", "requestContentCardsRefresh", "ClassicCard", "CaptionedImage", "openSession"] */
        "@braze/web-sdk"
      );

      const isInit = initialize(process.env.NEXT_PUBLIC_BRAZE_API_KEY!, {
        baseUrl: process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT!,
        manageServiceWorkerExternally: true,
        serviceWorkerLocation: "/sw.js",
        enableLogging: process.env.NODE_ENV === "development",
      });

      setBrazeStarted(isInit);

      if (isInit) {
        changeUser(userInfo.data.data.userInfo.id);
        automaticallyShowInAppMessages();

        subscribeToContentCardsUpdates((newCards) => {
          setUnreadCards(newCards.getUnviewedCardCount());

          const unSortedCards: ContentCard[] = [];

          newCards.cards.forEach((card) => {
            if (card instanceof ClassicCard || card instanceof CaptionedImage) {
              let contentCard: ContentCard = {
                id: card.id ?? "",
                type:
                  card instanceof ClassicCard
                    ? "ClassicCard"
                    : "CaptionedImage",
                categoryIconUrl: card.imageUrl ?? "",
                title: card.title,
                imageUrl: card.imageUrl ?? "",
                description: card.description,
                linkText: card.linkText,
                isPinned: card.pinned,
                isRead: card.viewed,
                actionType: card.extras?.action_type ?? LinkTextActionType.URL,
                actionUrl: card.url,
                detail: {
                  category: card.extras?.detail_category ?? "",
                  categoryIconUrl: card.extras?.detail_category_icon_url ?? "",
                  title: card.extras?.detail_title ?? "",
                  image: card.extras?.detail_image ?? "",
                  description: card.extras?.detail_description
                    ? JSON.parse(card.extras?.detail_description)
                    : [],
                  buttonTitle: card.extras?.detail_button_action_title ?? "",
                  buttonAction: card.extras?.detail_button_action_url ?? "",
                },
                createdAt: card.created ?? new Date(),
                originalCard: card,
              };
              unSortedCards.push(contentCard);
            }
          });

          const dateSortedCards: ContentCard[] = unSortedCards.sort(
            (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
          );

          const sortedCards: ContentCard[] = [
            ...dateSortedCards.filter((card) => card.isPinned),
            ...dateSortedCards.filter((card) => !card.isPinned),
          ];

          const sortedCardHashTable: Record<string, ContentCard> = {};
          sortedCards.forEach((card) => {
            sortedCardHashTable[card.id] = card;
          });

          setContentCards(sortedCardHashTable);
        });

        requestContentCardsRefresh();
        openSession();
      }
    }
  }, [unauthorized, userInfo.data?.data.userInfo.id]);

  const refreshContentCards = React.useCallback(async () => {
    if (isBrazeStarted) {
      const { requestContentCardsRefresh } = await import(
        /* webpackExports: ["requestContentCardsRefresh"] */
        "@braze/web-sdk"
      );
      requestContentCardsRefresh();
    }
  }, [isBrazeStarted]);

  useInterval(refreshContentCards, 1 * 60 * 1000);

  React.useEffect(() => {
    initializeBraze();
  }, [initializeBraze]);

  const value = React.useMemo(() => {
    return {
      isBrazeInit: isBrazeStarted,
      unreadCards,
      setUnreadCards,
      contentCards,
      setContentCards,
      activeContentCard,
      setActiveContentCard,
    };
  }, [activeContentCard, contentCards, isBrazeStarted, unreadCards]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationsProvider;

export const useNotifications = () => React.useContext(NotificationContext);
