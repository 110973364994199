import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../../common/hooks/useActions";
import { globalResetAction } from "../../../common/redux/actions";
import { DeliveryOrderListState } from "./types";

const initialState = {
  keyword: "",
  page: 1,
  pageSize: 10,
} as DeliveryOrderListState;

const deliveryOrderListSlice = createSlice({
  initialState,
  name: "deliveryOrderList",
  reducers: {
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setKeyword(state, { payload }: PayloadAction<string>) {
      state.keyword = payload;
      state.page = 1;
    },
    setEmployeeId(
      state,
      {
        payload,
      }: PayloadAction<{
        type: "sales" | "cashier";
        id: string;
      }>
    ) {
      if (payload.type === "sales") {
        state.salesPersonId = payload.id;
      }
      if (payload.type === "cashier") {
        state.cashierId = payload.id;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const useDeliveryOrderListActions = (): typeof deliveryOrderListSlice.actions =>
  useActions(deliveryOrderListSlice.actions);

export default deliveryOrderListSlice;
