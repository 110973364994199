import React from "react";
import { CoachMark as CoachMarkProvider } from "@coach-mark/CoachMark";
import { GadaProvider } from "@gada-saas/web-core";
import { ThemeProvider } from "@gada-saas/web-ui";
import { PageNameTrackingDataProvider } from "@miscellaneous/tracking/contexts/PageNameTrackingContext";
import { NotificationsProvider } from "../NotificationsProvider";
import RouteHistoryProvider from "../RouteHistoryProvider";
import { SnackbarProvider } from "../SnackbarProvider";
import { DatePickerProvider } from "../DatePickerProvider";
import { FeaturesFlagProvider } from "../FeaturesFlagProvider";
import { BaseProviderProps } from "../types";

const AppProvider = ({ children }: BaseProviderProps) => {
  const providers: React.ComponentType[] = [
    GadaProvider,
    ThemeProvider,
    SnackbarProvider,
    PageNameTrackingDataProvider,
    NotificationsProvider,
    RouteHistoryProvider,
    CoachMarkProvider,
    DatePickerProvider,
    FeaturesFlagProvider,
  ];

  return (
    <>
      {providers.reduceRight(
        (children, Provider) => (
          <Provider>{children}</Provider>
        ),
        children
      )}
    </>
  );
};

export default AppProvider;
