/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CustomerSummary } from "../../crm";
import { StoreTopType } from "../../stores";
import { Employee } from "../../employee-access";
import { iCartSaved } from "../../pos";
import { CartStatus } from "../../pos/cart/constants";
import { iGetUserInfoResponseData } from "../../user";
import { db, OfflineMetaData } from "./db";
import { S3InventoryListAPIResponseItem } from "./types";
import flatten from "lodash/flatten";
import { BankAccountModel } from "../../common/models";

export async function populate(
  responses: S3InventoryListAPIResponseItem[] | undefined
) {
  if (!responses) {
    return;
  }
  // Clear first
  await db.inventories.clear();

  await db.inventories.bulkPut(
    responses.map((r) => {
      return {
        ...r,
        tokens: r.productVariantName.toLowerCase().split(" "),
        inventoryIds: r.inventories.map((i) => i.inventoryId),
        barcodes: flatten(
          r.inventories.map((i) => {
            // Fitler only non empty string
            return i.barcodes.map((b) => b.barcode).filter((b) => b);
          })
        ),
      };
    })
  );
}

export const initStoreSettings = async (
  storeId: string,
  setting: StoreTopType
) => {
  await db.storeSettings.put(setting, storeId);
};

export const initUserInfo = async (
  storeId: string,
  user: iGetUserInfoResponseData
) => {
  await db.userInfo.put({ ...user, storeId }, storeId);
};

export async function populateCustomerData(
  responses: CustomerSummary[] | undefined
) {
  if (!responses) {
    return;
  }

  await db.customers.clear();

  await db.customers.bulkPut(
    responses.map((r) => {
      return {
        ...r,
        tokens: r.name.toLowerCase().split(" "),
      };
    })
  );
}

export async function populateEmployeeData(responses: Employee[] | undefined) {
  if (!responses) {
    return;
  }

  await db.employees.clear();

  await db.employees.bulkPut(
    responses.map((r) => {
      return {
        ...r,
        tokens: r.name.toLowerCase().split(" "),
      };
    })
  );
}

export const search = (query: string) =>
  db.inventories.where("tokens").equals(query).offset(25).toArray();

export const setInventories = (
  inventoryId: number,
  obj: S3InventoryListAPIResponseItem
) => db.inventories.where("inventoryIds").equals(inventoryId).modify(obj);

export function resetDatabase() {
  return db.transaction(
    "rw",
    db.inventories,
    db.storeSettings,
    db.carts,
    async () => {
      await Promise.all(db.tables.map((table) => table.clear()));
    }
  );
}

export async function replaceOnlineSavedCarts(savedCarts: iCartSaved[]) {
  // Step 1 clear saved offline carts
  await db.carts
    .where("cartStatus")
    .equals(CartStatus.SAVED)
    .and((cart) => !cart.isOfflineCart)
    .delete();

  if (savedCarts.length > 0) {
    // Step 2 replace with new carts
    await db.carts.bulkPut(savedCarts);
  }
}

export async function updateMetaData(metadata: OfflineMetaData) {
  await db.metadata.bulkPut([metadata]);
}

export async function initEmployee(employees: Employee[]) {
  if (!employees || employees.length === 0) {
    return;
  }

  await db.employees.clear();

  await db.employees.bulkPut(employees);
}

export async function populateBankAccounts(
  bankAccounts: BankAccountModel[] | undefined
) {
  if (!bankAccounts) return;

  await db.bankAccounts.clear();
  await db.bankAccounts.bulkPut(bankAccounts);
}

export function deleteCartFromDb(...cartIds: string[]) {
  return db.carts.bulkDelete(cartIds).catch((err) => {
    console.error(err);
    return "Error bulk delete cart from DB";
  });
}
