import { Endpoints } from "../../../../../common/api/endpoints";
import { db } from "../../../../../inventory/offline/db";
import {
  OrderApiResponse,
  PostOrderAPIRequest,
} from "../../../../../pos/order/models";
import { responseRouter as router } from "../../router";

router.post<OrderApiResponse, PostOrderAPIRequest, { cartId: string }>(
  Endpoints.ORDER,
  async (req, resp) => {
    const { cartId } = req.body;
    try {
      await db.carts.where("id").equals(cartId).delete();
    } catch (e) {
      console.log(e);
    }

    return resp;
  }
);
