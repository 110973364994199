import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const PurchaseListIcon: React.VFC<IconProps> = ({
  width = "24",
  height = "24",
  fill = theme.palette.interface.neutral.light,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <g clipPath="url(#clip0_13265_305)">
        <path
          d="m17.651 10.842c-0.097-0.0389-0.2052-0.0389-0.3022 0l-4.849 1.9394 1.9532 0.7808 5.0002-1.9996-1.8022-0.7206zm2.8958 1.1586-5.0002 1.9995 1.9533 0.7809 5.0001-1.9996-1.9532-0.7808zm2.6406 1.3812-5.2813 2.1125v6.4367l5.2813-2.1125v-6.4367zm-6.0938 8.55v-6.4383l-5.2812-2.1117v6.4375l5.2812 2.1125zm-0.0463-11.845c0.2906-0.11615 0.6146-0.11615 0.9052 0l5.7923 2.3173c0.0753 0.0302 0.1398 0.0822 0.1853 0.1494s0.0698 0.1465 0.0698 0.2276v7.0379c-1e-4 0.1623-0.0488 0.3209-0.1399 0.4553s-0.2204 0.2384-0.3712 0.2987l-5.8378 2.3351c-0.097 0.0389-0.2052 0.0389-0.3022 0l-5.837-2.3351c-0.1509-0.0601-0.2804-0.1641-0.3716-0.2985-0.0913-0.1344-0.1401-0.2931-0.1403-0.4555v-7.0379c0-0.0811 0.0243-0.1604 0.0698-0.2276s0.11-0.1192 0.1853-0.1494l5.7923-2.3173z"
          fill={fill}
          stroke={theme.palette.interface.neutral.light}
          clipRule="evenodd"
          fillRule="evenodd"
          strokeWidth="0.5"
        />
      </g>
      <path
        d="m15.222 2.7778h-0.8889v-1.7778h-1.7777v1.7778h-7.1112v-1.7778h-1.7778v1.7778h-0.88889c-0.97778 0-1.7778 0.8-1.7778 1.7778v12.444c0 0.9778 0.8 1.7778 1.7778 1.7778h8.0545v-1.7778l2.1677-3.5556 2.2222-2.7301 1.7778-0.82541v-5.3333c0-0.97778-0.8-1.7778-1.7778-1.7778zm-4.3899 14.222h-8.0545v-8.8889h12.444v2.6032l-2.2222 2.7301-2.1677 3.5556zm-8.0545-10.667v-1.7778h12.444v1.7778h-12.444z"
        fill={fill}
      />
    </svg>
  );
};

export default PurchaseListIcon;
