import { makeStyles } from "@material-ui/core";
import { AppTheme } from "../..";
import { pxToRem } from "../../utils";

export const useStyles = makeStyles<
  AppTheme,
  {
    withResetButton: boolean;
    withCloseButton: boolean;
    showFooterShadow: boolean;
  }
>(({ palette }) => ({
  resetButton: ({ withResetButton }) => ({
    display: withResetButton ? "flex" : "none",
    color: palette.interface.red.primary,
    marginRight: pxToRem(6),
  }),
  closeButton: ({ withCloseButton }) => ({
    display: withCloseButton ? "flex" : "none",
  }),
  footer: ({ showFooterShadow }) => ({
    boxShadow: showFooterShadow ? "0px -8px 8px rgba(0, 0, 0, 0.04)" : "none",
  }),
}));
