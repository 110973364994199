export const PAGES = {
  // AUTH
  LOGIN: "/login",
  REGISTRATION: "/registration",
  // USER
  HOME: "/",
  USER_ACCOUNT: "/user/account",
  ADD_NEW_STORE: "/store/add-new-store",
  // INVENTORY
  ADD_INVENTORY_CURATED_PRODUCT: "/inventory/add",
  ADD_INVENTORY_CUSTOM_PRODUCT: "/inventory/add-custom",
  EDIT_INVENTORY_CURATED_PRODUCT: "/inventory/edit",
  EDIT_INVENTORY_CURATED_PRODUCT_BY_PRODUCT_VARIANT_ID:
    "/inventory/edit?productVariantId",
  EDIT_INVENTORY_CUSTOM_PRODUCT: "/inventory/edit-custom",
  INVENTORY: "/inventory/list",
  INVENTORY_HISTORY: "/inventory/history",
  BULK_EDIT: "/inventory/bulk-edit",
  STOCK_OPNAME_LIST: "/inventory/stock-opname/list",
  STOCK_OPNAME_START: "/inventory/stock-opname/start",
  INVENTORY_BAD_STOCK_REPORT: "/report/bad-stock",
  INVENTORY_BAD_STOCK_REPORT_REFUND: "/report/bad-stock/refund",
  INVENTORY_BAD_STOCK_REPORT_RETURN: "/report/bad-stock/return",
  INVENTORY_BAD_STOCK_REPORT_DESTROY: "/report/bad-stock/destroy",
  INVENTORY_BULK_ADD: "/inventory/bulk-add",
  // POS
  POS: "/pos",
  PAYMENT: "/pos/payment",
  RECEIPT: "/receipt",
  SHARE: "/pos/share",
  BILL: "/bill",
  // REPORT
  REPORT: "/report",
  CUSTOMER_DEBT: "/report/customer-debt",
  CUSTOMER_DEBT_ALL_TAB: "/report/customer-debt?tab=all",
  CUSTOMER_DEBT_PAYMENT_TAB: "/report/customer-debt?tab=payment",
  CUSTOMER_DEBT_DEBT_TAB: "/report/customer-debt?tab=debt",
  PAY_OFF_CUSTOMER_DEBT: "/report/customer-debt/pay-off/",
  SALES: "/report/sales",
  TOP_SELLING_ITEMS: "/report/top-selling-items",
  TOP_SELLING_CATEGORY: "/report/sales-category",
  PROFIT: "/report/profit",
  PROFIT_PER_ITEM: "/report/item-profit",
  EXPENSE: "/report/expense",
  STORE_DEBT: "/report/store-debt",
  DELIVERY_ORDER_CREATE: "/report/delivery-order/create",
  DELIVERY_ORDER_SELECT_INVOICE: "/report/delivery-order/select-invoice",
  DELIVERY_ORDER_LIST: "/report/delivery-order/list",
  DELIVERY_ORDER_SUMMARY: "/report/delivery-order/summary",
  DELIVERY_ORDER_MANIFEST: "/report/delivery-order/manifest",
  OFFLINE_TRANSACTIONS: "/report/offline-transactions",
  CONSIGNMENT_DEBT: "/report/consignment-debt",
  // CRM
  CRM: "/crm",
  // Buying
  PURCHASE_ADD: "/purchase/add",
  PURCHASE_LIST: "/purchase/list",
  // Refund Recipt
  REFUND_RECEIPT: (refundId: string, isPrintable: boolean = false) =>
    isPrintable
      ? `/refund-receipt?refundId=${refundId}&printable=true`
      : `/refund-receipt?refundId=${refundId}`,
  // Supplier
  SUPPLIER_LIST: "/supplier/list",
  // CHANGE STATUS
  CHANGE_INVENTORY_STATUS: "/inventory/change-status",

  // Promotion
  PROMOTION_LIST: "/promotion/list",
  CREATE_PROMOTION: "/promotion/create",

  // NOTIFICATION DETAIL
  NOTIFICATION_DETAIL: "/notification-detail",
} as const;

export type PageType = typeof PAGES;
export type PageKeys = keyof PageType;
export type PageValues = PageType[PageKeys];
