import { SortIconProps } from "./types";

const SortIcon = ({ active, direction }: SortIconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: "4px" }}
    >
      <path
        d="m5 8 5-5 5 5h-10z"
        fill={
          active ? (direction === "asc" ? "#05A39B" : "#CDCBCB") : "#CDCBCB"
        }
      />
      <path
        d="m15 12-5 5-5-5h10z"
        fill={
          active ? (direction === "desc" ? "#05A39B" : "#CDCBCB") : "#CDCBCB"
        }
      />
    </svg>
  );
};

export default SortIcon;
