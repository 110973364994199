import { Endpoints } from "../../common/api/endpoints";
import {
  PostOrderAPIRequest,
  OrderApiResponse,
  GetCustomerOrderAPIRequest,
  GetCustomerOrderAPIResponse,
  OrderType,
  SendOrderReceiptRequest,
  SendOrderReceiptResponse,
  GetOrderByIdRequest,
  GetOrderReceiptByIdRequest,
  GetOrdersRequest,
  GetOrdersResponse,
} from "./models";
import omit from "lodash/omit";
import { WithOfflineFlag } from "../../offline";
import { gadaSaasApi } from "../../base-rtkq-api";

const OrderApi = gadaSaasApi.injectEndpoints({
  endpoints: (builder) => ({
    postOrder: builder.mutation<
      OrderApiResponse,
      WithOfflineFlag<PostOrderAPIRequest>
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.ORDER,
        data: omit(payload, "isOffline"),
        forceOffline: payload.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    getOrderById: builder.query<OrderType, GetOrderByIdRequest>({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_ORDER_DETAIL,
        routeParams: params,
      }),
      providesTags: ["Order"],
      transformResponse: (response: OrderApiResponse) => response.data,
    }),
    getOrderReceiptById: builder.query<OrderType, GetOrderReceiptByIdRequest>({
      query: (params) => ({
        method: "GET",
        url: Endpoints.ORDER_RECEIPT,
        queryParams: {
          storeId: params.storeId,
          paymentId: params.paymentId,
        },
      }),
      providesTags: ["OrderReceipt"],
      transformResponse: (response: OrderApiResponse) => response.data,
    }),
    getCustomerOrders: builder.query<
      { page: number; total: number; orderList: OrderType[] },
      GetCustomerOrderAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.ORDER,
        queryParams: {
          ...params,
          channel: "SAAS",
        },
      }),
      providesTags: ["CustomerOrders"],
      transformResponse: (response: GetCustomerOrderAPIResponse) => ({
        page: response.page,
        total: response.total,
        orderList: response.data,
      }),
    }),
    sendOrderReceipt: builder.mutation<
      SendOrderReceiptResponse,
      SendOrderReceiptRequest
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.SEND_ORDER_RECEIPT,
        data: payload,
      }),
    }),
    getOrders: builder.query<GetOrdersResponse, GetOrdersRequest>({
      query: (args) => ({
        method: "GET",
        url: Endpoints.ORDER,
        queryParams: args,
      }),
      providesTags: ["Order"],
    }),
  }),
});

export const {
  usePostOrderMutation,
  useGetOrderByIdQuery,
  useLazyGetOrderByIdQuery,
  useGetOrderReceiptByIdQuery,
  useLazyGetCustomerOrdersQuery,
  useSendOrderReceiptMutation,
  useLazyGetOrdersQuery,
  useGetOrdersQuery,
} = OrderApi;

export default OrderApi;
