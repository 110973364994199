import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../common/hooks/useActions";
import { CrmStateType } from "./types";
import { globalResetAction } from "../common/redux/actions";

const CrmInitialState: CrmStateType = {
  listState: {
    page: 1,
    pageSize: 10,
    keyword: "",
  },
  openModal: false,
};

const crmSlice = createSlice({
  name: "crmSlice",
  initialState: CrmInitialState,
  reducers: {
    setOpenModal: (state, action: PayloadAction<CrmStateType["openModal"]>) => {
      state.openModal = action.payload;
    },
    setListState: (state, action: PayloadAction<CrmStateType["listState"]>) => {
      state.listState = action.payload;
    },
    setCustomer: (state, action: PayloadAction<CrmStateType["customer"]>) => {
      state.customer = action.payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.listState.page = payload;
    },
    setCustomerSummary: (
      state,
      { payload }: PayloadAction<CrmStateType["customerSummary"]>
    ) => {
      state.customerSummary = payload;
    },
    setKeyword: (state, { payload }: PayloadAction<string>) => {
      state.listState.keyword = payload;
      state.listState.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => CrmInitialState);
  },
});

export const { actions: CrmActions } = crmSlice;

export const useCrmActions = (): typeof CrmActions => useActions(CrmActions);

export default crmSlice.reducer;
