import { getQueryParams, QueryParamMap } from "@utils/urls";
import { useRouter } from "next/router";
import * as React from "react";

const useQueryParams = () => {
  const router = useRouter();
  const [queryParam, setQueryParam] = React.useState<QueryParamMap>(
    getQueryParams(router.asPath)
  );

  React.useEffect(() => {
    setQueryParam(getQueryParams(router.asPath));
  }, [router.asPath]);

  return queryParam;
};

export default useQueryParams;
