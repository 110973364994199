import { createSlice, PayloadAction, isAnyOf } from "@reduxjs/toolkit";
import { PaymentStateType } from "./types";
import { useActions } from "../../common/hooks/useActions";
import { CartEndpoints } from "../cart/hooks";
import { SalesPerson } from "../order/models";
import OrderApi from "../order/hooks";
import { globalResetAction } from "../../common/redux/actions";

const paymentInitialState: PaymentStateType = {
  isLoading: false,
  downPaymentMethod: "",
  paymentType: "Cash",
  isPaymentSuccess: false,
  salesPerson: null,
  paymentDuration: undefined,
};

const PaymentSlice = createSlice({
  name: "paymentSlice",
  initialState: paymentInitialState,
  reducers: {
    setPaymentCustomerState: (
      state,
      { payload: customer }: PayloadAction<PaymentStateType["customer"]>
    ) => {
      state.customer = customer;
    },
    setLoadingPayment: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    resetPaymentCustomerState: (state) => {
      state.customer = paymentInitialState.customer;
    },
    setPaymentType: (state, { payload }: PayloadAction<string>) => {
      state.paymentType = payload;
    },
    setDownPaymentMethod: (state, { payload }: PayloadAction<string>) => {
      state.downPaymentMethod = payload;
    },
    setPaymentSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isPaymentSuccess = payload;
    },
    setSalesPerson: (state, { payload }: PayloadAction<SalesPerson | null>) => {
      state.salesPerson = payload;
    },
    setPaymentDuration: (state, { payload }: PayloadAction<number>) => {
      state.paymentDuration = payload;
    },
    resetSalesPerson: (state) => {
      state.salesPerson = null;
    },
    reset: () => paymentInitialState,
  },

  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => paymentInitialState);
    builder
      .addMatcher(CartEndpoints.getCartById.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(CartEndpoints.getCartById.matchFulfilled, (state, action) => {
        if (action.payload.data) {
          state.isLoading = false;
          if (action.payload.data.customer) {
            state.customer = action.payload.data.customer;
          }
        }
      })
      .addMatcher(
        isAnyOf(
          OrderApi.endpoints.postOrder.matchFulfilled,
          OrderApi.endpoints.postOrder.matchRejected
        ),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(CartEndpoints.saveCart.matchPending, (state, action) => {
        if (action.meta.arg.originalArgs.origin === "payment") {
          state.isLoading = true;
        }
      });
  },
});

export const usePaymentActions = (): typeof PaymentSlice.actions =>
  useActions(PaymentSlice.actions);

export default PaymentSlice.reducer;
