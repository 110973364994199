import { v4 as uuidv4 } from "uuid";
import { Endpoints } from "../../../../common/api/endpoints";
import { CustomerSummary } from "../../../../crm";
import { db, RequestQueueItem } from "../../../../inventory/offline/db";
import { OfflineOrderAPIRequest } from "../../../../offline/types";
import { PaymentTypes } from "../../../../pos/payment/types";
import {
  OrderApiResponse,
  PostOrderAPIRequest,
  RefundStatus,
  SalesPerson,
} from "../../../../pos/order/models";
import { DeliveryStatus } from "../../../../report/delivery-order/common/models";
import { PaymentStatus } from "../../../../report/types";
import { dayjsInstance } from "../../../utils";
import router from "../router";
import {
  currencyFormatter,
  formatDateToString,
  generateOrderCode,
  parseStringTo2DecimalFloat,
} from "../utils";

router.post<OrderApiResponse, PostOrderAPIRequest, { cartId: string }>(
  Endpoints.ORDER,
  async (req) => {
    /**
     * Offline Flow Handler
     */
    // Create uuid
    const {
      storeId,
      paymentType,
      amount,
      cartId,
      dueDateDuration,
      currency,
      salesPersonId,
      storeBankAccountId,
      referenceNo,
      customerId,
      selectedOfflineBankAccount,
    } = req.body;
    const today = new Date();

    const currCarts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const userInfos = await db.userInfo
      .where("storeId")
      .equals(storeId)
      .limit(1)
      .toArray();

    const storeSettings = await db.storeSettings
      .where("storeId")
      .equals(storeId)
      .limit(1)
      .toArray();

    let localCustomer: CustomerSummary | undefined = undefined;
    let localSalesPerson: SalesPerson | undefined = undefined;
    if (customerId) {
      const customers = await db.customers
        .where("customerId")
        .equals(customerId)
        .limit(1)
        .toArray();
      localCustomer = customers[0];
    }

    if (salesPersonId) {
      const listSalesPerson = await db.employees
        .where("id")
        .equals(salesPersonId)
        .limit(1)
        .toArray();
      localSalesPerson = listSalesPerson[0];
    }

    const currCart = currCarts[0];
    const currUser = userInfos[0];
    const currStoreSetting = storeSettings[0];

    const currStore = currUser.userStoreList.filter((s) => {
      return s.storeId == storeId;
    })[0];

    const bank =
      currStore.storeBankAccountList.find((b) => {
        if (storeBankAccountId) {
          return b.bankAccountId === storeBankAccountId;
        }
        return false;
      }) || selectedOfflineBankAccount;

    let paymentStatus = PaymentStatus.UNPAID;
    if (parseStringTo2DecimalFloat(amount) == 0) {
      paymentStatus = PaymentStatus.UNPAID;
    } else if (
      parseStringTo2DecimalFloat(amount) >=
      parseStringTo2DecimalFloat(currCart.netAmount)
    ) {
      paymentStatus = PaymentStatus.PAID;
    } else if (
      parseStringTo2DecimalFloat(amount) <
      parseStringTo2DecimalFloat(currCart.netAmount)
    ) {
      paymentStatus = PaymentStatus.PARTIALLY_PAID;
    }

    const paymentChange =
      parseStringTo2DecimalFloat(amount) -
      parseStringTo2DecimalFloat(currCart.netAmount);

    let totalDiscount = 0.0;

    if (currCart.cartDiscountList && currCart.cartDiscountList.length === 1) {
      const currDiscount = currCart.cartDiscountList[0];
      totalDiscount =
        currDiscount.discountType === "Price"
          ? parseStringTo2DecimalFloat(
              currCart.cartDiscountList[0].totalDiscount
            )
          : (parseInt(currDiscount.amount, 10) / 100) *
            parseFloat(currCart.grossAmount);
    }

    // Check if the current customer pay under his limit
    if (parseFloat(amount) < parseFloat(currCart.netAmount)) {
      const isCustomerMaxAmountActive =
        localCustomer?.top?.isMaxAmountActive ?? false;
      const isStoreMaxAmountActive =
        currStoreSetting?.isMaxAmountActive ?? false;
      const maxAmountLimit = isCustomerMaxAmountActive
        ? parseFloat(localCustomer?.top?.cumulativeMaxAmount ?? "0")
        : parseFloat(currStoreSetting.cumulativeMaxAmount);

      const minimumPayment = parseFloat(currCart.netAmount) - maxAmountLimit;

      // Consider the amount and total debt that user have
      if (
        parseFloat(currCart.netAmount) -
          parseFloat(amount) +
          parseFloat(localCustomer?.totalDebt ?? "0") >
        maxAmountLimit
      ) {
        if (isCustomerMaxAmountActive) {
          throw {
            status: 400,
            err: {
              data: {
                message: `Jumlah piutang melebihi batas maksimal ${currencyFormatter(
                  maxAmountLimit ?? 0
                )} untuk pelanggan ini. Ubah pengaturan atau bayar uang muka ${currencyFormatter(
                  minimumPayment ?? 0
                )} untuk melanjutkan.`,
                errorCode: "ORDER_TOP_EXCEED_CUSTOMER_CUMULATIVE_MAX_AMOUNT",
                data: null,
              },
            },
          };
        } else if (isStoreMaxAmountActive) {
          throw {
            status: 400,
            err: {
              data: {
                message: `Jumlah piutang melebihi batas maksimal ${currencyFormatter(
                  maxAmountLimit ?? 0
                )} untuk pelanggan ini. Ubah pengaturan atau bayar uang muka ${currencyFormatter(
                  minimumPayment ?? 0
                )} untuk melanjutkan.`,
                errorCode: "ORDER_TOP_EXCEED_STORE_CUMULATIVE_MAX_AMOUNT",
                data: null,
              },
            },
          };
        }
      }
    }

    const orderId = uuidv4();
    const orderCode = generateOrderCode();
    const new_order: OrderApiResponse["data"] = {
      cashierId: currUser.userInfo.id,
      cashierName: currUser.userInfo.name,
      channel: "SAAS",
      createdAt: formatDateToString(today),
      currency: currency,
      customerAddress: localCustomer?.address ?? "",
      customerId: localCustomer?.id ?? null,
      customerName: localCustomer?.name ?? null,
      deliveryStatus: DeliveryStatus.UNDELIVERED,
      dueDate: dayjsInstance()
        .add(dueDateDuration ?? 0, "days")
        .toDate(),
      grossAmount: currCart.grossAmount,
      id: orderId,
      netAmount: currCart.netAmount,
      objectHighlight: {
        amount: amount,
        balanceAfterPayment: "",
        balanceBeforePayment: "",
        bankAccountName: bank?.bankAccountName ?? "",
        bankAccountNo: bank?.bankAccountNumber ?? "",
        bankCode: bank?.bank.code ?? "",
        bankName: bank?.bank.name ?? "",
        createdAt: new Date(),
        id: "",
        paymentCashierId: currUser.userInfo.id,
        paymentCashierName: currUser.userInfo.name,
        paymentChange: paymentChange > 0 ? paymentChange.toString() : "",
        paymentCode: "",
        paymentType: "",
        referenceNo: "",
        updatedAt: new Date(),
      },
      orderCode, // TOOD : generated by frontend
      orderCustomList: currCart.cartCustomList,
      // Anything related to delivery is ignorable because the order is just created
      orderDeliveryList: [],
      orderDiscountList: currCart.cartDiscountList,
      orderItemList: currCart.cartItemList,
      orderPaymentList: [
        {
          amount,
          // balanceAfterPayment Definition : if the user pay in full, then should be 0
          balanceAfterPayment: Math.max(
            parseStringTo2DecimalFloat(currCart.netAmount) -
              parseStringTo2DecimalFloat(amount),
            0
          ).toString(),
          // balanceBeforePayment Defintion : net price
          balanceBeforePayment: currCart.netAmount,
          bankAccountName: bank?.bankAccountName ?? "",
          bankAccountNo: bank?.bankAccountNumber ?? "",
          bankCode: bank?.bank.code ?? "",
          bankName: bank?.bank.name ?? "",
          createdAt: new Date(),
          id: uuidv4(),
          paymentCashierId: currUser.userInfo.id,
          paymentCashierName: currUser.userInfo.name,
          paymentChange: paymentChange > 0 ? paymentChange.toString() : "", // TODO
          paymentCode: `${orderCode}-1`,
          paymentType: paymentType,
          referenceNo: null,
          updatedAt: new Date(),
        },
      ],
      // order status : State the condition of the order, not about the payment.
      orderStatus: "Completed",
      paymentStatus: paymentStatus,
      refundList: [],
      salesPerson: localSalesPerson,
      refundStatus: ("" as unknown) as RefundStatus,
      storeAddress: currStore.storeAddress,
      storeId: storeId,
      storeName: currStore.storeName,
      // Total Charges Definition : total price of custom titem
      totalCharges: currCart.cartCustomList
        .reduce(
          (price, c) => price + parseStringTo2DecimalFloat(c.totalPrice),
          0.0
        )
        .toString(),
      // TotalDeiscount Defintion : sum of cartDiscountList.totalDiscount
      totalDiscount: totalDiscount.toString(),
      // Total Items Defintiion : quantity of cart item
      totalItems: 0,
      // totalPayment Defintiion : amount paid by user
      totalPayment: amount,
      // Anything related to refund should be ignorable because we are just creating an order,
      // moreover refund flow is not nabled in offline mode
      totalRefundAmount: "",
      totalRefundChargesAmount: "",
      totalRefundDiscountAmount: "",
      totalSellerFee: "",
      // unpaidAmount Definition : netPrice - totalPayment
      unpaidAmount: (
        parseStringTo2DecimalFloat(currCart.netAmount) -
        parseStringTo2DecimalFloat(amount)
      ).toString(),
      updatedAt: formatDateToString(today),
      tax: currCart.tax,
    };

    /**
     * Construct API Payload to be queued in the request
     */

    const offlineOrderRequestQueueData: OfflineOrderAPIRequest = {
      cartId: cartId,
      customItemList: currCart.cartCustomList,
      cashierId: currCart.cashierId,
      cashierName: currUser.userInfo.name,
      customer:
        localCustomer !== undefined
          ? {
              customerName: localCustomer.name,
              customerPhoneNumber: localCustomer.phoneNumber,
              customerAddress: localCustomer.address,
              salesPerson: localCustomer.salesPerson,
              top: localCustomer.top,
              ...(!localCustomer.isOffline && {
                customerId: localCustomer.customerId,
              }),
            }
          : null,
      dueDateDuration: dueDateDuration,
      discountList: currCart.cartDiscountList,
      itemList: currCart.cartItemList.map((c) => ({
        lastUpdatedAt: null,
        basePrice: c.basePrice,
        discount:
          c.itemDiscount !== null
            ? {
                amount: Number(c.itemDiscount.amount),
                currency: c.itemDiscount.totalDiscount.currency,
                discountName: c.itemDiscount.discountName,
                discountType: c.itemDiscount.discountType,
                promotionId: c.itemDiscount.promotionId,
                promotionQuantityApplied:
                  c.itemDiscount.promotionQuantityApplied,
              }
            : null,
        inventoryId: c.inventoryId,
        price: c.price,
        quantity: c.quantity,
      })),
      netAmount: parseFloat(currCart.netAmount),
      grossAmount: parseFloat(currCart.grossAmount),
      tax: currCart.tax,
      totalDiscount: totalDiscount,

      orderCode: orderCode,
      payment: {
        paidAmount: amount,
        paymentCode: `${orderCode}-1`, // Need to confirm with backend
        paymentType: paymentType,
        referenceNo: paymentType === "Giro" ? referenceNo : undefined,
        bankAccountInfo:
          paymentType === "Giro" || paymentType === PaymentTypes.BANK_TRANSFER
            ? {
                bankAccountName: bank?.bankAccountName ?? "",
                bankAccountNumber: bank?.bankAccountNumber ?? "",
                bankCode: bank?.bank.code ?? "",
              }
            : undefined,
      },
      salesPerson: localSalesPerson,
      storeId: storeId,
      storeName: currStore.storeName,
      storeAddress: currStore.storeAddress,
      transactionDate: formatDateToString(today),
    };
    // order_code + ordinal payment number, since we only use this api only for payment page
    // it is guaranteed that the payment number equal to = 1 (first payment);

    const new_request_queue: RequestQueueItem = {
      method: "post",
      url: Endpoints.OFFLINE_ORDER,
      data: offlineOrderRequestQueueData,
      apiResponseStatus: "QUEUED",
      requestQueueId: uuidv4(),
      originalOrderObject: new_order,
      storeId,
      cartId,
      retryCount: 0,
    };

    await db.requestQueue.add(new_request_queue);

    await db.carts.where("id").equals(cartId).delete();

    return {
      data: new_order,
      message: ["Successfully created order 2021/10/48."],
    };
  }
);
