import * as React from "react";
import { BaseProviderProps } from "../types";
import { FeaturesFlagType } from "./types";

// Could be improved in the future for flagging some featuers
const initialState: FeaturesFlagType = {
  homePage: {
    // Hardcoded, to prevent the popup modal
    // Reference: https://linear.app/gudangada/issue/SAAS-4772/[store-specialty]-add-config-to-show-hide-store-specialty-pop-up-in
    storeSpecialtyPopup: false,
  },
  authLayout: {
    showCarousel: false
  }
};

export const FeaturesFlagContext =
  React.createContext<FeaturesFlagType>(initialState);

export const FeaturesFlagProvider = ({ children }: BaseProviderProps) => {
  const value: FeaturesFlagType = {
    ...initialState,
  };

  return (
    <FeaturesFlagContext.Provider value={value}>
      {children}
    </FeaturesFlagContext.Provider>
  );
};

export default FeaturesFlagProvider;
