import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const TransactionHistoryIcon: React.VFC<IconProps> = ({
  width = "24",
  height = "24",
  fill = theme.palette.interface.neutral.light,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        d="m15.222 2.7778h-0.8889v-1.7778h-1.7777v1.7778h-7.1112v-1.7778h-1.7778v1.7778h-0.88889c-0.97778 0-1.7778 0.8-1.7778 1.7778v12.444c0 0.9778 0.8 1.7778 1.7778 1.7778h10.444v-1.7778l0.5031-1.3684 1.4969-6.3699h1.7778v-4.7062c0-0.97778-0.8-1.7778-1.7778-1.7778zm-2 14.222h-10.444v-8.8889h12.444v1.1506l-0.9829 4.1827-0.514 2.1872-0.5031 1.3684zm-10.444-10.667v-1.7778h12.444v1.7778h-12.444z"
        fill={fill}
      />
      <path
        d="m17.75 23.375c2.8577 0 5.75-0.9876 5.75-2.875v-8.625c0-1.8874-2.8923-2.875-5.75-2.875s-5.75 0.98756-5.75 2.875v8.625c0 1.8874 2.8923 2.875 5.75 2.875zm0-1.4375c-2.6752 0-4.3125-0.9308-4.3125-1.4375v-0.9114c1.1076 0.6023 2.7147 0.9114 4.3125 0.9114s3.2049-0.3091 4.3125-0.9114v0.9114c0 0.5067-1.6373 1.4375-4.3125 1.4375zm0-11.5c2.6752 0 4.3125 0.9308 4.3125 1.4375s-1.6373 1.4375-4.3125 1.4375-4.3125-0.9308-4.3125-1.4375 1.6373-1.4375 4.3125-1.4375zm-4.3125 3.4011c1.1076 0.6023 2.7147 0.9114 4.3125 0.9114s3.2049-0.3091 4.3125-0.9114v0.9114c0 0.5067-1.6373 1.4375-4.3125 1.4375s-4.3125-0.9308-4.3125-1.4375v-0.9114zm0 2.875c1.1076 0.6023 2.7147 0.9114 4.3125 0.9114s3.2049-0.3091 4.3125-0.9114v0.9114c0 0.5067-1.6373 1.4375-4.3125 1.4375s-4.3125-0.9308-4.3125-1.4375v-0.9114z"
        fill={fill}
      />
    </svg>
  );
};

export default TransactionHistoryIcon;
