export const ErrorMessages = {
  required: (field: string): string => `${field} harus diisi`,
  numberOnly: (field: string): string =>
    `${field} hanya boleh menggunakan angka`,
  minimumValue: (min: number, field: string): string =>
    `${field} tidak boleh kurang dari ${min}`,
  noInternet: "Tidak ada koneksi Internet",
  invalid: (field: string): string => `${field} tidak valid`,
};

export default ErrorMessages;
