import { gadaSaasApi } from "../../base-rtkq-api";
import { Endpoints } from "../../common/api/endpoints";
import {
  GetOtpRequest,
  GetOtpResponse,
  SendOtpRequest,
  SendOtpResponse,
} from "./types";

const OtpApi = gadaSaasApi.injectEndpoints({
  endpoints: (build) => ({
    getOtp: build.mutation<GetOtpResponse, GetOtpRequest>({
      query: (args) => ({
        method: "POST",
        url: Endpoints.GET_OTP,
        data: args,
        dropCredentials: true,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    startOtp: build.mutation<SendOtpResponse, SendOtpRequest>({
      query: (args) => ({
        method: "POST",
        url: Endpoints.SEND_OTP,
        data: args,
        dropCredentials: true,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: Endpoints.LOGOUT,
      }),
      extraOptions: {
        maxRetries: 1,
      },
    }),
  }),
});

export const {
  useGetOtpMutation,
  useStartOtpMutation,
  useLogoutMutation,
} = OtpApi;

export default OtpApi;
