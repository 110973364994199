import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../common/hooks/useActions";
import { GADAOfflineStateType } from "./types";

const GADAOfflineInitialState: GADAOfflineStateType = {
  isOffline: false,
  snackbarKey: undefined,
};

const GadaOfflineSlice = createSlice({
  name: "GadaOfflineSlice",
  initialState: GADAOfflineInitialState,
  reducers: {
    markOnline(state) {
      state.isOffline = false;
      state.snackbarKey = undefined;
    },
    markOffline(state, action: PayloadAction<string | number>) {
      state.isOffline = true;
      state.snackbarKey = action.payload;
    },
  },
});

export const { actions } = GadaOfflineSlice;
export const { markOnline, markOffline } = actions;
export const useGadaOfflineActions = () => useActions(actions, undefined);

export default GadaOfflineSlice.reducer;
