import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const LogoBeta: React.VFC<IconProps> = ({
  width = "1246",
  height = "48",
  fill = theme.palette.interface.white,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1244 446"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="914"
        y="194"
        width="329.31"
        height="154.05"
        rx="77.026"
        fill={fill}
      />
      <path
        d="m973.98 300.03v-59.079h22.616c4.271 0 7.821 0.673 10.641 2.019 2.85 1.327 4.98 3.145 6.38 5.452 1.42 2.308 2.13 4.924 2.13 7.847 0 2.404-0.46 4.461-1.38 6.173-0.92 1.692-2.16 3.067-3.72 4.125s-3.3 1.818-5.22 2.279v0.577c2.09 0.115 4.1 0.76 6.03 1.933 1.94 1.154 3.52 2.788 4.76 4.904 1.23 2.115 1.84 4.673 1.84 7.673 0 3.058-0.74 5.808-2.22 8.251-1.48 2.423-3.71 4.336-6.69 5.74s-6.73 2.106-11.253 2.106h-23.914zm10.702-8.943h11.511c3.884 0 6.684-0.74 8.394-2.221 1.73-1.5 2.6-3.423 2.6-5.769 0-1.75-0.44-3.327-1.3-4.731-0.87-1.423-2.1-2.539-3.69-3.347-1.6-0.827-3.504-1.24-5.716-1.24h-11.799v17.308zm0-25.01h10.587c1.847 0 3.51-0.337 4.988-1.01 1.48-0.692 2.65-1.663 3.49-2.913 0.87-1.27 1.3-2.77 1.3-4.501 0-2.288-0.81-4.173-2.42-5.654-1.6-1.48-3.973-2.221-7.127-2.221h-10.818v16.299zm46.745 33.953v-59.079h38.43v8.971h-27.72v16.039h25.73v8.972h-25.73v16.125h27.95v8.972h-38.66zm50.81-50.108v-8.971h47.14v8.971h-18.26v50.108h-10.62v-50.108h-18.26zm60.73 50.108h-11.42l20.8-59.079h13.21l20.83 59.079h-11.42l-15.78-46.963h-0.46l-15.76 46.963zm0.38-23.164h31.15v8.596h-31.15v-8.596z"
        fill={
          fill === theme.palette.interface.white
            ? theme.palette.interface.neutral.dark
            : theme.palette.interface.white
        }
      />
      <path
        d="m213.22 126.31 0.02-63.19c3.36-2.04 5.6-5.73 5.55-9.94-0.07-6.35-5.31-11.44-11.69-11.37-6.29 0.07-11.34 5.12-11.43 11.34l-54.63 31.33c-1.68-0.92-3.62-1.44-5.67-1.41-6.39 0.07-11.51 5.28-11.44 11.63s5.31 11.44 11.69 11.37c6.25-0.07 11.27-5.06 11.42-11.22l54.16-31.06-0.02 62.52c-2.96 1.8-5.09 4.88-5.49 8.57-0.02 0.2-0.02 0.39-0.03 0.58l-54.65 31.34c-1.29-0.7-2.75-1.18-4.3-1.34-2.44-0.26-4.78 0.25-6.78 1.34l-54.63-31.38c-0.22-3.89-2.37-7.25-5.51-9.16l0.02-63.19c3.22-1.96 5.4-5.43 5.53-9.41l54.64-31.34c2.32 1.26 5.07 1.75 7.86 1.18 6.26-1.27 10.29-7.34 9.01-13.57-1.28-6.22-7.39-10.23-13.65-8.96-5.38 1.09-9.11 5.75-9.24 10.98l-54.53 31.27c-1.71-0.95-3.68-1.5-5.78-1.47-6.39 0.07-11.51 5.28-11.44 11.63 0.05 4.11 2.26 7.68 5.55 9.68l-0.02 63.19c-3.37 2.04-5.6 5.73-5.55 9.94 0.07 6.35 5.31 11.44 11.69 11.37 2.09-0.02 4.05-0.61 5.73-1.59l54.3 31.19c0.13 5.68 4.47 10.53 10.31 11.16 6.35 0.68 12.05-3.88 12.74-10.19 0.04-0.32 0.05-0.64 0.06-0.96l54.32-31.15c1.37 0.8 2.93 1.35 4.62 1.53 6.35 0.68 12.06-3.88 12.74-10.19 0.5-4.58-1.78-8.8-5.48-11.05z"
        fill="#00D1C7"
      />
      <path d="m137.41 110.58h-3.83v9.22h3.83v-9.22z" fill="#00D1C7" />
      <path d="m137.4 124.32h-3.83v9.22h3.83v-9.22z" fill="#00D1C7" />
      <path d="m137.4 137.91h-3.83v9.22h3.83v-9.22z" fill="#00D1C7" />
      <path d="m137.39 151.66h-3.83v9.22h3.83v-9.22z" fill="#00D1C7" />
      <path d="m137.44 28.25h-3.83v9.22h3.83v-9.22z" fill="#00D1C7" />
      <path d="m137.43 42h-3.83v9.22h3.83v-9.22z" fill="#00D1C7" />
      <path d="m137.43 55.59h-3.83v9.22h3.83v-9.22z" fill="#00D1C7" />
      <path d="m137.42 69.34h-3.83v9.22h3.83v-9.22z" fill="#00D1C7" />
      <path
        d="m76.76 126.05 1.91 3.3 8.04-4.61-1.92-3.29-8.03 4.6z"
        fill="#00D1C7"
      />
      <path
        d="m88.74 119.18 1.91 3.3 8.03-4.61-1.91-3.29-8.03 4.6z"
        fill="#00D1C7"
      />
      <path
        d="m100.58 112.39 1.91 3.3 8.04-4.61-1.92-3.29-8.03 4.6z"
        fill="#00D1C7"
      />
      <path
        d="m112.56 105.52 1.91 3.3 8.03-4.61-1.91-3.29-8.03 4.6z"
        fill="#00D1C7"
      />
      <path
        d="m150.4 100.93-1.91 3.29 8.03 4.62 1.91-3.3-8.03-4.61z"
        fill="#00D1C7"
      />
      <path
        d="m162.37 107.81-1.91 3.29 8.03 4.61 1.91-3.29-8.03-4.61z"
        fill="#00D1C7"
      />
      <path
        d="m174.21 114.61-1.91 3.29 8.03 4.61 1.91-3.29-8.03-4.61z"
        fill="#00D1C7"
      />
      <path
        d="m186.18 121.48-1.91 3.3 8.03 4.61 1.91-3.29-8.03-4.62z"
        fill="#00D1C7"
      />
      <path
        d="m78.7 59.74-1.92 3.3 8.03 4.61 1.92-3.3-8.03-4.61z"
        fill="#00D1C7"
      />
      <path
        d="m90.67 66.62-1.91 3.29 8.03 4.61 1.91-3.29-8.03-4.61z"
        fill="#00D1C7"
      />
      <path
        d="m102.51 73.42-1.92 3.29 8.03 4.61 1.92-3.29-8.03-4.61z"
        fill="#00D1C7"
      />
      <path
        d="m114.48 80.29-1.91 3.3 8.03 4.61 1.91-3.29-8.03-4.62z"
        fill="#00D1C7"
      />
      <path
        d="m158.46 23.26-8.03-4.62-1.91 3.3 8.03 4.61 1.91-3.29z"
        fill="#00D1C7"
      />
      <path
        d="m170.43 30.13-8.03-4.61-1.91 3.3 8.03 4.61 1.91-3.3z"
        fill="#00D1C7"
      />
      <path
        d="m182.27 36.93-8.03-4.61-1.91 3.3 8.03 4.61 1.91-3.3z"
        fill="#00D1C7"
      />
      <path
        d="m194.24 43.81-8.03-4.61-1.91 3.29 8.03 4.62 1.91-3.3z"
        fill="#00D1C7"
      />
      <path
        d="m276.69 116.24c0.19-0.54 0.31-1.12 0.36-1.77 0.05-0.64 0.08-1.31 0.08-2.01v-0.54c0-0.12-0.01-0.22-0.02-0.3s-0.02-0.18-0.02-0.3v-0.54c-0.78 0.83-1.58 1.65-2.38 2.45-0.81 0.8-1.7 1.52-2.69 2.15-0.98 0.63-2.09 1.14-3.33 1.52-1.24 0.39-2.68 0.58-4.32 0.58-3.63 0-7-0.62-10.09-1.85-3.1-1.23-5.77-2.95-8.03-5.16s-4.02-4.85-5.29-7.93c-1.26-3.08-1.9-6.46-1.89-10.16 0-3.67 0.63-7.1 1.9-10.3s3.03-5.98 5.29-8.35 4.94-4.23 8.04-5.58 6.46-2.03 10.1-2.02c1.67 0 3.19 0.19 4.56 0.56 1.37 0.38 2.63 0.89 3.78 1.55 1.14 0.66 2.19 1.43 3.13 2.31s1.82 1.82 2.62 2.81l1.13-3.57c0.16-0.45 0.31-0.87 0.44-1.24s0.31-0.69 0.53-0.94c0.21-0.25 0.49-0.45 0.83-0.58s0.77-0.2 1.31-0.2h6.02c0.38 0 0.75 0.07 1.13 0.2s0.72 0.32 1.03 0.56 0.56 0.55 0.75 0.92 0.28 0.8 0.28 1.28l-0.01 40.86c0 4.17-0.69 7.89-2.06 11.16-1.37 3.26-3.25 6.02-5.64 8.27-2.38 2.25-5.2 3.96-8.46 5.14s-6.76 1.76-10.5 1.76c-1.45 0-3.01-0.13-4.68-0.38-1.67-0.26-3.33-0.64-4.99-1.15s-3.26-1.16-4.8-1.95c-1.55-0.79-2.91-1.71-4.1-2.75-0.4-0.3-0.75-0.66-1.03-1.1s-0.42-0.93-0.42-1.47c0-0.67 0.26-1.38 0.77-2.13l3.84-5.26c0.38-0.4 0.73-0.7 1.05-0.9s0.67-0.3 1.05-0.3c0.59 0 1.33 0.32 2.22 0.96 0.67 0.64 1.45 1.16 2.32 1.55s1.78 0.7 2.71 0.92c0.93 0.23 1.84 0.37 2.75 0.44 0.9 0.07 1.72 0.1 2.44 0.1 1.21 0 2.48-0.16 3.8-0.48s2.55-0.8 3.7-1.42c1.14-0.63 2.15-1.4 3.03-2.31 0.84-0.89 1.44-1.93 1.76-3.11zm-23.09-23.85c0 1.79 0.27 3.46 0.83 5 0.55 1.54 1.34 2.87 2.36 3.99s2.25 2.01 3.69 2.65 3.03 0.96 4.78 0.97c1.72 0 3.34-0.3 4.87-0.9 1.52-0.6 2.85-1.44 3.98-2.51s2.04-2.33 2.73-3.77 1.07-3.01 1.15-4.7v-1.33c-0.08-1.63-0.46-3.18-1.15-4.64s-1.59-2.74-2.72-3.85-2.46-1.99-3.98-2.65-3.14-0.98-4.87-0.99c-1.75 0-3.35 0.34-4.79 1.02s-2.67 1.6-3.7 2.75c-1.02 1.15-1.81 2.5-2.36 4.05-0.54 1.56-0.82 3.19-0.82 4.91z"
        fill="#00D1C7"
      />
      <path
        d="m352.44 117.36c-0.35 0.29-0.72 0.48-1.11 0.56s-0.76 0.12-1.11 0.12h-6.1c-0.48 0-0.9-0.09-1.25-0.26s-0.65-0.41-0.89-0.7-0.44-0.63-0.61-1c-0.16-0.37-0.3-0.76-0.4-1.16l-0.85-3.92c-0.4 0.83-1.08 1.7-2.02 2.62s-2.11 1.77-3.49 2.54c-1.39 0.77-2.98 1.41-4.79 1.92-1.8 0.51-3.77 0.76-5.9 0.76-3.07 0-5.79-0.59-8.16-1.77s-4.37-2.78-5.99-4.82c-1.63-2.03-2.86-4.41-3.69-7.13s-1.25-5.61-1.25-8.69l0.01-26.29c0-1.02 0.22-1.79 0.67-2.31 0.44-0.52 1.15-0.78 2.12-0.78h9.33c1.16 0 1.95 0.2 2.38 0.58 0.43 0.39 0.65 1.15 0.65 2.27l-0.01 26.16c0 1.15 0.22 2.24 0.67 3.28 0.44 1.03 1.04 1.94 1.8 2.73 0.75 0.79 1.63 1.41 2.64 1.87s2.08 0.68 3.21 0.68c0.97 0 2.02-0.16 3.15-0.48s2.18-0.82 3.15-1.51c0.97-0.68 1.78-1.56 2.42-2.63 0.65-1.07 0.97-2.36 0.97-3.86l0.01-26.12c0-0.46 0.09-0.91 0.26-1.37s0.44-0.8 0.79-1.04c0.35-0.21 0.68-0.36 0.99-0.44s0.67-0.12 1.07-0.12h9.13c1.16 0 1.99 0.28 2.5 0.84s0.77 1.31 0.77 2.25l-0.02 44.92c0 0.51-0.12 0.98-0.34 1.4-0.23 0.43-0.55 0.76-0.95 1l0.24-0.1z"
        fill="#00D1C7"
      />
      <path
        d="m402.96 113.03c-0.73 0.72-1.56 1.44-2.48 2.15-0.93 0.71-1.94 1.34-3.03 1.91-1.09 0.56-2.26 1.02-3.51 1.36-1.25 0.35-2.58 0.52-3.98 0.52-3.66 0-7.03-0.68-10.11-2.05s-5.73-3.23-7.95-5.6-3.96-5.16-5.21-8.37-1.88-6.66-1.87-10.36c0-3.72 0.63-7.18 1.88-10.38s2.99-5.98 5.21-8.35 4.87-4.23 7.96-5.58c3.08-1.35 6.45-2.02 10.12-2.02 1.48 0 2.83 0.13 4.06 0.38 1.22 0.26 2.34 0.62 3.35 1.11 1.01 0.48 1.94 1.06 2.79 1.73s1.65 1.41 2.4 2.21l0.01-16.74c0-0.86 0.26-1.58 0.79-2.17s1.31-0.88 2.36-0.88h9.09c0.35 0 0.72 0.07 1.11 0.22s0.75 0.36 1.07 0.62c0.32 0.27 0.59 0.59 0.79 0.96s0.3 0.79 0.3 1.24l-0.02 60.01c0 2.06-1.09 3.09-3.27 3.09h-7.91c-0.54 0-0.96-0.07-1.25-0.2-0.3-0.13-0.54-0.33-0.75-0.58-0.2-0.25-0.38-0.58-0.54-0.96-0.16-0.39-0.36-0.84-0.61-1.34l-0.8-1.93zm-23.62-20.44c0 1.77 0.27 3.42 0.83 4.96 0.55 1.54 1.34 2.87 2.36 3.99s2.25 2.02 3.69 2.67c1.44 0.66 3.03 0.98 4.78 0.99 1.72 0 3.34-0.31 4.87-0.92 1.52-0.61 2.85-1.46 3.98-2.55 1.13-1.08 2.04-2.36 2.73-3.83s1.07-3.05 1.15-4.74v-0.56c0-1.71-0.32-3.34-0.97-4.9-0.65-1.55-1.54-2.92-2.68-4.1s-2.49-2.11-4.04-2.79-3.22-1.03-5.03-1.03c-1.75 0-3.35 0.34-4.79 1.02s-2.67 1.61-3.7 2.79c-1.02 1.18-1.81 2.54-2.36 4.09-0.54 1.57-0.82 3.2-0.82 4.91z"
        fill="#00D1C7"
      />
      <path
        d="m484.81 115.11c0 0.46-0.08 0.89-0.24 1.3-0.16 0.42-0.43 0.74-0.81 0.98-0.3 0.29-0.65 0.48-1.05 0.56s-0.75 0.12-1.05 0.12h-7.95c-0.57 0-1.02-0.07-1.37-0.22s-0.63-0.35-0.85-0.62-0.4-0.6-0.54-0.98c-0.15-0.39-0.3-0.81-0.46-1.26l-0.52-1.81c-0.73 0.72-1.56 1.43-2.48 2.13s-1.95 1.32-3.07 1.87-2.32 0.99-3.59 1.32c-1.28 0.33-2.6 0.5-3.98 0.5-2.45 0-4.8-0.31-7.05-0.93s-4.35-1.49-6.3-2.63-3.72-2.5-5.31-4.1c-1.59-1.59-2.95-3.37-4.1-5.32-1.14-1.95-2.02-4.06-2.64-6.32s-0.93-4.62-0.93-7.09c0-3.72 0.69-7.18 2.06-10.38s3.25-5.98 5.62-8.35 5.16-4.23 8.36-5.58 6.64-2.02 10.3-2.02c1.67 0 3.22 0.19 4.66 0.56 1.44 0.38 2.76 0.88 3.96 1.53 1.2 0.64 2.28 1.41 3.25 2.31s1.82 1.87 2.54 2.91l1.17-3.65c0.27-0.67 0.49-1.21 0.67-1.63 0.17-0.41 0.36-0.72 0.57-0.92s0.45-0.33 0.75-0.4 0.71-0.1 1.25-0.1h6.02c0.3 0 0.65 0.04 1.05 0.12s0.75 0.27 1.05 0.56c0.38 0.3 0.65 0.63 0.81 1s0.24 0.8 0.24 1.28l-0.04 45.26zm-39.77-22.49c0 1.77 0.27 3.42 0.83 4.96 0.55 1.54 1.34 2.87 2.36 3.99s2.25 2.02 3.69 2.67c1.44 0.66 3.03 0.98 4.78 0.99 1.8 0 3.48-0.33 5.03-0.98s2.89-1.54 4.04-2.67c1.14-1.12 2.04-2.45 2.69-3.99s0.97-3.19 0.97-4.96c0-1.71-0.32-3.34-0.97-4.9-0.65-1.55-1.54-2.92-2.68-4.1s-2.49-2.11-4.04-2.79-3.22-1.03-5.03-1.03c-1.75 0-3.35 0.34-4.79 1.02s-2.67 1.61-3.7 2.79c-1.02 1.18-1.81 2.54-2.36 4.09-0.54 1.56-0.82 3.19-0.82 4.91z"
        fill="#00D1C7"
      />
      <path
        d="m498.79 67.82c0.35-0.29 0.72-0.48 1.11-0.56s0.76-0.12 1.11-0.12h6.1c0.48 0 0.9 0.09 1.25 0.26s0.65 0.41 0.89 0.7 0.44 0.63 0.59 1 0.29 0.76 0.42 1.16l0.85 3.52c0.4-0.83 1.06-1.68 1.96-2.56s2.02-1.68 3.35-2.4 2.87-1.31 4.62-1.78 3.7-0.7 5.86-0.7c3.07 0 5.81 0.59 8.22 1.76 2.41 1.18 4.45 2.78 6.14 4.81 1.68 2.03 2.96 4.4 3.83 7.11s1.31 5.61 1.31 8.68l-0.01 26.29c0 1.02-0.22 1.79-0.67 2.31-0.44 0.52-1.15 0.78-2.12 0.78h-9.33c-1.16 0-1.95-0.19-2.38-0.58s-0.65-1.14-0.65-2.27l0.01-26.13c0-1.15-0.26-2.24-0.79-3.27-0.52-1.03-1.22-1.94-2.08-2.73s-1.84-1.41-2.93-1.87-2.2-0.68-3.33-0.68c-0.97 0-1.96 0.21-2.97 0.64s-1.94 1.02-2.79 1.79c-0.85 0.76-1.56 1.66-2.14 2.69s-0.91 2.15-0.99 3.35l-0.01 26.09c0 0.46-0.09 0.91-0.26 1.36-0.18 0.46-0.44 0.8-0.79 1.04-0.35 0.21-0.68 0.36-0.99 0.44s-0.67 0.12-1.07 0.12h-9.13c-1.16 0-1.99-0.28-2.5-0.84s-0.77-1.31-0.77-2.25l0.02-44.89c0-0.51 0.11-0.97 0.34-1.4s0.55-0.76 0.95-1l-0.23 0.13z"
        fill="#00D1C7"
      />
      <path
        d="m596.46 116.36c0.19-0.54 0.31-1.12 0.36-1.77 0.05-0.64 0.08-1.31 0.08-2.01v-0.54c0-0.12-0.01-0.22-0.02-0.3s-0.02-0.18-0.02-0.3v-0.54c-0.78 0.83-1.58 1.65-2.38 2.45-0.81 0.8-1.7 1.52-2.69 2.15-0.98 0.63-2.09 1.14-3.33 1.52-1.24 0.39-2.68 0.58-4.32 0.58-3.63 0-7-0.62-10.09-1.85-3.1-1.23-5.77-2.95-8.03-5.16s-4.02-4.85-5.29-7.93c-1.26-3.08-1.9-6.46-1.89-10.16 0-3.67 0.63-7.1 1.9-10.3s3.03-5.98 5.29-8.35 4.94-4.23 8.04-5.58 6.46-2.03 10.1-2.02c1.67 0 3.19 0.19 4.56 0.56 1.37 0.38 2.63 0.89 3.78 1.55 1.14 0.66 2.19 1.43 3.13 2.31s1.82 1.82 2.62 2.81l1.13-3.57c0.16-0.45 0.31-0.87 0.44-1.24s0.31-0.69 0.53-0.94c0.21-0.25 0.49-0.45 0.83-0.58s0.77-0.2 1.31-0.2h6.02c0.38 0 0.75 0.07 1.13 0.2s0.72 0.32 1.03 0.56 0.56 0.55 0.75 0.92 0.28 0.8 0.28 1.28l-0.01 40.86c0 4.17-0.69 7.89-2.06 11.16-1.37 3.26-3.25 6.02-5.64 8.27-2.38 2.25-5.2 3.96-8.46 5.14s-6.76 1.76-10.5 1.76c-1.45 0-3.01-0.13-4.68-0.38-1.67-0.26-3.33-0.64-4.99-1.15s-3.26-1.16-4.8-1.95c-1.55-0.79-2.91-1.71-4.1-2.75-0.4-0.3-0.75-0.66-1.03-1.1s-0.42-0.93-0.42-1.47c0-0.67 0.26-1.38 0.77-2.13l3.84-5.26c0.38-0.4 0.73-0.7 1.05-0.9s0.67-0.3 1.05-0.3c0.59 0 1.33 0.32 2.22 0.96 0.67 0.64 1.45 1.16 2.32 1.55s1.78 0.7 2.71 0.92c0.93 0.23 1.84 0.37 2.75 0.44 0.9 0.07 1.72 0.1 2.44 0.1 1.21 0 2.48-0.16 3.8-0.48s2.55-0.8 3.7-1.42c1.14-0.63 2.15-1.4 3.03-2.31 0.83-0.89 1.43-1.94 1.76-3.11zm-23.09-23.86c0 1.79 0.27 3.46 0.83 5 0.55 1.54 1.34 2.87 2.36 3.99s2.25 2.01 3.69 2.65 3.03 0.96 4.78 0.97c1.72 0 3.34-0.3 4.87-0.9 1.52-0.6 2.85-1.44 3.98-2.51s2.04-2.33 2.73-3.77 1.07-3.01 1.15-4.7v-1.33c-0.08-1.63-0.46-3.18-1.15-4.64s-1.59-2.74-2.72-3.85-2.46-1.99-3.98-2.65-3.14-0.98-4.87-0.99c-1.75 0-3.35 0.34-4.79 1.02s-2.67 1.6-3.7 2.75c-1.02 1.15-1.81 2.5-2.36 4.05-0.55 1.57-0.82 3.2-0.82 4.91z"
        fill="#00D1C7"
      />
      <path
        d="m668.41 111.36c-2.32 2.35-5.01 4.21-8.08 5.56s-6.39 2.02-9.97 2.02c-2.4 0-4.72-0.32-6.97-0.95s-4.35-1.53-6.32-2.69c-1.97-1.17-3.75-2.55-5.37-4.16-1.61-1.61-3.01-3.39-4.18-5.36s-2.07-4.07-2.7-6.3-0.95-4.56-0.95-6.97 0.32-4.73 0.95-6.96 1.54-4.33 2.71-6.28 2.57-3.73 4.18-5.34c1.62-1.61 3.41-2.98 5.37-4.13s4.07-2.04 6.3-2.67 4.56-0.94 6.99-0.94c3.58 0 6.9 0.68 9.97 2.03s5.76 3.21 8.07 5.56v-4.9c0-1.18 0.59-1.77 1.78-1.77h4.16c1.27 0 1.9 0.59 1.9 1.77l-0.02 47.49c0 0.54-0.18 0.96-0.53 1.28s-0.77 0.48-1.25 0.48h-4.2c-0.57 0-1.02-0.17-1.35-0.52-0.34-0.35-0.5-0.76-0.5-1.24l0.01-5.01zm0.41-18.82c0-2.52-0.48-4.92-1.45-7.21s-2.28-4.29-3.95-6-3.62-3.08-5.87-4.1-4.63-1.53-7.16-1.53-4.9 0.51-7.12 1.52c-2.22 1.02-4.16 2.38-5.81 4.09s-2.96 3.71-3.91 6c-0.96 2.29-1.43 4.69-1.43 7.21 0 2.49 0.47 4.88 1.43 7.19 0.95 2.3 2.26 4.33 3.91 6.08s3.59 3.15 5.81 4.2c2.22 1.04 4.59 1.57 7.12 1.57s4.91-0.52 7.16-1.56c2.24-1.04 4.2-2.44 5.87-4.19s2.99-3.78 3.96-6.08c0.96-2.31 1.44-4.7 1.44-7.19z"
        fill="#00D1C7"
      />
      <path
        d="m734.96 118.17c-1.18 0-1.78-0.59-1.78-1.77v-4.86c-2.32 2.35-5.01 4.2-8.08 5.54s-6.39 2-9.97 2c-2.4 0-4.72-0.32-6.96-0.95-2.25-0.63-4.35-1.52-6.32-2.67s-3.75-2.53-5.37-4.14c-1.61-1.61-3.01-3.39-4.18-5.34s-2.07-4.05-2.7-6.28-0.95-4.56-0.95-6.96c0-2.41 0.32-4.73 0.95-6.96s1.54-4.33 2.71-6.3 2.57-3.75 4.18-5.36c1.62-1.61 3.41-2.99 5.37-4.15s4.07-2.06 6.3-2.69 4.56-0.94 6.99-0.94c3.58 0 6.9 0.68 9.97 2.03s5.76 3.21 8.07 5.56l0.01-20.11c0-0.48 0.17-0.9 0.51-1.24 0.34-0.35 0.79-0.52 1.35-0.52h4.2c0.48 0 0.9 0.16 1.25 0.48s0.52 0.75 0.52 1.28l-0.02 62.58c0 1.18-0.63 1.77-1.9 1.77h-4.15zm-1.37-25.41c0-2.49-0.48-4.88-1.45-7.19-0.97-2.3-2.28-4.33-3.95-6.08s-3.62-3.15-5.87-4.2c-2.25-1.04-4.63-1.57-7.16-1.57s-4.9 0.52-7.12 1.56-4.16 2.44-5.81 4.19-2.96 3.78-3.91 6.08c-0.96 2.3-1.43 4.7-1.43 7.19 0 2.54 0.47 4.95 1.43 7.23 0.95 2.28 2.26 4.27 3.91 5.98s3.59 3.08 5.81 4.1 4.59 1.53 7.12 1.53 4.91-0.51 7.16-1.52c2.24-1.02 4.2-2.38 5.87-4.09s2.99-3.7 3.96-5.98c0.96-2.28 1.44-4.69 1.44-7.23z"
        fill="#00D1C7"
      />
      <path
        d="m797.95 111.41c-2.32 2.35-5.01 4.21-8.08 5.56s-6.39 2.02-9.97 2.02c-2.4 0-4.72-0.32-6.96-0.95-2.25-0.63-4.35-1.53-6.32-2.69-1.97-1.17-3.75-2.55-5.37-4.16-1.61-1.61-3.01-3.39-4.18-5.36s-2.07-4.07-2.7-6.3-0.95-4.56-0.95-6.97 0.32-4.73 0.95-6.96 1.54-4.33 2.71-6.28 2.57-3.73 4.18-5.34c1.62-1.61 3.41-2.98 5.37-4.13s4.07-2.04 6.3-2.67 4.56-0.94 6.99-0.94c3.58 0 6.9 0.68 9.97 2.03s5.76 3.21 8.07 5.56v-4.9c0-1.18 0.59-1.77 1.78-1.77h4.16c1.27 0 1.9 0.59 1.9 1.77l-0.02 47.49c0 0.54-0.18 0.96-0.53 1.28s-0.77 0.48-1.25 0.48h-4.2c-0.57 0-1.02-0.17-1.35-0.52-0.34-0.35-0.5-0.76-0.5-1.24v-5.01zm0.41-18.83c0-2.52-0.48-4.92-1.45-7.21s-2.28-4.29-3.95-6-3.62-3.08-5.87-4.1-4.63-1.53-7.16-1.53-4.9 0.51-7.12 1.52c-2.22 1.02-4.16 2.38-5.81 4.09s-2.96 3.71-3.91 6c-0.96 2.29-1.43 4.69-1.43 7.21 0 2.49 0.48 4.88 1.43 7.19 0.95 2.3 2.26 4.33 3.91 6.08s3.59 3.15 5.81 4.2c2.22 1.04 4.59 1.57 7.12 1.57s4.91-0.52 7.16-1.56c2.24-1.04 4.2-2.44 5.87-4.19s2.99-3.78 3.96-6.08c0.95-2.3 1.44-4.7 1.44-7.19z"
        fill="#00D1C7"
      />
      <path
        d="m4.05 411.5c-0.84-0.91-1.6-1.83-2.3-2.75-0.7-0.91-1.18-1.85-1.46-2.82-0.28-0.96-0.26-1.95 0.07-2.96 0.32-1.01 1.05-2.06 2.16-3.16l23.14-21.71c1.76-1.74 3.48-2.36 5.16-1.85 1.67 0.51 3.39 1.49 5.15 2.96 2.51 2.11 5.31 4.35 8.43 6.73 3.11 2.39 6.43 4.61 9.96 6.67s7.24 3.78 11.14 5.16 7.89 2.07 11.98 2.07c4.55 0 8.27-0.5 11.15-1.51s5.16-2.34 6.83-3.98 2.81-3.59 3.42-5.84c0.6-2.24 0.91-4.6 0.91-7.08 0-2.75-0.97-5.2-2.92-7.35s-4.37-4.03-7.24-5.64c-2.88-1.6-5.9-3-9.05-4.19-3.16-1.19-5.94-2.2-8.36-3.03-10.78-3.67-20.25-7.66-28.42-11.97s-15-9.07-20.48-14.3c-5.48-5.22-9.63-11-12.46-17.32-2.83-6.32-4.24-13.33-4.24-21.03 0-8.15 1.77-15.57 5.3-22.26 3.53-6.69 8.48-12.39 14.84-17.1 6.36-4.72 13.98-8.38 22.85-10.99s18.69-3.91 29.47-3.91c5.94 0 12.03 0.65 18.25 1.93 6.22 1.29 12.19 3.05 17.9 5.3s11.03 4.95 15.95 8.11 9.15 6.62 12.68 10.38c1.86 2.11 2.79 4.12 2.78 6.05 0 1.92-1.07 3.94-3.21 6.04l-20.49 21.57c-1.86 1.83-3.74 2.45-5.64 1.85s-3.78-1.72-5.64-3.37c-2.32-2.11-5.18-4.33-8.57-6.67s-7.04-4.19-10.94-5.57c-2.42-0.83-5.53-1.42-9.33-1.79-3.81-0.37-7.55-0.21-11.22 0.48s-6.83 2.02-9.48 3.98c-2.65 1.97-3.97 4.83-3.97 8.59 0 2.47 0.69 4.6 2.09 6.39 1.39 1.79 3.43 3.44 6.13 4.95 2.69 1.51 5.94 2.98 9.75 4.4s8.17 2.91 13.1 4.47c9.47 3.12 18.13 6.37 25.98 9.76 7.85 3.4 14.58 7.41 20.2 12.03 5.62 4.63 9.98 10.15 13.09 16.56s4.66 14.2 4.66 23.36c0 9.62-1.94 18.37-5.79 26.24-3.86 7.88-9.2 14.59-16.03 20.13s-14.89 9.82-24.18 12.84-19.42 4.53-30.38 4.52c-6.88 0-13.7-0.9-20.48-2.69s-13.3-4.2-19.57-7.22c-6.27-3.03-12.17-6.55-17.69-10.59-5.53-3.98-10.52-8.29-14.98-12.87z"
        fill="#00D1C7"
      />
      <path
        d="m381.8 215.98c0-2.29 0.63-3.97 1.88-5.07 1.25-1.09 2.81-1.64 4.67-1.64l37.9 0.01c1.86 0 3.39 0.62 4.6 1.86s1.81 2.86 1.81 4.85l-0.08 222.34c0 1.99-0.56 3.6-1.67 4.85-1.12 1.25-2.7 1.86-4.74 1.86l-37.9-0.01c-2.04 0-3.65-0.55-4.81-1.64s-1.74-2.78-1.74-5.07l0.08-222.34z"
        fill="#00D1C7"
      />
      <path
        d="m569.1 280.19c-0.09-2.11 0.44-3.66 1.6-4.67s2.76-1.51 4.81-1.51l38.73 0.01c4.18 0 6.27 2.06 6.27 6.18l-0.06 155.97c0 4.12-2.09 6.18-6.27 6.18l-24.8-0.01c-2.14 0-4.13-0.76-5.99-2.27s-3.06-3.28-3.62-5.29l-3.9-14.7c-3.53 4.67-7.41 8.61-11.64 11.81s-8.64 5.77-13.24 7.69-9.31 3.32-14.14 4.19-9.66 1.3-14.49 1.3c-3.81 0-7.99-0.42-12.54-1.24-4.55-0.83-9.17-2.27-13.86-4.33s-9.24-4.84-13.65-8.32-8.31-7.9-11.7-13.26-6.13-11.75-8.21-19.17c-2.09-7.42-3.13-16.08-3.13-25.97l0.03-92.75c0-4.03 2.04-6.05 6.13-6.04l37.9 0.01c4.09 0 6.13 2.02 6.13 6.05l-0.03 89.18c0 3.85 0.72 7.51 2.16 10.99s3.48 6.55 6.13 9.21 5.85 4.77 9.61 6.33 8.01 2.34 12.75 2.34c2.23 0 4.99-0.41 8.29-1.23s6.48-2.33 9.55-4.53 5.69-5.17 7.88-8.93c2.18-3.75 3.28-8.56 3.28-14.43l0.02-88.79z"
        fill="#00D1C7"
      />
      <path
        d="m650.21 417.4c-0.74-0.81-1.39-1.69-1.95-2.64s-0.93-1.91-1.11-2.91c-0.19-0.99-0.09-2.01 0.28-3.04 0.37-1.04 1.16-2.14 2.37-3.31l18.82-17.85c1.3-1.26 2.69-1.78 4.18-1.55 1.48 0.23 3.02 1.02 4.6 2.37 2.23 1.9 4.6 3.74 7.1 5.55 2.51 1.81 5.15 3.38 7.94 4.74 2.79 1.35 5.66 2.44 8.64 3.25 2.97 0.81 5.99 1.22 9.06 1.22 3.53 0 6.46-0.29 8.78-0.88 2.32-0.58 4.18-1.4 5.57-2.43 1.39-1.04 2.37-2.25 2.93-3.65 0.56-1.39 0.84-2.91 0.84-4.53 0-1.89-0.35-3.49-1.04-4.8-0.7-1.31-2.02-2.52-3.97-3.65s-4.65-2.28-8.08-3.45c-3.44-1.17-7.85-2.53-13.24-4.06-8.55-2.44-15.93-5.33-22.15-8.67s-11.35-7.26-15.39-11.77-7.03-9.67-8.98-15.49-2.92-12.42-2.92-19.82c0-6.67 1.42-12.85 4.26-18.53 2.83-5.68 6.97-10.57 12.41-14.67s12.12-7.32 20.07-9.66c7.94-2.34 16.98-3.51 27.1-3.51 7.52 0 14.09 0.77 19.71 2.31s10.56 3.48 14.84 5.82c4.27 2.35 8.01 4.9 11.21 7.65s6.15 5.34 8.84 7.78c1.86 1.63 2.85 3.2 2.99 4.74s-0.91 3.25-3.14 5.14l-20.49 17.3c-1.86 1.54-3.39 2.21-4.6 2.03s-2.6-0.9-4.18-2.16-3.18-2.53-4.81-3.79-3.37-2.37-5.22-3.31c-1.86-0.95-3.92-1.72-6.2-2.3-2.28-0.59-4.85-0.88-7.73-0.88-5.29 0-9.22 0.81-11.77 2.43-2.56 1.62-3.83 3.47-3.83 5.54 0 2.08 0.58 3.72 1.74 4.94s3.11 2.35 5.85 3.38c2.74 1.04 6.31 2.17 10.73 3.39 4.41 1.22 9.91 2.87 16.51 4.94 8.92 2.89 16.16 6.34 21.73 10.36 5.57 4.01 9.89 8.37 12.95 13.06s5.15 9.56 6.26 14.61 1.67 10.06 1.67 15.01c0 8.57-1.84 16.16-5.51 22.79s-8.55 12.22-14.64 16.77-13.03 8.02-20.83 10.41-15.88 3.58-24.24 3.58c-5.67 0-11.33-0.41-17-1.22s-11.24-2.28-16.72-4.4-10.77-4.96-15.88-8.53c-5.12-3.62-9.9-8.15-14.36-13.65z"
        fill="#00D1C7"
      />
      <path
        d="m806.21 221.01c0-3.19 0.81-5.87 2.44-8.03 1.62-2.16 4.48-3.24 8.57-3.23l30.65 0.01c3.62 0 6.2 1.01 7.73 3.03s2.3 4.77 2.3 8.24l-0.01 25.64c0 3.85-0.86 6.46-2.58 7.82s-4.2 2.04-7.46 2.04l-30.65-0.01c-3.25 0-5.9-0.66-7.94-1.97-2.04-1.32-3.06-3.9-3.06-7.75l0.01-25.79zm0.12 58.64c0-1.96 0.63-3.43 1.88-4.41s2.58-1.47 3.97-1.47l39.71 0.01c1.67 0 3.06 0.54 4.18 1.61 1.11 1.08 1.67 2.5 1.67 4.27l-0.06 159.66c0 3.92-1.95 5.88-5.85 5.88l-39.71-0.01c-3.9 0-5.85-1.96-5.85-5.88l0.06-159.66z"
        fill="#00D1C7"
      />
      <path
        d="m267.57 315.72c-7.92 0.52-13.5 3.28-17.86 8.56-3.73 4.51-5.57 9.81-5.63 15.77-0.02 2.09 1.44 3.69 3.35 3.82 1.99 0.13 3.56-1.21 3.83-3.27 0.07-0.53 0.11-1.06 0.14-1.6 0.48-7.27 5.2-13.21 11.92-15.08 1.5-0.42 3.03-0.37 4.54-0.63 1.94-0.33 3.14-1.92 3.05-3.95-0.1-2.34-1.91-3.72-3.34-3.62z"
        fill="#00D1C7"
      />
      <path
        d="m347.53 325.1c-4.54-10.46-10.76-19.61-18.65-27.46-7.89-7.84-17.13-14.04-27.73-18.58s-21.91-6.81-33.93-6.81c-7.98 0-15.69 1.03-23.12 3.1-7.43 2.06-14.36 4.95-20.78 8.67-6.42 3.71-12.3 8.21-17.62 13.49s-9.86 11.1-13.63 17.48c-3.76 6.38-6.68 13.26-8.74 20.65s-3.1 15.07-3.1 23.05c0 12.02 2.27 23.28 6.81 33.79s10.76 19.66 18.65 27.46 17.11 13.97 27.66 18.51 21.84 6.81 33.86 6.81 23.31-2.27 33.86-6.81 19.77-10.74 27.66-18.58c7.89-7.85 14.13-17.02 18.72-27.53s6.88-21.72 6.88-33.65c0.01-11.93-2.26-23.13-6.8-33.59zm-65.37 73.27c-0.14 2.66-1.76 4.88-4.18 5.6-0.74 0.22-1.03 0.62-1.19 1.31-0.2 0.86-0.39 1.73-0.66 2.57-0.72 2.19-2.19 3.34-4.39 3.37-2.97 0.04-5.95 0.04-8.93 0-2.15-0.03-3.58-1.17-4.32-3.28-0.12-0.33-0.24-0.67-0.28-1.02-0.21-1.66-0.79-2.78-2.52-3.43-1.99-0.74-3.07-2.62-3.22-4.89-0.1-1.6-0.12-3.21-0.05-4.8 0.1-2.25 1.56-3.69 3.72-3.71 3.7-0.03 7.4-0.01 11.1-0.01 3.61 0 7.23-0.01 10.84 0 2.65 0.01 4.08 1.49 4.11 4.28 0.03 1.34 0.04 2.68-0.03 4.01zm16.92-53.09c-0.95 6.65-3.63 12.47-7.99 17.4-3.82 4.32-6.72 9.15-8 14.94-0.17 0.78-0.32 1.56-0.46 2.35-0.6 3.19-2.58 4.95-5.7 4.99-3.19 0.04-6.38 0.01-9.57 0.01-3.15 0-6.3 0.01-9.45 0-3.39-0.02-5.31-1.69-5.92-5.16-1.07-6.05-3.47-11.42-7.5-15.92-4.31-4.81-7.26-10.34-8.67-16.8-2.9-13.35 2.98-28.23 14.09-35.44 13.98-9.08 31.12-6.51 41.88 6.53 6.4 7.76 8.74 16.92 7.29 27.1z"
        fill="#00D1C7"
      />
    </svg>
  );
};

export default LogoBeta;
