import { gadaSaasApi } from "../base-rtkq-api";
import { Endpoints } from "../common/api/endpoints";
import {
  CustomerTopRequest,
  CustomerTopResponse,
  GetAllMarketplaceStoresResponse,
  GetCurrentStoreRequest,
  GetCurrentStoreResponse,
  GetSaasStoreProductCountRequest,
  GetSaasStoreProductCountResponse,
  GetStoreAddressAreaRequest,
  GetStoreAddressAreaResponse,
  GetStoreOperatingTimeAPIResponse,
  GetStoreRefundSettingRequest,
  GetStoreRefundSettingResponse,
  GetStoreSpecialtyAPIResponse,
  StoreTopRequest,
  StoreTopResponse,
  UpdateStoreRefundSettingRequest,
  UpdateStoreRefundSettingResponse,
  UpdateStoreTopRequest,
  UpsertStoreOperatingTimeAPIRequest,
  UpsertStoreSpecialtyAPIRequest,
} from "./models";

const StoreApi = gadaSaasApi.injectEndpoints({
  endpoints: (build) => ({
    getAllMarketplaceStores: build.query<GetAllMarketplaceStoresResponse, void>(
      {
        query: () => ({
          method: "GET",
          url: Endpoints.GET_ALL_MARKETPLACE_STORES,
        }),
      }
    ),
    getStoreTopSetting: build.query<StoreTopResponse, StoreTopRequest>({
      query: (args) => ({
        method: "GET",
        url: Endpoints.STORE_SETTING,
        queryParams: args,
      }),
      extraOptions: {
        withOffline: true,
      },
      providesTags: ["StoreSetting"],
    }),
    updateStoreTopSetting: build.mutation<void, UpdateStoreTopRequest>({
      query: (args) => ({
        method: "PATCH",
        url: Endpoints.STORE_SETTING,
        data: args,
      }),
      invalidatesTags: ["StoreSetting"],
    }),
    getCustomerTopSetting: build.query<CustomerTopResponse, CustomerTopRequest>(
      {
        query: (args) => ({
          method: "GET",
          url: Endpoints.STORE_SETTING,
          queryParams: args,
        }),
      }
    ),
    getStoreRefundSettings: build.query<
      GetStoreRefundSettingResponse,
      GetStoreRefundSettingRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.STORE_REFUND_SETTINGS,
        routeParams: {
          storeId: args.storeId,
        },
      }),
      providesTags: ["RefundConfig"],
    }),
    updateStoreRefundSettings: build.mutation<
      UpdateStoreRefundSettingResponse,
      UpdateStoreRefundSettingRequest
    >({
      query: (args) => ({
        method: "PUT",
        url: Endpoints.REFUND_SETTINGS,
        data: args,
      }),
      invalidatesTags: ["RefundConfig"],
    }),
    getSaasStoreProductCount: build.query<
      GetSaasStoreProductCountResponse,
      GetSaasStoreProductCountRequest
    >({
      query: (args) => ({
        method: "POST",
        url: Endpoints.GET_INVENTORY_LIST,
        data: args,
      }),
    }),
    getCurrentStore: build.query<
      GetCurrentStoreResponse,
      GetCurrentStoreRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.CURRENT_STORE,
        routeParams: {
          storeId: args.storeId,
        },
      }),
    }),
    getStoreSpecialty: build.query<GetStoreSpecialtyAPIResponse, string>({
      query: (storeId) => ({
        method: "GET",
        url: Endpoints.STORE_SPECIALTY_BY_ID,
        routeParams: {
          storeId,
        },
      }),
      providesTags: ["StoreSpecialtyType"],
    }),
    putStoreSpecialty: build.mutation<
      GetStoreSpecialtyAPIResponse,
      UpsertStoreSpecialtyAPIRequest
    >({
      query: (args) => ({
        method: "PUT",
        url: Endpoints.STORE_SPECIALTY_BY_ID,
        routeParams: args.routeParams,
        data: args.requestPayload,
      }),
      invalidatesTags: ["StoreSpecialtyType"],
    }),
    getStoreOperatingTime: build.query<
      GetStoreOperatingTimeAPIResponse,
      string
    >({
      query: (storeId) => ({
        method: "GET",
        url: Endpoints.STORE_OPERATING_TIME_BY_ID,
        routeParams: {
          storeId,
        },
      }),
      providesTags: ["StoreOperatingTime"],
    }),
    putStoreOperatingTime: build.mutation<
      GetStoreOperatingTimeAPIResponse,
      UpsertStoreOperatingTimeAPIRequest
    >({
      query: (args) => ({
        method: "PUT",
        url: Endpoints.STORE_OPERATING_TIME_BY_ID,
        routeParams: args.routeParams,
        data: args.requestPayload,
      }),
      invalidatesTags: ["StoreOperatingTime"],
    }),
    getStoreAddressArea: build.query<
      GetStoreAddressAreaResponse,
      GetStoreAddressAreaRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.STORE_ADDRESS_AREA,
        queryParams: args,
      }),
    }),
  }),
});

export const {
  useGetAllMarketplaceStoresQuery,
  useLazyGetAllMarketplaceStoresQuery,
  useGetStoreTopSettingQuery,
  useLazyGetStoreTopSettingQuery,
  useUpdateStoreTopSettingMutation,
  useGetCustomerTopSettingQuery,
  useGetSaasStoreProductCountQuery,
  useLazyGetCurrentStoreQuery,
  useLazyGetStoreRefundSettingsQuery,
  useUpdateStoreRefundSettingsMutation,
  useGetStoreSpecialtyQuery,
  usePutStoreSpecialtyMutation,
  useGetStoreOperatingTimeQuery,
  usePutStoreOperatingTimeMutation,
  useGetStoreAddressAreaQuery,
  useLazyGetStoreAddressAreaQuery,
} = StoreApi;

export default StoreApi;
