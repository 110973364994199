import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  SlideProps,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import * as React from "react";
import Button from "../Button/Button";
import { Row } from "../Layout";
import Text from "../Typography/Typography";
import { useStyles } from "./styles";
import { ModalProps } from "./types";

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal: React.VFC<ModalProps> = ({
  open,
  withCloseButton = false,
  withResetButton = false,
  isCenterAligned = false,
  onClickCloseButton,
  onClickResetButton,
  title,
  content,
  primaryButtonText,
  onClickPrimaryButton,
  primaryButtonProps,
  secondaryButtonText,
  onClickSecondaryButton,
  secondaryButtonProps,
  modalProps,
  modalTitleProps,
  modalContentProps,
  modalActionProps = {},
  showFooterShadow = false,
  closeButtonProps = {},
  resetButtonProps,
}: ModalProps) => {
  const classes = useStyles({
    withResetButton,
    withCloseButton,
    showFooterShadow,
  });
  const {
    className: modalActionClassName,
    ...restModelActionProps
  } = modalActionProps;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      {...modalProps}
    >
      {title ? (
        <DialogTitle disableTypography {...modalTitleProps}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={
              isCenterAligned && !withCloseButton ? "center" : "space-between"
            }
          >
            {typeof title === "string" ? (
              <Text variant="h2">{title}</Text>
            ) : (
              title
            )}
            <Row>
              <Button
                variant="text"
                onClick={onClickResetButton}
                className={classes.resetButton}
                {...resetButtonProps}
              >
                Reset
              </Button>

              <IconButton
                {...closeButtonProps}
                onClick={onClickCloseButton}
                className={classes.closeButton}
              >
                <Close />
              </IconButton>
            </Row>
          </Box>
        </DialogTitle>
      ) : null}
      <DialogContent {...modalContentProps}>
        {typeof content === "string" ? (
          <DialogContentText align={isCenterAligned ? "center" : "left"}>
            {content}
          </DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      {secondaryButtonText || primaryButtonText ? (
        <DialogActions
          className={clsx(modalActionClassName, classes.footer)}
          {...restModelActionProps}
        >
          {secondaryButtonText && (
            <Button
              onClick={onClickSecondaryButton}
              variant="secondary"
              fullWidth
              {...secondaryButtonProps}
            >
              {secondaryButtonText}
            </Button>
          )}
          {primaryButtonText && (
            <Button
              onClick={onClickPrimaryButton}
              variant="primary"
              fullWidth
              {...primaryButtonProps}
            >
              {primaryButtonText}
            </Button>
          )}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default Modal;
