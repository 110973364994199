import * as React from "react";
import { IconProps } from "./types";

const CalendarIcon: React.VFC<IconProps & { active?: boolean }> = ({
  active,
  width = "16",
  height = "18",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill={active ? "#05A39B" : "#6B7E7D"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.4 1.8H13.5V0H11.7V1.8H4.5V0H2.7V1.8H1.8C0.81 1.8 0 2.61 0 3.6V16.2C0 17.19 0.81 18 1.8 18H14.4C15.39 18 16.2 17.19 16.2 16.2V3.6C16.2 2.61 15.39 1.8 14.4 1.8ZM14.4 16.2H1.8V7.2H14.4V16.2ZM1.8 5.4V3.6H14.4V5.4H1.8ZM3.6 9H12.6V10.8H3.6V9ZM3.6 12.6H9.9V14.4H3.6V12.6Z" />
    </svg>
  );
};

export default CalendarIcon;
