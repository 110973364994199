import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../common/hooks/useActions";
import { globalResetAction } from "../common/redux/actions";
import { SupplierState } from "./types";

const initialState: SupplierState = {
  showSupplierModal: false,
  supplierEditingPurpose: "update",
  layoutType: "LIST",
  hideBackButton: false,
};
const SupplierSlice = createSlice({
  name: "supplierState",
  initialState,
  reducers: {
    showSupplierModal(
      state,
      action: PayloadAction<"LIST" | "EDIT" | undefined>
    ) {
      state.showSupplierModal = true;
      state.layoutType = !action.payload ? "LIST" : action.payload;
    },
    hideSupplierModal(state) {
      state.showSupplierModal = false;
    },
    setSupplierEditingPurpose(
      state,
      action: PayloadAction<"create" | "update" | "read">
    ) {
      state.supplierEditingPurpose = action.payload;
    },
    setLayoutType(state, action: PayloadAction<"LIST" | "EDIT">) {
      state.layoutType = action.payload;
    },
    setHideBackButton(state, action: PayloadAction<boolean>) {
      state.hideBackButton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

const { actions } = SupplierSlice;

export const useSupplierActions = () => useActions(actions, undefined);

export default SupplierSlice;
