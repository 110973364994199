import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={19}
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 16.88c.04.3-.06.62-.29.83a.996.996 0 01-1.41 0L5.29 13.7a.989.989 0 01-.29-.83V7.75L.21 1.62A1 1 0 01.38.22C.57.08.78 0 1 0h14c.22 0 .43.08.62.22a1 1 0 01.17 1.4L11 7.75v9.13zM3.04 2L7 7.06v5.52l2 2V7.05L12.96 2H3.04z"
        fill="#6B7E7D"
      />
    </svg>
  );
}

export default SvgComponent;
