import { responseRouter as router } from "../../router";
import { Endpoints } from "../../../../../common/api/endpoints";
import {
  GetOfflineTransactionReportResponse,
  GetOfflineTransactionReportRequest,
  GetOfflineTransactionByIdResponse,
  GetOfflineTransactionByIdRequest,
} from "../../../../../report/offline-transaction";

router.get<
  GetOfflineTransactionReportResponse,
  void,
  void,
  GetOfflineTransactionReportRequest
>(Endpoints.GET_OFFLINE_PAYMENT_REPORT, async (_req, resp) => {
  // TODO : Implement save to local db
  return resp;
});

router.get<
  GetOfflineTransactionByIdResponse,
  void,
  GetOfflineTransactionByIdRequest
>(Endpoints.GET_OFFLINE_PAYMENT_REPORT_BY_ID, async (_req, resp) => {
  // TODO : Implement save to local db
  return resp;
});
