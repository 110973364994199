import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "@gada-saas/web-core/common/hooks/useActions";
import { DraftItem, InventoryConversionState } from "./types";
import { globalResetAction } from "../../common/redux/actions";

const initialState: InventoryConversionState = {
  source: {
    showConversionModal: false,
    productVariantId: -1,
    productVariantName: "",
    initialFormState: {
      hierarchy: [],
      conversion: [],
    },
  },
  result: {
    multiplierMap: {},
    smallestUOMId: null,
  },
};

const InventoryConversionSlice = createSlice({
  name: "inventoryConversionState",
  initialState,
  reducers: {
    showConversionModal(
      state,
      action: PayloadAction<{
        productVariantId: number;
        productVariantName: string;
        draft: DraftItem[];
      }>
    ) {
      state.source.showConversionModal = true;
      state.source.initialFormState.hierarchy = action.payload.draft;
      state.source.productVariantId = action.payload.productVariantId;
      state.source.productVariantName = action.payload.productVariantName;
    },
    hideConversionModal(state) {
      state.source.showConversionModal = false;
    },
    setConversionResult(
      state,
      action: PayloadAction<InventoryConversionState["result"]>
    ) {
      state.result = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

const { actions } = InventoryConversionSlice;

export const useInventoryConversionActions = () =>
  useActions(actions, undefined);

export default InventoryConversionSlice;
