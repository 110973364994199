import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { globalResetAction } from "../common/redux/actions";
import { TutorialCode } from "./models";
import { coachMarkChains, CoachMarkInitialState } from "./types";

const initialState: CoachMarkInitialState = {
  chainName: undefined,
  tutorialName: undefined,
  totalTutorialSteps: -1,
};

const coachMarkSlice = createSlice({
  name: "coachMarkSlice",
  initialState,
  reducers: {
    setTutorialSteps(
      state: CoachMarkInitialState,
      {
        payload,
      }: PayloadAction<{
        chainName?: coachMarkChains;
        tutorialName?: TutorialCode;
        totalTutorialSteps: number;
      }>
    ) {
      state.chainName = payload.chainName;
      state.tutorialName = payload.tutorialName;
      state.totalTutorialSteps = payload.totalTutorialSteps;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const { setTutorialSteps } = coachMarkSlice.actions;

export default coachMarkSlice.reducer;

export const initialCoachMarkState = initialState;
