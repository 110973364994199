import {
  ApiResponse,
  create,
  RequestTransform,
  ResponseTransform,
} from "apisauce";
import { ApisauceConfig } from "apisauce";
import { compile } from "path-to-regexp"; // Please take  a look at their github repo
import { camelizeKeys, decamelizeKeys } from "humps";
import getConfig from "next/config";
import qs from "qs";
import { clean } from "./apiSauceBaseQuery";

const { publicRuntimeConfig } = getConfig();
const { host } = publicRuntimeConfig;

const apiSauce = create({ baseURL: host });

type AxiosWrapperParam = Omit<ApisauceConfig, "baseURL"> & {
  routeParams?: Record<string, string>;
  token?: string;
};

export type DefaultSAASErrorType = {
  message: string;
};

const caseRequestParserTransform: RequestTransform = (request) => {
  if (request.headers["Content-Type"] === "multipart/form-data") return request;
  if (request.params) {
    request.params = decamelizeKeys(request.params);
  }
  if (request.data) {
    request.data = decamelizeKeys(request.data);
  }
  return request;
};

const caseResponseParserTransform: ResponseTransform = (response) => {
  if (
    response.data &&
    response.headers &&
    response.headers["content-type"] === "application/json"
  ) {
    response.data = camelizeKeys(response.data);
  }
  return response;
};

apiSauce.addRequestTransform(caseRequestParserTransform);
apiSauce.addResponseTransform(caseResponseParserTransform);

// See : https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
const ABSOLUTE_URL_PATTERN = new RegExp("(?:^[a-z][a-z0-9+.-]*:|//)");

type RequestWrapperReturnType<S, E> = Omit<ApiResponse<S, E>, "data"> & {
  data?: S;
  error?: E;
};

export function request<
  SuccessResponseType = unknown,
  ErrorType = DefaultSAASErrorType
>(
  params: AxiosWrapperParam
): Promise<RequestWrapperReturnType<SuccessResponseType, ErrorType>> {
  const { routeParams = undefined } = params;

  const isAbsoluteURL = ABSOLUTE_URL_PATTERN.test(params.url as string);

  return apiSauce
    .any<SuccessResponseType, ErrorType>({
      ...params,
      url: isAbsoluteURL
        ? params.url
        : compile(params.url || "", { encode: encodeURIComponent })(
            routeParams
          ),
      paramsSerializer: (params) =>
        qs.stringify(clean(params), { arrayFormat: "repeat" }),
    })
    .then((res) => {
      return {
        ...res,
        data: res.ok ? (res.data as SuccessResponseType) : undefined,
        error: !res.ok ? (res.data as ErrorType) : undefined,
      };
    });
}
