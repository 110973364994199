import {
  useUserMenusState,
  UserStoreRole,
  useCachedUserInfo,
} from "@gada-saas/web-core";
import { useMemo } from "react";

function useUserStoreRole(): string {
  const { data: userInfoData } = useCachedUserInfo();
  const { activeStore } = useUserMenusState();

  return useMemo(() => {
    if (userInfoData) {
      const userStores = userInfoData.userStoreList;
      const role = userStores.find((s) => s.storeId === activeStore.storeId)
        ?.storeRoleList[0].name;
      return role
        ? role === UserStoreRole["OWNER"]
          ? "OWNER"
          : "EMPLOYEE"
        : "";
    }
    return "";
  }, [activeStore.storeId, userInfoData]);
}

export default useUserStoreRole;
