import * as React from "react";

function SortIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.916 14.85a.943.943 0 001.31 0l3.464-3.338a.944.944 0 10-1.31-1.36l-1.864 1.796V.945a.944.944 0 10-1.89 0v11.003l-1.863-1.796a.944.944 0 10-1.31 1.36l3.463 3.338zM5.052.277a.945.945 0 00-1.335 0L.254 3.741a.945.945 0 001.335 1.335L3.44 3.224V14.17a.944.944 0 101.89 0V3.224l1.85 1.852A.945.945 0 008.517 3.74L5.052.277z"
        fill="#6B7E7D"
      />
    </svg>
  );
}

export default SortIcon;
