import * as React from "react";
import { useReferrer } from "@common/hooks/useReferrer/useReferrer";
import { createContext, ReactNode, useContext, useState } from "react";
import { useSegmentAnalytics } from "../hooks/useSegmentAnalytics";

export interface PageTrackingDetail {
  name?: string;
  referrer?: string;
  setPageName: (pageName?: string) => void;
}

const PageNameTrackingContext = createContext<PageTrackingDetail>({
  setPageName: () => {
    /* do nothing */
  },
});

type PageNameTrackingProviderProps = {
  children?: ReactNode;
};

export function PageNameTrackingDataProvider(
  props: PageNameTrackingProviderProps
) {
  const analytics = useSegmentAnalytics();
  const referrer = useReferrer();
  const [pageName, setPageName] = useState<string | undefined>();

  React.useEffect(() => {
    if (!pageName) return;

    analytics.page(pageName);
  }, [analytics, pageName]);

  return (
    <PageNameTrackingContext.Provider
      value={{
        referrer,
        setPageName,
        name: pageName,
      }}
    >
      {props.children}
    </PageNameTrackingContext.Provider>
  );
}

export function usePageNameTrackingDataContext() {
  return useContext(PageNameTrackingContext);
}
