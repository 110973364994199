import { coachMarkChains, TutorialCode } from "@gada-saas/web-core";
import { AppTheme } from "@gada-saas/web-ui";
import { useTheme } from "@material-ui/core";
import React from "react";
import { CoachMarkChainStepsType } from "./types";

export const coachMarkChainSteps: CoachMarkChainStepsType = {
  NO_INVENTORY: [
    TutorialCode.ADD_INVENTORY_INTRO,
    TutorialCode.ADD_INVENTORY_SEARCH_PRODUCT,
    TutorialCode.ADD_INVENTORY_CHOOSE_STOCK_UNIT,
    TutorialCode.ADD_INVENTORY_CHOOSE_SELLING_UNIT,
  ],

  HAS_INVENTORY_POS: [
    TutorialCode.POS_INTRO,
    TutorialCode.POS_SELECT_PAYMENT_METHOD,
    TutorialCode.POS_ORDER_COMPLETE,
  ],
  HAS_INVENTORY_LIST: [
    TutorialCode.INVENTORY_LIST_SEE_CURRENT_STOCK,
    TutorialCode.INVENTORY_LIST_EDIT_STOCK_AMOUNT,
    TutorialCode.INVENTORY_LIST_RECOUNT_STOCK,
  ],
  HAS_TRANSACTION: [TutorialCode.REPORTING_AFTER_ORDER_COMPLETE],
};

function useCoachMark() {
  const theme = useTheme<AppTheme>();
  
  const getCoachMarkChainInfo = React.useCallback(
    (chain: coachMarkChains, currentStep: TutorialCode) => {
      let curStepIdx: number = -1;
      let obj: {
        fullChain: TutorialCode[];
        pastSteps: TutorialCode[];
        futureSteps: TutorialCode[];
      } = {
        fullChain: [],
        pastSteps: [],
        futureSteps: [],
      };

      switch (chain) {
        case coachMarkChains.NO_INVENTORY:
          obj.fullChain = coachMarkChainSteps[coachMarkChains.NO_INVENTORY];

          curStepIdx = obj.fullChain.findIndex((item) => item === currentStep);

          if (curStepIdx !== -1) {
            obj.pastSteps = obj.fullChain.slice(0, curStepIdx);
            obj.futureSteps = obj.fullChain.slice(curStepIdx + 1);
          }

          break;
        case coachMarkChains.HAS_INVENTORY_LIST:
          obj.fullChain =
            coachMarkChainSteps[coachMarkChains.HAS_INVENTORY_LIST];

          curStepIdx = obj.fullChain.findIndex((item) => item === currentStep);

          if (curStepIdx !== -1) {
            obj.pastSteps = obj.fullChain.slice(0, curStepIdx);
            obj.futureSteps = obj.fullChain.slice(curStepIdx + 1);
          }

          break;
        case coachMarkChains.HAS_INVENTORY_POS:
          obj.fullChain =
            coachMarkChainSteps[coachMarkChains.HAS_INVENTORY_POS];

          curStepIdx = obj.fullChain.findIndex((item) => item === currentStep);

          if (curStepIdx !== -1) {
            obj.pastSteps = obj.fullChain.slice(0, curStepIdx);
            obj.futureSteps = obj.fullChain.slice(curStepIdx + 1);
          }

          break;
        case coachMarkChains.HAS_TRANSACTION:
          obj.fullChain = coachMarkChainSteps[coachMarkChains.HAS_TRANSACTION];

          curStepIdx = obj.fullChain.findIndex((item) => item === currentStep);

          if (curStepIdx !== -1) {
            obj.pastSteps = obj.fullChain.slice(0, curStepIdx);
            obj.futureSteps = obj.fullChain.slice(curStepIdx + 1);
          }

          break;

        default:
          break;
      }
      return obj;
    },
    []
  );

  const opositeSide: any = {
    top: "bottom",
    bottom: "top",
    right: "left",
    left: "right",
  };

  function doArrow(position: any, verticalAlign: any, horizontalAlign: any) {
    if (!position || position === "custom") {
      return {};
    }

    const width = 16;
    const height = 12;
    const color = theme.palette.interface.neutral.dark;
    const isVertical = position === "top" || position === "bottom";
    const spaceFromSide = 10;

    const obj = {
      [`--rtp-arrow-${
        isVertical ? opositeSide[horizontalAlign] : verticalAlign
      }`]: height + spaceFromSide + "px",
      [`--rtp-arrow-${opositeSide[position]}`]: -height + 2 + "px",
      [`--rtp-arrow-border-${isVertical ? "left" : "top"}`]: `${
        width / 2
      }px solid transparent`,
      [`--rtp-arrow-border-${isVertical ? "right" : "bottom"}`]: `${
        width / 2
      }px solid transparent`,
      [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
    };
    return obj;
  }

  return {
    getCoachMarkChainInfo,
    doArrow,
  };
}

export default useCoachMark;
