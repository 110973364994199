import * as React from "react";
import { IconProps } from "./types";

const ExchangeIcon: React.VFC<IconProps> = ({
  width = "25",
  height = "24",
  fill = "none",
}) => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m15.5 12 5-4-5-4v2.999h-13v2h13v3.001zm7 3h-13v-3l-5 4 5 4v-3h13v-2z"
        fill="#6B7E7D"
      />
    </svg>
  );
};

export default ExchangeIcon;
