import { iDeliveryFeasibility, iDeliveryOrderResponse } from "../common/models";
import { iDeliverOrderItem, iDeliveryOrderData } from "./types";

export const mapFeasibilityToDeliveryOrder = (
  payloadData: iDeliveryFeasibility[],
  deliveryOrderData: iDeliveryOrderResponse | undefined
): iDeliveryOrderData[] => {
  const result: iDeliveryOrderData[] = [];
  payloadData.forEach((order) => {
    const deliveryOrder = deliveryOrderData?.deliveryOrderList.find(
      (_deliveryOrder) => _deliveryOrder.orderId === order.orderId
    );
    const _order = {
      ...order,
      orderItemList: order.orderItemList.map((orderItem) => {
        const deliveryItem = deliveryOrder?.deliveryItemList.find(
          (_deliveryItem) => _deliveryItem.orderItemId === orderItem.id
        );
        return {
          ...orderItem,
          selectedQuantity: deliveryOrder
            ? deliveryItem
              ? deliveryItem.deliveryQuantity
              : 0
            : orderItem.availableDeliveryQuantity,
          maxAvailable: deliveryItem
            ? orderItem.availableDeliveryQuantity +
              deliveryItem.deliveryQuantity
            : orderItem.availableDeliveryQuantity,
        } as iDeliverOrderItem;
      }),
    } as iDeliveryOrderData;
    result.push(_order);
  });
  return result;
};
