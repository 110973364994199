import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { StockStatusInterface } from "./types";

const initialStockStatusState: StockStatusInterface[] = [];

export const ChangeInventoryStatusSlice = createSlice({
  name: "changeInventoryStatus",
  initialState: initialStockStatusState,
  reducers: {
    resetState: () => {
      return initialStockStatusState;
    },
    addInventory: (state, action: PayloadAction<StockStatusInterface>) => {
      const existingInventory = state.find(
        (inventory) =>
          inventory.productVariantId === action.payload.productVariantId
      );

      if (!existingInventory) {
        state.push(action.payload);
      }
    },
    removeInventory: (state, action: PayloadAction<number>) => {
      const index = state.findIndex(
        (item) => item.productVariantId === action.payload
      );
      if (index > -1) {
        state.splice(index, 1);
      }
    },
    updateInventory: (state, action: PayloadAction<StockStatusInterface>) => {
      const index = state.findIndex(
        (item) => item.productVariantId === action.payload.productVariantId
      );
      if (index > -1) {
        state[index] = action.payload;
      }
    },
  },
});

const { actions } = ChangeInventoryStatusSlice;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useChangeInventoryStatusStateActions = () => useActions(actions);

export default ChangeInventoryStatusSlice.reducer;
