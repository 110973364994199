import { useSnackbar } from "notistack";
import * as React from "react";
import { keys } from "lodash";
import { ErrorMessages } from "@common/constants";
import { SerializedError } from "@reduxjs/toolkit";
import { iApiErrorHandlerReturnType } from "./types";
import { iBaseQueryErrorResponse } from "@gada-saas/web-core/common/api";
import { useStockOpnameActions } from "@gada-saas/web-core";

const useApiErrorHandler = <E = unknown>() => {
  const { enqueueSnackbar } = useSnackbar();
  const StockOpnameActions = useStockOpnameActions();

  const handleError = React.useCallback(
    (
      error?: iBaseQueryErrorResponse<E> | SerializedError | null,
      preferSnack: boolean = false,
      showSnackBar: boolean = true
    ): iApiErrorHandlerReturnType => {
      if (!error) return null;

      const apiError = error as iBaseQueryErrorResponse;
      // check for form errors
      if (apiError?.formErrors) {
        if (showSnackBar) {
          enqueueSnackbar(apiError?.formErrors.schema, {
            variant: "error",
          });
        }
        return null;
      }

      // check for extension errors
      if (apiError?.data?.data?.schemaErrors) {
        if (apiError?.data?.data?.schemaErrors?.inventories) {
          return null;
        }
        if (apiError?.data?.data?.schemaErrors?.extensions) {
          apiError?.data?.data?.schemaErrors?.extension?.forEach((error) => {
            if (showSnackBar) {
              enqueueSnackbar(error, {
                variant: "error",
              });
            }
          });
        } else {
          const schemaErrors = apiError?.data?.data?.schemaErrors;
          const errorKeys = keys(schemaErrors);
          for (let i = 0; i < errorKeys.length; i++) {
            schemaErrors[errorKeys[i]]?.forEach((msg) => {
              if (showSnackBar) {
                enqueueSnackbar(`${errorKeys}: ${msg}`, {
                  variant: "error",
                });
              }
            });
          }
        }
        return null;
      }

      if (apiError?.data?.data?.message && preferSnack) {
        if (showSnackBar) {
          enqueueSnackbar(apiError?.data?.data.message, {
            variant: "error",
          });
        }
        return null;
      }

      if (
        (error as SerializedError).stack &&
        (error as SerializedError).name !== "TypeError"
      ) {
        // A SerializedError
        enqueueSnackbar((error as SerializedError)?.message, {
          variant: "error",
        });
        return null;
      }

      if (apiError?.data?.problem === "NETWORK_ERROR") {
        if (showSnackBar) {
          enqueueSnackbar(ErrorMessages.noInternet, {
            variant: "error",
          });
        }
        return { status: apiError.status, problem: apiError.data.problem };
      }

      const errorsData = apiError.data?.data;
      const schemaErrors =
        (errorsData?.errors?.schemaErrors as Record<string, string[]>) ??
        errorsData?.schemaErrors;
      if (!preferSnack && schemaErrors) {
        const fields: Record<string, string> = {};
        const errorKeys = keys(schemaErrors);
        errorKeys.forEach((errorField) => {
          fields[errorField] = schemaErrors[errorField][0];
        });
        return {
          status: apiError.status,
          message: errorsData?.message,
          formErrors: fields,
        };
      }
      if (errorsData?.errors) {
        const errorsObj = errorsData.errors as Record<string, string>;
        const errorKeys = keys(errorsObj);
        for (let i = 0; i < errorKeys.length; i++) {
          if (typeof errorsObj[errorKeys[i]] === "string") {
            if (showSnackBar) {
              enqueueSnackbar(`[${errorKeys[i]}] ${errorsObj[errorKeys[i]]}`, {
                variant: "error",
              });
            }
            break;
          }
        }
      }

      // error handling when stock opname in progress
      if (
        errorsData?.errorCode === "STOCK_RECOUNT_IN_PROGRESS" ||
        errorsData?.errorCode ===
          "STOCK_RECOUNT_IN_PROGRESS_ALLOWED_SEE_CURRENT"
      ) {
        StockOpnameActions.setStockOpnameModal(true);
      }

      if (errorsData?.message) {
        if (showSnackBar) {
          enqueueSnackbar(errorsData.message, { variant: "error" });
        }
      }

      return {
        status: apiError.status,
        message: errorsData?.message,
        errorCode: errorsData?.errorCode,
        data: errorsData?.data,
      };
    },
    [StockOpnameActions, enqueueSnackbar]
  );

  return [handleError];
};

export default useApiErrorHandler;
