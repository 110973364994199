export enum PERMISSION_LIST {
  COMMON = "Common",
  READ_BANK = "Read Bank",
  CASHIER = "Catat Penjualan",
  ADD_INVENTORY = "Tambah Produk",
  UPDATE_INVENTORY = "Ubah Produk",
  READ_INVENTORY = "Lihat Produk",
  DELETE_INVENTORY = "Hapus Produk",
  CREATE_EMPLOYEE = "Tambah Karyawan",
  UPDATE_EMPLOYEE = "Ubah Karyawan",
  READ_EMPLOYEE = "Lihat Karyawan",
  DELETE_EMPLOYEE = "Hapus Karyawan",
  READ_REPORT = "Lihat Laporan",
  PURCHASE = "Pembelian",
  MANAGE_DISCOUNT = "Kelola Diskon",
}
