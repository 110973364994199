import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useActions } from "../../common/hooks/useActions";
import { InventoryPriceEditorState } from "./types";

const initialState: InventoryPriceEditorState = {
  productVariantId: 0,
  productVariantName: "",
  unitOfMeasurementId: 0,
  unitOfMeasurementName: "",
  inventoryId: undefined,
  productUnitId: undefined,
  showPriceEditorModal: false,
  initialFormState: {
    priceTiers: [],
    isMultiple: false,
  },
};

const PricingSlice = createSlice({
  initialState,
  name: "priceEditorState",
  reducers: {
    showPriceEditor(
      state,
      action: PayloadAction<
        Partial<Omit<InventoryPriceEditorState, "showPriceEditorModal">>
      >
    ) {
      state.initialFormState = action.payload.initialFormState
        ? action.payload.initialFormState
        : initialState.initialFormState;
      state.productVariantId = action.payload.productVariantId
        ? action.payload.productVariantId
        : initialState.productVariantId;
      state.unitOfMeasurementId = action.payload.unitOfMeasurementId
        ? action.payload.unitOfMeasurementId
        : initialState.unitOfMeasurementId;
      state.unitOfMeasurementName = action.payload.unitOfMeasurementName
        ? action.payload.unitOfMeasurementName
        : initialState.unitOfMeasurementName;
      state.inventoryId = action.payload.inventoryId
        ? action.payload.inventoryId
        : initialState.inventoryId;
      state.showPriceEditorModal = true;
    },
    hidePriceEditor(state) {
      state.showPriceEditorModal = false;
    },
  },
});

const { actions } = PricingSlice;

export const usePriceEditorActions = () => useActions(actions, undefined);

export default PricingSlice;
