import { iProductUnit, iUOM } from "../models";
import {
  ConversionErrorMessages,
  ConversionItem,
  DraftItem,
  IntegrityErrorMessage,
} from "../slices/InventoryFormSlice";
import {
  CuratedProductDetailAPIResponse,
  InventoryProductDetail,
} from "@gada-saas/web-core/inventory";
import uniqBy from "lodash/uniqBy";

/**
 * Ensure that a hierarchy is sorted, please check the test case
 * @param {iProductUnit[]} hierarchy - Server Hierarchy
 * @returns {iPrdoductUnit[]} Sorted Hierarchy
 */
export type SimpleProductUnit = {
  id: number;
  smallerProductUnitId?: number | null;
  multiplierToSmallerProductUnit?: number | null;
  unitOfMeasurement: {
    storeId: string;
    longName: string;
    id: number;
    shortName: string;
  };
};

export function sortHierarchy(
  hierarchy: SimpleProductUnit[]
): SimpleProductUnit[] {
  if (hierarchy.length === 0) {
    return [];
  }

  const smallerIdToArrIdxMap: {
    [id: number]: number;
  } = {};
  let smallestIdx = 0;

  for (let i = 0; i < hierarchy.length; i++) {
    // Find the smallest ProductUnit(PU)
    if (hierarchy[i].smallerProductUnitId === null) {
      smallestIdx = i;
    }

    // At the same time construct hashmap
    smallerIdToArrIdxMap[hierarchy[i].smallerProductUnitId as number] = i;
  }

  const sortedArray = [hierarchy[smallestIdx]];

  // One by one insert the element at the beginning of array
  let insertedCount = 0;
  while (insertedCount < hierarchy.length - 1) {
    const head = sortedArray[0];
    // Find the index of element who has smallerId equal to head's id
    const indexOfParentNode = smallerIdToArrIdxMap[head.id];

    // insert the element as new head of sorted array
    sortedArray.unshift(hierarchy[indexOfParentNode]);
    insertedCount++;
  }

  return sortedArray;
}

export function sortProductUnitHierarchy(
  hierarchy: iProductUnit[]
): iProductUnit[] {
  if (hierarchy.length === 0) {
    return [];
  }

  const smallerIdToArrIdxMap: {
    [id: number]: number;
  } = {};
  let smallestIdx = 0;

  for (let i = 0; i < hierarchy.length; i++) {
    // Find the smallest ProductUnit(PU)
    if (hierarchy[i].smallerProductUnitId === null) {
      smallestIdx = i;
    }

    // At the same time construct hashmap
    smallerIdToArrIdxMap[hierarchy[i].smallerProductUnitId as number] = i;
  }

  const sortedArray = [hierarchy[smallestIdx]];

  // One by one insert the element at the beginning of array
  let insertedCount = 0;
  while (insertedCount < hierarchy.length - 1) {
    const head = sortedArray[0];
    // Find the index of element who has smallerId equal to head's id
    const indexOfParentNode = smallerIdToArrIdxMap[head.id];

    // insert the element as new head of sorted array
    sortedArray.unshift(hierarchy[indexOfParentNode]);
    insertedCount++;
  }

  return sortedArray;
}

export type MultiplierMap = {
  [uomId: number]: {
    [uomId: number]: number;
  };
};

export function sortProductDetailInventories(
  inventories: CuratedProductDetailAPIResponse["inventories"]
): InventoryProductDetail[] {
  if (inventories.length == 0) {
    return [];
  }

  const smallerIdToArrIdxMap: {
    [id: number]: number;
  } = {};
  let smallestIdx = 0;

  for (let i = 0; i < inventories.length; i++) {
    // Find the smallest ProductUnit(PU)
    if (inventories[i].conversion.smallerProductUnitId === null) {
      smallestIdx = i;
    }

    // At the same time construct hashmap
    smallerIdToArrIdxMap[inventories[i].conversion.smallerProductUnitId] = i;
  }

  const sortedArray = [inventories[smallestIdx]];

  // One by one insert the element at the beginning of array
  let insertedCount = 0;
  while (insertedCount < inventories.length - 1) {
    const head = sortedArray[0];
    // Find the index of element who has smallerId equal to head's id
    const indexOfParentNode = smallerIdToArrIdxMap[head.productUnitId];

    // insert the element as new head of sorted array
    sortedArray.unshift(inventories[indexOfParentNode]);
    insertedCount++;
  }

  return sortedArray;
}

export function generateMultiplierMap(
  conversion: ConversionItem[]
): MultiplierMap {
  const result: MultiplierMap = {};
  for (let i = 0; i < conversion.length; i++) {
    const currMultiplier: {
      [uomId: number]: number;
    } = {};
    let accumulatedMultiplier = 1;
    for (let j = i; j < conversion.length; j++) {
      const currConversion = conversion[j];
      const { multiplierToSmallerProductUnit } = currConversion;

      // Avoid multiplying with undefined
      const multiplier =
        typeof multiplierToSmallerProductUnit === "number"
          ? multiplierToSmallerProductUnit
          : 1;

      accumulatedMultiplier = accumulatedMultiplier * multiplier;

      currMultiplier[
        currConversion.smallerUnitOfMeasurementId
      ] = accumulatedMultiplier;
    }

    result[conversion[i].unitOfMeasurementId] = currMultiplier;
  }

  return result;
}

export function generateServerMultiplierMap(
  serverHierachy: iProductUnit[]
): MultiplierMap {
  const result: MultiplierMap = {};
  for (let i = 0; i < serverHierachy.length; i++) {
    const currMultiplier: {
      [uomId: number]: number;
    } = {};

    let accumulatedMultiplier = 1;

    for (let j = i; j < serverHierachy.length; j++) {
      const currProductUnit = serverHierachy[j];
      const {
        multiplierToSmallerProductUnit,
        smallerProductUnitId,
      } = currProductUnit;

      // Avoid multiplying with undefined
      const multiplier =
        typeof multiplierToSmallerProductUnit === "number"
          ? multiplierToSmallerProductUnit
          : 1;

      accumulatedMultiplier = accumulatedMultiplier * multiplier;

      const smallerProductUnit = serverHierachy.find(
        (h) => h.id === smallerProductUnitId
      );

      // if exist, there is possiblity smallerProductUnitId is null
      if (smallerProductUnit) {
        // set the value to be
        const smallerUomId = smallerProductUnit.unitOfMeasurement.id;

        currMultiplier[smallerUomId] = accumulatedMultiplier;
      }
    }

    result[serverHierachy[i].unitOfMeasurement.id] = currMultiplier;
  }

  return result;
}

type ConversionMapItem = {
  storeId: string;
  longName: string;
  id: number;
  shortName: string;
  immediateChildUOM: {
    storeId: string;
    longName: string;
    id: number;
    shortName: string;
    conversionRate: number;
  } | null;
};

export function generateServerConversionMap(
  serverHierachy: iProductUnit[]
): ConversionMapItem[] {
  const conversionMap: ConversionMapItem[] = [];

  for (let i = 0; i < serverHierachy.length; i++) {
    const conversionItem: ConversionMapItem = {
      ...serverHierachy[i].unitOfMeasurement,
      immediateChildUOM: null,
    };

    if (serverHierachy[i].smallerProductUnitId) {
      const smallerProductUnit = serverHierachy.find(
        (item) => item.id === serverHierachy[i].smallerProductUnitId
      );

      if (smallerProductUnit && smallerProductUnit.unitOfMeasurement) {
        conversionItem.immediateChildUOM = {
          ...smallerProductUnit.unitOfMeasurement,
          conversionRate: serverHierachy[i].multiplierToSmallerProductUnit || 0,
        };
      }
    }

    conversionMap.push(conversionItem);
  }

  return conversionMap;
}

export function generateProductDetailMultiplierMap(
  productDetail: CuratedProductDetailAPIResponse
): MultiplierMap {
  const inventories = productDetail.inventories;
  if (inventories.length === 0) {
    return {};
  }

  // STEP 1 : sort hierachy (ensure integrity)
  const sortedInventories = sortProductDetailInventories(inventories);

  // STEP 2 : generate
  const result: MultiplierMap = {};
  for (let i = 0; i < sortedInventories.length; i++) {
    const currMultiplier: {
      [uomId: number]: number;
    } = {};

    let accumulatedMultiplier = 1;
    const currUomId =
      sortedInventories[i].sellable?.uomId || sortedInventories[i].stock?.uomId;

    for (let j = i; j < sortedInventories.length; j++) {
      const currProductUnit = sortedInventories[j];
      const {
        conversion: { multiplierToSmallerProductUnit, smallerProductUnitId },
      } = currProductUnit;

      // Avoid multiplying with undefined
      const multiplier =
        typeof multiplierToSmallerProductUnit === "number"
          ? multiplierToSmallerProductUnit
          : 1;

      accumulatedMultiplier = accumulatedMultiplier * multiplier;

      const smallerProductUnit = sortedInventories.find(
        (h) => h.productUnitId === smallerProductUnitId
      );

      // if exist, there is possiblity smallerProductUnitId is null
      if (smallerProductUnit) {
        // set the value to be
        const smallerUomId =
          smallerProductUnit.sellable?.uomId || smallerProductUnit.stock?.uomId;

        currMultiplier[smallerUomId] = accumulatedMultiplier;
      }
    }

    result[currUomId] = currMultiplier;
  }

  return result;
}

export function generateConversion(
  draft: DraftItem[],
  hierarchy: SimpleProductUnit[]
): ConversionItem[] {
  const conversion = [];
  const serverHierarchy = sortHierarchy(hierarchy);

  for (let i = 0; i < draft.length - 1; i++) {
    const currDraftItem = draft[i];
    const nextDraftItem = draft[i + 1];

    const serverItem = serverHierarchy.find((h) => {
      if (!h) {
        return false;
      }

      return h.id == currDraftItem.id;
    });

    const hasDefaultMultiplier =
      currDraftItem.id !== undefined && nextDraftItem.id !== undefined;

    const conversionItem: ConversionItem = {
      productUnitId: currDraftItem.id,
      smallerProductUnitId: nextDraftItem.id,
      multiplierToSmallerProductUnit:
        hasDefaultMultiplier && serverItem !== undefined
          ? (serverItem.multiplierToSmallerProductUnit as number)
          : 0,
      unitOfMeasurementId: currDraftItem.unitOfMeasurement.id,
      smallerUnitOfMeasurementId: nextDraftItem.unitOfMeasurement.id,
      unitOfMeasurementName: currDraftItem.unitOfMeasurement.longName,
      smallerUnitOfMeasurementName: nextDraftItem.unitOfMeasurement.longName,
    };

    conversion.push(conversionItem);
  }
  return conversion;
}

export function validateNonZeroValues(
  conversion: ConversionItem[]
): {
  isValid: boolean;
  errorMessages: ConversionErrorMessages;
} {
  const errorMessages: ConversionErrorMessages = {};

  /**
   * Check Non emtpy
   */
  for (let i = 0; i < conversion.length; i++) {
    const item = conversion[i];
    if (item.multiplierToSmallerProductUnit < 2) {
      errorMessages[i] = "Harus lebih besar dari 1";
    } else if (!Number.isInteger(item.multiplierToSmallerProductUnit)) {
      errorMessages[i] = "Tidak boleh bilangan pecahan";
    }
  }

  return {
    isValid: Object.values(errorMessages).length === 0,
    errorMessages,
  };
}

function generateIdToIndexLUT(conversion: ConversionItem[]) {
  // Lookup table Id of product unit to it's index/array position in conversion table
  const FromUOMIdLUT: {
    [id: number]: number;
  } = {};

  const ToUOMIdLUT: {
    [id: number]: number;
  } = {};

  for (let i = 0; i < conversion.length; i++) {
    const { unitOfMeasurementId, smallerUnitOfMeasurementId } = conversion[i];
    if (typeof unitOfMeasurementId === "number") {
      const id = unitOfMeasurementId;
      FromUOMIdLUT[id] = i;
    }

    if (typeof smallerUnitOfMeasurementId === "number") {
      const id = smallerUnitOfMeasurementId;
      ToUOMIdLUT[id] = i;
    }
  }
  return [FromUOMIdLUT, ToUOMIdLUT];
}

export function validateServerIntegrity(
  conversion: ConversionItem[],
  unsortedServerHierachy: iProductUnit[]
): {
  isValid: boolean;
  errorMessages: ConversionErrorMessages;
  integrityErrorMessages: IntegrityErrorMessage[];
} {
  const serverHierarchy = sortProductUnitHierarchy(unsortedServerHierachy);
  const multiplicationMap = generateMultiplierMap(conversion);
  const serverMultiplicationMap = generateServerMultiplierMap(serverHierarchy);
  const [FromUOMIdToArrIdxLUT, ToUOMIdToArrIdxLUT] = generateIdToIndexLUT(
    conversion
  );

  // Step 1 : find any product unit that included in conversion
  const includedUoms: Array<iUOM> = [];
  for (let i = 0; i < serverHierarchy.length; i++) {
    const currServerHierarchy = serverHierarchy[i];
    const includedProductUnit = conversion.find((c) => {
      return (
        c.productUnitId === currServerHierarchy.id ||
        c.smallerProductUnitId === currServerHierarchy.id
      );
    });

    // If found, include it in 'includedUoms'
    if (includedProductUnit) {
      includedUoms.push(currServerHierarchy.unitOfMeasurement);
    }
  }

  // Step 2 find the mismatch
  const integrityErrorMessages: Array<IntegrityErrorMessage> = [];
  for (let i = 0; i < includedUoms.length - 1; i++) {
    const uom = includedUoms[i];
    const smallerUom = includedUoms[i + 1];

    if (
      serverMultiplicationMap[uom.id][smallerUom.id] !==
      multiplicationMap[uom.id][smallerUom.id]
    ) {
      integrityErrorMessages.push({
        from: uom.id,
        to: smallerUom.id,
        fromName: uom.longName,
        toName: smallerUom.longName,
        serverMultiplier: serverMultiplicationMap[uom.id][smallerUom.id],
      });
    }
  }

  // Step 3 : Find any indices in between those integrityErrorMessages
  const errorMessages: ConversionErrorMessages = {};
  for (let i = 0; i < integrityErrorMessages.length; i++) {
    const { from, to } = integrityErrorMessages[i];
    const start = FromUOMIdToArrIdxLUT[from];
    const end = ToUOMIdToArrIdxLUT[to];
    for (let j = start; j <= end; j++) {
      errorMessages[j] = " ";
    }
  }

  return {
    isValid: Object.values(errorMessages).length === 0,
    errorMessages,
    integrityErrorMessages: integrityErrorMessages,
  };
}

export function getSmallestUOMId(draft: DraftItem[]): number {
  if (draft.length === 0) {
    return -1;
  }

  return draft[draft.length - 1].unitOfMeasurement.id;
}

export function getSmallestUOMName(draft: DraftItem[]): string | null {
  if (draft.length === 0) {
    return null;
  }

  return draft[draft.length - 1].unitOfMeasurement.shortName;
}

export function getSmallestUOMIdFromProductUnitAPI(
  productUnits: iProductUnit[]
): number {
  if (productUnits.length === 0) {
    return -1;
  }

  const sortedProductUnit = sortProductUnitHierarchy(productUnits);

  return sortedProductUnit[sortedProductUnit.length - 1].unitOfMeasurement.id;
}

export function getSmallestUOMNameFromProductUnitAPI(
  productUnits: iProductUnit[]
): string | null {
  if (productUnits.length === 0) {
    return null;
  }

  const sortedProductUnit = sortProductUnitHierarchy(productUnits);

  return sortedProductUnit[sortedProductUnit.length - 1].unitOfMeasurement
    .shortName;
}

export function getSmallestProductDetailUOMId(
  productDetail: CuratedProductDetailAPIResponse
): number {
  const inventories = productDetail.inventories;
  if (inventories.length === 0) {
    return -1;
  }

  const sorted = sortProductDetailInventories(inventories);
  return (
    sorted[sorted.length - 1].sellable?.uomId ||
    sorted[sorted.length - 1].stock?.uomId ||
    0
  );
}

export function countTotalStockInSmallestUOMFromProductDetail(
  productDetail: CuratedProductDetailAPIResponse,
  multiplierMap: MultiplierMap,
  smallestUomId: number
): number {
  let accumulated = 0;
  let buyingUomId = 0;
  productDetail.inventories.forEach((inv) => {
    buyingUomId = inv.stock?.uomId;

    const multiplier =
      buyingUomId === smallestUomId || smallestUomId === -1
        ? 1
        : multiplierMap[buyingUomId][smallestUomId];

    accumulated = accumulated + inv.stock.availableStock * multiplier;
  });

  return accumulated;
}

export function mergeBuyingAndSellingDraft(
  draft: DraftItem[],
  buyingDraft: DraftItem[],
  sellingDraft: DraftItem[]
): DraftItem[] {
  const unionDraft: DraftItem[] = [...draft];

  for (let i = 0; i < buyingDraft.length; i++) {
    const idOfBuyingDraft = buyingDraft[i].unitOfMeasurement.id;
    // check if the uom is not already exist in the union
    const isFound = unionDraft.find(
      (d) => d.unitOfMeasurement.id === idOfBuyingDraft
    );

    if (!isFound) {
      unionDraft.push(buyingDraft[i]);
    }
  }

  for (let i = 0; i < sellingDraft.length; i++) {
    const idOfSellingDraft = sellingDraft[i].unitOfMeasurement.id;
    // check if the uom is not already exist in the union
    const isFound = unionDraft.find(
      (d) => d.unitOfMeasurement.id === idOfSellingDraft
    );

    if (!isFound) {
      unionDraft.push(sellingDraft[i]);
    }
  }

  unionDraft.sort((d1, d2) => {
    if (d1.priority < d2.priority) {
      return -1;
    } else if (d1.priority > d2.priority) {
      return 1;
    } else {
      return 0;
    }
  });

  return unionDraft;
}

export function mergeBuyingAndSellingDraftWithProductUnits(
  productUnits: DraftItem[],
  buyingDraft: DraftItem[],
  sellingDraft: DraftItem[],
  draft: DraftItem[]
): DraftItem[] {
  // assign priority of draft to the union draft
  const priorityMap: { [key: number]: number } = {};
  draft.forEach((d) => {
    priorityMap[d?.unitOfMeasurement?.id] = d?.priority;
  });

  const unionDraft = uniqBy(
    [...productUnits, ...buyingDraft, ...sellingDraft],
    (draft) => {
      return draft?.unitOfMeasurement?.id;
    }
  ).map((d) => {
    let priority = d?.priority;
    if (priorityMap[d?.unitOfMeasurement?.id]) {
      priority = priorityMap[d?.unitOfMeasurement?.id];
    }
    return {
      ...d,
      priority,
    };
  });

  // check if item inside the unionDraft is not in the draft
  const draftIds = draft.map((d) => d?.unitOfMeasurement?.id);
  const unionDraftIds = unionDraft.map((d) => d?.unitOfMeasurement?.id);

  const UOMIdsNotInDraft = unionDraftIds.filter(
    (id) => !draftIds?.includes(id)
  );

  if (UOMIdsNotInDraft?.length > 0) {
    unionDraft.sort((d1, d2) => {
      if (d1?.priority < d2?.priority) {
        return -1;
      } else if (d1?.priority > d2?.priority) {
        return 1;
      } else {
        return 0;
      }
    });

    return unionDraft;
  }

  const priorities: { [key: number]: number } = {};

  for (let i = 0; i < draft?.length; i++) {
    const idOfDraft = draft[i]?.unitOfMeasurement?.id;
    priorities[idOfDraft] = i;
  }

  unionDraft.sort((d1, d2) => {
    if (
      priorities[d1?.unitOfMeasurement?.id] <
      priorities[d2?.unitOfMeasurement?.id]
    ) {
      return -1;
    } else if (
      priorities[d1?.unitOfMeasurement?.id] >
      priorities[d2?.unitOfMeasurement?.id]
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  for (let i = 0; i < draft?.length; i++) {
    if (
      unionDraft[i]?.priority === 9999 &&
      unionDraft[i]?.unitOfMeasurement?.id === draft[i]?.unitOfMeasurement?.id
    ) {
      unionDraft[i] = draft[i];
    }
  }

  return unionDraft;
}
