import {
  iListApiRequest,
  iListApiResponse,
  iListSortApiRequest,
  iApiResponse,
} from "..//common/models";

export enum EmployeePositions {
  KASIR = "KASIR",
  SALES = "SALES",
  GUDANG = "GUDANG",
  ADMIN = "ADMIN",
  KURIR = "KURIR",
}

export interface GetAllEmployeesRequest extends iListSortApiRequest<string> {
  storeId: string;
  position?: EmployeePositions;
  keyword?: string;
}

export type GetEmployeesResponse = iListApiResponse<Employee[]>;

export interface Employee {
  id: string;
  name: string;
  email?: string | null;
  position: EmployeePositions;
  phoneNumber: string;
  permissionCategoryList: EmployeePermissionCategory[];
  updatedAt: Date;
  createdAt: Date;
}

export interface EmployeePermissionCategory {
  permissionCategoryName: string;
  isGranted: boolean;
  permissionList: EmployeePermission[];
}

export interface EmployeePermission {
  permissionId: string;
  permissionName: string;
  permissionDisplayName: string;
  permissionDescription: string;
  prerequisite: string[];
  isGranted: boolean;
}

export interface EditEmployeeRequest {
  employeeId: string;
  employeeName: string;
  employeeEmail: string | null;
  employeePhoneNumber: string;
  storeId: string;
  position: string;
  permissionIdList: string[];
}

export type EditEmployeeResponse = iApiResponse<Employee>;

export interface AddEmployeeRequest {
  employeeName: string;
  employeeEmail: string | null;
  employeePhoneNumber: string;
  storeId: string;
  position: string;
  permissionIdList: string[];
}

export type AddEmployeeResponse = iApiResponse<Employee>;

export interface DeleteEmployeeRequest {
  storeId: string;
  employeeId: string;
}

export type DeleteEmployeeResponse = iApiResponse<Record<
  string,
  unknown
> | null>;

export type GetAllPermissionsRequest = iListApiRequest;

export type GetAllPermissionsResponse = iListApiResponse<Permission[]>;

export interface Permission {
  permissionCategoryName: string;
  permissionList: PermissionListModel[];
}

export interface PermissionListModel {
  id: string;
  name: string;
  displayName: string;
  description: string;
  prerequisite: string[];
}

export type TransactionEmployeeModel = {
  position: string;
  phoneNumber: string;
  id: string;
  name: string;
};

export interface GetTransactionEmployeesRequest {
  queryParams: iListApiRequest & {
    position?: Array<EmployeePositions>;
    keyword?: string;
  };
  routeParams: { storeId: string };
}

export type GetTransactionEmployeesResponse = iListApiResponse<
  TransactionEmployeeModel[]
>;

export type GetAllPositionsResponse = iApiResponse<string[]>;

export type GetEmployeeDetailsRequest = DeleteEmployeeRequest;

export type GetEmployeeDetailsResponse = iApiResponse<Employee>;
