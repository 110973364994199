import * as React from "react";
import { theme } from "@gada-saas/web-ui";
import { IconProps } from "./types";

const StoreIcon: React.VFC<IconProps> = ({
  width = "25",
  height = "24",
  fill = theme.palette.interface.neutral.primary,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m20.325 6.6022v-2.8822c0-0.39825-0.3217-0.72-0.72-0.72h-15.21c-0.39825 0-0.72 0.32175-0.72 0.72v2.8822c-0.37575 0.0225-0.675 0.33525-0.675 0.71775v2.9633c-0.00144 0.5445 0.10884 1.0837 0.324 1.584 0.09675 0.2295 0.216 0.4455 0.351 0.6502v7.7625c0 0.396 0.32175 0.72 0.72 0.72h15.21c0.3983 0 0.72-0.3217 0.72-0.72v-7.7625c0.1371-0.2053 0.2546-0.423 0.351-0.6502 0.2137-0.5018 0.324-1.035 0.324-1.584v-2.9633c0-0.3825-0.2992-0.69525-0.675-0.71775zm-15.03-1.9822h13.41v1.98h-13.41v-1.98zm8.145 14.762h-2.88v-2.3422h2.88v2.3422zm5.274 0h-3.834v-3.0622c0-0.3982-0.3217-0.72-0.72-0.72h-4.32c-0.39825 0-0.72 0.3218-0.72 0.72v3.0622h-3.825v-5.4494c0.06525 0.0315 0.13275 0.063 0.2025 0.09 0.50175 0.2115 1.035 0.3172 1.584 0.3172s1.08-0.1057 1.584-0.3172c0.3105-0.1305 0.603-0.2971 0.87075-0.4973 0.0045-0.0022 9e-3 -0.0022 0.0135 0 0.26858 0.2011 0.56104 0.3681 0.87074 0.4973 0.5018 0.2115 1.035 0.3172 1.584 0.3172s1.08-0.1057 1.584-0.3172c0.3105-0.1305 0.603-0.2971 0.8708-0.4973 0.0045-0.0022 9e-3 -0.0022 0.0135 0 0.2685 0.2011 0.561 0.3681 0.8707 0.4973 0.5018 0.2115 1.035 0.3172 1.584 0.3172s1.08-0.1057 1.584-0.3172c0.0675-0.0293 0.135-0.0585 0.2025-0.09v5.4494zm0.666-9.0989c0 1.3455-1.1025 2.4367-2.4592 2.4367-0.918 0-1.719-0.4972-2.142-1.2352-0.0653-0.1125-0.1823-0.1823-0.3128-0.1823h-0.0135c-0.1282 0-0.2475 0.0698-0.3127 0.1823-0.2159 0.3764-0.5276 0.689-0.9034 0.906-0.3759 0.2169-0.8024 0.3305-1.2364 0.3292-0.9157 0-1.7145-0.495-2.1375-1.2307-0.0675-0.1148-0.189-0.1868-0.32175-0.1868s-0.2565 0.072-0.32175 0.1868c-0.21713 0.375-0.52921 0.6862-0.90485 0.9022-0.37563 0.2161-0.80156 0.3294-1.2349 0.3285-1.3568 0-2.4592-1.0912-2.4592-2.4367v-2.052c0-0.00675 0.0045-0.01125 0.01125-0.01125h14.738c0.0067 0 0.0112 0.0045 0.0112 0.01125v2.052z"
        fill={fill}
      />
    </svg>
  );
};

export default StoreIcon;
