import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { globalResetAction } from "../../common/redux/actions";
import { RefundInitialState } from "./types";

const initialState: RefundInitialState = {
  itemIndex: -1,
  uomIndex: -1,
};

const refundSlice = createSlice({
  name: "refundSlice",
  initialState,
  reducers: {
    setIndexes(
      state: RefundInitialState,
      action: PayloadAction<RefundInitialState>
    ) {
      state.itemIndex = action.payload.itemIndex;
      state.uomIndex = action.payload.uomIndex;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const { setIndexes } = refundSlice.actions;

export default refundSlice.reducer;
