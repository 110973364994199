import * as React from "react";
import { IconProps } from "./types";

const CloseIcon: React.VFC<IconProps> = ({
  width = 14,
  height = 14,
  fill = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.6" cx="7" cy="7" r="7" fill="#383838" />
      <g clipPath="url(#clip0_5776_54669)">
        <path
          d="M10.5 4.205L9.795 3.5L7 6.295L4.205 3.5L3.5 4.205L6.295 7L3.5 9.795L4.205 10.5L7 7.705L9.795 10.5L10.5 9.795L7.705 7L10.5 4.205Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5776_54669">
          <rect
            width={Number(width) - 2}
            height={Number(height) - 2}
            fill={fill}
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
