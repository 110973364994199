import React from "react";
import { IconProps } from "./types";

const SyncIcon: React.VFC<IconProps & { active?: boolean }> = ({
  active,
  width = "20",
  height = "16",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill={active ? "#05A39B" : "#6B7E7D"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 8.5L18 4.5L13 0.5V3.499H0V5.499H13V8.5ZM20 11.5H7V8.5L2 12.5L7 16.5V13.5H20V11.5Z" />
    </svg>
  );
};

export default SyncIcon;
