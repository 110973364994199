import * as React from "react";
import { useActiveStoreState } from "@gada-saas/web-core";
import { usePrevious } from "@gada-saas/web-ui/hooks";
import { UserMenusStateType } from "@gada-saas/web-core/user/menus/types";

const useActiveStoreChanged = () => {
  const [storeChanged, setStoreChanged] = React.useState(false);
  const activeStore = useActiveStoreState();
  const previousStore: UserMenusStateType["activeStore"] =
    usePrevious(activeStore)!;

  React.useEffect(() => {
    if (activeStore.storeId !== previousStore.storeId) {
      setStoreChanged(true);
    }
  }, [activeStore, previousStore]);

  return { isChanged: storeChanged, currentStore: activeStore, previousStore };
};

export default useActiveStoreChanged;
