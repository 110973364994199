import router from "../router";
import { Endpoints } from "../../../../common/api/endpoints";
import { db, OfflineMetaData } from "../../../../inventory/offline";
import {
  GetOfflineTransactionReportResponse,
  GetOfflineTransactionReportRequest,
  GetOfflineTransactionByIdResponse,
  GetOfflineTransactionByIdRequest,
} from "../../../../report/offline-transaction";
import { DeliveryStatus } from "../../../../report/delivery-order/common/models";
import { RefundStatus } from "../../../../pos/order/models";
import { parseStringToDate } from "../utils";
import { dayDiff } from "../../../utils";
import { PaymentStatus } from "../../../../report/types";
import { iApiResponse } from "../../../models";

router.get<
  GetOfflineTransactionReportResponse,
  void,
  void,
  GetOfflineTransactionReportRequest
>(Endpoints.GET_OFFLINE_PAYMENT_REPORT, async (req) => {
  // TODO : consider using types to filter according to data
  const {
    page,
    storeId,
    types = "",
    apiStatuses = "",
    orderBy,
    orderType,
  } = req.query;

  const reqStatuses = apiStatuses.split(".");
  const paymentTypes = types.split(".");

  let currRequests = await db.requestQueue
    .where("storeId")
    .equals(storeId)
    .and((queueItem) => {
      if (reqStatuses.length === 0) {
        return true;
      }

      return reqStatuses.includes(queueItem.apiResponseStatus);
    })
    .and((queueItem) => {
      console.log("queueItem", queueItem, reqStatuses, paymentTypes);
      if (paymentTypes.length === 0) {
        return true;
      }

      return paymentTypes.includes(queueItem.originalOrderObject.paymentStatus);
    })
    .toArray();

  if (orderType && orderBy) {
    currRequests = currRequests.sort((r1, r2) => {
      if (orderBy === "time") {
        const t1 = parseStringToDate(r1.originalOrderObject.createdAt);
        const t2 = parseStringToDate(r2.originalOrderObject.createdAt);
        if (orderType === "asc") {
          return t1.getTime() - t2.getTime();
        } else {
          return t2.getTime() - t1.getTime();
        }
      } else if (orderBy === "dueDate") {
        const t1 =
          r1.originalOrderObject.dueDate ??
          parseStringToDate(r1.originalOrderObject.createdAt);
        const t2 =
          r2.originalOrderObject.dueDate ??
          parseStringToDate(r2.originalOrderObject.createdAt);
        if (orderType === "asc") {
          return t1.getTime() - t2.getTime();
        } else {
          return t2.getTime() - t1.getTime();
        }
      } else if (orderBy === "receivableRemaining") {
        const val1 = parseInt(r1.originalOrderObject.unpaidAmount, 10);
        const val2 = parseInt(r2.originalOrderObject.unpaidAmount, 10);
        if (orderType === "asc") {
          return val1 - val2;
        } else {
          return val2 - val1;
        }
      }
      return 0;
    });
  }

  const today = new Date();
  const data: GetOfflineTransactionReportResponse["data"] = currRequests.map(
    (req) => {
      let pastDueDate = 0;
      if (req.originalOrderObject.dueDate) {
        pastDueDate = Math.max(
          dayDiff(today, req.originalOrderObject.dueDate),
          0
        );
      }
      return {
        requestQueueId: req.requestQueueId,
        customerId: req.originalOrderObject.customerId as string,
        customerName: req.originalOrderObject.customerName as string,
        dueDate: (req.originalOrderObject.dueDate ?? "").toString(),
        orderId: req.originalOrderObject.id,
        pastDueDate: pastDueDate,
        paymentAmount: req.originalOrderObject.totalPayment,
        paymentType: req.originalOrderObject.orderPaymentList[0].paymentType,
        paymentCode: req.originalOrderObject.orderPaymentList[0].paymentCode,
        paymentChange:
          req.originalOrderObject.orderPaymentList[0].paymentChange,
        transactionDate: req.originalOrderObject.createdAt,
        unpaidAmount: req.originalOrderObject.unpaidAmount,
      };
    }
  );

  return {
    page,
    total: data.length,
    data,
    message: "Success",
  };
});

router.get<
  GetOfflineTransactionByIdResponse,
  void,
  GetOfflineTransactionByIdRequest
>(Endpoints.GET_OFFLINE_PAYMENT_REPORT_BY_ID, async (req) => {
  const { requestQueueId } = req.params;

  if (!requestQueueId) {
    return {
      data: {
        requestQueueId: "",
        originalOrderObject: {
          cashierId: "",
          cashierName: "",
          channel: "",
          createdAt: "",
          currency: "",
          customerAddress: "",
          customerId: "",
          customerName: "",
          deliveryStatus: DeliveryStatus.UNDELIVERED,
          grossAmount: "",
          id: "",
          netAmount: "",
          objectHighlight: {
            amount: "",
            balanceAfterPayment: "",
            balanceBeforePayment: "",
            bankAccountName: "",
            bankAccountNo: "",
            bankCode: "",
            bankName: "",
            createdAt: new Date(),
            id: "",
            paymentCashierId: "",
            paymentCashierName: "",
            paymentChange: "",
            paymentCode: "",
            paymentType: "",
            referenceNo: "",
            updatedAt: new Date(),
          },
          orderCode: "",
          orderCustomList: [],
          orderDeliveryList: [],
          orderDiscountList: [],
          orderItemList: [],
          orderPaymentList: [],
          orderStatus: "",
          paymentStatus: PaymentStatus.UNPAID,
          refundList: [],
          refundStatus: RefundStatus.REFUNDABLE,
          storeAddress: "",
          storeId: "",
          storeName: "",
          totalCharges: "",
          totalDiscount: "",
          totalItems: 0,
          totalPayment: "",
          totalRefundAmount: "",
          totalRefundChargesAmount: "",
          totalRefundDiscountAmount: "",
          totalSellerFee: "",
          unpaidAmount: "",
          updatedAt: "",
        },
        apiResponseStatus: "",
        storeId: "",
        retryCount: 0,
        cartId: "",
      },
    };
  }

  const reqQueue = await db.requestQueue
    .where("requestQueueId")
    .equals(requestQueueId)
    .toArray();

  return {
    data: reqQueue[0],
  };
});

router.get<iApiResponse<OfflineMetaData>, void, void, { storeId: string }>(
  Endpoints.GET_OFFLINE_META_DATA,
  async (req) => {
    const { storeId } = req.query;

    const metadatas = await db.metadata
      .where("storeId")
      .equals(storeId)
      .toArray();

    const currMetaData = metadatas[0];

    return {
      data: currMetaData,
    };
  }
);
