import {
  iPosState,
  DeleteCartPayload,
  DeleteOfflineCartPayload,
  LoadCartPayload,
  AddInventoryToCartPayload,
  ChangeInventoryCountPayload,
  ChangeCustomItemCountPayload,
  AddCustomItemToCartPayload,
  DeleteInventoryFromCartPayload,
  DeleteCustomItemFromCartPayload,
  iSavedCarts,
  CartItemInventory,
  CustomCartItem,
} from "./types";
import { useActions } from "../../common/hooks/useActions";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import CartApi from "./hooks";
import { iCart, iCartSaved, iInsufficientStockResponse } from "./models";
import { globalResetAction } from "../../common/redux/actions";
import { createAddedToCartTimeStamp } from "./utils";
import { iDiscountItem } from "../models";
import { parseDate } from "../../common/utils";

const initialState: iPosState = {
  savedCarts: {},
  offlineSavedCarts: {},
  cart: {
    currentCartId: "",
    customer: undefined,
    isLoading: true,
    cartItemCountInCart: {},
  },
  cartDetail: null,
  errors: {},
  barcodeErrorHandled: null,
  showReservedQuantityToolTip: null,
  isCartDiscountRemovedModalShown: false,
  isLoadingSavedCarts: false,
  cartIdBeingLoaded: null,
  uiState: {
    cartItemList: [],
    isDiscountItemModalOpen: false,
  },
  dataState: {
    mapCartItemDiscount: {},
    mapCartItem: {},
  },
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setOpenDiscountItemModal(state, action: PayloadAction<boolean>) {
      state.uiState.isDiscountItemModalOpen = action.payload;
      if (action.payload === false) {
        delete state.cartItem;
      }
    },
    setCartIdBeingLoaded(
      state,
      action: PayloadAction<iPosState["cartIdBeingLoaded"]>
    ) {
      state.cartIdBeingLoaded = action.payload;
    },
    toggleCartDiscountRemovedModal(state) {
      state.isCartDiscountRemovedModalShown = !state.isCartDiscountRemovedModalShown;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.cart.isLoading = action.payload;
    },
    addInventoryToCart(
      state,
      { payload }: PayloadAction<AddInventoryToCartPayload>
    ) {
      const { productVariant, inventoryUnit } = payload;
      if (
        state.cart.cartItemCountInCart[inventoryUnit.inventoryId] !== undefined
      ) {
        state.cart.cartItemCountInCart[inventoryUnit.inventoryId]++;
      } else {
        state.cart.cartItemCountInCart[inventoryUnit.inventoryId] = 1;
      }

      const currProductVariantState = state.dataState.mapCartItem[
        productVariant.productVariantId
      ] as CartItemInventory;
      if (currProductVariantState !== undefined) {
        if (
          currProductVariantState.mapInventories[inventoryUnit.inventoryId] ===
          undefined
        ) {
          currProductVariantState.mapInventories = {
            ...currProductVariantState.mapInventories,
            [inventoryUnit.inventoryId]: { ...inventoryUnit },
          };
        }
      } else {
        state.dataState.mapCartItem[productVariant.productVariantId] = {
          ...productVariant,
          mapInventories: {
            [inventoryUnit.inventoryId]: { ...inventoryUnit },
          },
        };
      }

      const cartItemIndex = state.uiState.cartItemList.findIndex(
        (v) => Number(v.id) === productVariant.productVariantId
      );

      if (cartItemIndex >= 0) {
        const cartItemInventoryIndex = state.uiState.cartItemList[
          cartItemIndex
        ]?.inventoryIds?.findIndex((i) => i === inventoryUnit.inventoryId);
        if (
          cartItemInventoryIndex !== undefined &&
          cartItemInventoryIndex < 0
        ) {
          state.uiState.cartItemList[cartItemIndex].inventoryIds?.push(
            inventoryUnit.inventoryId
          );
        }
      } else {
        state.uiState.cartItemList.push({
          id: productVariant.productVariantId,
          inventoryIds: [inventoryUnit.inventoryId],
        });
      }
    },
    addCustomItemToCart(
      state,
      {
        payload: { id, name, price },
      }: PayloadAction<AddCustomItemToCartPayload>
    ) {
      if (state.cart.cartItemCountInCart[id] !== undefined) {
        state.cart.cartItemCountInCart[id]++;
      } else {
        state.cart.cartItemCountInCart[id] = 1;
      }

      state.dataState.mapCartItem[id] = {
        createdAt: createAddedToCartTimeStamp(),
        id,
        name,
        price,
      } as CustomCartItem;
      state.uiState.cartItemList.push({ id: id });
    },
    removeInventoryFromCart(
      state,
      {
        payload: { productVariantId, inventoryUnitId },
      }: PayloadAction<DeleteInventoryFromCartPayload>
    ) {
      delete state.cart.cartItemCountInCart[inventoryUnitId];
      delete (state.dataState.mapCartItem[
        productVariantId
      ] as CartItemInventory).mapInventories[inventoryUnitId];

      const cartItemIndex = state.uiState.cartItemList.findIndex(
        (item) => item.id === productVariantId
      );

      if (cartItemIndex >= 0) {
        const cartItemInventoryIndex = state.uiState.cartItemList[
          cartItemIndex
        ]?.inventoryIds?.findIndex((id) => id === inventoryUnitId);
        if (
          cartItemInventoryIndex !== undefined &&
          cartItemInventoryIndex >= 0
        ) {
          state.uiState.cartItemList[cartItemIndex].inventoryIds?.splice(
            cartItemInventoryIndex,
            1
          );
          if (
            state.uiState.cartItemList[cartItemIndex].inventoryIds?.length === 0
          ) {
            state.uiState.cartItemList.splice(cartItemIndex, 1);
            delete state.dataState.mapCartItem[productVariantId];
          }
        }
      }
    },
    removeCustomItemFromCart(
      state,
      { payload: { id } }: PayloadAction<DeleteCustomItemFromCartPayload>
    ) {
      delete state.cart.cartItemCountInCart[id];

      const cartItemIndex = state.uiState.cartItemList.findIndex(
        (item) => item.id === id
      );

      if (cartItemIndex !== undefined && cartItemIndex >= 0) {
        state.uiState.cartItemList.splice(cartItemIndex, 1);
        delete state.dataState.mapCartItem[id];
      }
    },
    changeInventoryCount(
      state,
      {
        payload: { inventoryUnitId, count },
      }: PayloadAction<ChangeInventoryCountPayload>
    ) {
      state.cart.cartItemCountInCart[inventoryUnitId] = count;
    },
    changeCustomItemCount(
      state,
      { payload: { id, count } }: PayloadAction<ChangeCustomItemCountPayload>
    ) {
      state.cart.cartItemCountInCart[id] = count;
    },
    clearCart(state) {
      state.cart = initialState.cart;
      state.cartDetail = initialState.cartDetail;
      state.uiState = initialState.uiState;
      state.dataState = initialState.dataState;
    },
    loadCart(
      state,
      {
        payload,
      }: PayloadAction<{
        loadedCart: LoadCartPayload;
        isSavedCart: boolean;
      }>
    ) {
      const arr: Array<{
        id: string | number;
        items?: Array<{ id: number; k: number }>;
      }> = [];
      // Reset State
      state.cart.cartItemCountInCart = {};
      state.dataState.mapCartItem = {};
      state.dataState.mapCartItemDiscount = {};

      const mapCartItemIdIndex: Record<number, number> = {};
      const mapCartItemIdCreatedAt: Record<number, number> = {};

      const newMapCartItemDiscount: iPosState["dataState"]["mapCartItemDiscount"] = {};

      payload.loadedCart.cartDetail.cartItemList.forEach((i) => {
        state.cart.cartItemCountInCart[i.inventoryId] = i.quantity;
        if (i.itemDiscount) {
          newMapCartItemDiscount[i.inventoryId] = { ...i.itemDiscount };
        }
        const itemDetail = {
          id: i.inventoryId,
          k: parseDate(
            i.createdAt,
            "YYYY-MM-DD[T]HH:mm:ss[.]SSS[00]ZZ"
          ).getTime(),
        };
        if (mapCartItemIdIndex[i.productVariantId] !== undefined) {
          mapCartItemIdCreatedAt[i.productVariantId] = Math.min(
            mapCartItemIdCreatedAt[i.productVariantId],
            parseDate(
              i.createdAt,
              "YYYY-MM-DD[T]HH:mm:ss[.]SSS[00]ZZ"
            ).getTime()
          );

          const newArrItems: Array<{ id: number; k: number }> = [
            ...(arr[mapCartItemIdIndex[i.productVariantId]]?.items || []),
            { ...itemDetail },
          ];
          newArrItems.sort((a, b) => a.k - b.k);
          arr[mapCartItemIdIndex[i.productVariantId]] = {
            ...arr[mapCartItemIdIndex[i.productVariantId]],
            items: [...newArrItems],
          };
        } else {
          mapCartItemIdCreatedAt[i.productVariantId] = parseDate(
            i.createdAt,
            "YYYY-MM-DD[T]HH:mm:ss[.]SSS[00]ZZ"
          ).getTime();
          mapCartItemIdIndex[i.productVariantId] =
            arr.push({
              id: i.productVariantId,
              items: [{ ...itemDetail }],
            }) - 1;
        }
      });

      state.dataState.mapCartItemDiscount = { ...newMapCartItemDiscount };

      payload.loadedCart.cartDetail.cartCustomList.forEach((i) => {
        state.cart.cartItemCountInCart[i.id] = i.quantity;
        mapCartItemIdIndex[Number(i.id)] = arr.push({ id: i.id });
        mapCartItemIdCreatedAt[Number(i.id)] = parseDate(
          i.createdAt,
          "YYYY-MM-DD[T]HH:mm:ss[.]SSS[00]ZZ"
        ).getTime();
      });

      const newUiCartItemsListState: Array<{
        id: string | number;
        inventoryIds?: Array<number>;
      }> = arr
        .slice()
        .sort(
          (a, b) =>
            mapCartItemIdCreatedAt[Number(a.id)] -
            mapCartItemIdCreatedAt[Number(b.id)]
        )
        .map((value) => ({
          id: value.id,
          ...(value.items && {
            inventoryIds: value.items.map((i) => i.id),
          }),
        }));

      state.uiState.cartItemList = [...newUiCartItemsListState];

      const cartTransaction = payload.loadedCart.cartTransaction;
      state.cart.currentCartId = cartTransaction.id;

      payload.loadedCart.cartTransaction.items.forEach((item) => {
        if (item.custom) {
          state.dataState.mapCartItem[item.custom.id] = {
            createdAt: createAddedToCartTimeStamp(),
            id: item.custom.id,
            name: item.custom.name,
            price: item.custom.price,
          } as CustomCartItem;
        } else if (item.inventory) {
          const { inventories, ...rest } = item.inventory;
          state.dataState.mapCartItem[item.inventory.productVariantId] = {
            ...rest,
            mapInventories: keyBy(inventories, "inventoryId"),
          };
        }
      });

      state.cart.customer = cartTransaction.customer;
      state.cartDetail = payload.loadedCart.cartDetail;
      if (payload.isSavedCart) {
        delete state.savedCarts[cartTransaction.id];
      }
    },

    saveCurrentCart(state) {
      if (!state.cartDetail) return;
      state.savedCarts[state.cartDetail.id] = state.cartDetail as iCartSaved;
      state.cart = initialState.cart;
      state.cartDetail = initialState.cartDetail;
      state.dataState = initialState.dataState;
      state.uiState = initialState.uiState;
    },
    setSavedCartBulk(
      state,
      { payload }: PayloadAction<{ carts: iCart[]; offlineCarts: iCart[] }>
    ) {
      if (payload.carts.length === 0) {
        state.savedCarts = initialState.savedCarts;
      }
      if (payload.offlineCarts.length === 0) {
        state.offlineSavedCarts = initialState.offlineSavedCarts;
      }

      if (payload.carts.length > 0) {
        state.savedCarts = keyBy(payload.carts, "id") as iSavedCarts;
      }
      if (payload.offlineCarts.length > 0) {
        state.offlineSavedCarts = keyBy(
          payload.offlineCarts,
          "id"
        ) as iSavedCarts;
      }
    },
    deleteSavedCart(state, { payload }: PayloadAction<DeleteCartPayload>) {
      payload.cartIds.forEach((cartId) => {
        delete state.savedCarts[cartId];
      });
    },
    deleteOfflineSavedCart(
      state,
      { payload }: PayloadAction<DeleteOfflineCartPayload>
    ) {
      payload.offlineCartIds.forEach((cartId) => {
        delete state.offlineSavedCarts[cartId];
      });
    },
    setErrors(
      state,
      {
        payload,
      }: PayloadAction<{
        errorResponse: iInsufficientStockResponse;
        inventoryId: string;
      }>
    ) {
      state.errors[payload.inventoryId] = payload.errorResponse;
    },
    onErrorHandled(state, { payload }: PayloadAction<{ inventoryId: number }>) {
      delete state.errors[payload.inventoryId];
    },
    setBarcodeError(state, { payload }: PayloadAction<boolean | null>) {
      state.barcodeErrorHandled = payload;
    },
    setCartDetail(state, { payload }: PayloadAction<iPosState["cartDetail"]>) {
      state.cartDetail = payload;
      state.cart.isLoading = false;
      state.cart.cartItemCountInCart = initialState.cart.cartItemCountInCart;
      if (payload?.id) {
        state.cart.currentCartId = payload.id;
      }
      state.uiState = initialState.uiState;
      state.dataState = initialState.dataState;
    },
    setCartItem(state, { payload }: PayloadAction<iPosState["cartItem"]>) {
      state.cartItem = payload;
    },
    setShowReservedQuantityToolTip(state, { payload }: PayloadAction<string>) {
      state.showReservedQuantityToolTip = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);

    builder.addMatcher(
      CartApi.endpoints.addItemToCart.matchPending,
      (state) => {
        state.cart.isLoading = true;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.addItemToCart.matchFulfilled,
      (state, { payload, meta }) => {
        state.cartDetail = payload.data;
        state.cart.isLoading = false;

        const inventoryItemDiscount =
          payload.data.cartItemList.find(
            (c) => c.inventoryId === meta.arg.originalArgs.inventoryId
          )?.itemDiscount || null;

        if (inventoryItemDiscount) {
          state.dataState.mapCartItemDiscount[
            meta.arg.originalArgs.inventoryId
          ] = { ...inventoryItemDiscount };
        }
      }
    );

    builder.addMatcher(
      CartApi.endpoints.addItemToCart.matchRejected,
      (state) => {
        state.cart.isLoading = false;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.addCustomItemToCart.matchPending,
      (state) => {
        state.cart.isLoading = true;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.addCustomItemToCart.matchRejected,
      (state) => {
        state.cart.isLoading = false;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.addCustomItemToCart.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
        state.cart.isLoading = false;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.updateCustomItemInCart.matchPending,
      (state) => {
        state.cart.isLoading = true;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.updateCustomItemInCart.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
        state.cart.isLoading = false;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.clearItemFromCart.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.clearCustomItemFromCart.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.saveCart.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.createDiscountItem.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
        const cartItem = state.cartItem;
        if (cartItem !== undefined) {
          state.dataState.mapCartItemDiscount[cartItem.inventoryId] = {
            ...(payload.data.updatedObject as iDiscountItem),
          };
          delete state.cartItem;
        }
      }
    );

    builder.addMatcher(
      CartApi.endpoints.addCartDiscount.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.updateDiscountItem.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
        const cartItem = state.cartItem;
        if (cartItem !== undefined) {
          state.dataState.mapCartItemDiscount[cartItem.inventoryId] = {
            ...(payload.data.updatedObject as iDiscountItem),
          };
          delete state.cartItem;
        }
      }
    );

    builder.addMatcher(
      CartApi.endpoints.updateCartDiscount.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
      }
    );

    builder.addMatcher(
      CartApi.endpoints.deleteDiscountItem.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
        const cartItem = state.cartItem;
        if (cartItem !== undefined) {
          delete state.dataState.mapCartItemDiscount[cartItem.inventoryId];
          delete state.cartItem;
        }
      }
    );

    builder.addMatcher(
      CartApi.endpoints.deleteCartDiscount.matchFulfilled,
      (state, { payload }) => {
        state.cartDetail = payload.data;
      }
    );

    builder
      .addMatcher(
        isAnyOf(
          CartApi.endpoints.getSavedCarts.matchPending,
          CartApi.endpoints.getOfflineSavedCarts.matchPending
        ),
        (state) => {
          state.isLoadingSavedCarts = true;
        }
      )
      .addMatcher(
        isAnyOf(
          CartApi.endpoints.getSavedCarts.matchFulfilled,
          CartApi.endpoints.getSavedCarts.matchRejected,
          CartApi.endpoints.getOfflineSavedCarts.matchFulfilled,
          CartApi.endpoints.getSavedCarts.matchRejected
        ),
        (state) => {
          state.isLoadingSavedCarts = false;
        }
      );
  },
});

const { actions } = cartSlice;

export const useCartActions = (): typeof actions => useActions(actions);
export default cartSlice.reducer;
