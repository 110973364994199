export const NUMBER_ONLY_STRING_REGEX = /^\d+$/;
export const NON_NUMBER_ONLY_STRING = /^([^0-9]*)$/;
export const NON_NUMBER_NO_SPECIAL_CHARACTERS = /^[A-Za-z\s]*$/;
export const FLOATING_POINT_NUMBERS_REGEX = /[+-]?([0-9]*[.])?[0-9]+/;
export const PHONE_NUMBER_ONLY_STRING_REGEX = /^(08[0-9]{8,11}|8[0-9]{8,11})$/;
// Source : https://emailregex.com/
export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
export const NO_SPECIAL_CHARACTERS = /^[A-Za-z0-9\s]*$/;
export const FLOATING_POINT_NUMBERS_NOT_STARTING_WITH_ZERO =
  /^(?!0)([0-9]*[.])?[0-9]+/;
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

// Source: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s06.html#:~:text=On%20a%2024%2Dhour%20clock,%5D%3F%5B0%2D9%5D%20%E2%80%BA.
export const TIME_24_FORMAT_REGEX = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
