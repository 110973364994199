import CartReducer from "./cart";
import InventoriesReducer from "./inventories";
import { combineReducers } from "@reduxjs/toolkit";

const posReducer = combineReducers({
  cart: CartReducer,
  inventories: InventoriesReducer,
});

export default posReducer;
