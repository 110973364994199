import { useSelector, shallowEqual } from "react-redux";
import { AppStateType } from "../store";

const cachedUserInfoSelector = (s: AppStateType) => s.cachedUserInfo;

export const useCachedUserInfo = (): AppStateType["cachedUserInfo"] =>
  useSelector(cachedUserInfoSelector, shallowEqual);

export const selectUserHasManageDiscountPermission = (storeId: string) => (
  state: AppStateType
): boolean =>
  Boolean(
    state.cachedUserInfo.data.userStoreList
      .find((s) => s.storeId === storeId)
      ?.storePermissionList.some((p) => p.name === "Kelola Diskon")
  );

const selectCashierId = (state: AppStateType): string =>
  state.cachedUserInfo.data.userInfo.id;

export const useCashierIdSelector = () => useSelector(selectCashierId);

export const useUserHasManageDiscountPermissionSelector = (storeId: string) =>
  useSelector(selectUserHasManageDiscountPermission(storeId), shallowEqual);
