import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import * as localforage from "localforage";

// Apis
import { gadaSaasApi } from "./base-rtkq-api";

// Reducers
import InventoryListReducer from "./inventory/list/slice";
import CrmReducer from "./crm/slice";
import PaymentReducer from "./pos/payment/slice";
import CuratedInventoryReducer from "./inventory/common/slices/InventoryFormSlice";
import BuyingSlice from "./inventory/buying/slice";
import PosReducer from "./pos";
import GadaOfflineReducer from "./offline/slice";
import InventoryHistoryReducer from "./inventory/history/slice";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import GlobalReportReducer from "./report/slice";
import CustDebtReducer from "./report/customer-debt/slice";
import SalesCategoryReducer from "./report/sales-category/slice";
import PricingEditorSlice from "./inventory/pricing/slice";
import SyncStoresReducer from "./sync-stores/slice";
import {
  SideMenuSliceName,
  SideMenuSliceReducer,
  UserMenuSliceName,
  UserMenuSliceReducer,
} from "./user/menus/slice";
import CachedUserSlice from "./user/slice";
import SalesPerProductReducer from "./report/sales-product/slice";
import ProfitPerProductSlice from "./report/profit/slice";
import StoreReducer from "./stores/slice";
import SupplierReducer from "./supplier/slice";
import InventoryConversionReducer from "./inventory/hierarchy/slice";
import DeliveryOrderListSlice from "./report/delivery-order/list/slice";
import SelectInvoiceSlice from "./report/delivery-order/select-invoice/slice";
import DeliveryOrderReducer from "./report/delivery-order/delivery-order/slice";
import RefundReducer from "./report/refund/slice";
import StockOpnameSlice from "./stock-opname/slice";
import SupplierListSlice from "./supplier/list/slice";
import StoreDebtReducer from "./report/store-debt/slice";
import InventoryValueSlice from "./report/inventory-value/slice";
import { ChangeInventoryStatusSlice } from "./inventory/change-status/slice";
import { BadStockReportSlice } from "./inventory/bad-stock-report/slice";
import { HandleBadStockUIStateSlice } from "./inventory/handle-bad-stock";
import CoachMarkReducer from "./coach-mark/slice";
import { BulkAddInventoryState } from "./inventory/bulk-add/slice";
import { promotionListSlice, createPromotionUISlice } from "./promotion";
import ClosedBannersSlice from "./notifications/closed-banners/slice";
import { CachedInventoryRecommendationRankingSlice } from "./pos/inventories/slice";

// Configurations
export const localForageStorage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: "gada-persist",
  storeName: "gada-storage",
});

const persistConfig = {
  key: "root",
  version: 1,
  storage: localForageStorage,
  whitelist: [
    UserMenuSliceName,
    CachedUserSlice.name,
    BulkAddInventoryState.name,
    ClosedBannersSlice.name,
    CachedInventoryRecommendationRankingSlice.name,
  ],
};

const RootReducer = combineReducers({
  // ---- Reducers ----
  inventoryList: InventoryListReducer,
  crm: CrmReducer,
  payment: PaymentReducer,
  customerDebt: CustDebtReducer,
  globalReport: GlobalReportReducer,
  curatedProducts: CuratedInventoryReducer,
  salesCategory: SalesCategoryReducer,
  syncStores: SyncStoresReducer,
  pos: PosReducer,
  salesPerProduct: SalesPerProductReducer,
  gadaOffline: GadaOfflineReducer,
  storeReducer: StoreReducer,
  deliveryOrder: DeliveryOrderReducer,
  refund: RefundReducer,
  stockOpname: StockOpnameSlice,
  inventoryHistory: InventoryHistoryReducer,
  storeDebt: StoreDebtReducer,
  coachMark: CoachMarkReducer,
  [ClosedBannersSlice.name]: ClosedBannersSlice.reducer,
  [CachedUserSlice.name]: CachedUserSlice.reducer,
  [UserMenuSliceName]: UserMenuSliceReducer,
  [SideMenuSliceName]: SideMenuSliceReducer,
  [SupplierReducer.name]: SupplierReducer.reducer,
  [InventoryConversionReducer.name]: InventoryConversionReducer.reducer,
  [ProfitPerProductSlice.name]: ProfitPerProductSlice.reducer,
  [BuyingSlice.name]: BuyingSlice.reducer,
  [DeliveryOrderListSlice.name]: DeliveryOrderListSlice.reducer,
  [SelectInvoiceSlice.name]: SelectInvoiceSlice.reducer,
  [PricingEditorSlice.name]: PricingEditorSlice.reducer,
  [SupplierListSlice.name]: SupplierListSlice.reducer,
  [InventoryValueSlice.name]: InventoryValueSlice.reducer,
  [ChangeInventoryStatusSlice.name]: ChangeInventoryStatusSlice.reducer,
  [BadStockReportSlice.name]: BadStockReportSlice.reducer,
  [HandleBadStockUIStateSlice.name]: HandleBadStockUIStateSlice.reducer,
  [BulkAddInventoryState.name]: BulkAddInventoryState.reducer,
  [promotionListSlice.name]: promotionListSlice.reducer,
  [createPromotionUISlice.name]: createPromotionUISlice.reducer,
  [CachedInventoryRecommendationRankingSlice.name]:
    CachedInventoryRecommendationRankingSlice.reducer,
  // ---- APIs ----
  [gadaSaasApi.reducerPath]: gadaSaasApi.reducer,
});

const store = configureStore({
  reducer: persistReducer(persistConfig, RootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([gadaSaasApi.middleware]),
  devTools: true,
  // devTools: process.env.NODE_ENV !== "production",
});

export default store;

export const { dispatch: appDispatch } = store;
export type AppStateType = ReturnType<typeof RootReducer>;
export type AppDispatch = typeof appDispatch;
export const useThunkDispatch = (): ThunkDispatch<
  AppDispatch,
  null,
  AnyAction
> => useDispatch();

export const persistor = persistStore(store);
export const useTypedSelector: TypedUseSelectorHook<AppStateType> = useSelector;
