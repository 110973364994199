export enum CartStatus {
  ACTIVE = "Active",
  SAVED = "Saved",
  CHECKOUT = "Checkout",
  COMPLETED = "Completed",
  DELETED = "Deleted",
}

export enum CartQueryType {
  CASHIER = "cashier_id",
  STATUS = "cart_status",
  AND = "and",
  OR = "or",
}

export enum CartErrorCode {
  INSUFFICIENT_STOCK = "INSUFFICIENT_STOCK",
}
