import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const SouthWestIcon: React.VFC<IconProps> = ({
  width = 15,
  height = 15,
  fill = theme.palette.interface.teal.primary,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14c0-.56-.45-1-1-1H3.41L14.3 2.11A.997.997 0 1012.89.7L2 11.59V6c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1h8c.55 0 1-.45 1-1z"
        fill={fill}
      />
    </svg>
  );
};

export default SouthWestIcon;
