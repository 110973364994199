import React from "react";
import { IconProps } from "./types";
import { AppTheme } from "@gada-saas/web-ui";
import { useTheme } from "@material-ui/core";

const CheckIcon: React.VFC<IconProps> = ({ width = 48, height = 48, fill }) => {
  const theme = useTheme<AppTheme>();

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.716 10.8491C44.2597 11.393 44.5651 12.1305 44.5651 12.8995C44.5651 13.6685 44.2597 14.406 43.716 14.9498L20.5152 38.1506C19.9714 38.6943 19.2339 38.9998 18.4649 38.9998C17.6959 38.9998 16.9583 38.6943 16.4145 38.1506L4.81409 26.5502C4.28582 26.0033 3.9935 25.2707 4.00011 24.5103C4.00672 23.7499 4.31172 23.0225 4.84942 22.4848C5.38712 21.9471 6.1145 21.6421 6.8749 21.6355C7.6353 21.6289 8.36787 21.9212 8.91483 22.4495L18.4649 31.9995L39.6153 10.8491C40.1591 10.3054 40.8967 10 41.6657 10C42.4347 10 43.1722 10.3054 43.716 10.8491Z"
        fill={fill ? fill : theme.palette.interface.green.primary}
      />
    </svg>
  );
};

export default CheckIcon;
