import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BarcodeItem, iProductUnit } from "../models";
import { useActions } from "@gada-saas/web-core/common/hooks/useActions";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

// Todo Remove this when pr for inventory list is merged
import {
  generateConversion,
  mergeBuyingAndSellingDraft,
  MultiplierMap,
} from "../utils/InventoryFormUtils";
import { BarcodeResult } from "../../list";
import { globalResetAction } from "../../../common/redux/actions";

type EditingSectionType =
  | "none"
  | "InventoryProductCard"
  | "InventoryProductInfoCard"
  | "InventoryStockCard"
  | "InventorySellingCard"
  | "StockReminder"
  | "ConsignorInfoCard";

export type DraftItem = {
  id?: number;
  unitOfMeasurement: {
    storeId: string;
    longName: string;
    id: number;
    shortName: string;
  };
  storeId?: string;
  barcode: BarcodeItem[]; // diganti jadi barcodes (array)
  priority: number;
};

export type ConversionItem = {
  productUnitId?: number;
  smallerProductUnitId?: number;
  unitOfMeasurementId: number;
  smallerUnitOfMeasurementId: number;
  multiplierToSmallerProductUnit: number;
  unitOfMeasurementName: string;
  smallerUnitOfMeasurementName: string;
};

export type ConversionErrorMessages = Record<number | string, string>;

export interface TopupItem {
  productUnitId: number;
  uomName: string;
  uomId: number;
  currentStock: number;
  inventoryId: number;
  newStock: number;
  unitPrice: number;
}

export type IntegrityErrorMessage = {
  from: number;
  fromName: string;
  to: number;
  toName: string;
  serverMultiplier: number;
};

export type SelectedBuyingOUM = {
  productVariantId: number;
  buyingDraftItems: DraftItem[];
};

export type RestockMethod = "damage" | "topup" | "restock" | "notSelected";

type InitialState = {
  dataStore: {
    conversion: Array<ConversionItem>;
    draft: Array<DraftItem>;
    buyingDraft: Array<DraftItem>;
    sellingDraft: Array<DraftItem>;
  };
  uiStore: {
    showConversionModal: boolean;
    conversionTargetField: "buying" | "selling";
    conversionErrorMessages: ConversionErrorMessages;
    integrityErrorMessages: IntegrityErrorMessage[];
    showPriceEditorModal: boolean;
    showOnlineSellingModal: boolean;
    priceEditorTargetIndexField: number;
    showScannerModal: boolean;
    showPrincipalEditorModal: boolean;
    showCategoryEditorModal: boolean;
    editingSection: EditingSectionType;
    showTopupRestockModal: boolean;
    topupRestockStep: number;
    initialTopupFormValue: TopupItem[];
    multiplierMap: MultiplierMap;
    showProductProposalDrawer: boolean;
    totalStockInSmallestUOM: number;
    showDeleteProductVariantModal: boolean;
    showDeleteProductUnitModal: boolean;
    showProductProposalDetailDrawer: boolean;
    smallestUOMId: number;
    smallestUOMName: string | null;
    isDirty: boolean;
    proposedProductUnits: Array<number>;
    showStockAdjustmentModal: boolean;
    restockMethod: RestockMethod;
    showCategoryNameModal: boolean;
    categoryLevel: number;
    productVarientEdited: boolean;
    selectedBuyingUOMs: SelectedBuyingOUM[];
    isInventorySubmitted: boolean;
    barcodeResults: BarcodeResult[];
    skipRefetchOnProductProposalClose: boolean;
  };
};

const initialState: InitialState = {
  dataStore: {
    draft: [],
    buyingDraft: [],
    sellingDraft: [],
    conversion: [],
  },
  uiStore: {
    showConversionModal: false,
    conversionTargetField: "buying",
    conversionErrorMessages: {} as ConversionErrorMessages,
    integrityErrorMessages: [],
    showPriceEditorModal: false,
    priceEditorTargetIndexField: 0,
    showScannerModal: false,
    editingSection: "none",
    showTopupRestockModal: false,
    topupRestockStep: 0,
    initialTopupFormValue: [],
    showOnlineSellingModal: false,
    showPrincipalEditorModal: false,
    showCategoryEditorModal: false,
    multiplierMap: {},
    totalStockInSmallestUOM: 0,
    showProductProposalDrawer: false,
    showDeleteProductVariantModal: false,
    showDeleteProductUnitModal: false,
    showProductProposalDetailDrawer: false,
    smallestUOMId: -1,
    smallestUOMName: null,
    isDirty: true,
    proposedProductUnits: [],
    showStockAdjustmentModal: false,
    restockMethod: "notSelected",
    showCategoryNameModal: false,
    categoryLevel: 2,
    productVarientEdited: false,
    selectedBuyingUOMs: [],
    isInventorySubmitted: false,
    barcodeResults: [],
    skipRefetchOnProductProposalClose: false,
  },
};

const AddCuratedInventorySlice = createSlice({
  name: "curatedInventory",
  initialState,
  reducers: {
    resetState(state) {
      state.dataStore = initialState.dataStore;
      state.uiStore = {
        ...state.uiStore,
        ...omit(initialState.uiStore, [
          "smallestUOMId",
          "smallestUOMName",
          "multiplierMap",
          "totalStockInSmallestUOM",
          "showProductProposalDrawer",
          "showProductProposalDetailDrawer",
          "proposedProductUnits",
          "showStockAdjustmentModal",
          "barcodeResults",
        ]),
      };
    },
    addToDraft(
      state,
      action: PayloadAction<{ type: "selling" | "buying"; item: DraftItem }>
    ) {
      const { type, item } = action.payload;
      if (type === "buying") {
        const lowerPriorityIdx = state.dataStore.buyingDraft.findIndex(
          (draft) => draft.priority > item.priority
        );

        if (lowerPriorityIdx >= 0) {
          state.dataStore.buyingDraft.splice(
            lowerPriorityIdx,
            0,
            action.payload.item
          );
        } else {
          state.dataStore.buyingDraft.push(item);
        }
      } else {
        const lowerPriorityIdx = state.dataStore.sellingDraft.findIndex(
          (draft) => draft.priority > item.priority
        );

        if (lowerPriorityIdx >= 0) {
          state.dataStore.sellingDraft.splice(
            lowerPriorityIdx,
            0,
            action.payload.item
          );
        } else {
          state.dataStore.sellingDraft.push(item);
        }
      }
    },
    removeFromDraft(
      state,
      action: PayloadAction<{ type: "selling" | "buying"; item: DraftItem }>
    ) {
      const { type, item } = action.payload;
      if (type === "buying") {
        state.dataStore.buyingDraft = state.dataStore.buyingDraft.filter(
          (itemDraft) =>
            itemDraft.unitOfMeasurement.id !== item.unitOfMeasurement.id
        );
      } else {
        state.dataStore.sellingDraft = state.dataStore.sellingDraft.filter(
          (itemDraft) =>
            itemDraft.unitOfMeasurement.id !== item.unitOfMeasurement.id
        );
      }
    },
    initDraftOrdering(state) {
      const draft = state.dataStore.draft;
      const buyingDraft = state.dataStore.buyingDraft;
      const sellingDraft = state.dataStore.sellingDraft;

      const unionDraft = mergeBuyingAndSellingDraft(
        draft,
        buyingDraft,
        sellingDraft
      );

      state.dataStore.draft = unionDraft;
    },
    setDraftOrdering(state, action: PayloadAction<DraftItem[]>) {
      state.dataStore.draft = action.payload;
    },
    showConversionModal(state) {
      state.uiStore.showConversionModal = true;
    },
    hideConversionModal(state) {
      state.uiStore.showConversionModal = false;
    },
    setConversionTargetField(
      state,
      action: PayloadAction<"buying" | "selling">
    ) {
      state.uiStore.conversionTargetField = action.payload;
    },
    showPriceEditorModal(state) {
      state.uiStore.showPriceEditorModal = true;
    },
    hidePriceEditorModal(state) {
      state.uiStore.showPriceEditorModal = false;
    },
    setPriceEditorTargetIndexField(state, action: PayloadAction<number>) {
      state.uiStore.priceEditorTargetIndexField = action.payload;
    },
    showScannerModal(state) {
      state.uiStore.showScannerModal = true;
    },
    hideScannerModal(state) {
      state.uiStore.showScannerModal = false;
    },
    switchItemWithPrevious(state, action: PayloadAction<number>) {
      if (action.payload === 0) {
        return;
      }
      const temp = state.dataStore.draft[action.payload - 1];
      state.dataStore.draft[action.payload - 1] =
        state.dataStore.draft[action.payload];
      state.dataStore.draft[action.payload] = temp;
    },
    switchItemWithNext(state, action: PayloadAction<number>) {
      if (action.payload === state.dataStore.draft.length - 1) {
        return;
      }
      const temp = state.dataStore.draft[action.payload + 1];
      state.dataStore.draft[action.payload + 1] =
        state.dataStore.draft[action.payload];
      state.dataStore.draft[action.payload] = temp;
    },
    resyncPriority(state) {
      const draft = state.dataStore.draft;
      const buyingDraft = state.dataStore.buyingDraft;
      const sellingDraft = state.dataStore.sellingDraft;

      const UOMIdToOPriorityLUT: {
        [uomId: number]: number;
      } = {};
      for (let i = 0; i < draft.length; i++) {
        const newPriority = i + 1;
        draft[i].priority = newPriority;
        UOMIdToOPriorityLUT[draft[i].unitOfMeasurement.id] = newPriority;
      }
      state.dataStore.draft = draft;

      // set priority for buying and selling draft
      for (let i = 0; i < buyingDraft.length; i++) {
        const newPriority =
          UOMIdToOPriorityLUT[buyingDraft[i].unitOfMeasurement.id];
        buyingDraft[i].priority = newPriority;
      }

      for (let i = 0; i < sellingDraft.length; i++) {
        const newPriority =
          UOMIdToOPriorityLUT[sellingDraft[i].unitOfMeasurement.id];
        sellingDraft[i].priority = newPriority;
      }

      // sort buying and selling draft
      sellingDraft.sort((d1, d2) => {
        const uom1 = d1.unitOfMeasurement.id;
        const uom2 = d2.unitOfMeasurement.id;

        const priority1 = UOMIdToOPriorityLUT[uom1];
        const priority2 = UOMIdToOPriorityLUT[uom2];

        if (priority1 < priority2) {
          return -1;
        } else if (priority1 > priority2) {
          return 1;
        } else {
          return 0;
        }
      });

      buyingDraft.sort((d1, d2) => {
        const uom1 = d1.unitOfMeasurement.id;
        const uom2 = d2.unitOfMeasurement.id;
        const priority1 = UOMIdToOPriorityLUT[uom1];
        const priority2 = UOMIdToOPriorityLUT[uom2];

        if (priority1 < priority2) {
          return -1;
        } else if (priority1 > priority2) {
          return 1;
        } else {
          return 0;
        }
      });

      state.dataStore.sellingDraft = sellingDraft;
      state.dataStore.buyingDraft = buyingDraft;
    },
    setDrafts(
      state,
      action: PayloadAction<{
        buyingDraft: DraftItem[];
        sellingDraft: DraftItem[];
        draft: DraftItem[];
      }>
    ) {
      state.dataStore.buyingDraft = action.payload.buyingDraft;
      state.dataStore.sellingDraft = action.payload.sellingDraft;
      state.dataStore.draft = action.payload.draft;
    },
    setBuyingDrat(state, action: PayloadAction<DraftItem[]>) {
      state.dataStore.buyingDraft = action.payload;
    },
    setSellingDrat(state, action: PayloadAction<DraftItem[]>) {
      state.dataStore.sellingDraft = action.payload;
    },
    setInitialTopupFormValue(state, action: PayloadAction<TopupItem[]>) {
      state.uiStore.initialTopupFormValue = action.payload;
    },
    initConversionForms(state, action: PayloadAction<iProductUnit[]>) {
      const { draft } = state.dataStore;

      const conversion = generateConversion(draft, action.payload);

      state.dataStore.conversion = conversion;
      state.uiStore.conversionErrorMessages = {};
      state.uiStore.integrityErrorMessages = [];
    },
    setConversionForms(state, action: PayloadAction<ConversionItem[]>) {
      state.dataStore.conversion = action.payload;
      state.uiStore.conversionErrorMessages = {};
      state.uiStore.integrityErrorMessages = [];
    },
    resetConversionForms(state) {
      const conversion = [];
      const multiplierMap = state.uiStore.multiplierMap;
      const validUOMIds: number[] = [];

      for (const [key, value] of Object.entries(multiplierMap)) {
        const obj = Object.entries(value);

        if (obj.length === 0) continue;

        const [smallerKey, multiplier] = obj[0];
        const UOMId = Number(key);
        const smallerUOMId = Number(smallerKey);

        validUOMIds.push(UOMId);
        validUOMIds.push(smallerUOMId);

        const currDraftItem = state.dataStore.draft.find(
          (d) => d.unitOfMeasurement.id === UOMId
        );
        const nextDraftItem = state.dataStore.draft.find(
          (d) => d.unitOfMeasurement.id === smallerUOMId
        );

        if (!currDraftItem || !nextDraftItem) {
          continue;
        }

        const conversionItem: ConversionItem = {
          productUnitId: currDraftItem?.id,
          smallerProductUnitId: nextDraftItem?.id,
          multiplierToSmallerProductUnit: multiplier,
          unitOfMeasurementId: currDraftItem.unitOfMeasurement.id,
          smallerUnitOfMeasurementId: nextDraftItem.unitOfMeasurement.id,
          unitOfMeasurementName: currDraftItem.unitOfMeasurement.longName,
          smallerUnitOfMeasurementName:
            nextDraftItem.unitOfMeasurement.longName,
        };

        conversion.push(conversionItem);
      }

      // remove invalid UOMIds from draft, buyingDraft, sellingDraft
      const draft = state.dataStore.draft.filter((d) =>
        validUOMIds.includes(d.unitOfMeasurement.id)
      );
      const buyingDraft = state.dataStore.buyingDraft.filter((d) =>
        validUOMIds.includes(d.unitOfMeasurement.id)
      );
      const sellingDraft = state.dataStore.sellingDraft.filter((d) =>
        validUOMIds.includes(d.unitOfMeasurement.id)
      );

      state.dataStore.conversion = conversion;
      state.dataStore.draft = draft;
      state.dataStore.buyingDraft = buyingDraft;
      state.dataStore.sellingDraft = sellingDraft;
    },
    setConversionMultiplier(
      state,
      action: PayloadAction<{ index: number; value: number }>
    ) {
      const { index, value } = action.payload;
      state.dataStore.conversion[index].multiplierToSmallerProductUnit = value;
    },
    // remove unclean items from draft, items which are not in conversion
    cleanDraft(state) {
      const { draft, buyingDraft, sellingDraft } = state.dataStore;
      const multiplierMap = state.uiStore.multiplierMap;

      // if multiplierMap is empty, simply remove dirty UOMs [having priority 9999]
      // else remove all UOMs which are not in multiplierMap
      if (isEmpty(multiplierMap)) {
        state.dataStore.buyingDraft = buyingDraft.filter(
          (draft) => draft.priority !== 9999
        );
        state.dataStore.sellingDraft = sellingDraft.filter(
          (draft) => draft.priority !== 9999
        );
        state.dataStore.draft = draft.filter(
          (draft) => draft.priority !== 9999
        );
        return;
      }

      // get all ids inside multiplier map
      const validUOMIds: number[] = [];
      for (const [bigUOM, value] of Object.entries(multiplierMap)) {
        const smallUOM = Object.keys(value);
        validUOMIds.push(Number(bigUOM));
        validUOMIds.push(Number(smallUOM));
      }

      // remove all items from draft not inside validUOMIds
      const newDraft: DraftItem[] = [];
      for (let i = 0; i < draft.length; i++) {
        if (validUOMIds.indexOf(draft[i].unitOfMeasurement.id) !== -1) {
          newDraft.push(draft[i]);
        }
      }
      state.dataStore.draft = newDraft.filter(
        (draft) => draft.priority !== 9999
      );

      // remove all items from buyingDraft not inside validUOMIds
      const newBuyingDraft: DraftItem[] = [];
      for (let i = 0; i < buyingDraft.length; i++) {
        if (validUOMIds.indexOf(buyingDraft[i].unitOfMeasurement.id) !== -1) {
          newBuyingDraft.push(buyingDraft[i]);
        }
      }
      state.dataStore.buyingDraft = newBuyingDraft.filter(
        (draft) => draft.priority !== 9999
      );

      // remove all items from sellingDraft not inside validUOMIds
      const newSellingDraft: DraftItem[] = [];
      for (let i = 0; i < sellingDraft.length; i++) {
        if (validUOMIds.indexOf(sellingDraft[i].unitOfMeasurement.id) !== -1) {
          newSellingDraft.push(sellingDraft[i]);
        }
      }

      state.dataStore.sellingDraft = newSellingDraft.filter(
        (draft) => draft.priority !== 9999
      );
    },
    cleanMultiplierMap(state) {
      // remove all items from multiplierMap with value 0
      const multiplierMap = state.uiStore.multiplierMap;

      const newMultiplierMap: MultiplierMap = {};

      for (const [key, value] of Object.entries(multiplierMap)) {
        const obj = Object.entries(value);

        if (obj.length === 0 && key.length > 0) {
          newMultiplierMap[Number(key)] = {};
          continue;
        }

        if (obj.length === 0) continue;

        const [smallerKey, multiplier] = obj[0];

        if (multiplier === 0) continue;

        newMultiplierMap[Number(key)] = { [Number(smallerKey)]: multiplier };
      }

      state.uiStore.multiplierMap = newMultiplierMap;
    },
    setConversionErrorMessages(
      state,
      action: PayloadAction<ConversionErrorMessages>
    ) {
      state.uiStore.conversionErrorMessages = action.payload;
    },
    setIntegrityErrorMessages(
      state,
      action: PayloadAction<IntegrityErrorMessage[]>
    ) {
      state.uiStore.integrityErrorMessages = action.payload;
    },
    setEditingScreen(state, action: PayloadAction<EditingSectionType>) {
      state.uiStore.editingSection = action.payload;
    },
    showTopupRestockModal(state) {
      state.uiStore.showTopupRestockModal = true;
    },
    setTopupRestockStep(state, action: PayloadAction<number>) {
      state.uiStore.topupRestockStep = action.payload;
    },
    hideTopupRestockModal(state) {
      state.uiStore.showTopupRestockModal = false;
    },
    setMultiplierTable(state, action: PayloadAction<MultiplierMap>) {
      state.uiStore.multiplierMap = action.payload;
    },
    setTotalStockInSmallest(state, action: PayloadAction<number>) {
      state.uiStore.totalStockInSmallestUOM = action.payload;
    },
    setSmallestUOMId(state, action: PayloadAction<number>) {
      state.uiStore.smallestUOMId = action.payload;
    },
    setSmallestUOMName(state, action: PayloadAction<string | null>) {
      state.uiStore.smallestUOMName = action.payload;
    },
    setIsDirty(state, action: PayloadAction<boolean>) {
      state.uiStore.isDirty = action.payload;
    },
    showOnlineSellingModal(state) {
      state.uiStore.showOnlineSellingModal = true;
    },
    hideOnlineSellingModal(state) {
      state.uiStore.showOnlineSellingModal = false;
    },
    showPrincipalEditorModal(state) {
      state.uiStore.showPrincipalEditorModal = true;
    },
    hidePrincipalEditorModal(state) {
      state.uiStore.showPrincipalEditorModal = false;
    },
    showCategoryEditorModal(state) {
      state.uiStore.showCategoryEditorModal = true;
    },
    hideCategoryEditorModal(state) {
      state.uiStore.showCategoryEditorModal = false;
    },
    showProductProposalDrawer(state) {
      state.uiStore.showProductProposalDrawer = true;
    },
    hideProductProposalDrawer(state) {
      state.uiStore.showProductProposalDrawer = false;
    },
    showDeleteProductVariantModal(state) {
      state.uiStore.showDeleteProductVariantModal = true;
    },
    hideDeleteProductVariantModal(state) {
      state.uiStore.showDeleteProductVariantModal = false;
    },
    showDeleteProductUnitModal(state) {
      state.uiStore.showDeleteProductUnitModal = true;
    },
    hideDeleteProductUnitModal(state) {
      state.uiStore.showDeleteProductUnitModal = false;
    },
    addProposedProductUnit(state, action: PayloadAction<number>) {
      state.uiStore.proposedProductUnits.push(action.payload);
    },
    showProductProposalDetailDrawer(state) {
      state.uiStore.showProductProposalDetailDrawer = true;
    },
    hideProductProposalDetailDrawer(state) {
      state.uiStore.showProductProposalDetailDrawer = false;
    },
    setShowStockAdjustmentModal(state, action: PayloadAction<boolean>) {
      state.uiStore.showStockAdjustmentModal = action.payload;
    },
    setRestockMethod(state, action: PayloadAction<RestockMethod>) {
      state.uiStore.restockMethod = action.payload;
    },
    showCategoryNameModal(state) {
      state.uiStore.showCategoryNameModal = true;
    },
    hideCategoryNameModal(state) {
      state.uiStore.showCategoryNameModal = false;
    },
    setCategoryLevel(state, action: PayloadAction<number>) {
      state.uiStore.categoryLevel = action.payload;
    },
    setProductVarientEdited(state, action: PayloadAction<boolean>) {
      state.uiStore.productVarientEdited = action.payload;
    },
    setSelectedBuyingUOMs(state, action: PayloadAction<SelectedBuyingOUM[]>) {
      state.uiStore.selectedBuyingUOMs = action.payload;
    },
    setIsInventorySubmitted(state, action: PayloadAction<boolean>) {
      state.uiStore.isInventorySubmitted = action.payload;
    },
    setBarcodeResults(state, action: PayloadAction<BarcodeResult[]>) {
      state.uiStore.barcodeResults = action.payload;
    },
    setSkipRefetchOnProductProposalClose(state, action: PayloadAction<boolean>) {
      state.uiStore.skipRefetchOnProductProposalClose = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

const { actions } = AddCuratedInventorySlice;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCuratedInventoryActions = () => useActions(actions, undefined);

export default AddCuratedInventorySlice.reducer;
