import { HTTPMethod, RouteItemEntry, RequestObj, HandlerFunc } from "./types";

class Router {
  routes: RouteItemEntry[] = [];

  private _addRoute(
    method: HTTPMethod,
    path: string,
    handler: HandlerFunc<any, any, any, any>
  ) {
    this.routes.push({
      method,
      path,
      handler,
    });
  }

  private _findRoute(req: RequestObj<unknown>) {
    return this.routes.find(
      (route) =>
        route.method === (req.method || "GET").toLowerCase() &&
        route.path.toLowerCase() === req.url.toLowerCase()
    );
  }

  any<RetObj, ReqObj = void, ParamObj = void, QueryType = void>(
    path: string,
    handler: HandlerFunc<RetObj, ReqObj, ParamObj, QueryType>
  ) {
    this._addRoute("get", path, handler);
    this._addRoute("post", path, handler);
    this._addRoute("put", path, handler);
    this._addRoute("patch", path, handler);
    this._addRoute("delete", path, handler);
  }

  get<RetObj, ReqObj = void, ParamObj = void, QueryType = void>(
    path: string,
    handler: HandlerFunc<RetObj, ReqObj, ParamObj, QueryType>
  ) {
    this._addRoute("get", path, handler);
  }

  post<RetObj, ReqObj = void, ParamObj = void, QueryType = void>(
    path: string,
    handler: HandlerFunc<RetObj, ReqObj, ParamObj, QueryType>
  ) {
    this._addRoute("post", path, handler);
  }

  put<RetObj, ReqObj = void, ParamObj = void, QueryType = void>(
    path: string,
    handler: HandlerFunc<RetObj, ReqObj, ParamObj, QueryType>
  ) {
    this._addRoute("put", path, handler);
  }

  patch<RetObj, ReqObj = void, ParamObj = void, QueryType = void>(
    path: string,
    handler: HandlerFunc<RetObj, ReqObj, ParamObj, QueryType>
  ) {
    this._addRoute("patch", path, handler);
  }

  delete<RetObj, ReqObj = void, ParamObj = void, QueryType = void>(
    path: string,
    handler: HandlerFunc<RetObj, ReqObj, ParamObj, QueryType>
  ) {
    this._addRoute("delete", path, handler);
  }

  async listen(req: RequestObj<unknown>, resp?: unknown) {
    const matchedHandler = this._findRoute(req);

    if (matchedHandler) {
      return await matchedHandler.handler(req, resp);
    }

    throw new Error(`no matching handler for ${req.method} ${req.url}`);
  }
}

const router = new Router();

export const responseRouter = new Router();

export default router;
