import { makeStyles } from "@material-ui/core";
import { AppTheme } from "../../theme";
import { pxToRem } from "../../utils";

const useStyles = makeStyles((theme: AppTheme) => ({
  destructive: {
    borderColor: theme.palette.interface.red.primary,
    color: theme.palette.interface.red.primary,
    backgroundColor: theme.palette.interface.white,
    "&&> .MuiTouchRipple-root span": {
      backgroundColor: theme.palette.interface.red.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.interface.red.light,
      borderColor: theme.palette.interface.red.primary,
    },
    "&:disabled": {
      backgroundColor: theme.palette.interface.neutral.light,
      color: theme.palette.interface.neutral.secondary,
    },
  },
  destructiveSmall: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(14),
  },
  outlined: {
    border: `1px solid ${theme.palette.interface.neutral.light}`,
    color: theme.palette.interface.neutral.primary,
    fontSize: 14,
    padding: `${pxToRem(12)} ${pxToRem(8)}`,
  },
  neutral: {
    backgroundColor: theme.palette.interface.neutral.lighter,
    color: theme.palette.interface.neutral.dark,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 18,
    height: 18,
    marginRight: 14,
  },
}));

export const useIconButtonStyles = makeStyles<AppTheme>({
  container: {
    boxSizing: "content-box",
    width: 24,
    height: 24,
    padding: 8,
  },
});

export default useStyles;
