import omit from "lodash/omit";
import { gadaSaasApi } from "../base-rtkq-api";
import { Endpoints } from "../common/api/endpoints";
import {
  AddBankAccountRequest,
  AddBankAccountResponse,
  AgreeTnCResponse,
  DeleteBankAccountRequest,
  DeleteBankAccountResponse,
  GetAllBankAccountsRequest,
  GetAllBankAccountsResponse,
  GetGeolocationRequest,
  GetGeolocationResponse,
  GetStoreSettingRequest,
  GetStoreSettingResponse,
  GetTnCAgreementResponse,
  GetUserInfoResponse,
  IsMarketplaceUserResponse,
  LinkStoresRequest,
  LinkStoresResponse,
  UnlinkStoresRequest,
  UpdateBankAccountRequest,
  UpdateBankAccountResponse,
  UpdateStoreRequest,
  UpdateStoreResponse,
  UpdateStoreSettingRequest,
  UpdateUserRequest,
  UpdateUserResponse,
} from "./models";

const UserApi = gadaSaasApi.injectEndpoints({
  endpoints: (build) => ({
    isMarketplaceUser: build.query<IsMarketplaceUserResponse, void>({
      query: () => ({
        method: "GET",
        url: Endpoints.USER,
      }),
    }),
    getUserInfo: build.query<GetUserInfoResponse, void>({
      query: () => ({
        method: "GET",
        url: Endpoints.GET_USER_INFO,
      }),
      providesTags: ["User"],
      extraOptions: {
        maxRetries: 2,
      },
    }),
    updateUser: build.mutation<UpdateUserResponse, UpdateUserRequest>({
      query: (args) => ({
        method: "PATCH",
        url: Endpoints.USER,
        data: omit(args, "skipRefetchUser"),
      }),
      invalidatesTags: (_result, _error, args) =>
        args.skipRefetchUser ? [] : ["User"],
    }),
    getAllBankAccounts: build.query<
      GetAllBankAccountsResponse,
      GetAllBankAccountsRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_ALL_BANK_ACCOUNTS,
        queryParams: args,
      }),
      extraOptions: {
        withOffline: true,
      },
      providesTags: ["BankAccount"],
    }),
    addBankAccount: build.mutation<
      AddBankAccountResponse,
      AddBankAccountRequest
    >({
      query: (args) => ({
        method: "POST",
        url: Endpoints.ADD_BANK_ACCOUNT,
        data: args,
      }),
      invalidatesTags: ["User"],
    }),
    updateBankAccount: build.mutation<
      UpdateBankAccountResponse,
      UpdateBankAccountRequest
    >({
      query: (args) => ({
        method: "PATCH",
        url: Endpoints.UPDATE_BANK_ACCOUNT,
        data: args,
      }),
      invalidatesTags: ["User"],
    }),
    deleteBankAccount: build.mutation<
      DeleteBankAccountResponse,
      DeleteBankAccountRequest
    >({
      query: (args) => ({
        method: "DELETE",
        url: Endpoints.DELETE_BANK_ACCOUNT,
        routeParams: {
          bankAccountId: args.bankAccountId,
        },
      }),
      invalidatesTags: ["User"],
    }),
    getGeolocation: build.mutation<
      GetGeolocationResponse,
      GetGeolocationRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_GEOLOCATION,
        queryParams: args,
      }),
    }),
    updateStore: build.mutation<UpdateStoreResponse, UpdateStoreRequest>({
      query: (args) => ({
        method: "PATCH",
        url: Endpoints.STORE,
        data: args,
      }),
      invalidatesTags: ["User"],
    }),
    linkStores: build.mutation<LinkStoresResponse, LinkStoresRequest>({
      query: (args) => ({
        method: "POST",
        url: Endpoints.LINK_STORES,
        data: args,
      }),
    }),
    unlinkStores: build.mutation<void, UnlinkStoresRequest>({
      query: (args) => ({
        method: "POST",
        url: Endpoints.UNLINK_STORES,
        data: args,
      }),
      invalidatesTags: ["User"],
    }),
    getStoreSetting: build.mutation<
      GetStoreSettingResponse,
      GetStoreSettingRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.STORE_SETTING,
        queryParams: payload,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    updateStoreSetting: build.mutation<
      GetStoreSettingResponse,
      UpdateStoreSettingRequest
    >({
      query: (payload) => ({
        method: "PATCH",
        url: Endpoints.STORE_SETTING,
        data: payload,
      }),
    }),
    getTnCAgreement: build.query<GetTnCAgreementResponse, void>({
      query: () => ({
        method: "GET",
        url: Endpoints.TNC_AGREEMENT,
      }),
    }),
    agreeTnc: build.mutation<AgreeTnCResponse, void>({
      query: () => ({
        method: "PUT",
        url: Endpoints.TNC_AGREEMENT,
      }),
    }),
  }),
});

export const {
  useIsMarketplaceUserQuery,
  useLazyIsMarketplaceUserQuery,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useUpdateUserMutation,
  useGetAllBankAccountsQuery,
  useAddBankAccountMutation,
  useUpdateBankAccountMutation,
  useDeleteBankAccountMutation,
  useGetGeolocationMutation,
  useUpdateStoreMutation,
  useLinkStoresMutation,
  useUnlinkStoresMutation,
  useGetStoreSettingMutation,
  useUpdateStoreSettingMutation,
  useGetTnCAgreementQuery,
  useAgreeTncMutation,
  useLazyGetAllBankAccountsQuery,
  endpoints: UserApiEndpoints,
} = UserApi;

export default UserApi;
