import { alpha, createTheme, Theme } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import token from "./token";
import { pxToRem } from "./utils";

export const theme: AppTheme = createTheme({
  spacing: 4,
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiAppBar: {
      elevation: 4,
    },
    MuiAccordion: {
      elevation: 0,
    },
    MuiLink: {
      underline: "none",
      style: { lineHeight: 0 },
    },
    MuiCircularProgress: {
      color: "primary",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: pxToRem(4),
          height: pxToRem(4),
        },
        "*::-webkit-scrollbar-thumb": {
          background: token.palette.interface.neutral.primary,
          borderRadius: pxToRem(8),
        },
      },
    },
    MuiPopover: {
      paper: {
        border: 0,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
        borderRadius: 8,
      },
    },
    // Table
    MuiTableCell: {
      root: {
        padding: `${pxToRem(18)} ${pxToRem(16)}`,
        borderBottom: `${pxToRem(1)} solid ${
          token.palette.interface.neutral.light
        }`,
      },
      head: {
        color: token.palette.interface.neutral.primary,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
        fontWeight: 600,
        letterSpacing: "-0.006em",
        padding: `${pxToRem(19)} ${pxToRem(16)} ${pxToRem(9)} ${pxToRem(16)}`,
        borderBottom: `${pxToRem(1)} solid ${
          token.palette.interface.neutral.light
        }`,
      },
      stickyHeader: {
        backgroundColor: token.palette.interface.white,
      },
    },
    MuiTableSortLabel: {
      root: {
        "&$active": {
          color: token.palette.interface.neutral.primary,
          fontSize: pxToRem(14),
          lineHeight: pxToRem(20),
          fontWeight: 600,
          letterSpacing: "-0.006em",
        },
      },
    },
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: token.palette.interface.teal.lighter,
          "&:hover": {
            backgroundColor: token.palette.interface.teal.lighter,
          },
        },
      },
    },
    // Miscellenous
    MuiCard: {
      root: {
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.05)",
      },
    },
    MuiCardContent: {
      root: {
        padding: pxToRem(16),
        "&:last-child": {
          paddingBottom: pxToRem(16),
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: token.palette.interface.teal.dark,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: token.palette.interface.neutral.light,
      },
      vertical: {
        backgroundColor: token.palette.interface.teal.primary,
      },
    },
    MuiSwitch: {
      switchBase: {
        color: token.palette.interface.neutral.primary,
      },
      track: {
        backgroundColor: token.palette.interface.neutral.secondary,
      },
      colorSecondary: {
        "&$checked": {
          color: token.palette.interface.teal.primary,
          "&:hover": {
            backgroundColor: alpha(
              token.palette.interface.teal.lighter as string,
              0.7
            ),
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
        },
        "&$checked + $track": {
          backgroundColor: token.palette.interface.teal.secondary,
        },
        "&$disabled": {
          color: token.palette.interface.neutral.secondary,
        },
        "&$disabled + $track": {
          backgroundColor: token.palette.interface.neutral.light,
          opacity: 1,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: token.palette.interface.teal.primary,
      },
    },
    MuiLinearProgress: {
      root: {
        height: pxToRem(8),
        borderRadius: pxToRem(8),
      },
      colorPrimary: {
        backgroundColor: token.palette.interface.neutral.light,
      },
      bar: {
        borderRadius: pxToRem(8),
      },
      barColorPrimary: {
        backgroundColor: token.palette.interface.teal.primary,
      },
    },
    MuiDrawer: {
      paperAnchorBottom: {
        borderRadius: `${pxToRem(16)} ${pxToRem(16)} 0 0`,
      },
    },
    MuiFab: {
      root: {
        position: "fixed",
        bottom: pxToRem(16),
        right: pxToRem(16),
      },
    },
    MuiChip: {
      root: {
        padding: `${pxToRem(6)} ${pxToRem(16)}`,
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
      },
      label: {
        paddingRight: 0,
        paddingLeft: 0,
      },
      icon: {
        marginRight: pxToRem(4),
      },
    },
    MuiLink: {
      root: {
        textTransform: "none",
        "&:disabled": {
          cursor: "auto",
        },
      },
      button: {
        "&:disabled": {
          cursor: "auto",
        },
      },
    },
    // Button
    MuiButton: {
      root: {
        minHeight: 38,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 30,
        paddingRight: 30,
        fontSize: "1rem",
        color: token.palette.interface.teal.primary,
        borderRadius: pxToRem(8),
      },
      contained: {
        backgroundColor: token.palette.interface.teal.primary,
        color: token.palette.interface.white,
        "&:hover": {
          backgroundColor: token.palette.interface.teal.dark,
        },
        "&$disabled": {
          backgroundColor: token.palette.interface.neutral.light,
          color: token.palette.interface.neutral.secondary,
        },
        "@media (hover: none)": {
          "&:hover": {
            backgroundColor: `${token.palette.interface.teal.dark} !important`,
          },
        },
      },
      containedSecondary: {
        backgroundColor: token.palette.interface.teal.light,
        color: token.palette.interface.teal.dark,
        "&:hover": {
          backgroundColor: token.palette.interface.teal.secondary,
          color: token.palette.interface.white,
        },
      },
      outlined: {
        borderColor: token.palette.interface.teal.primary,
        "&:hover": {
          backgroundColor: token.palette.interface.teal.lighter,
        },
        minHeight: 38,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 30,
        paddingRight: 30,
        fontSize: "1rem",
        color: token.palette.interface.teal.primary,
        borderRadius: pxToRem(8),
      },
      textPrimary: {
        color: token.palette.interface.neutral.dark,
      },
      textSecondary: {
        color: token.palette.interface.neutral.primary,
      },
      startIcon: {
        marginRight: 18,
      },
    },
    MuiRadio: {
      root: {
        color: token.palette.interface.neutral.primary,
        "&:hover": {
          backgroundColor: alpha(
            token.palette.interface.neutral.lighter as string,
            0.7
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      },
      colorSecondary: {
        "&$checked": {
          color: token.palette.interface.teal.primary,
          "&:hover": {
            backgroundColor: alpha(
              token.palette.interface.teal.lighter as string,
              0.7
            ),
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
          "&$disabled": {
            color: token.palette.interface.neutral.secondary,
          },
        },
        "&$disabled": {
          color: token.palette.interface.neutral.secondary,
        },
      },
    },
    // Form
    MuiFormGroup: {
      root: {
        display: "flex",
        flex: 1,
      },
    },
    MuiFormHelperText: {
      root: {
        color: token.palette.interface.neutral.primary,
        fontWeight: 400,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
        letterSpacing: "-0.006em",
        marginTop: pxToRem(8),
        "&$error": {
          color: token.palette.interface.red.primary,
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiFormControlLabel: {
      root: {
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
      },
      label: {
        width: "100%",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: token.palette.interface.teal.primary,
        },
        "&$focused $notchedOutline": {
          borderColor: token.palette.interface.teal.primary,
        },
        "&$error": {
          borderColor: token.palette.interface.red.primary,
        },
      },
      input: {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
        padding: `${pxToRem(14)} ${pxToRem(16)}`,
        color: token.palette.interface.neutral.dark,
        borderColor: token.palette.interface.neutral.secondary,

        "&::placeholder": {
          color: token.palette.interface.neutral.primary,
          opacity: 1,
        },
        "&:disabled": {
          background: token.palette.interface.neutral.light,
          color: token.palette.interface.neutral.primary,
        },
      },
      inputMultiline: {
        padding: `${pxToRem(14)} ${pxToRem(16)}`,
      },
      multiline: {
        padding: 0,
      },
    },
    MuiInputLabel: {
      root: {
        marginBottom: pxToRem(8),
      },
    },
    MuiCheckbox: {
      root: {
        color: token.palette.interface.neutral.primary,
        "&:hover": {
          backgroundColor: alpha(
            token.palette.interface.neutral.lighter as string,
            0.7
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      },
      colorSecondary: {
        "&$checked": {
          color: token.palette.interface.teal.primary,
          "&:hover": {
            backgroundColor: alpha(
              token.palette.interface.teal.lighter as string,
              0.7
            ),
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
        },
        "&$disabled": {
          color: token.palette.interface.neutral.secondary,
        },
      },
    },
    // Navigation
    MuiBottomNavigation: {
      root: {
        zIndex: 1,
        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        "& + &": {
          borderLeft: `1px solid ${token.palette.interface.neutral.light}`,
        },
      },
      wrapper: {
        display: "flex",
        flexDirection: "row",
        "& > svg": {
          marginRight: pxToRem(16),
        },
        "& > span": {
          fontSize: "14px",
        },
      },
    },
    // Layout
    MuiContainer: {
      root: {
        backgroundColor: token.palette.interface.teal.dark,
      },
    },
    MuiAppBar: {
      root: {
        borderBottom: `1px solid ${token.palette.interface.neutral.light}`,
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: token.palette.interface.white,
        color: token.palette.interface.neutral.dark,
      },
      regular: {
        paddingLeft: pxToRem(48),
        paddingRight: 0,
      },
    },
    // Dialog / Modal
    MuiDialogTitle: {
      root: {
        padding: `${pxToRem(16)} ${pxToRem(24)} ${pxToRem(0)}`,
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${pxToRem(16)} ${pxToRem(24)}`,
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${pxToRem(16)} ${pxToRem(24)}`,
      },
    },
    // Stepper
    MuiStepper: {
      root: {
        padding: 0,
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: 0,
        paddingRight: pxToRem(4),
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
    MuiStepConnector: {
      root: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: pxToRem(4),
        flex: 1,
        borderRadius: pxToRem(16),
        backgroundColor: token.palette.interface.teal.light,
      },
      alternativeLabel: {
        position: "unset",
        top: 0,
        left: 0,
        right: 0,
      },
      active: {
        backgroundColor: token.palette.interface.teal.primary,
      },
      completed: {
        backgroundColor: token.palette.interface.teal.primary,
      },
    },
    MuiStepLabel: {
      root: {
        "&$alternativeLabel": {
          marginTop: pxToRem(-10),
        },
      },
      label: {
        "&$alternativeLabel": {
          display: "flex",
          flex: 1,
          alignItems: "center",
        },
      },
    },
    // Accordion
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: 0,
        },
        "&:before": {
          display: "none",
        },
        "&$disabled": {
          backgroundColor: token.palette.interface.white,
        },
      },
      rounded: {
        border: `${pxToRem(1)} solid ${
          token.palette.interface.neutral.secondary
        }`,
        borderBottomWidth: 0,
        borderRadius: 0,
        "&:first-of-type": {
          borderTopLeftRadius: pxToRem(8),
          borderTopRightRadius: pxToRem(8),
        },
        "&:last-child": {
          borderBottomLeftRadius: pxToRem(8),
          borderBottomRightRadius: pxToRem(8),
          borderBottomWidth: pxToRem(1),
          // Fix a rendering issue on Edge
          "@supports (-ms-ime-align: auto)": {
            borderBottomLeftRadius: pxToRem(8),
            borderBottomRightRadius: pxToRem(8),
            borderBottomWidth: pxToRem(1),
          },
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: `${pxToRem(16)} ${pxToRem(26)}`,
        minHeight: 0,
        "&$expanded": {
          minHeight: 0,
        },
        "&$disabled": {
          opacity: 1,
        },
      },
      content: {
        display: "unset",
        margin: 0,
        "&$expanded": {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        // Work-around
        borderBottomLeftRadius: pxToRem(8),
        borderBottomRightRadius: pxToRem(8),
        padding: 0,
        borderTop: `${pxToRem(1)} solid ${
          token.palette.interface.neutral.secondary
        }`,
        backgroundColor: token.palette.interface.neutral.lighter,
      },
    },
    MuiList: {
      padding: {
        paddingTop: pxToRem(24),
        paddingBottom: pxToRem(16),
      },
      root: {
        backgroundColor: token.palette.interface.white,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 0,
        "&$selected": {
          backgroundColor: token.palette.interface.teal.lighter,
        },
      },
      gutters: {
        paddingLeft: pxToRem(28),
        paddingRight: pxToRem(28),
        paddingBottom: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
        marginRight: pxToRem(16),
      },
    },
    MuiListItemText: {
      root: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        paddingTop: pxToRem(16),
        paddingBottom: pxToRem(16),
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `${pxToRem(1)} solid ${
          token.palette.interface.neutral.light
        }`,
      },
      indicator: {
        backgroundColor: token.palette.interface.teal.primary,
      },
    },
    MuiTab: {
      textColorInherit: {
        color: token.palette.interface.neutral.primary,
        opacity: 1,
        "&$selected": {
          color: token.palette.interface.teal.primary,
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: token.typography,
  palette: token.palette,
});

export interface AppTheme extends Omit<Theme, "palette" | "typography"> {
  palette: PaletteOptions;
  typography: TypographyOptions;
}

export default theme;
