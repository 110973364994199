export enum Channels {
  SAAS = "SAAS",
  MARKETPLACE = "MARKETPLACE",
  ALL = "ALL",
}

export enum DurationTypes {
  ALL = "All",
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  ANNUALLY = "Annually",
  PERIOD = "Period",
}

export type GlobalReportState = {
  durationType: DurationTypes; // enum(Daily, Weekly, Monthly, Annually, Period),
  durationStart: string | null; //, required only if durationType is period (yyyy-MM-DD)
  durationEnd: string | null; //, required only if durationType is period (yyyy-MM-DD)
  channel: Channels; // enum(saas, marketplace, all),
};

export type GlobalReportAPIRequest = GlobalReportState & { storeId: string };

export interface DeltaCalculation {
  type: DeltaCalculationType;
  currency: string;
  delta: string;
}

export enum DeltaCalculationType {
  ABSOLUTE = "absolute",
  PERCENTAGE = "percentage",
}

export enum PaymentStatus {
  UNSOLD = "Unsold",
  UNPAID = "Unpaid",
  PARTIALLY_PAID = "Partially Paid",
  PAID = "Paid",
}
