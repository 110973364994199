import router from "../router";
import { v4 as uuidv4 } from "uuid";
import { Endpoints } from "../../../../common/api/endpoints";
import { PaymentStatus } from "../../../../report/types";
import {
  CreateCartAPIRequest,
  CartAPIResponse,
  CartAddItemAPIRequest,
  CartCheckoutAPIResponse,
  CartCheckoutAPIRequest,
  GetCartByIdAPIRequest,
  CartCancelAPIResponse,
  CartSavedListAPIResponse,
  CartSavedListAPIRequest,
  iCartSaved,
  CartSaveAPIRequest,
  CartDiscountAPIRequest,
  CartClearItemAPIRequest,
  DeleteCartDiscountRequest,
  UpdateCartDIscountAPIRequest,
  CartAddCustomItemAPIRequest,
  CartUpdateCustomItemAPIRequest,
  CartClearCustomItemAPIRequest,
  CartActivateAPIRequest,
  GetLocalCartAPIRequest,
  GetLocalCartAPIResponse,
  CreateDiscountItemAPIRequest,
  UpdateDiscountItemAPIRequest,
  DeleteDiscountItemAPIRequest,
} from "../../../../pos/cart/models";
import { DiscountItemTypes } from "../../../../pos/cart/types";
import { ItemType, iDiscountItem } from "../../../../pos/models";
import { DiscountTypes } from "../../../../pos/payment";
import {
  db,
  IndexedCartItem,
  S3InventoryItem,
  SavedCartsRequestQueueItem,
} from "../../../../inventory/offline";
import {
  formatDateToString,
  getPlaceholderResponse,
  recalculateOverallPrices,
} from "../utils";
import { iPriceTier } from "../../../../inventory";
import { CartQueryType, CartStatus } from "../../../../pos/cart/constants";
import { OfflineSaveCartAPIRequest } from "../../../../offline/types";

router.post<CartAPIResponse, CreateCartAPIRequest>(
  Endpoints.CART,
  async (req, _resp) => {
    // Create uuid
    const { cashierId, storeId } = req.body;
    const cart_id = uuidv4();
    const today = new Date();
    const newCart: IndexedCartItem = {
      cartCustomList: [],
      cartItemList: [],
      cartDiscountList: [],
      cartStatus: CartStatus.ACTIVE,
      cashierId: cashierId,
      cashierName: "",
      currency: "IDR",
      createdAt: formatDateToString(today),
      updatedAt: formatDateToString(today),
      customer: null,
      grossAmount: "0",
      id: cart_id,
      netAmount: "0",
      paymentStatus: PaymentStatus.UNPAID,
      storeId: storeId,
      totalItems: 0,
      totalPayment: "0",
      isOfflineCart: true,
    };
    await db.carts.add(newCart);

    return {
      data: newCart,
    };
  }
);

router.put<CartAPIResponse, CartAddItemAPIRequest>(
  Endpoints.ADD_ITEM_TO_CART,
  async (req, _resp) => {
    /**
     * Offline Flow Handler
     */
    // Create uuid
    const { inventoryId, cartId, quantity, storeId } = req.body;
    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const userInfos = await db.userInfo
      .where("storeId")
      .equals(storeId)
      .limit(1)
      .toArray();

    const storeSettings = await db.storeSettings
      .where("storeId")
      .equals(storeId)
      .limit(1)
      .toArray();

    let curr_cart = curr_carts[0];
    const currUser = userInfos[0];
    const currStoreSetting = storeSettings[0];

    if (!curr_cart) {
      // If we don't have curr_cart, then create a new cart
      curr_cart = {
        cartCustomList: [],
        cartDiscountList: [],
        cartItemList: [],
        cartStatus: CartStatus.ACTIVE,
        cashierId: currUser.userInfo.id,
        cashierName: currUser.userInfo.name,
        createdAt: "",
        currency: "",
        customer: null,
        grossAmount: "",
        id: cartId,
        netAmount: "",
        paymentStatus: PaymentStatus.UNPAID,
        storeId: storeId,
        totalItems: 0,
        totalPayment: "",
        updatedAt: "",
        tax: {
          isTaxActive: currStoreSetting.isTaxActive,
          isTaxAfterDiscount: currStoreSetting.isTaxAfterDiscount,
          isTaxIncludeInPrice: currStoreSetting.isTaxIncludeInPrice,
          taxPercentageAmount: String(currStoreSetting.taxPercentageAmount),
          totalTaxAmount: "0",
        },
        isOfflineCart: true,
      };
      await db.carts.add(curr_cart);
    }

    const currProductVariants = await db.inventories
      .where("inventoryIds")
      .equals(inventoryId)
      .limit(1)
      .toArray();

    const currProductVariant = currProductVariants[0];

    // TODO :
    // Handle if not found
    if (!currProductVariant) {
      const placeholderResponse = getPlaceholderResponse({
        storeId,
        currUser,
      });

      return {
        data: placeholderResponse,
      };
    }

    const currInventory = currProductVariant.inventories.find(
      (i) => i.inventoryId === inventoryId
    ) as S3InventoryItem;
    const currPriceTier = currInventory.priceTiers
      .sort((p1, p2) => p2.minimumQuantity - p1.minimumQuantity)
      .find((p) => quantity >= p.minimumQuantity) as iPriceTier;
    const currCreatedAt = currInventory.createdAt;

    const cartItemIndex = curr_cart.cartItemList.findIndex(
      (i) => i.inventoryId === inventoryId
    );
    const isCurrCartItemContainsNewInv = cartItemIndex >= 0;

    let discount: string | null = null;
    let itemDiscount: iDiscountItem | null = null;

    // Check for possible Item Discount
    if (isCurrCartItemContainsNewInv) {
      const cartItemDiscount =
        curr_cart.cartItemList[cartItemIndex].itemDiscount;
      if (cartItemDiscount !== null) {
        const cartItemDiscountType = cartItemDiscount.discountType;

        itemDiscount = {
          ...cartItemDiscount,
          ...(cartItemDiscount.promotionQuantityApplied !== null && {
            promotionQuantityApplied:
              cartItemDiscount.promotionQuantityApplied <
              curr_cart.cartItemList[cartItemIndex].quantity
                ? cartItemDiscount.promotionQuantityApplied
                : quantity,
          }),
        };
        discount = curr_cart.cartItemList[cartItemIndex].discount;

        if (itemDiscount.promotionId !== null) {
          if (itemDiscount.discountType === "PERCENTAGE") {
            discount = (
              Number(itemDiscount.amount) *
              0.01 *
              Number(currPriceTier.unitPrice) *
              (itemDiscount.promotionQuantityApplied ?? 0)
            ).toString();
          } else if (itemDiscount.discountType === "FLAT") {
            discount = (
              Number(itemDiscount.amount) *
              ((itemDiscount.promotionQuantityApplied ?? 0) /
                curr_cart.cartItemList[cartItemIndex].quantity)
            ).toString();
          }
        } else {
          // Need to recalculate for percentage discount
          if (cartItemDiscountType === DiscountItemTypes.PERCENTAGE) {
            // This is an actual amount (1 free then amount is 1, 10% then amount is 10, etc.)
            // not the total amount
            const cartItemDiscountAmount = cartItemDiscount.amount;

            const newCartItemPrice = Number(currPriceTier.unitPrice) * quantity;

            discount = (
              newCartItemPrice *
              (Number(cartItemDiscountAmount) / 100)
            ).toString();
          }
        }

        itemDiscount = {
          ...itemDiscount,
          ...(itemDiscount.promotionId !== null &&
            itemDiscount.discountType === "FLAT" &&
            discount !== null && { amount: discount }),
          totalDiscount: {
            ...itemDiscount.totalDiscount,
            ...(discount !== null && {
              amount: discount,
            }),
          },
        };

        if (isCurrCartItemContainsNewInv) {
          curr_cart.cartItemList[cartItemIndex].itemDiscount = itemDiscount;
          curr_cart.cartItemList[cartItemIndex].discount = discount;
        }
      }
    }
    // End of Check Item Discount

    const today = new Date();
    const newFormattedDate = formatDateToString(today);
    const newCartItemId = uuidv4();

    // Check stock
    if (currInventory.convertedSellableStock < quantity) {
      throw {
        status: 400,
        err: {
          // Just to fulfill minimum shape requested by pos/slice
          config: {
            data: JSON.stringify(req.body),
          },
          data: {
            data: {
              storeId,
              inventoryId,
              productVariantId: currProductVariant.productVariantId,
              availableStock: currInventory.sellableStock,
              requestedQuantity: quantity,
              uomName: currInventory.unitOfMeasurement.longName,
            },
            errorCode: "INSUFFICIENT_STOCK",
            message: "Jumlah stok barang tidak mencukupi.",
          },
        },
      };
    }

    const updatedObject: ItemType = {
      // basePrice Defintiion : price when we buy some stock from supplier
      basePrice: String(currProductVariant.basePriceInSmallestUom),
      // categoryId Defintion : category of inventory
      // Need backend to update api so inventory db contain categoryId
      categoryId: -1,
      categoryName: "",
      consignorId: -1,
      consignorName: "",
      createdAt: currCreatedAt ? currCreatedAt : newFormattedDate,
      discount,
      // id of cart item..
      id: newCartItemId,
      inventoryId: inventoryId,
      // for now it is always false, because we assume every item is refundable
      isNonRefundable: false,
      // NetPrice defintion : (unitPrice * quantity) - discount per item
      // TotalPrice Defintion : (unitPrice * quantity)
      netPrice: String(quantity * currPriceTier.unitPrice - Number(discount)),
      // price defintion: unitPrice according applied price tier
      price: String(currPriceTier.unitPrice),
      productUnitId: currInventory.productUnitId,
      productVariantId: currProductVariant.productVariantId,
      productVariantName: currProductVariant.productVariantName,
      quantity: quantity,
      // quantityInSmallestUOM is ignorable as it is not being used
      quantityInSmallestUom: currInventory.multiplierToSmallest * quantity,
      // Refunds is ignorable while creating cart. it is only important in refund flow,
      // which currently is not enabled for offline state
      refundQuantity: 0,
      refundQuantityInSmallestUom: 0,
      refundSmallestUomId: 0,
      refundSmallestUomName: "",
      // sellerFee : only marketplace who have this field
      sellerFee: "",
      // Smallest UOMID : not being used
      smallestUomId: 0,
      smallestUomName: currProductVariant.smallestUomLongName,
      // totalPrice Defintiion : (applied price tier  unit* quantity),
      // since discount is 0 then it is equal to net price for now
      totalPrice: String(quantity * currPriceTier.unitPrice),
      // Anything related to refund is ignorable when creating a cart
      totalRefundAmount: "",
      totalRefundChargesAmount: "",
      totalRefundDiscountAmount: "",
      uomId: currInventory.unitOfMeasurement.id,
      uomName: currInventory.unitOfMeasurement.longName,
      updatedAt: newFormattedDate,
      itemDiscount,
    };

    const newCartItemList = isCurrCartItemContainsNewInv
      ? curr_cart.cartItemList.map((c) => {
          if (c.inventoryId !== inventoryId) {
            return c;
          }

          return {
            ...c,
            quantity: quantity,
            // NetPrice defintion : (unitPrice * quantity) - discount per item
            // TotalPrice Defintion : (unitPrice * quantity)
            totalPrice: String(quantity * currPriceTier.unitPrice),
            netPrice: String(
              quantity * currPriceTier.unitPrice - Number(discount)
            ),
            discount,
            itemDiscount,
            price: String(currPriceTier.unitPrice),
          };
        })
      : curr_cart.cartItemList.concat(updatedObject);

    const {
      totalItems,
      netAmount,
      tax,
      grossAmount,
      cartDiscountList,
    } = recalculateOverallPrices({
      cartCustomItemList: curr_cart.cartCustomList,
      cartItemList: newCartItemList,
      cartDiscountList: curr_cart.cartDiscountList,
      taxSettings: {
        isTaxActive: currStoreSetting.isTaxActive,
        isTaxAfterDiscount: currStoreSetting.isTaxAfterDiscount,
        isTaxIncludeInPrice: currStoreSetting.isTaxIncludeInPrice,
        taxPercentageAmount: String(currStoreSetting.taxPercentageAmount),
      },
    });

    const new_cart: IndexedCartItem = {
      ...curr_cart,
      cartDiscountList,
      tax,
      cartItemList: newCartItemList,
      // grossAmount Definition : cartitemlist.totalPrice + cartcustom list.totalPrice, iterate all the items
      grossAmount: grossAmount.toString(),
      // netAmount Defintion : cartitemlist.totalPrice + cartcustom list.totalPrice - cartDiscountList.totalDiscount + tax, iterate semua
      netAmount: netAmount.toString(),
      // totalItems Defintiion : sum of all quantity, exclude custom Item
      totalItems,
      updatedAt: formatDateToString(today),
      updatedObject,
      isOfflineCart: true,
    };

    await db.carts.put(new_cart);

    return {
      data: new_cart,
    };
  }
);

router.post<CartCheckoutAPIResponse, CartCheckoutAPIRequest>(
  Endpoints.CHECKOUT_CART,
  async (req, _resp) => {
    /**
     * Offline Flow Handler
     */
    // Create uuid
    const { cartId, storeId } = req.body;
    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const userInfos = await db.userInfo
      .where("storeId")
      .equals(storeId)
      .limit(1)
      .toArray();

    const curr_cart = curr_carts[0];
    const currUser = userInfos[0];

    const today = new Date();
    const newFormattedDate = formatDateToString(today);

    // TODO :
    // Handle if not found
    if (!curr_cart) {
      return {
        data: {
          cartCustomList: [],
          cartDiscountList: [],
          cartItemList: [],
          cartStatus: CartStatus.ACTIVE,
          cashierId: currUser.userInfo.id,
          cashierName: "",
          categoryId: -1,
          categoryName: "",
          changedInventory: [],
          createdAt: newFormattedDate,
          currency: "",
          customer: null,
          customerId: "",
          deletedInventory: [],
          discount: "0",
          grossAmount: "0",
          id: cartId,
          netAmount: "0",
          paymentStatus: PaymentStatus.UNPAID,
          quantityInSmallestUom: -1,
          refundQuantity: -1,
          smallestUomId: -1,
          smallestUomName: "",
          storeId: storeId,
          totalItems: 0,
          // total user pay
          totalPayment: "0",
          updatedAt: newFormattedDate,
          // isOfflineCart : Just to mark if a cart is generated while offline
          isOfflineCart: true,
        },
      };
    }

    // Substract inventories for every cartItesm
    for (let i = 0; i < curr_cart.cartItemList.length; i++) {
      const currItem = curr_cart.cartItemList[i];
      const inventoryId = currItem.inventoryId;
      const currProductVariants = await db.inventories
        .where("inventoryIds")
        .equals(inventoryId)
        .limit(1)
        .toArray();

      const currInventory = currProductVariants[0].inventories.find(
        (i) => i.inventoryId === inventoryId
      ) as S3InventoryItem;

      const newInventory: S3InventoryItem = {
        ...currInventory,
        sellableStock: currInventory.sellableStock - currItem.quantity,
      };

      const newProductVariant = {
        ...currProductVariants[0],
        inventories: [
          ...currProductVariants[0].inventories.filter(
            (inv) => inv.inventoryId !== inventoryId
          ),
          newInventory,
        ],
      };

      await db.inventories.put(newProductVariant);
    }

    const new_cart: IndexedCartItem = {
      ...curr_cart,
      cartStatus: CartStatus.CHECKOUT,
      updatedAt: formatDateToString(today),
      isOfflineCart: true,
    };

    await db.carts.put(new_cart);

    const response: CartCheckoutAPIResponse["data"] = {
      cartCustomList: curr_cart.cartCustomList,
      cartDiscountList: curr_cart.cartDiscountList,
      cartItemList: curr_cart.cartItemList.map((i) => ({
        ...i,
        reservedInventoryId: 0,
      })),
      cartStatus: CartStatus.ACTIVE,
      cashierId: currUser.userInfo.id,
      changedInventory: [],
      createdAt: newFormattedDate,
      currency: "IDR",
      customer: null,
      customerId: "",
      deletedInventory: [],
      grossAmount: curr_cart.grossAmount,
      id: curr_cart.id,
      netAmount: curr_cart.netAmount,
      paymentStatus: PaymentStatus.UNPAID,
      storeId: storeId,
      totalItems: curr_cart.totalItems,
      totalPayment: "0",
      updatedAt: newFormattedDate,
    };

    return {
      data: response,
    };
  }
);

router.post<CartAPIResponse, GetCartByIdAPIRequest>(
  Endpoints.GET_CART_BY_ID,
  async (req, _resp) => {
    /**
     * Offline Flow Handler
     */
    // Create uuid
    const { cartId } = req.body;
    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const cartDetail = curr_carts[0];

    return {
      data: cartDetail,
    };
  }
);

router.get<CartAPIResponse, void, GetCartByIdAPIRequest>(
  Endpoints.GET_CART_BY_ID,
  async (req, _resp) => {
    /**
     * Offline Flow Handler
     */
    // Create uuid
    const { cartId } = req.params;
    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const cartDetail = curr_carts[0];

    return {
      data: cartDetail,
    };
  }
);

router.patch<CartCancelAPIResponse, { id: string[]; storeId: string }>(
  Endpoints.CANCEL_CART,
  async (req, _resp) => {
    const { id: cartIds } = req.body;

    cartIds.forEach(async (cartId) => {
      await db.carts.delete(cartId);
      await db.savedCartsRequestQueue.where("cartId").equals(cartId).delete();
    });

    /**
     * Offline Flow Handler
     */
    return { data: null };
  }
);

router.get<
  CartSavedListAPIResponse,
  void,
  void,
  CartSavedListAPIRequest & { isOffline?: boolean }
>(Endpoints.CART, async (req, _resp) => {
  const { cartStatus, queryType, storeId, isOffline } = req.query;
  const page = Number(req.query.page || 1);
  const pageSize = Number(req.query.pageSize || 1);

  let currSavedCart = [];

  currSavedCart = await db.carts
    .where("storeId")
    .equals(storeId)
    .and((c) => {
      if (queryType === undefined) {
        return true;
      }

      if (queryType === CartQueryType.STATUS) {
        if (cartStatus === undefined) {
          return true;
        } else {
          return c.cartStatus === cartStatus;
        }
      }
      return true;
    })
    .and((c) => {
      if (isOffline && c.isOfflineCart) {
        return true;
      }
      if (!isOffline && !c.isOfflineCart) {
        return true;
      }
      return false;
    })
    .limit(pageSize)
    .toArray();

  const totalSavedCarts = await db.carts.count();

  const savedCarts: iCartSaved[] = currSavedCart.map((s) => ({
    ...s,
    customer: s.customer ?? {
      createdAt: "",
      id: "",
      name: "",
      phoneNumber: "",
      storeId: "",
      updatedAt: "",
      address: "",
    },
  }));

  // TODO :

  return {
    data: savedCarts,
    page: page,
    total: totalSavedCarts,
  };
});

router.post<CartAPIResponse, CartSaveAPIRequest["data"]>(
  Endpoints.SAVE_CART,
  async (req, _resp) => {
    const {
      cartId,
      customerAddress,
      customerId,
      customerName,
      customerPhoneNumber,
      storeId,
    } = req.body;

    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const userInfos = await db.userInfo
      .where("storeId")
      .equals(storeId)
      .limit(1)
      .toArray();

    const curr_cart = curr_carts[0];
    const currUser = userInfos[0];

    const currStore = currUser.userStoreList.filter((s) => {
      return s.storeId == storeId;
    })[0];

    const today = new Date();
    // TODO :
    // Handle if not found
    if (!curr_cart) {
      const placeholderResponse = getPlaceholderResponse({
        storeId,
        currUser,
        cartId,
        paymentStatus: PaymentStatus.UNPAID,
      });

      return {
        data: placeholderResponse,
      };
    }

    const new_cart: IndexedCartItem = {
      ...curr_cart,
      cartStatus: CartStatus.SAVED,
      customer: {
        id: customerId,
        name: customerName,
        address: customerAddress,
        phoneNumber: customerPhoneNumber,
        storeId: storeId,
        updatedAt: formatDateToString(today),
        createdAt: formatDateToString(today),
      },
      updatedAt: formatDateToString(today),
      isOfflineCart: true,
    };

    // Construct payload
    const offlineSaveCartRequestQueueData: OfflineSaveCartAPIRequest = {
      cartId,
      cashierId: curr_cart.cashierId,
      cashierName: curr_cart.cashierName,
      customItemList: curr_cart.cartCustomList,
      customer: {
        customerAddress,
        customerId,
        customerName,
        customerPhoneNumber,
      },
      discountList: curr_cart.cartDiscountList,
      grossAmount: Number(curr_cart.grossAmount),
      itemList: curr_cart.cartItemList.map((i) => ({
        basePrice: Number(i.basePrice),
        inventoryId: i.inventoryId,
        lastUpdatedAt: i.updatedAt,
        price: Number(i.price),
        quantity: i.quantity,
      })),
      netAmount: Number(curr_cart.netAmount),
      salesPerson: null,
      storeAddress: currStore.storeAddress,
      storeId,
      storeName: currStore.storeName,
      tax: null,
      totalDiscount: 0,
      transactionDate: formatDateToString(today),
    };

    const savedCartRequest: SavedCartsRequestQueueItem = {
      apiResponseStatus: "QUEUED",
      cartId: curr_cart.id,
      data: offlineSaveCartRequestQueueData,
      method: "post",
      requestQueueId: uuidv4(),
      retryCount: 0,
      storeId,
      url: Endpoints.OFFLINE_CART_SAVE,
    };

    await db.carts.put(new_cart);

    await db.savedCartsRequestQueue.add(savedCartRequest);

    return {
      data: new_cart,
      message: [`Successfully save cart ${formatDateToString(today)}`],
    };
  }
);

router.delete<CartAPIResponse, void, CartClearItemAPIRequest>(
  Endpoints.CART_ITEM,
  async (req, _resp) => {
    const { cartId, inventoryId } = req.params;

    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const curr_cart = curr_carts[0];

    const today = new Date();
    // TODO :
    // Handle if not found
    if (!curr_cart) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
        paymentStatus: PaymentStatus.UNPAID,
      });

      return {
        data: placeholderResponse,
      };
    }

    const newCartItemList = curr_cart.cartItemList.filter((c) => {
      return c.inventoryId !== Number(inventoryId);
    });

    const {
      cartDiscountList,
      grossAmount,
      netAmount,
      tax,
      totalItems,
    } = recalculateOverallPrices({
      cartCustomItemList: curr_cart.cartCustomList,
      cartItemList: newCartItemList,
      cartDiscountList: curr_cart.cartDiscountList,
      taxSettings: curr_cart.tax,
    });

    const new_cart: IndexedCartItem = {
      ...curr_cart,
      // TOOD: Upsert logic, currently this one only for update, if the array size empty then it remains empty
      cartItemList: newCartItemList,
      cartDiscountList,
      grossAmount: grossAmount.toString(),
      netAmount: netAmount.toString(),
      totalItems,
      updatedAt: formatDateToString(today),
      tax,
      isOfflineCart: true,
    };

    await db.carts.put(new_cart);

    return {
      data: new_cart,
    };
  }
);

router.post<CartAPIResponse, CartDiscountAPIRequest>(
  Endpoints.CART_DISCOUNT,
  async (req, _resp) => {
    const { amount, cartId, discountName, discountType } = req.body;

    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const curr_cart = curr_carts[0];

    const today = new Date();
    // TODO :
    // Handle if not found, impossible state
    if (!curr_cart) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
        paymentStatus: PaymentStatus.UNPAID,
      });

      return {
        data: placeholderResponse,
      };
    }

    const discountId = uuidv4();
    const totalDiscount =
      discountType === "Price"
        ? amount
        : (
            (parseInt(amount, 10) / 100) *
            parseFloat(curr_cart.grossAmount)
          ).toString();

    const newCartDiscountList = [
      {
        id: discountId,
        createdAt: formatDateToString(today),
        updatedAt: formatDateToString(today),
        amount,
        discountType: discountType as DiscountTypes,
        discountName,
        totalDiscount: totalDiscount,
      },
    ];

    const {
      cartDiscountList,
      grossAmount,
      netAmount,
      tax,
      totalItems,
    } = recalculateOverallPrices({
      cartCustomItemList: curr_cart.cartCustomList,
      cartItemList: curr_cart.cartItemList,
      cartDiscountList: newCartDiscountList,
      taxSettings: curr_cart.tax,
    });

    const new_cart: IndexedCartItem = {
      ...curr_cart,
      // TOOD: Upsert logic, currently this one only for update, if the array size empty then it remains empty
      cartDiscountList,
      grossAmount: grossAmount.toString(),
      netAmount: netAmount.toString(),
      tax,
      totalItems,
      updatedAt: formatDateToString(today),
      updatedObject: {
        amount,
        discountName,
        discountType: discountType as DiscountTypes,
        id: discountId,
        totalDiscount: totalDiscount,
        createdAt: formatDateToString(today),
        updatedAt: formatDateToString(today),
      },
      isOfflineCart: true,
    };

    await db.carts.put(new_cart);

    return {
      data: new_cart,
    };
  }
);

router.delete<CartAPIResponse, void, DeleteCartDiscountRequest>(
  Endpoints.CART_DISCOUNT_BY_ID,
  async (req, _resp) => {
    const { cartId, discountId } = req.params;

    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const curr_cart = curr_carts[0];

    const today = new Date();
    // TODO :
    // Handle if not found
    if (!curr_cart) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
      });

      return {
        data: placeholderResponse,
      };
    }

    const newCartDiscountList = curr_cart.cartDiscountList.filter(
      (c) => c.id !== discountId
    );

    const {
      cartDiscountList,
      grossAmount,
      netAmount,
      tax,
      totalItems,
    } = recalculateOverallPrices({
      cartCustomItemList: curr_cart.cartCustomList,
      cartItemList: curr_cart.cartItemList,
      cartDiscountList: newCartDiscountList,
      taxSettings: curr_cart.tax,
    });

    const new_cart: IndexedCartItem = {
      ...curr_cart,
      // TOOD: Upsert logic, currently this one only for update, if the array size empty then it remains empty
      grossAmount: grossAmount.toString(),
      netAmount: netAmount.toString(),
      tax,
      totalItems,
      cartDiscountList,
      updatedAt: formatDateToString(today),
      isOfflineCart: true,
    };

    await db.carts.put(new_cart);

    return {
      data: new_cart,
    };
  }
);

router.patch<
  CartAPIResponse,
  Omit<UpdateCartDIscountAPIRequest, "cartId" | "discountId">,
  Pick<UpdateCartDIscountAPIRequest, "cartId" | "discountId">
>(Endpoints.CART_DISCOUNT_BY_ID, async (req, _resp) => {
  const { amount, discountName, discountType } = req.body;

  const { cartId, discountId } = req.params;

  const curr_carts = await db.carts
    .where("id")
    .equals(cartId)
    .limit(1)
    .toArray();

  const curr_cart = curr_carts[0];

  const today = new Date();
  // TODO :
  // Handle if not found
  if (!curr_cart) {
    const placeholderResponse = getPlaceholderResponse({
      cartId,
    });

    return {
      data: placeholderResponse,
    };
  }

  const newCartDiscountList = curr_cart.cartDiscountList.map((c) => {
    if (c.id !== discountId) {
      return c;
    }

    return {
      ...c,
      amount,
      discountType: discountType as DiscountTypes,
      discountName,
    };
  });

  const {
    cartDiscountList,
    grossAmount,
    netAmount,
    tax,
    totalItems,
  } = recalculateOverallPrices({
    cartCustomItemList: curr_cart.cartCustomList,
    cartItemList: curr_cart.cartItemList,
    cartDiscountList: newCartDiscountList,
    taxSettings: curr_cart.tax,
  });

  const new_cart: IndexedCartItem = {
    ...curr_cart,
    // TOOD: Upsert logic, currently this one only for update, if the array size empty then it remains empty
    grossAmount: grossAmount.toString(),
    netAmount: netAmount.toString(),
    tax,
    totalItems,
    cartDiscountList,
    updatedAt: formatDateToString(today),
    isOfflineCart: true,
  };

  await db.carts.put(new_cart);

  return {
    data: new_cart,
  };
});

router.post<CartAPIResponse, CartAddCustomItemAPIRequest>(
  Endpoints.ADD_CUSTOM_ITEM_TO_CART,
  async (req, _resp) => {
    const { cartId, itemName, price } = req.body;

    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const curr_cart = curr_carts[0];

    const today = new Date();
    // TODO :
    // Handle if not found
    if (!curr_cart) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
      });

      return {
        data: placeholderResponse,
      };
    }

    const newCartCustomList = curr_cart.cartCustomList.concat([
      {
        createdAt: formatDateToString(today),
        id: uuidv4(),
        itemName,
        price,
        quantity: 1,
        totalPrice: price,
        updatedAt: formatDateToString(today),
      },
    ]);

    const {
      cartDiscountList,
      grossAmount,
      netAmount,
      tax,
      totalItems,
    } = recalculateOverallPrices({
      cartCustomItemList: newCartCustomList,
      cartItemList: curr_cart.cartItemList,
      cartDiscountList: curr_cart.cartDiscountList,
      taxSettings: curr_cart.tax,
    });

    const new_cart: IndexedCartItem = {
      ...curr_cart,
      // TOOD: Upsert logic, currently this one only for update, if the array size empty then it remains empty
      cartCustomList: newCartCustomList,
      cartDiscountList,
      grossAmount: grossAmount.toString(),
      netAmount: netAmount.toString(),
      tax,
      totalItems,
      isOfflineCart: true,
      updatedAt: formatDateToString(today),
    };

    await db.carts.put(new_cart);

    return {
      data: new_cart,
    };
  }
);

router.patch<
  CartAPIResponse,
  Pick<CartUpdateCustomItemAPIRequest, "cartId" | "customItemId">,
  Omit<CartUpdateCustomItemAPIRequest, "cartId" | "customItemId">
>(Endpoints.CART_CUSTOM_ITEM, async (req, _resp) => {
  const { cartId, customItemId } = req.body;
  const { itemName, price, quantity } = req.params;

  const curr_carts = await db.carts
    .where("id")
    .equals(cartId)
    .limit(1)
    .toArray();

  const curr_cart = curr_carts[0];

  const today = new Date();
  // TODO :
  // Handle if not found
  if (!curr_cart) {
    const placeholderResponse = getPlaceholderResponse({
      cartId,
    });

    return {
      data: placeholderResponse,
    };
  }

  const newCartCustomList = curr_cart.cartCustomList.map((c) => {
    if (c.id !== customItemId) {
      return c;
    }

    return {
      ...c,
      ...(quantity !== undefined && { quantity: Number(quantity) }),
      ...(price !== undefined && { price }),
      ...(itemName !== undefined && { itemName }),
    };
  });

  const {
    cartDiscountList,
    grossAmount,
    netAmount,
    tax,
    totalItems,
  } = recalculateOverallPrices({
    cartCustomItemList: newCartCustomList,
    cartItemList: curr_cart.cartItemList,
    cartDiscountList: curr_cart.cartDiscountList,
    taxSettings: curr_cart.tax,
  });

  const new_cart: IndexedCartItem = {
    ...curr_cart,
    // TOOD: Upsert logic, currently this one only for update, if the array size empty then it remains empty
    cartDiscountList,
    cartCustomList: newCartCustomList,
    grossAmount: grossAmount.toString(),
    netAmount: netAmount.toString(),
    tax,
    totalItems,
    isOfflineCart: true,
    updatedAt: formatDateToString(today),
  };

  await db.carts.put(new_cart);

  return {
    data: new_cart,
  };
});

router.delete<CartAPIResponse, void, CartClearCustomItemAPIRequest>(
  Endpoints.CART_CUSTOM_ITEM,
  async (req, _resp) => {
    const { cartId, customItemId } = req.params;

    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const curr_cart = curr_carts[0];

    const today = new Date();
    // TODO :
    // Handle if not found
    if (!curr_cart) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
      });

      return {
        data: placeholderResponse,
      };
    }

    const newCartCustomList = curr_cart.cartCustomList.filter((c) => {
      return c.id !== customItemId;
    });

    const {
      cartDiscountList,
      grossAmount,
      netAmount,
      tax,
      totalItems,
    } = recalculateOverallPrices({
      cartCustomItemList: newCartCustomList,
      cartItemList: curr_cart.cartItemList,
      cartDiscountList: curr_cart.cartDiscountList,
      taxSettings: curr_cart.tax,
    });

    const new_cart: IndexedCartItem = {
      ...curr_cart,
      // TOOD: Upsert logic, currently this one only for update, if the array size empty then it remains empty
      cartDiscountList,
      cartCustomList: newCartCustomList,
      grossAmount: grossAmount.toString(),
      totalItems,
      netAmount: netAmount.toString(),
      updatedAt: formatDateToString(today),
      tax,
      isOfflineCart: true,
    };

    await db.carts.put(new_cart);

    return {
      data: new_cart,
    };
  }
);

router.post<CartAPIResponse, CartActivateAPIRequest>(
  Endpoints.ACTIVATE_CART,
  async (req, _resp) => {
    const { cartId } = req.body;
    const curr_carts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const cartDetail = curr_carts[0];

    const new_cart: IndexedCartItem = {
      ...cartDetail,
      cartStatus: CartStatus.ACTIVE,
      isOfflineCart: true,
    };

    await db.carts.put(new_cart);

    return {
      data: new_cart,
    };
  }
);

router.get<GetLocalCartAPIResponse, void, void, GetLocalCartAPIRequest>(
  Endpoints.GET_LOCAL_CART,
  async (req) => {
    const { storeId, cartStatus } = req.query;

    const activeCarts = await db.carts
      .where("storeId")
      .equals(storeId)
      .and((c) => {
        if (cartStatus && cartStatus.includes(c.cartStatus)) {
          return true;
        }
        return false;
      })
      .toArray();

    return {
      data: activeCarts,
    };
  }
);

router.post<
  CartAPIResponse,
  CreateDiscountItemAPIRequest["payload"],
  CreateDiscountItemAPIRequest["routeParams"]
>(Endpoints.CREATE_DISCOUNT_ITEM, async (req) => {
  const { amount, discountType, discountName } = req.body;

  const { cartId, itemId } = req.params;

  const currCarts = await db.carts
    .where("id")
    .equals(cartId)
    .limit(1)
    .toArray();

  const cart = currCarts[0];

  if (!cart) {
    const placeholderResponse = getPlaceholderResponse({
      cartId,
      paymentStatus: PaymentStatus.UNPAID,
    });

    return {
      data: placeholderResponse,
    };
  }

  const today = new Date();
  const discountId = uuidv4();

  const cartItem = cart.cartItemList.find((item) => item.id === itemId);

  if (!cartItem) {
    const placeholderResponse = getPlaceholderResponse({
      cartId,
      paymentStatus: PaymentStatus.UNPAID,
    });

    return {
      data: placeholderResponse,
    };
  }

  let discountAmount = "0";

  switch (discountType) {
    case DiscountItemTypes.PERCENTAGE:
      discountAmount = (
        (Number(cartItem.totalPrice) * Number(amount)) /
        100
      ).toString();
      break;
    case DiscountItemTypes.FLAT:
      discountAmount = amount;
      break;
    case DiscountItemTypes.FREE:
      discountAmount = (Number(cartItem.price) * Number(amount)).toString();
      break;
    default:
      break;
  }

  const newItemDiscount: ItemType["itemDiscount"] = {
    id: discountId,
    amount,
    discountName: discountName ?? null,
    discountType: discountType,
    totalDiscount: {
      amount: discountAmount,
      currency: "IDR",
    },
    promotionId: null,
    promotionQuantityApplied: null,
  };

  cartItem.discount = discountAmount;
  cartItem.itemDiscount = newItemDiscount;
  cartItem.netPrice = (
    Number(cartItem.totalPrice) - Number(discountAmount)
  ).toString();

  cart.updatedObject = {
    ...newItemDiscount,
    createdAt: formatDateToString(today),
    updatedAt: formatDateToString(today),
  } as iDiscountItem & { updatedAt: string; createdAt: string };

  const { grossAmount, netAmount, tax } = recalculateOverallPrices({
    cartCustomItemList: cart.cartCustomList,
    cartItemList: cart.cartItemList,
    cartDiscountList: cart.cartDiscountList,
    taxSettings: cart.tax,
  });

  const newCart: IndexedCartItem = {
    ...cart,
    grossAmount: grossAmount.toString(),
    netAmount: netAmount.toString(),
    tax,
  };

  await db.carts.put(newCart);
  return {
    data: newCart,
  };
});

router.patch<
  CartAPIResponse,
  UpdateDiscountItemAPIRequest["payload"],
  UpdateDiscountItemAPIRequest["routeParams"]
>(Endpoints.MUTATE_DISCOUNT_ITEM, async (req) => {
  const { amount, discountType, discountName } = req.body;

  const { cartId, itemId, discountId } = req.params;

  const currCarts = await db.carts
    .where("id")
    .equals(cartId)
    .limit(1)
    .toArray();

  const cart = currCarts[0];

  if (!cart) {
    const placeholderResponse = getPlaceholderResponse({
      cartId,
      paymentStatus: PaymentStatus.UNPAID,
    });

    return {
      data: placeholderResponse,
    };
  }

  const cartItem = cart.cartItemList.find((item) => item.id === itemId);

  if (!cartItem) {
    const placeholderResponse = getPlaceholderResponse({
      cartId,
      paymentStatus: PaymentStatus.UNPAID,
    });

    return {
      data: placeholderResponse,
    };
  }

  if (cartItem.itemDiscount === null) {
    const placeholderResponse = getPlaceholderResponse({
      cartId,
      paymentStatus: PaymentStatus.UNPAID,
    });

    return {
      data: placeholderResponse,
    };
  }

  if (
    cartItem.itemDiscount !== null &&
    cartItem.itemDiscount.id !== discountId
  ) {
    const placeholderResponse = getPlaceholderResponse({
      cartId,
      paymentStatus: PaymentStatus.UNPAID,
    });

    return {
      data: placeholderResponse,
    };
  }

  const today = new Date();
  let discountAmount = "0";

  switch (discountType) {
    case DiscountItemTypes.PERCENTAGE:
      discountAmount = (
        (Number(cartItem.totalPrice) * Number(amount)) /
        100
      ).toString();
      break;
    case DiscountItemTypes.FLAT:
      discountAmount = amount;
      break;
    case DiscountItemTypes.FREE:
      discountAmount = (Number(cartItem.price) * Number(amount)).toString();
      break;
    default:
      break;
  }

  // Update Cart Item Discount
  cartItem.itemDiscount = {
    ...cartItem.itemDiscount,
    amount,
    discountName: discountName ?? null,
    discountType,
    totalDiscount: {
      ...cartItem.itemDiscount.totalDiscount,
      amount: discountAmount,
    },
  };

  cartItem.discount = discountAmount;
  cartItem.netPrice = (
    Number(cartItem.totalPrice) - Number(discountAmount)
  ).toString();

  cart.updatedObject = {
    ...cartItem.itemDiscount,
    createdAt: formatDateToString(today),
    updatedAt: formatDateToString(today),
  } as iDiscountItem & { updatedAt: string; createdAt: string };

  const { grossAmount, netAmount, tax } = recalculateOverallPrices({
    cartCustomItemList: cart.cartCustomList,
    cartItemList: cart.cartItemList,
    cartDiscountList: cart.cartDiscountList,
    taxSettings: cart.tax,
  });

  const updatedCart: IndexedCartItem = {
    ...cart,
    grossAmount: grossAmount.toString(),
    netAmount: netAmount.toString(),
    tax,
  };

  await db.carts.put(updatedCart);
  return {
    data: updatedCart,
  };
});

router.delete<CartAPIResponse, unknown, DeleteDiscountItemAPIRequest>(
  Endpoints.MUTATE_DISCOUNT_ITEM,
  async (req) => {
    const { cartId, itemId, discountId } = req.params;

    const currCarts = await db.carts
      .where("id")
      .equals(cartId)
      .limit(1)
      .toArray();

    const cart = currCarts[0];

    if (!cart) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
        paymentStatus: PaymentStatus.UNPAID,
      });

      return {
        data: placeholderResponse,
      };
    }

    const cartItem = cart.cartItemList.find((item) => item.id === itemId);

    if (!cartItem) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
        paymentStatus: PaymentStatus.UNPAID,
      });

      return {
        data: placeholderResponse,
      };
    }

    if (cartItem.itemDiscount === null) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
        paymentStatus: PaymentStatus.UNPAID,
      });

      return {
        data: placeholderResponse,
      };
    }

    if (
      cartItem.itemDiscount !== null &&
      cartItem.itemDiscount.id !== discountId
    ) {
      const placeholderResponse = getPlaceholderResponse({
        cartId,
        paymentStatus: PaymentStatus.UNPAID,
      });

      return {
        data: placeholderResponse,
      };
    }

    const deletedItemDiscountObject = cartItem.itemDiscount;

    cartItem.itemDiscount = null;
    cartItem.discount = "0.00";
    cartItem.netPrice = cartItem.totalPrice;

    const today = new Date();

    const { grossAmount, netAmount, tax } = recalculateOverallPrices({
      cartCustomItemList: cart.cartCustomList,
      cartItemList: cart.cartItemList,
      cartDiscountList: cart.cartDiscountList,
      taxSettings: cart.tax,
    });

    const updatedCart: IndexedCartItem = {
      ...cart,
      grossAmount: grossAmount.toString(),
      netAmount: netAmount.toString(),
      tax,
      updatedObject: {
        ...deletedItemDiscountObject,
        createdAt: formatDateToString(today),
        updatedAt: formatDateToString(today),
      },
    };

    await db.carts.put(updatedCart);
    return {
      data: updatedCart,
    };
  }
);
