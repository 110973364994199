import { theme } from "@gada-saas/web-ui";
import * as React from "react";
import { IconProps } from "./types";

const BoxIcon: React.VFC<IconProps> = ({
  width = "21",
  height = "21",
  fill = theme.palette.interface.neutral.light,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7678 2.23649C10.6253 2.1794 10.4664 2.1794 10.3239 2.23649L3.20271 5.08473L6.07124 6.23143L13.4144 3.29488L10.7678 2.23649ZM15.0205 3.93804L7.67733 6.87458L10.5459 8.02128L17.889 5.08473L15.0205 3.93804ZM18.8985 5.96653L11.1425 9.06894V18.5217L18.8985 15.4193V5.96773V5.96653ZM9.94924 18.5229V9.06774L2.19323 5.96653V15.4205L9.94924 18.5229ZM9.88123 1.12797C10.3079 0.957342 10.7838 0.957342 11.2105 1.12797L19.717 4.53107C19.8276 4.5754 19.9224 4.65182 19.9892 4.7505C20.056 4.84917 20.0917 4.96559 20.0917 5.08473V15.4205C20.0916 15.6589 20.02 15.8919 19.8862 16.0892C19.7524 16.2866 19.5626 16.4394 19.3412 16.5278L10.7678 19.9572C10.6253 20.0143 10.4664 20.0143 10.3239 19.9572L1.75174 16.5278C1.53009 16.4396 1.34 16.2869 1.206 16.0895C1.072 15.8921 1.00025 15.6591 1 15.4205V5.08473C1.00003 4.96559 1.03573 4.84917 1.10251 4.7505C1.16928 4.65182 1.26408 4.5754 1.37468 4.53107L9.88123 1.12797Z"
        fill={fill}
        stroke="#F3F3F3"
        strokeWidth="0.7"
      />
    </svg>
  );
};

export default BoxIcon;
