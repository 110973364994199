import { useCoachMarkSelector } from "@gada-saas/web-core";
import { pxToRem, theme } from "@gada-saas/web-ui";
import { TourProvider } from "@reactour/tour";
import * as React from "react";
import useStyles from "./styles";
import { CoachMarkProps } from "./types";
import useCoachMark from "./useCoachMark";

const CoachMark: React.VFC<CoachMarkProps> = ({ children }) => {
  const { tutorialName } = useCoachMarkSelector();
  const { doArrow } = useCoachMark();
  const classes = useStyles({ tutorialName });

  return (
    <TourProvider
      steps={[]}
      disableFocusLock
      disableKeyboardNavigation
      showPrevNextButtons={false}
      disableDotsNavigation
      scrollSmooth
      onClickMask={() => {}}
      disableInteraction
      maskClassName={classes.roundedEdges}
      styles={{
        popover: (base, state) => ({
          ...base,
          minWidth: pxToRem(350),
          borderRadius: pxToRem(8),
          background: theme.palette.interface.neutral.dark,
          padding: 0,
          ...doArrow(
            state?.position,
            state?.verticalAlign,
            state?.horizontalAlign
          ),
        }),
        dot: (base) => ({ ...base, display: "none" }),
        close: (base) => ({ ...base, display: "none" }),
        badge: (base) => ({ ...base, display: "none" }),
        controls: (base) => ({ ...base, display: "none" }),
      }}
    >
      {children}
    </TourProvider>
  );
};

export default CoachMark;
