import { iListApiResponse } from "../common/models";
import { TutorialCode } from "./models";

export enum coachMarkChains {
  NO_INVENTORY = "NO_INVENTORY",
  HAS_INVENTORY_POS = "HAS_INVENTORY_POS",
  HAS_INVENTORY_LIST = "HAS_INVENTORY_LIST",
  HAS_TRANSACTION = "HAS_TRANSACTION",
}

export interface CoachMarkInitialState {
  chainName?: coachMarkChains;
  tutorialName?: TutorialCode;
  totalTutorialSteps: number;
}

export type iGetAllTutorialsResponse = iListApiResponse<iGetAllTutorialsResponseData>;

export interface iGetAllTutorialsResponseData {
  progressList: Record<TutorialCode, boolean>;
}
