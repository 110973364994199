import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { globalResetAction } from "../common/redux/actions";
import { iUserStoreList, LinkStoresResponseData } from "../user";
import initialState from "./initialState";
import { InitialState } from "./types";

const syncStoresSlice = createSlice({
  name: "sync-stores",
  initialState: initialState,
  reducers: {
    updateLoading(state: InitialState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateSaasStore(
      state: InitialState,
      action: PayloadAction<iUserStoreList>
    ) {
      state.saasStore = action.payload;
    },
    updateMarketplaceStoreId(
      state: InitialState,
      action: PayloadAction<string>
    ) {
      state.marketplaceStoreId = action.payload;
    },
    updateHasStores(state: InitialState, { payload }: PayloadAction<boolean>) {
      state.hasStores = payload;
    },
    updateHasConflict(state: InitialState, action: PayloadAction<boolean>) {
      state.hasConflict = action.payload;
    },
    updateConflicts(
      state: InitialState,
      action: PayloadAction<LinkStoresResponseData[]>
    ) {
      state.conflicts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const {
  updateLoading,
  updateSaasStore,
  updateMarketplaceStoreId,
  updateHasStores,
  updateHasConflict,
  updateConflicts,
} = syncStoresSlice.actions;

export default syncStoresSlice.reducer;
