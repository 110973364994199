import { responseRouter as router } from "../../router";
import { Endpoints } from "../../../endpoints";
import {
  iGetInventoryListRequest,
  iGetInventoryListResponse,
} from "../../../../../inventory/list";

router.post<iGetInventoryListResponse, iGetInventoryListRequest>(
  Endpoints.GET_INVENTORY_LIST,
  async (_req, resp) => {
    // TODO : Implement save to local db
    return resp;
  }
);
