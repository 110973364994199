import { useActiveStoreState, useGetUserInfoQuery } from "@gada-saas/web-core";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { initialState as defaultActiveUserState } from "@gada-saas/web-core/user/menus/slice";
import isEqual from "lodash/isEqual";
import * as React from "react";

// Instead put this in `_app` we 'convert' it to component returning null component because
// we need access to userInfo data, whchi means we have to put it inside GadaProvider

export default function UserIdTracker() {
  const { data: userInfoData } = useGetUserInfoQuery();
  const activeStore = useActiveStoreState();
  const analytics = useSegmentAnalytics();

  React.useEffect(() => {
    if (userInfoData) {
      if (isEqual(activeStore, defaultActiveUserState)) return;

      const traits: Record<string, unknown> = {
        anonymousId: analytics.user().anonymousId,
        user_id: userInfoData.data.userInfo.id,
        phone_number: userInfoData.data.userInfo.phoneNumber,
        email: userInfoData.data.userInfo.email,
        created_at: userInfoData.data.userInfo.createdAt,
        store_id: activeStore.storeId,
        store_access_role: activeStore.storeRoleList
          .map((s) => s.name)
          .join("|"),
        store_access_position: activeStore.storeRoleList
          .map((s) => s.position)
          .join("|"),
      };

      analytics.identify(userInfoData.data.userInfo.id, traits);
    } else {
      const anonymousId = analytics.user().anonymousId;
      const traits: Record<string, unknown> = {
        anonymousId: anonymousId,
        user_id: null,
        phone_number: null,
        email: null,
        created_at: null,
        store_id: null,
        store_access_role: null,
        store_access_position: null,
      };
      analytics.identify(anonymousId, traits);
    }
  }, [userInfoData, analytics, activeStore]);

  return null;
}
