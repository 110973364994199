import { makeStyles } from "@material-ui/core";
import theme from "../../theme";
import { pxToRem } from "../../utils";
import { TextProps } from "./types";

export const useTextStyles = makeStyles({
  fontConfigs: (props: TextProps) => {
    return { ...getMuiCompatibleFontConfigs(props.variant) };
  },
  colors: {
    color: (props: TextProps) => {
      return getMuiCompatibleFontColor(props.ink);
    },
  },
});

function getMuiCompatibleFontConfigs(
  variant: TextProps["variant"]
): React.CSSProperties {
  switch (variant) {
    case "h1":
      return {
        fontFamily: "Inter-Bold",
        fontWeight: "bold",
        fontSize: pxToRem(32),
        lineHeight: pxToRem(48),
        letterSpacing: "-0.022em",
      };
    case "h2":
      return {
        fontFamily: "Inter-Bold",
        fontWeight: "bold",
        fontSize: pxToRem(24),
        lineHeight: pxToRem(36),
        letterSpacing: "0.0025em",
      };
    case "h3":
      return {
        fontFamily: "Inter-Bold",
        fontWeight: "bold",
        fontSize: pxToRem(20),
        lineHeight: pxToRem(28),
        letterSpacing: "-0.017em",
      };
    case "h4":
      return {
        fontFamily: "Inter-SemiBold",
        fontWeight: 600,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
        letterSpacing: "-0.014em",
      };
    case "h5":
      return {
        fontFamily: "Inter-SemiBold",
        // fontWeight: 600,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
        letterSpacing: "-0.006em",
      };
    case "button":
      return {
        fontFamily: "Inter-Regular",
        fontWeight: 600,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(16),
        letterSpacing: "0.0125em",
      };
    case "buttonSmall":
      return {
        fontFamily: "Inter-Regular",
        fontWeight: 600,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(14),
        letterSpacing: "0.0125em",
      };
    case "body2":
      return {
        fontFamily: "Inter-Regular",
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
        letterSpacing: "-0.006em",
      };
    case "pricing":
      return {
        fontFamily: "Inter-Bold",
        fontWeight: "bold",
        fontSize: pxToRem(16),
        lineHeight: pxToRem(16),
      };
    case "pricingSmall":
      return {
        fontFamily: "Inter-SemiBold",
        fontWeight: 600,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(14),
      };
    case "pricingSmallLight":
      return {
        fontFamily: "Inter-Regular",
        fontSize: pxToRem(14),
        lineHeight: pxToRem(14),
      };
    case "caption":
      return {
        fontFamily: "Inter-SemiBold",
        fontWeight: 600,
        fontSize: pxToRem(12),
        lineHeight: pxToRem(16),
      };
    case "captionLight":
      return {
        fontFamily: "Inter-Regular",
        fontSize: pxToRem(12),
        lineHeight: pxToRem(16),
      };
    case "label":
      return {
        fontFamily: "Inter-Regular",
        fontWeight: 600,
        fontSize: pxToRem(10),
        lineHeight: pxToRem(10),
        letterSpacing: "0.005em",
      };
    case "labelSemiBold":
      return {
        fontFamily: "Inter-SemiBold",
        fontWeight: 600,
        fontSize: pxToRem(10),
        lineHeight: pxToRem(10),
        letterSpacing: "0.005em",
      };
    // Make body 1 as default size
    case "body1":
    default:
      return {
        fontFamily: "Inter-Regular",
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
        letterSpacing: "-0.014em",
      };
  }
}

function getMuiCompatibleFontColor(ink: TextProps["ink"]) {
  switch (ink) {
    case "neutralPrimary": {
      return theme.palette.interface.neutral.primary;
    }
    case "neutralSecondary": {
      return theme.palette.interface.neutral.secondary;
    }
    case "neutralLight": {
      return theme.palette.interface.neutral.light;
    }
    case "neutralLighter": {
      return theme.palette.interface.neutral.lighter;
    }
    case "orangeDark": {
      return theme.palette.interface.orange.dark;
    }
    case "orangePrimary": {
      return theme.palette.interface.orange.primary;
    }
    case "orangeSecondary": {
      return theme.palette.interface.orange.secondary;
    }
    case "orangeLight": {
      return theme.palette.interface.orange.light;
    }
    case "tealDark": {
      return theme.palette.interface.teal.dark;
    }
    case "tealPrimary": {
      return theme.palette.interface.teal.primary;
    }
    case "tealSecondary": {
      return theme.palette.interface.teal.secondary;
    }
    case "tealLight": {
      return theme.palette.interface.teal.light;
    }
    case "tealLighter": {
      return theme.palette.interface.teal.lighter;
    }
    case "bluePrimary": {
      return theme.palette.interface.blue.primary;
    }
    case "blueSecondary": {
      return theme.palette.interface.blue.secondary;
    }
    case "redDark": {
      return theme.palette.interface.red.dark;
    }
    case "redPrimary": {
      return theme.palette.interface.red.primary;
    }
    case "redLight": {
      return theme.palette.interface.red.light;
    }
    case "greenPrimary": {
      return theme.palette.interface.green.primary;
    }
    case "brand": {
      return theme.palette.brand;
    }
    case "white": {
      return theme.palette.interface.white;
    }
    // Make neutral dark as default
    case "neutralDark":
    default: {
      return theme.palette.interface.neutral.dark;
    }
  }
}
