import router from "../router";
import { v4 as uuidv4 } from "uuid";
import { Endpoints } from "../../../../common/api/endpoints";
import {
  Customer,
  CustomerDetail,
  GetCustomersRequest,
  GetCustomersResponse,
  CustomerSummary,
  GetCustomerSummaryResponse,
  AddCustomerRequest,
  CustomerMutationResponse,
} from "../../../../crm";
import { db } from "../../../../inventory/offline";
import { formatDateToString } from "../utils";
import { iApiResponse } from "../../../models";

router.get<GetCustomersResponse, void, void, GetCustomersRequest>(
  Endpoints.CUSTOMER,
  async (req, _resp) => {
    const { page, pageSize, keyword, storeId } = req.query;
    let customerSummaryList: CustomerSummary[] = [];
    const customerList: Customer[] = [];

    if (keyword) {
      customerSummaryList = await db.customers
        .where("tokens")
        .equals(keyword)
        .and((c) => c.storeId === storeId)
        .offset((page - 1) * pageSize)
        .limit(pageSize)
        .toArray();
    } else {
      customerSummaryList = await db.customers
        .where("storeId")
        .equals(storeId)
        .offset((page - 1) * pageSize)
        .limit(pageSize)
        .toArray();
    }

    customerSummaryList.forEach((c) => {
      customerList.push({
        id: c.customerId,
        name: c.name,
        phoneNumber: c.phoneNumber,
        storeId: c.storeId,
        address: c.address,
        createdAt: c.createdAt,
        updatedAt: c.updatedAt,
      });
    });

    return {
      page,
      pageSize,
      total: customerList.length,
      data: customerList,
    };
  }
);

router.post<CustomerMutationResponse, AddCustomerRequest["payload"]>(
  Endpoints.CUSTOMER,
  async (req, _resp) => {
    const {
      name,
      phoneNumber,
      storeId,
      address,
      cumulativeMaxAmount,
      isMaxAmountActive,
      salesPersonId,
      topDuration,
    } = req.body;

    const localCustomerId = uuidv4();
    const localId = uuidv4();
    const today = new Date();
    await db.customers.add({
      createdAt: formatDateToString(today),
      creditCounter: 0,
      customerId: localCustomerId,
      id: localId,
      lastTransactionAt: "",
      name,
      noOfTransaction: 0,
      phoneNumber: phoneNumber,
      storeId,
      totalDebt: "0",
      totalTransaction: "0",
      updatedAt: formatDateToString(today),
      address,
      salesPerson: salesPersonId
        ? {
            id: salesPersonId,
            name: "",
            phoneNumber: "",
          }
        : undefined,
      top: {
        cumulativeMaxAmount: cumulativeMaxAmount ? cumulativeMaxAmount : "0",
        duration: topDuration ? topDuration : 0,
        isMaxAmountActive: isMaxAmountActive ? isMaxAmountActive : false,
      },
      isOffline: true,
    });

    return {
      data: {
        address,
        createdAt: formatDateToString(today),
        id: localCustomerId,
        name,
        phoneNumber,
        storeId,
        updatedAt: formatDateToString(today),
      },
    };
  }
);

router.get<
  iApiResponse<CustomerDetail>,
  void,
  { storeId: string; customerId: string }
>(Endpoints.CUSTOMER_BY_ID_STORE_ID, async (req, _resp) => {
  const { storeId, customerId } = req.params;

  const customers = await db.customers
    .where("customerId")
    .equals(customerId)
    .limit(1)
    .toArray();
  const currCustomer = customers[0];

  const today = new Date();

  if (!currCustomer) {
    return {
      data: {
        address: "",
        createdAt: formatDateToString(today),
        id: "",
        name: "",
        phoneNumber: "",
        // TODO : Ask backend to return salesPeroson information in download
        salesPerson: null,
        storeId: storeId,
        // TODO : Ask backend to return top information in download
        top: {
          cumulativeMaxAmount: "0",
          duration: 0,
          isMaxAmountActive: false,
        },
        updatedAt: formatDateToString(today),
      },
    };
  }

  return {
    data: {
      address: currCustomer.address,
      createdAt: formatDateToString(today),
      id: currCustomer.customerId,
      name: currCustomer.name,
      phoneNumber: currCustomer.phoneNumber,
      // TODO : Ask backend to return salesPeroson information in download
      salesPerson: null,
      storeId: storeId,
      // TODO : Ask backend to return top information in download
      top: {
        cumulativeMaxAmount: "0",
        duration: 0,
        isMaxAmountActive: false,
      },
      updatedAt: formatDateToString(today),
    },
  };
});

router.get<
  GetCustomerSummaryResponse,
  void,
  { storeId: string; customerId: string },
  void
>(Endpoints.GET_CUSTOMER_SUMMARY_BY_CUSTOMER_ID, async (req) => {
  const { storeId, customerId } = req.params;

  const customers = await db.customers
    .where("customerId")
    .equals(customerId)
    .and((c) => c.storeId === storeId)
    .limit(1)
    .toArray();

  const currCustomer = customers[0];

  return {
    data: currCustomer,
  };
});
