import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { globalResetAction } from "../../common/redux/actions";
import { ClosedBannersInitialState } from "./types";

const initialState: ClosedBannersInitialState = {
  banners: [],
};

const closedBannersSlice = createSlice({
  name: "closedBannerSlice",
  initialState,
  reducers: {
    updatedClosedBanners(
      state: ClosedBannersInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.banners.push(payload);
    },
    revertToInitialState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalResetAction, () => initialState);
  },
});

export const {
  updatedClosedBanners,
  revertToInitialState,
} = closedBannersSlice.actions;

export default closedBannersSlice;
