import * as React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

const GadaProvider: React.VFC<GadaProviderProps> = ({
  children,
}: GadaProviderProps) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

type GadaProviderProps = { children?: React.ReactNode };

export default GadaProvider;
