import { ButtonProps } from "@gada-saas/web-ui";
import * as React from "react";
import { useModalStateOptions } from "./types";

const useModalState = (options?: useModalStateOptions) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(
    options?.initialState ?? false
  );

  const toggleIsOpen: ButtonProps["onClick"] = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (options?.customToggle) {
        options.customToggle(event);
      } else {
        event.preventDefault();
        setIsOpen((isOpen) => !isOpen);
      }
    },
    [options]
  );

  return [isOpen, toggleIsOpen, setIsOpen] as const;
};

export default useModalState;
