import { useRouter } from "next/router";
import * as React from "react";
import { useQueryParams } from "../useQueryParams";
import { iUsePaginationProps } from "./types";

const usePagination = ({ total = 0, perPage = 20 }: iUsePaginationProps) => {
  const { page: queryPage } = useQueryParams();
  const pageNum = queryPage ? parseInt(queryPage) : 1;

  const [page, setPage] = React.useState<number>(pageNum);
  const pageRef = React.useRef<number>(page);
  const router = useRouter();

  const changePage = React.useCallback(
    (newPage: number) => {
      pageRef.current = newPage;
      setPage(newPage);
      router.push(
        router.asPath,
        {
          query: {
            page: newPage,
          },
        },
        { shallow: true }
      );
    },
    [router]
  );

  React.useEffect(() => {
    if (pageRef.current !== pageNum) {
      setPage(pageNum);
    }
  }, [pageNum]);

  return {
    page,
    total,
    perPage,
    setPage: changePage,
  };
};

export default usePagination;
