import { IconButton } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import * as React from "react";
import { CollapseIconProps } from "./types";
import { useStyles } from "./styles";

const CollapseIcon: React.VFC<CollapseIconProps> = ({
  isOpen,
  toggle,
  iconButtonProps,
}) => {
  const classes = useStyles({ isOpen });

  return (
    <IconButton
      className={classes.button}
      onClick={toggle}
      {...iconButtonProps}
    >
      <ExpandMore className={classes.icon} />
    </IconButton>
  );
};

export default CollapseIcon;
