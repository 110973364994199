import { iListApiResponse } from "../common/models";

export type GetAllTutorialsResponse = iListApiResponse<GetAllTutorialsResponseData>;

export interface GetAllTutorialsResponseData {
  progressList: Array<ProgressList>;
}

export interface ProgressList {
  tutorialCode: TutorialCode;
  isComplete: boolean;
}

export enum TutorialCode {
  FIRST_TUTORIAL = "FIRST_TUTORIAL",
  TUTORIAL_BANNER = "TUTORIAL_BANNER",
  CREATE_TRANSACTION = "CREATE_TRANSACTION",
  CREATE_INVENTORY = "CREATE_INVENTORY",
  ADD_INVENTORY_CHOOSE_SELLING_UNIT = "ADD_INVENTORY_CHOOSE_SELLING_UNIT",
  ADD_INVENTORY_CHOOSE_STOCK_UNIT = "ADD_INVENTORY_CHOOSE_STOCK_UNIT",
  ADD_INVENTORY_INTRO = "ADD_INVENTORY_INTRO",
  ADD_INVENTORY_SEARCH_PRODUCT = "ADD_INVENTORY_SEARCH_PRODUCT",
  INVENTORY_LIST_EDIT_STOCK_AMOUNT = "INVENTORY_LIST_EDIT_STOCK_AMOUNT",
  INVENTORY_LIST_RECOUNT_STOCK = "INVENTORY_LIST_RECOUNT_STOCK",
  INVENTORY_LIST_SEE_CURRENT_STOCK = "INVENTORY_LIST_SEE_CURRENT_STOCK",
  POS_INTRO = "POS_INTRO",
  POS_ORDER_COMPLETE = "POS_ORDER_COMPLETE",
  POS_SELECT_PAYMENT_METHOD = "POS_SELECT_PAYMENT_METHOD",
  REPORTING_AFTER_ORDER_COMPLETE = "REPORTING_AFTER_ORDER_COMPLETE",
}

export interface UpdateTutorialRequest {
  tutorialCodeList: Array<TutorialCode>;
  skip?: boolean;
}

export interface UpdateTutorialResponse {
  progressList: Array<ProgressList>;
}
